import { useAxios } from "../../../hooks/useAxios"
import { TicketPublicAdapters } from "./ticketspublic.adapter"

const TicketPublicServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/ticketing/ticket-url`, type: 'json', adapters: TicketPublicAdapters.GET })
    },

    POST: function () {

        return useAxios({ method: 'post', url: `/ticketing/ticket-form-link/mensaje`, type: 'json', adapters: TicketPublicAdapters.POST })
    },

    PATCH: function (idevento: string) {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/admin/ofertasempleo/${idevento}`, type: 'json', adapters: TicketPublicAdapters.PATCH })
    },

    PATCH_CHANGE_ESTADO: function (idevento: string) {

        return useAxios({ method: 'patch', url: `/inscripciones-bolsa/eventos/${idevento}`, type: 'json', adapters: TicketPublicAdapters.PATCH_CHANGE_ESTADO })
    },

    DELETE: function (idvisado: any) {

        return useAxios({ method: 'delete', url: `/inscripciones-bolsa/eventos/${idvisado}`, type: 'json', adapters: TicketPublicAdapters.DELETE })
    },
    
    DELETE_ADJUNTO: function () {

        return useAxios({ method: 'DELETE', url: `/bolsadetrabajo/admin/ofertasempleo/adjunto`, type: 'json', adapters: TicketPublicAdapters.DELETE_ADJUNTO })
    },
}

export {
    TicketPublicServices,
}
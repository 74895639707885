import React, { useState } from 'react'
import '../../../assetsTheme/css/layouts/LayoutMain2.scss'
import NavTop from './NavTop';
import Body from './Body';

interface ILayoutMain2 {
    children: any;
    label?: string;
}

const LayoutMain2 = ({ children, label }: ILayoutMain2) => {

    const [showNavLeft, setshowNavLeft] = useState(false)

    return (
        <section className='LayoutMain2 py-4'>
            {
                children?.map((childElement: any, indexchildElement: any) =>
                    addPropsToReactElement(childElement, { showNavLeft, setshowNavLeft, label, 'key': indexchildElement } )
                )
            }
        </section>
    )
}

function addPropsToReactElement(element: any, props: any) {
    if (React.isValidElement(element)) {
        return React.cloneElement(element, props)
    }
    return element
}


LayoutMain2.NavTop = NavTop
LayoutMain2.Body = Body

export default LayoutMain2



import { useAxios } from "../../../hooks/useAxios"
import { ParametrosTicketAdapters } from "./parametrosticket.adapter"

const ParametrosTicketServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/ticketing/tkparametros`, type: 'json', adapters: ParametrosTicketAdapters.GET })
    },
}

export {
    ParametrosTicketServices,
}
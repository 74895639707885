import { useEffect, useState } from 'react'
import { useLang } from '../../../../../language';
import queryString from 'query-string';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { getURLQuery, getValuesParams } from '../../../../../helpers/helpers';
import { ListMenu } from '../../../../../components/ListMenu/ListMenu';
import { DatosGenerales } from './DatosGenerales';
import { ListMenuRoutes } from '../../../../../components/ListMenu/ListMenuRoutes';
import { InformacionAdicional } from './InformacionAdicional';
import { PersonaColegiadoBTServices } from './controllers/personacolegiadobt.service';
import TablonOfertas from './TablonOfertas/Index';
import MisOfertas from './MisOfertas/Index';
import { Curriculums } from './Curriculums';

const Index = () => {

  const Lang = useLang()

  const [fetchPersonaBTGET, fetchPersonaBTGETData] = PersonaColegiadoBTServices.GET()

  useEffect(() => {

    fetchPersonaBTGET()

  }, []);
  // const [iDactive, setiDactive] = useState('datos_generales')

  const listMenu: any = {
    datos_generales: {
      title: Lang('DATOS_GENERALES'),
      link: 'datos-generales'
    },
    informacion_adicional: {
      title: Lang('INFORMACION_ADICIONAL'),
      link: 'informacion-adicional'
    },
    curriculums: {
      title: Lang('CURRICULUMS'),
      link: 'curriculums'
    },

  }
  const listMenu2: any = {

    tablon_ofertas: {
      title: Lang('TABLON_OFERTAS'),
      link: 'tablon-ofertas'
    },
    mis_ofertas: {
      title: Lang('MIS_OFERTAS'),
      link: 'mis-ofertas'
    },
  }
  return (
    <div className='row my-3'>

      {/* <div className="col-12">
        <h5 className='h6 mb-3 mt-2 border-bottom pb-3'>{Lang('GUIA_DE_PROFESIONALES')}</h5>
      </div> */}

      <div className="col-lg-3 mb-4">

        {/* <ListMenu values={listMenu} iDactive={iDactive} setiDactive={setiDactive} /> */}
        <ListMenuRoutes values={listMenu} />

        <div className="mt-4">
          <ListMenuRoutes values={listMenu2} />

        </div>
      </div>

      <div className="col-lg-9 animate__animated animate__fadeInUp">


        <Routes>

          <Route
            path='datos-generales'
            element={
              <DatosGenerales
                fetchPersonaBTGETData={fetchPersonaBTGETData}
                fetchPersonaBTGET={fetchPersonaBTGET}
              />
            }
          />

          <Route
            path='informacion-adicional'
            element={
              <InformacionAdicional
                fetchPersonaBTGETData={fetchPersonaBTGETData}
                fetchPersonaBTGET={fetchPersonaBTGET}
              />
            }
          />

          <Route
            path='tablon-ofertas'
            element={
              <TablonOfertas
              // fetchPersonaBTGETData={fetchPersonaBTGETData}
              // fetchPersonaBTGET={fetchPersonaBTGET}
              />
            }
          />
          <Route
            path='mis-ofertas'
            element={
              <MisOfertas
              // fetchPersonaBTGETData={fetchPersonaBTGETData}
              // fetchPersonaBTGET={fetchPersonaBTGET}
              />
            }
          />

          <Route
            path='curriculums'
            element={
              <Curriculums
              // fetchPersonaBTGETData={fetchPersonaBTGETData}
              // fetchPersonaBTGET={fetchPersonaBTGET}
              />
            }
          />

          <Route
            path="*"
            element={<Navigate to='datos-generales' replace />}
          />
        </Routes>

      </div>

    </div>
  )
}

export default Index;
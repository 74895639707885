import { faSave, faTrash, faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cloneDeep } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Form, InputGroup, Modal } from 'react-bootstrap'
import { useLang } from '../../../../../../language'
import { LoaderForm } from '../../../../../gestorContenidos/pages/Patrocinadores/LoaderForm'
import { InputText } from '../../../../../../components/Inputs'
import { InputSelect } from '../../../../../../components/Inputs/InputSelect'
import { ModalStatusServer } from '../../../../../../components/Modals/ModalStatusServer'
import { InputChecks } from '../../../../../../components/Inputs/InputChecks'
import { useValidator } from '../../../../../../hooks/useValidator'
import { useForm } from '../../../../../../hooks/useForm'
import { ButtonSpinner } from '../../../../../../components/ButtonSpinner/ButtonSpinner'
import { ParametrosServices } from '../../../../../../services/parametros.service'
import { isStatusSucess } from '../../../../../../helpers/helpers'
import { CVServices, CVsServices } from '../controllers/cv.service'
import { InputFile } from '../../../../../../components/Inputs/InputFile'
import { ListFiles } from '../../../../../../components/ListFiles/ListFiles'

export const ModalCurriculum = ({ data, handleClose, fetchMisCurriculumsGET }: any) => {

    // -------- variables 

    const Lang = useLang()

    const [valuesValidationEntidades, handleValidationEntidades, handleResetValidationEntidades] = useValidator({
        titulocv: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
    })

    const inputFileFichero: any = useRef(null)


    // -------- Formulario principal

    const [
        formPersonaEntidad, handleInputPersonaEntidad, handleFormPersonaEntidad, resetFormPersonaEntidad
    ] = useForm({
        preferido: [],

    })

    // -------------------- POST Persona Entidad

    const [fetchCurriculumPOST, fetchCurriculumPOSTData] = CVServices.POST()

    const handleSubmitCrear = () => {

        if (handleValidationEntidades(formPersonaEntidad)) {

            fetchCurriculumPOST({
                body: {
                    ...formPersonaEntidad,
                    archivo: inputFileFichero.current?.files?.[0],
                }
            })
        }
    }

    useEffect(() => {
        if (isStatusSucess(fetchCurriculumPOSTData.status)) {
            fetchMisCurriculumsGET()
            handleClose()
        }
    }, [fetchCurriculumPOSTData])


    // -------------------- PATCH Persona Entidad

    const [fetchCurriculumPATCH, fetchCurriculumPATCHData] = CVServices.PATCH()

    const handleSubmitPATCH = () => {

        if (handleValidationEntidades(formPersonaEntidad)) {

            fetchCurriculumPATCH({
                id: formPersonaEntidad.id,
                body: {
                    ...formPersonaEntidad,
                }
            })
        }
    }

    useEffect(() => {
        if (isStatusSucess(fetchCurriculumPATCHData.status)) {
            fetchMisCurriculumsGET()
            handleClose()
        }
    }, [fetchCurriculumPATCHData])

    
    // -------------------- PATCH Persona Entidad

    const [fetchCurriculumDELETE, fetchCurriculumDELETEData] = CVServices.DELETE()

    const handleSubmitDELETE = () => {

        fetchCurriculumDELETE({
            id: formPersonaEntidad.id,
        })
    }

    useEffect(() => {

        if (isStatusSucess(fetchCurriculumDELETEData.status)) {

            fetchMisCurriculumsGET()
            handleClose()
        }
        
    }, [fetchCurriculumDELETEData])

    // Acciones al Mostrar / Ocultar la Modal

    useEffect(() => {

        if (data.value) {

            if (data.item?.id) {

                // fetchPersonaEntidadGET()
                handleFormPersonaEntidad({
                    ...data.item,
                })
            }

        } else {
            resetFormPersonaEntidad()
            handleResetValidationEntidades()
        }

    }, [data.value])


    // Funciones helpers

    const handleChangeCheck = (e: any, name: string) => {

        let listaModulosTemp = cloneDeep(formPersonaEntidad[name])

        if (e.target.checked) {

            listaModulosTemp.push(e.target.name)

        } else {

            listaModulosTemp = listaModulosTemp.filter((item: any) => item !== e.target.name)
        }

        handleFormPersonaEntidad({
            ...formPersonaEntidad,
            [name]: listaModulosTemp
        })
    }

    return (
        <>
            <Modal show={data.value} onHide={handleClose} size='lg' id='ModalRegistro'>
                <Modal.Header className='border-bottom-0 py-4'>
                    <Modal.Title className='h5'>
                        {data.action === 'EDIT' ? Lang('AGREGAR_CURRICULUM') : Lang('EDITAR_CURRICULUM')}
                    </Modal.Title>
                    <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleClose}></button>
                </Modal.Header>

                <Modal.Body className='px-4 py-0'>
                    <Form className='row'>

                        <Form.Group className="mb-3 col-12">
                            <InputText
                                label={Lang('NOMBRE') + ' CV: *'}
                                name='titulocv'
                                value={formPersonaEntidad.titulocv}
                                onChange={handleInputPersonaEntidad}
                                validation={valuesValidationEntidades}
                                readonly={data.action === 'EDIT'}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3 col-lg-8">
                            <Form.Label className='fw-500'>{Lang('DOCUMENTO')}:</Form.Label>
                            {
                                data.action === 'CREATE' ?
                                    <>
                                        <InputFile
                                            name='file'
                                            onChange={handleInputPersonaEntidad}
                                            referencia={inputFileFichero}
                                        />
                                    </>
                                    :
                                    <>
                                        <ListFiles
                                            isBlob={false}
                                            list={[
                                                {
                                                    titulo: Lang('DOCUMENTO'),
                                                    url: formPersonaEntidad.pathcv,
                                                }
                                            ]}
                                        // onClickDelete={() => handleDeleteAdjunto()}
                                        />
                                    </>

                            }

                        </Form.Group>

                        <Form.Group className="my-2 col">
                            <InputChecks
                                name='preferido'
                                value={formPersonaEntidad.preferido}
                                values={[
                                    {
                                        id: 'preferido',
                                        nombre: Lang('MARCAR_DOCUMENTO_PREFERIDO')
                                    }
                                ]}
                                classNameContainer='d-block me-0 mb-1 mt-4 pt-1'
                                onChange={(e: any) => handleChangeCheck(e, 'preferido')}
                            // fetchData={nuevo ? fetchPersonaPOSTData : fetchPerfilesPATCHData}
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer className='border-0 d-flex justify-content-between'>
                    {
                        data.action === 'CREATE' ?
                            <>
                                <div></div>
                                <ButtonSpinner
                                    variant="secondary" type="submit" className="me-2"
                                    handleSubmit={handleSubmitCrear} fetchDataLoading={[fetchCurriculumPOSTData.loading]}
                                    value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} />}
                                />
                            </>
                            :
                            <>
                                <ButtonSpinner
                                    variant="danger" type="submit" className="me-2"
                                    handleSubmit={handleSubmitDELETE} fetchDataLoading={[fetchCurriculumDELETEData.loading]}
                                    value={Lang('ELIMINAR')} icon={<FontAwesomeIcon icon={faTrash} />}
                                />
                                <ButtonSpinner
                                    variant="secondary" type="submit" className="me-2"
                                    handleSubmit={handleSubmitPATCH} fetchDataLoading={[fetchCurriculumPATCHData.loading]}
                                    value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} />}
                                />
                            </>
                    }

                </Modal.Footer>
            </Modal>

            <ModalStatusServer fetchData={fetchCurriculumPOSTData} />

            <ModalStatusServer fetchData={fetchCurriculumPATCHData} />

            <ModalStatusServer fetchData={fetchCurriculumDELETEData} />

        </>
    )
}

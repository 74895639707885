
import { AuthAdapters } from "../adapters/auth.adapter"
import { useAxios } from "../hooks/useAxios"

const AuthServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/auth/current-user-modulos`, type: 'json', adapters: AuthAdapters.GET })
    },

    POST_INICIAR_SESION: function () {

        return useAxios({ method: 'post', url: `/auth/login`, type: 'json', adapters: AuthAdapters.POST_INICIAR_SESION })
    },

    POST_REINICIAR_CLAVE: function () {

        return useAxios({ method: 'post', url: `/auth/reiniciarPassword`, type: 'json', adapters: AuthAdapters.POST_REINICIAR_CLAVE })
    },
}

export {
    AuthServices,
}
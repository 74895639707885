import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { BreadcrumbLine } from '../../../../../components/BreadcrumbLine/BreadcrumbLine';
import FilterAdvanced2 from '../../../../../components/Filter/FilterAdvanced/FilterAdvanced2';
import { getURLQuery, getValuesParams } from '../../../../../helpers/helpers';
import { useLang } from '../../../../../language';
import queryString from 'query-string';
import { useForm } from '../../../../../hooks/useForm';
import { FilterFormHead } from './Filters/FilterFormHead';
import { FilterForm } from './Filters/FilterForm';
import { ParametrosServices } from '../../../../../services/parametros.service';
import { TablePersonas } from './TablePersonas';
import { PersonasBTServices } from './controllers/personasbt.service';

const Personas = () => {

  // Variables iniciales

  const Lang = useLang()

  const location = useLocation()

  const BreadcrumbLineItems = [
    {
      title: Lang('PERSONAS'),
      link: '/'
    }
  ]

  const [fetchFacturasGET, fetchFacturasGETData] = PersonasBTServices.GET()

  const defaultValuesSearchAlways = {
    magic: '',
    estado: '',
    idtipoevento: '',
  }

  const defaultValuesSearchSimple = {
    page: 1,
    limit: '',
    order: '',

  }

  const defaultValuesSearchAdvanced = {
    page: 1,
    limit: '',
    order: '',

    fechainicioinscripcion: '',
    fechafininscripcion: '',
  }

  const defaultValuesSearch = { ...defaultValuesSearchSimple, ...defaultValuesSearchAdvanced, ...defaultValuesSearchAlways }

  const params = queryString.parse(location.search);

  const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
    page: Number(params.page) || defaultValuesSearch.page,
    limit: params.limit || defaultValuesSearch.limit,
    magic: params.magic || defaultValuesSearch.magic,
    order: params.order || defaultValuesSearch.order,

    idtipoevento: params.idtipoevento || defaultValuesSearch.idtipoevento,

    fechainicioinscripcion: params.fechainicioinscripcion || defaultValuesSearch.fechainicioinscripcion,
    fechafininscripcion: params.fechafininscripcion || defaultValuesSearch.fechafininscripcion,
    estado: params.estado || defaultValuesSearch.estado,
  });

  const handleSearch = (e: any, formValues: any) => {

    if (e) {
      e.preventDefault();
    }

    // Obtener todos los valores existentes en el form de values Search
    const urlParams = getValuesParams(formValues)

    // Convertir esos valores del objeto a url amigable
    const query = getURLQuery(urlParams)

    // history.push(`?${query}`);
    window.history.replaceState(null, '', `?${query}`);

    if (formValues.page !== 1) {

      handleFormChange({
        ...formValues,
        page: 1
      });

    } else {

      let formValuesTemp = { ...formValues }

      Object.keys(formValuesTemp).forEach((k) => formValuesTemp[k] === '' && delete formValuesTemp[k]);

      fetchFacturasGET({
        params: {
          ...formValuesTemp
        }
      })
    }
  }

  useEffect(() => {

    // Obtener todos los valores existentes en el form de values Search
    const urlParams = getValuesParams(formValuesSearch)

    // Convertir esos valores del objeto a url amigable
    const query = getURLQuery(urlParams)

    window.history.replaceState(null, '', `?${query}`);

    fetchFacturasGET({
      params: {
        ...urlParams
      }
    })


  }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit])


  const [fetchParamsMultiple, fetchParamsMultipleData] = ParametrosServices.GET()

  useEffect(() => {

    fetchParamsMultiple({
      params: {
        tiposeventos: true
      }
    })
  }, [])

  return (

    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className='d-flex justify-content-between align-items-center mb-1 Nav-top-DAPcon'>
            <BreadcrumbLine BreadcrumbLineItems={BreadcrumbLineItems} />
          </div>
        </div>

        <div className="col-12">

          <div className="mb-4">

            <FilterAdvanced2
              handleSearch={handleSearch}
              formValuesSearch={formValuesSearch}
              handleInputChangeSearch={handleInputChangeSearch}
              fetchData={fetchFacturasGETData}
              resetformValuesSearch={resetformValuesSearch}

              defaultValuesSearchSimple={defaultValuesSearchSimple}
              defaultValuesSearchAdvanced={defaultValuesSearchAdvanced}
              defaultValuesSearchAlways={defaultValuesSearchAlways}
              handleFormChange={handleFormChange}
            >

              <FilterAdvanced2.Head label={Lang('BUSCADOR')}>

                <FilterFormHead fetchParamsMultipleData={fetchParamsMultipleData} />

              </FilterAdvanced2.Head>

              <FilterAdvanced2.Body>

                <FilterForm />

              </FilterAdvanced2.Body>

            </FilterAdvanced2>

          </div>


          <div className=" animate__animated animate__fadeInUp">

            <TablePersonas
              data={fetchFacturasGETData.data?.items}
              loading={fetchFacturasGETData.loading}
              total={fetchFacturasGETData.data?.total}
              perPage={fetchFacturasGETData.data?.per_page}

              formValuesSearch={formValuesSearch}
              handleFormChange={handleFormChange}
              handleInputChangeSearch={handleInputChangeSearch}

              urlParams={getValuesParams(formValuesSearch)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Personas;
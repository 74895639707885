import { receiveCheck, receiveFecha, receiveFechaFormat2, sendCheck } from "../../../../../adapters/helpers"

var misFacturasAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                var data = {
                    id: item.idfactura || '',
                    numerofactura: item.numerofactura ? item.numerofactura : item.numeroproforma,
                    numeroproforma: item.numeroproforma || '',
                    abona: item.abona ? (item.abona === 'E' ? item.entidad?.nombre : item.persona?.nombre_completo) : '',
                    abonaKey: item.abona || '',
                    fechafactura: receiveFechaFormat2(receiveFecha(item.fechafactura)),
                    estado: item.estado || '',
                    importe: item.importe || '',
                    importe__label: item.importe ? (item.importe + ' €') : '',
                    importe_base: item.importe_base || '',
                    importe_iva: item.importe_iva || '',
                    importe_irpf: item.importe_irpf || '',

                    idservicio: item.servicio?.idservicio || '',
                    servicio: item.servicio?.servicio || '',

                    identidad: item.entidad?.identidad || '',
                    entidad: item.entidad?.nombrecomercial || '',

                    identidadsede: item.entidad_sede?.identidadsede || '',
                    entidadsede: item.entidad_sede?.nombresede || '',

                    idpersona: item.persona?.idpersona || '',
                    persona: `${item.persona?.nombre || ''} ${item.persona?.apellido1 || ''} ${item.persona?.apellido2 || ''} ` || '',
                    identidadpropia: item.entidad_propia?.identidad || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },

    GET_EXCEL: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    headers: item.headers
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}
                

                const data = {
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idperitacion: item.idperitacion || '',
                    fechaalta: item.fechaalta || '',
                    fechabaja: item.fechabaja || '',
                    publicaweb: sendCheck(item.publicaweb),
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },
    
    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idperitacion: item.idperitacion || '',
                    fechaalta: item.fechaalta || '',
                    fechabaja: item.fechabaja || '',
                    publicaweb: item.publicaweb,
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message || '',
                    status: item.status || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                // 

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },

    },
    
}
export {
    misFacturasAdapters
}
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Index.css'

export const UploadCard = ({ width = 'auto', height = 'auto', label = '', onClick, classname }: any) => {
    return (
        <div className={`UploadCard d-flex flex-column ${classname}`} style={{ 'width': width, 'height': 'auto' }} onClick={onClick}>
            <div className='UploadCard__item d-inline-flex flex-column justify-content-center align-items-center' style={{ 'width': '100%', 'height': height }}>
                <FontAwesomeIcon className='fa-2x mb-2' icon={faPlus} />
                {label}
            </div>
        </div>
    )
}

import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ButtonSpinner } from "../../../../../../components/ButtonSpinner/ButtonSpinner";
import { InputChecks } from "../../../../../../components/Inputs/InputChecks";
import { ModalStatusServer } from "../../../../../../components/Modals/ModalStatusServer";
import { isStatusSucess } from "../../../../../../helpers/helpers";
import { useForm } from "../../../../../../hooks/useForm";
import { useLang } from "../../../../../../language";
import { InputSelect } from "../../../../../../components/Inputs/InputSelect";
import { ParametrosServices } from "../../../../../../services/parametros.service";
import { PersonaColegiadoBTServices } from "../controllers/personacolegiadobt.service";
import { cloneDeep } from "lodash";
import { Placeholder } from "../../../../../../components/Placeholder/Placeholder";

export const DatosGenerales = ({ fetchPersonaBTGETData, fetchPersonaBTGET }: any) => {

    const Lang = useLang()

    const [fetchMultipleParamBolsa, fetchMultipleParamBolsaData] = ParametrosServices.GET_BOLSA_TRABAJO()

    useEffect(() => {

        fetchMultipleParamBolsa({
            params: {
                funciones: true,
                tiposContratos: true,
                estadooferta: true,
                salarios: true,
                horarios: true,
                order: 'asc',
            }
        })

    }, [])


    const [formDatosGenerales, handleInputDatosGenerales, handleFormDatosGenerales, resetDatosGenerales] = useForm({
        habilitarofertas: [],
        recibirnotificacionesmail: [],
    })


    useEffect(() => {

        if (isStatusSucess(fetchPersonaBTGETData.status)) {

            handleFormDatosGenerales({
                ...fetchPersonaBTGETData.data
            })
        }

    }, [fetchPersonaBTGETData]);


    // -------------- PATCH persona

    const [fetchPersonaBTPATCH, fetchPersonaBTPATCHData] = PersonaColegiadoBTServices.PATCH()

    const handleSubmitPatch = () => {

        fetchPersonaBTPATCH({
            body: formDatosGenerales
        })
    }

    useEffect(() => {

        if (isStatusSucess(fetchPersonaBTPATCHData.status)) {
            resetDatosGenerales()
            fetchPersonaBTGET()
        }

    }, [fetchPersonaBTPATCHData]);



    // Funciones helpers

    const handleChangeCheck = (e: any, name: string) => {

        let listaModulosTemp = cloneDeep(formDatosGenerales[name])

        if (e.target.checked) {

            listaModulosTemp.push(e.target.name)

        } else {

            listaModulosTemp = listaModulosTemp.filter((item: any) => item !== e.target.name)
        }

        handleFormDatosGenerales({
            ...formDatosGenerales,
            [name]: listaModulosTemp
        })
    }

    return (
        <div className="card p-3">

            <h5 className='h6 mb-3 mt-2 border-bottom pb-3'>{Lang('DATOS_GENERALES')}</h5>

            {
                (fetchPersonaBTGETData.loading || fetchMultipleParamBolsaData.loading) ?
                    <LoaderForm />
                    :
                    <>
                        <div className="row">

                            <Form.Group className="mb-3 col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                                <InputSelect
                                    label={`${Lang('TIPO_CONTRATO')}:`}
                                    name='idtipocontrato'
                                    value={formDatosGenerales.idtipocontrato}
                                    values={fetchMultipleParamBolsaData.data?.['tiposContratos'] || []}
                                    onChange={handleInputDatosGenerales}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3 col-12 col-sm-6 col-md-5 col-lg-5 col-xl-4">
                                <InputSelect
                                    label={`${Lang('SALARIO')}:`}
                                    name='idsalario'
                                    value={formDatosGenerales.idsalario}
                                    values={fetchMultipleParamBolsaData.data?.['salarios'] || []}
                                    onChange={handleInputDatosGenerales}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3 col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                                <InputSelect
                                    label={`${Lang('HORARIO')}:`}
                                    name='idhorario'
                                    value={formDatosGenerales.idhorario}
                                    values={fetchMultipleParamBolsaData.data?.['horarios'] || []}
                                    onChange={handleInputDatosGenerales}
                                />
                            </Form.Group>

                            <div className="col-12"></div>

                            <Form.Group className="my-2 col flex-grow-0 text-nowrap me-2">
                                <InputChecks
                                    name='habilitarofertas'
                                    value={formDatosGenerales.habilitarofertas}
                                    values={[
                                        {
                                            id: 'habilitarofertas',
                                            nombre: Lang('HABILITAR_OFERTAS')
                                        }
                                    ]}
                                    classNameContainer='d-block me-0 mb-1'
                                    onChange={(e: any) => handleChangeCheck(e, 'habilitarofertas')}
                                // fetchData={nuevo ? fetchPersonaPOSTData : fetchPerfilesPATCHData}
                                />
                            </Form.Group>

                            <Form.Group className="my-2 col flex-grow-0 text-nowrap">
                                <InputChecks
                                    name='recibirnotificacionesmail'
                                    value={formDatosGenerales.recibirnotificacionesmail}
                                    values={[
                                        {
                                            id: 'recibirnotificacionesmail',
                                            nombre: Lang('RECIBIR_NOTIFICACIONES_EMAIL')
                                        }
                                    ]}
                                    classNameContainer='d-block me-0 mb-1'
                                    onChange={(e: any) => handleChangeCheck(e, 'recibirnotificacionesmail')}
                                // fetchData={nuevo ? fetchPersonaPOSTData : fetchPerfilesPATCHData}
                                />
                            </Form.Group>

                            <div className='col-12 mt-2 pt-3 d-flex justify-content-between border-top'>
                                <div></div>
                                <ButtonSpinner
                                    variant="secondary" type="submit" className="ms-auto"
                                    handleSubmit={handleSubmitPatch} fetchDataLoading={[fetchPersonaBTPATCHData.loading]}
                                    value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                />
                            </div>
                        </div>
                    </>
            }

            <ModalStatusServer fetchData={fetchPersonaBTPATCHData} />
        </div>
    );
};


const LoaderForm = () => {
    return (

        <div className='row'>
            <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <Placeholder className='py-2' height='2rem' />
            </div>
            <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <Placeholder className='py-2' height='2rem' />
            </div>
            <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <Placeholder className='py-2' height='2rem' />
            </div>
            <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <Placeholder className='py-2' height='2rem' />
            </div>
            <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <Placeholder className='py-2' height='2rem' />
            </div>
            <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <Placeholder className='py-2' height='2rem' />
            </div>
            <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <Placeholder className='py-2' height='2rem' />
            </div>
            <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <Placeholder className='py-2' height='2rem' />
            </div>
            <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <Placeholder className='py-2' height='2rem' />
            </div>
            <div className='col-12 mt-0 pt-3 d-flex justify-content-end border-top'>
                <Placeholder className='py-0 me-0' height='2rem' width='5rem' />
            </div>
        </div>
    )
}

import { useAxios } from "../../../../../../hooks/useAxios"
import { TicketClientAdapters, TicketClientMensajeAdapters, TicketsClientAdapters, TicketsClientMensajesAdapters } from "./tickets.adapter"

const TicketsClientServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/ticketing/client/tickets`, type: 'json', adapters: TicketsClientAdapters.GET })
    },

    GET_EXCEL: function () {

        return useAxios({ method: 'get', url: `/visados/admin/visados`, type: 'blob', adapters: TicketsClientAdapters.GET_EXCEL })
    },

}

const TicketClientServices = {

    GET: function (id: string) {

        return useAxios({ method: 'get', url: `/ticketing/client/${id}/detalle`, type: 'json', adapters: TicketClientAdapters.GET })
    },

    POST: function () {

        return useAxios({ method: 'post', url: `/ticketing/client/tickets/create`, type: 'json', adapters: TicketClientAdapters.POST })
    },

    PATCH: function (idevento: string) {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/admin/ofertasempleo/${idevento}`, type: 'json', adapters: TicketClientAdapters.PATCH })
    },

    PATCH_CHANGE_ESTADO: function (idevento: string) {

        return useAxios({ method: 'patch', url: `/inscripciones-bolsa/eventos/${idevento}`, type: 'json', adapters: TicketClientAdapters.PATCH_CHANGE_ESTADO })
    },

    DELETE: function (idvisado: any) {

        return useAxios({ method: 'delete', url: `/inscripciones-bolsa/eventos/${idvisado}`, type: 'json', adapters: TicketClientAdapters.DELETE })
    },
    
    DELETE_ADJUNTO: function () {

        return useAxios({ method: 'DELETE', url: `/bolsadetrabajo/admin/ofertasempleo/adjunto`, type: 'json', adapters: TicketClientAdapters.DELETE_ADJUNTO })
    },
}

const TicketsClientMensajesServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/ticketing/tickets-mensajes`, type: 'json', adapters: TicketsClientMensajesAdapters.GET })
    },
}

const TicketClientMensajeServices = {

    GET: function (id: string) {

        return useAxios({ method: 'get', url: `/ticketing/tickets-mensajes/${id}`, type: 'json', adapters: TicketClientMensajeAdapters.GET })
    },

    POST: function () {

        return useAxios({ method: 'POST', url: `/ticketing/client/tickets-mensajes/create`, type: 'json', adapters: TicketClientMensajeAdapters.POST })
    },
}

export {
    TicketsClientServices,
    TicketClientServices,
    TicketsClientMensajesServices,
    TicketClientMensajeServices
}
import { useEffect, useRef, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useModalData } from '../../../../../../hooks/useModalData'
import { useLang } from '../../../../../../language'
import { ButtonSpinner } from '../../../../../../components/ButtonSpinner/ButtonSpinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { InputText } from '../../../../../../components/Inputs'
import { ModalStatusServer } from '../../../../../../components/Modals/ModalStatusServer'
import { useForm } from '../../../../../../hooks/useForm'
import { isStatusSucess } from '../../../../../../helpers/helpers'
import { InputSelect } from '../../../../../../components/Inputs/InputSelect'
import { InputChecks } from '../../../../../../components/Inputs/InputChecks'
import { cloneDeep } from 'lodash'
import { InputFile } from '../../../../../../components/Inputs/InputFile'
import { LoaderFormParametro } from '../../../../admin/pages/Parametros/LoaderFormParametro'
import { CVsServices } from '../controllers/cv.service'
import { InputRadios } from '../../../../../../components/Inputs/InputRadios'
import { MisInscripcionesServices } from '../controllers/inscripciones.service'
import { sendCheck } from '../../../../../../adapters/helpers'

export const ModalEditarInscripcion = ({ data, handleClose, fetchMisInscripcionesGET, urlParams,
    fetchMultipleParamBolsaData }: any) => {

    const Lang = useLang()

    const inputFileFichero: any = useRef(null)

    // Modal Crear visado

    const [dataFilterPersona, handleShowFilterPersona, handleCloseFilterPersona] = useModalData()

    const [formInscripcion, handleInputInscripcion, handleFormInscripcion, resetFormInscripcion] = useForm({
        idpersona: '',
        interes: [],
        notificarempresa: [],
        preferido: [],
        radio_cv: 'false',
    })

    const [filterPersona, setFilterPersona] = useState({
        id: '',
        nombre: ''
    })

    useEffect(() => {

        const obj = {
            target: {
                name: 'idpersona',
                value: filterPersona.id
            }
        }

        handleInputInscripcion(obj)

    }, [filterPersona])

    useEffect(() => {
        if (!formInscripcion.idpersona) {
            setFilterPersona({
                id: '',
                nombre: ''
            })
        }
    }, [formInscripcion.idpersona])


    // --------------- POST Agregar persona

    const [fetchAgregarPersonaPATCH, fetchAgregarPersonaPATCHData] = MisInscripcionesServices.PATCH()

    const handleSubmitEditarPersonaPATCH = () => {

        let bodyTemp: any = {
            // interes: sendCheck(formInscripcion.interes),
            interes: true,
            titulocv: formInscripcion.titulocv,
            preferido: sendCheck(formInscripcion.preferido),
            archivo: inputFileFichero.current?.files?.[0],
            idcv: formInscripcion.idcv,
        }

        if (formInscripcion.radio_cv === 'true') {

            delete bodyTemp.idcv

        } else {
            delete bodyTemp.titulocv
            delete bodyTemp.archivo
            delete bodyTemp.preferido
        }

        fetchAgregarPersonaPATCH({
            id: formInscripcion.idofertaempleo + '/update',
            body: bodyTemp
        })
    }

    useEffect(() => {

        if (isStatusSucess(fetchAgregarPersonaPATCHData.status)) {

            fetchMisInscripcionesGET({
                params: {
                    ...urlParams
                }
            })
            handleClose()
        }

    }, [fetchAgregarPersonaPATCHData]);


    // Funciones helpers

    const handleChangeCheck = (e: any, name: string) => {

        let listaModulosTemp = cloneDeep(formInscripcion[name])

        if (e.target.checked) {

            listaModulosTemp.push(e.target.name)

        } else {

            listaModulosTemp = listaModulosTemp.filter((item: any) => item !== e.target.name)
        }

        handleFormInscripcion({
            ...formInscripcion,
            [name]: listaModulosTemp
        })
    }

    // --------------- DELETE Agregar persona

    const [fetchAgregarPersonaDELETE, fetchAgregarPersonaDELETEData] = MisInscripcionesServices.DELETE()

    const handleSubmitEditarPersonaDELETE = () => {

        fetchAgregarPersonaDELETE({
            id: formInscripcion.idofertaempleo + '/delete',
        })
    }

    useEffect(() => {

        if (isStatusSucess(fetchAgregarPersonaDELETEData.status)) {

            fetchMisInscripcionesGET({
                params: {
                    ...urlParams
                }
            })
            handleClose()
        }

    }, [fetchAgregarPersonaDELETEData]);

    const [fetchCVsPersonaGET, fetchCVsPersonaGETData] = CVsServices.GET()

    useEffect(() => {

        if (data.value) {

            

            handleFormInscripcion({
                ...data.payload?.form,
                idpersona: data.payload?.form?.id,
                radio_cv: 'false',
            })

            fetchCVsPersonaGET()

        } else {

            resetFormInscripcion()
        }

    }, [data.value])

    return (
        <>
            <Modal show={data.value} onHide={handleClose}>
                <Modal.Header className='border-bottom-0 py-4'>
                    <Modal.Title className='h5'>{Lang('EDITAR_INSCRIPCION')}</Modal.Title>
                    <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleClose}></button>
                </Modal.Header>
                {
                    fetchCVsPersonaGETData?.loading ?
                        <LoaderFormParametro />
                        :
                        <>
                            <Modal.Body className='px-4 py-0'>

                                <div className="row">

                                    <Form.Group className="mb-3 col-12">
                                        <InputRadios
                                            className=''
                                            name='radio_cv'
                                            value={formInscripcion.radio_cv}
                                            values={[
                                                { id: 'false', nombre: `${Lang('SELECCIONAR_CV_EXISTENTE')}:` },
                                                { id: 'true', nombre: `${Lang('SUBIR_NUEVO_CV')}:` },
                                            ]}
                                            onChange={handleInputInscripcion}

                                        />
                                    </Form.Group>

                                    {
                                        formInscripcion.radio_cv === 'true' ?

                                            <div className="col-12">

                                                <div className="card p-2 p-lg-3">

                                                    <div className="row">

                                                        <Form.Group className="mb-3 col-12">
                                                            <InputText
                                                                label={Lang('NOMBRE') + ' CV:'}
                                                                name='titulocv'
                                                                value={formInscripcion.titulocv}
                                                                onChange={handleInputInscripcion}
                                                            />
                                                        </Form.Group>

                                                        <Form.Group className="mb-3 col-12">
                                                            <Form.Label className='fw-500'>{Lang('DOCUMENTO')}:</Form.Label>
                                                            <InputFile
                                                                name='file'
                                                                onChange={handleInputInscripcion}
                                                                referencia={inputFileFichero}
                                                            />
                                                        </Form.Group>

                                                        <Form.Group className="my-2 col">
                                                            <InputChecks
                                                                name='preferido'
                                                                value={formInscripcion.preferido}
                                                                values={[
                                                                    {
                                                                        id: 'preferido',
                                                                        nombre: Lang('MARCAR_DOCUMENTO_PREFERIDO')
                                                                    }
                                                                ]}
                                                                classNameContainer='d-block me-0 mb-1 mt-0 pt-1'
                                                                onChange={(e: any) => handleChangeCheck(e, 'preferido')}
                                                            // fetchData={nuevo ? fetchPersonaPOSTData : fetchPerfilesPATCHData}
                                                            />
                                                        </Form.Group>

                                                    </div>

                                                </div>
                                            </div>
                                            :
                                            <Form.Group className="mb-3 col-12">
                                                <InputSelect
                                                    label={Lang('CV_DEFECTO') + ':'}
                                                    name='idcv'
                                                    value={formInscripcion.idcv}
                                                    onChange={handleInputInscripcion}
                                                    values={fetchCVsPersonaGETData.data?.items?.map((item: any) => {
                                                        return {
                                                            id: item.id,
                                                            nombre: item.titulocv
                                                        }
                                                    }) || []}
                                                />
                                            </Form.Group>
                                    }

                                </div>

                            </Modal.Body>
                            <Modal.Footer className='border-0 d-flex justify-content-between mt-2'>
                                <ButtonSpinner
                                    variant="danger" type="submit" className="me-2"
                                    handleSubmit={handleSubmitEditarPersonaDELETE} fetchDataLoading={[fetchAgregarPersonaDELETEData.loading]}
                                    value={Lang('ELIMINAR_INSCRIPCION')} icon={<FontAwesomeIcon icon={faTrash} />}
                                />
                                <ButtonSpinner
                                    variant="secondary" type="submit" className="me-2"
                                    handleSubmit={handleSubmitEditarPersonaPATCH} fetchDataLoading={[fetchAgregarPersonaPATCHData.loading]}
                                    value={Lang('AGREGAR')} icon={<FontAwesomeIcon icon={faSave} />}
                                />
                            </Modal.Footer>
                        </>

                }
            </Modal>

            <ModalStatusServer fetchData={fetchAgregarPersonaPATCHData} />

            <ModalStatusServer fetchData={fetchAgregarPersonaDELETEData} />
        </>
    )
}

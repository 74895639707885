import { useEffect } from 'react'
import { TableFunciones } from './TableFunciones';
import './SeccionFunciones.scss'

export const SeccionFunciones = ({ idofertaempleo, fetchCondicionPagosRemesasGETData, fetchPersonaBTGETData = {},
    fetchMultipleParamBolsaData, fetchPersonaBTGET }: any) => {

    return (
        <div>
            <TableFunciones
                data={fetchPersonaBTGETData.data?.funciones || []}
                loading={false}
                idofertaempleo={idofertaempleo}
                fetchPersonaBTGETData={fetchPersonaBTGETData}
                fetchCondicionPagosRemesasGETData={fetchCondicionPagosRemesasGETData}
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                fetchPersonaBTGET={fetchPersonaBTGET}
            />
        </div>
    )
}

import { useEffect } from 'react'
import { TableEstudios } from './TableEstudios';

export const SeccionEstudios = ({ idofertaempleo, fetchCondicionPagosRemesasGETData, fetchPersonaBTGETData = {},
    fetchMultipleParamBolsaData, fetchPersonaBTGET }: any) => {

    return (
        <div>
            <TableEstudios
                data={fetchPersonaBTGETData.data?.estudios || []}
                loading={false}
                // idofertaempleo={idofertaempleo}
                fetchPersonaBTGETData={fetchPersonaBTGETData}
                fetchCondicionPagosRemesasGETData={fetchCondicionPagosRemesasGETData}
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                fetchPersonaBTGET={fetchPersonaBTGET}
            />
        </div>
    )
}

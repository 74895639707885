import { faExternalLink, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { InputText } from '../../../../../components/Inputs'
import { ModalConfirm } from '../../../../../components/Modals/ModalConfirm'
import { ModalStatusServer } from '../../../../../components/Modals/ModalStatusServer'
import TableAdvanced from '../../../../../components/Tables/TableAdvanced/TableAdvanced'
import { getDataFile, isStatusSucess } from '../../../../../helpers/helpers'
import { useForm } from '../../../../../hooks/useForm'
import { useLang } from '../../../../../language'
import { misInscripcionesServices } from '../../controllers/services/inscripciones.service'

export const TableMisInscripciones = ({ data, loading, total, perPage, handleFormChange, formValuesSearch, urlParams,
    fetchMisInscripcionesGET }: any) => {

    const Lang = useLang()

    const [excluidos, setExcluidos] = useState([])
    const [incluidos, setIncluidos] = useState([])

    const [checkAll, setCheckAll] = useState(false)

    const selectAllItems = () => {
        setCheckAll(true)
        setIncluidos([])
        setExcluidos([])
    }

    const unselectAllItems = () => {
        setCheckAll(false)
        setIncluidos([])
        setExcluidos([])
    }

    const allActions: any = [
        
    ]

    const allActionsPerLine = [
        {
            label: <><FontAwesomeIcon icon={faFilePdf} className='' /> <small>{Lang('CERTIFICADO')}</small></>,
            action: (form: any) => handleClickExcel(form?.id),
            withoutID: true,
            variant: 'outline-primary',
            // dependsOn: 'habilitarcertificado'
            onlyExistKey: 'habilitarcertificado',
        },
        {
            label: <FontAwesomeIcon icon={faExternalLink} className='' />,
            action: (form: any) => {
                window.open(`${process.env.REACT_APP_PUBLIC_URL_PORTAL}/${process.env.REACT_APP_URL_PORTAL_ID_CURSO}${form.idevento}`, '_blank')?.focus();
            },
            withoutID: true,
        },
        {
            variant: 'danger',
            label: <FontAwesomeIcon icon={faTrash} className='' />,
            action: (id: any) => setshowConfirm_DELETE({
                value: true,
                payload: {
                    id
                }
            }),
        },
    ]

    const handleChangeCheckAll = (e: any) => {

        if (e.target.checked) {

            selectAllItems()

        } else {

            unselectAllItems()
        }
    }

    const headersTable = [
        <small>{Lang('NOMBRE_DEL_EVENTO')}</small>,
        <small>{Lang('CONDICION_DE_PAGO')}</small>,
        <small>{Lang('IMPORTE')}</small>,
        <small className='text-center d-block'>{Lang('FECHA')}</small>,
        ''
    ]

    const dataLineTable = [
        {
            className: '',
            name: 'nombreevento'
        },
        {
            className: '',
            name: 'condicionpago'
        },
        {
            className: '',
            name: 'importe'
        },
        {
            className: 'text-center',
            name: 'fecha'
        },
    ]


    // ---------- Cambiar condicion de pago

    const [showConfirm_DELETE, setshowConfirm_DELETE] = useState<any>({
        value: false,
        payload: {}
    })

    const [fetchInscripcionDELETE, fetchInscripcionDELETEData] = misInscripcionesServices.DELETE()

    useEffect(() => {

        if (isStatusSucess(fetchInscripcionDELETEData.status)) {
            setshowConfirm_DELETE({
                value: false,
                payload: {}
            })
            fetchMisInscripcionesGET({
                params: urlParams
            })
        }
    }, [fetchInscripcionDELETEData])

    const [formAnular, handleInputChangeAnular, handleFormChangeAnular, resetformAnular] = useForm({
        observaciones: ''
    });

    const handleSubmitAnular = (id: any) => {

        fetchInscripcionDELETE({
            id: id + '/anular',
            body: formAnular
        })
    }

    // Descargar Plantilla

    const [showConfirm_Excel, setshowConfirm_Excel] = useState(false)

    const [fetchPlantillaCertificadoGET, fetchPlantillaCertificadoGETData, resetfetchPlantillaCertificadoGET]
        = misInscripcionesServices.GET_MI_CERTIFICADO()

    useEffect(() => {

        if (isStatusSucess(fetchPlantillaCertificadoGETData.status)) {
            getDataFile(fetchPlantillaCertificadoGETData, resetfetchPlantillaCertificadoGET, 'Plantilla', 'pdf')
            setshowConfirm_Excel(false)
        }
    }, [fetchPlantillaCertificadoGETData])

    const handleClickExcel = (id: any) => {

        setshowConfirm_Excel(true)

        fetchPlantillaCertificadoGET({
            id: id + '/download-plantilla-personalizada'
        })
    }

    return (
        <>
            <TableAdvanced>

                <TableAdvanced.Head
                    title={Lang('')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    headers={headersTable}
                    dataLineTable={dataLineTable}
                    data={data}
                    isLoading={loading}

                    excluidos={excluidos}
                    incluidos={incluidos}
                    setExcluidos={setExcluidos}
                    setIncluidos={setIncluidos}

                    checkAll={checkAll}
                    allActionsPerLine={allActionsPerLine}
                    checkMultiples={false}
                    classNameTable='table-shrink-th AllActionsPerLineAlignEnd'
                />

                <TableAdvanced.Footer
                    total={total}
                    handleFormChange={handleFormChange}
                    formValuesSearch={formValuesSearch}
                    perPage={perPage}
                />
            </TableAdvanced>

            <ModalConfirm
                handleClose={() => setshowConfirm_DELETE({
                    value: false,
                    payload: {}
                })}
                show={showConfirm_DELETE.value}
                onClickOK={() => handleSubmitAnular(showConfirm_DELETE.payload?.id)}
                messageHTML={
                    <>
                        <h5 className='text-center fw-normal mb-4'>{Lang('PARRAFO_SEGURO_ANULAR_INSCRIPCION')}</h5>
                        <div className="row justify-content-center">
                            <Form.Group className="mb-3 col-12">
                                <InputText
                                    label={`${Lang('OBSERVACIONES')}:`}
                                    name='observaciones'
                                    asParam='textarea'
                                    rows={3}
                                    value={formAnular.observaciones}
                                    onChange={handleInputChangeAnular}
                                />
                            </Form.Group>
                        </div>
                    </>
                }
                textOnClickOk={Lang('ANULAR')}
                variantClickOk='danger'
                fetchData={fetchInscripcionDELETEData}
            />

            <ModalStatusServer fetchData={fetchInscripcionDELETEData} />

            <ModalConfirm
                handleClose={() => setshowConfirm_Excel(false)}
                show={showConfirm_Excel}
                // onClickOK={handleLineaFacturaDELETE}
                messageHTML={
                    <h5 className='text-center fw-normal h5'>{Lang('DESCARGAR_PLANTILLA')}</h5>
                }
                textOnClickOk={Lang('DESCARGANDO')}
                fetchData={fetchPlantillaCertificadoGETData}
                disableBtnClose
            />

            <ModalStatusServer fetchData={fetchPlantillaCertificadoGETData} onlyError={true} />

        </>

    )
}

import { Placeholder } from '../../components/Placeholder/Placeholder'

export const Loader = () => {
    return (
        <div className="row mb-3 justify-content-center">
            <div className='ButtonModule ButtonModule__Admin'>
                <Placeholder width='100px' height='100px'classNameChild='PageApp__LoaderIcon' />
                <Placeholder width='80%' height='22px' className='mt-3' />
            </div>
            <div className='ButtonModule ButtonModule__Admin'>
                <Placeholder width='100px' height='100px'classNameChild='PageApp__LoaderIcon' className='delay-1' />
                <Placeholder width='80%' height='22px' className='mt-3 delay-1' />
            </div>
            <div className='ButtonModule ButtonModule__Admin'>
                <Placeholder width='100px' height='100px'classNameChild='PageApp__LoaderIcon' className='delay-2' />
                <Placeholder width='80%' height='22px' className='mt-3 delay-2' />
            </div>
            <div className='ButtonModule ButtonModule__Admin'>
                <Placeholder width='100px' height='100px'classNameChild='PageApp__LoaderIcon' className='delay-3' />
                <Placeholder width='80%' height='22px' className='mt-3 delay-3' />
            </div>
            <div className='ButtonModule ButtonModule__Admin'>
                <Placeholder width='100px' height='100px'classNameChild='PageApp__LoaderIcon' className='delay-4' />
                <Placeholder width='80%' height='22px' className='mt-3 delay-4' />
            </div>
            
        </div>
    )
}

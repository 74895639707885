import { useEffect, useState } from 'react'
import { Form, Tab, Tabs } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import logo from '../../assetsTheme/images/logo.png'
import { ButtonSpinner } from '../../components/ButtonSpinner/ButtonSpinner'
import { InputText } from '../../components/Inputs'
import { ModalStatusServer } from '../../components/Modals/ModalStatusServer'
import { isStatusSucess } from '../../helpers/helpers'
import { useForm } from '../../hooks/useForm'
import { useValidator } from '../../hooks/useValidator'
import { useLang } from '../../language'
import { AuthServices } from '../../services/auth.service'
import { logIn } from '../../store/slices/auth'
import '../../assetsTheme/css/pages/PageLogin.scss'
import { ModalReiniciarClave } from './ModalReiniciarClave'
import jwt_decode from "jwt-decode";

const Login = () => {

  const Lang = useLang()

  const [formDatosGenerales, handleInputDatosGenerales, handleFormDatosGenerales, resetDatosGenerales] = useForm({
    usuario: '',
    password: '',
    es_asociado: process.env.REACT_APP_MAIN_VARIABLE === 'USER_ARES' ? 'no-colegiado' : "colegiado",
  })

  const handleInputSelectEsAsociado = (key: any) => {

    handleFormDatosGenerales({
      ...formDatosGenerales,
      es_asociado: key
    })
  }

  const [valuesValidationLogin, handleValidationLogin, handleResetValidationLogin] = useValidator({
    descripcion: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
  })

  // ------------ POST Iniciar sesion

  const [fetchIniciarSesionPOST, fetchIniciarSesionPOSTData] = AuthServices.POST_INICIAR_SESION()

  const handlefetchIniciarSesionPOST = () => {

    fetchIniciarSesionPOST({
      body: {
        ...formDatosGenerales,
        es_asociado: formDatosGenerales.es_asociado === 'colegiado'
      }
    })
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {

    if (isStatusSucess(fetchIniciarSesionPOSTData.status)) {

      const data: any = jwt_decode(fetchIniciarSesionPOSTData?.data?.data?.token)



      dispatch(logIn({
        user: data,
        token: fetchIniciarSesionPOSTData?.data?.data?.token || '',
        perfiles: data.perfiles || []
      }))

      // navigate('/admin')
    }

  }, [fetchIniciarSesionPOSTData])

  // Modal Rercortar imagen

  const [showModal_ReiniciarClave, setShowModal_ReiniciarClave] = useState({
    value: false,
    payload: null
  });

  const handleCloseModal_ReiniciarClave = () => setShowModal_ReiniciarClave({
    value: false,
    payload: null
  })

  const handleShowModal_ReiniciarClave = (payload: any) => setShowModal_ReiniciarClave({
    value: true,
    payload
  });

  return (
    <section className="PageLogin">

      <div className="container-fluid">

        <div className='row justify-content-center'>

          <div className="col-12 col-sm-6 col-md-5 col-lg-5 col-xl-3 animate__animated animate__fadeInUpModules">

            <h4 className='PageLogin__title text-center'>{Lang('INICIAR_SESION')}</h4>

            <Tabs
              defaultActiveKey={'colegiado'}
              activeKey={formDatosGenerales.es_asociado}
              onSelect={(k: any) => handleInputSelectEsAsociado(k)}
            >

              {
                process.env.REACT_APP_MAIN_VARIABLE !== 'USER_ARES' &&
                <Tab eventKey="colegiado" title={<span className='h6'>{Lang('COLEGIADO')}</span>} >

                  <div className="row">

                    <Form.Group className="mb-3 col-12">
                      <InputText
                        label={Lang('NUM_COLEGIADO') + ':'}
                        name='usuario'
                        value={formDatosGenerales.usuario}
                        onChange={handleInputDatosGenerales}
                        validation={valuesValidationLogin}
                        fetchData={fetchIniciarSesionPOSTData}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3 col-12">
                      <InputText
                        label={Lang('CONTRASENIA') + ':'}
                        type='password'
                        name='password'
                        value={formDatosGenerales.password}
                        onChange={handleInputDatosGenerales}
                        validation={valuesValidationLogin}
                        fetchData={fetchIniciarSesionPOSTData}
                      />
                    </Form.Group>

                    <div className="col-12 d-flex justify-content-between align-items-center mt-2">
                      <div className="text-center color-primary cursor-pointer h6 my-0" onClick={() => handleShowModal_ReiniciarClave({
                        es_asociado: formDatosGenerales.es_asociado === 'colegiado'
                      })}>
                        {Lang('OLVIDO_SU_CLAVE')}
                      </div>
                      <ButtonSpinner
                        variant="secondary" type="submit" className="ms-3 text-nowrap"
                        handleSubmit={handlefetchIniciarSesionPOST} fetchDataLoading={[fetchIniciarSesionPOSTData.loading]}
                        value={Lang('ACCEDER')}
                      />
                    </div>

                  </div>
                </Tab>
              }

              <Tab eventKey="no-colegiado" title={<span className='h6'>{process.env.REACT_APP_MAIN_VARIABLE !== 'USER_ARES' ? Lang('NO_COLEGIADO') : ''}</span>} >

                <div className="row">

                  <Form.Group className="mb-3 col-12">
                    <InputText
                      label={process.env.REACT_APP_MAIN_VARIABLE !== 'USER_ARES' ? `${Lang('USUARIOA')}:` : 'NIF:'}
                      name='usuario'
                      value={formDatosGenerales.usuario}
                      onChange={handleInputDatosGenerales}
                      validation={valuesValidationLogin}
                      fetchData={fetchIniciarSesionPOSTData}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 col-12">
                    <InputText
                      label={Lang('CONTRASENIA') + ':'}
                      type='password'
                      name='password'
                      value={formDatosGenerales.password}
                      onChange={handleInputDatosGenerales}
                      validation={valuesValidationLogin}
                      fetchData={fetchIniciarSesionPOSTData}
                    />
                  </Form.Group>

                  <div className="col-12 d-flex justify-content-between align-items-center mt-2">
                    <div className="text-center color-primary cursor-pointer h6 my-0" onClick={() => handleShowModal_ReiniciarClave({
                      es_asociado: formDatosGenerales.es_asociado === 'colegiado'
                    })}>
                      {Lang('OLVIDO_SU_CLAVE')}
                    </div>
                    <ButtonSpinner
                      variant="secondary" type="submit" className="ms-3"
                      handleSubmit={handlefetchIniciarSesionPOST} fetchDataLoading={[fetchIniciarSesionPOSTData.loading]}
                      value={Lang('ACCEDER')}
                    />
                  </div>

                </div>
              </Tab>

            </Tabs>

            <img src={logo} alt="logo" className='PageLogin__Logo' />

          </div>
        </div>
      </div>

      <ModalReiniciarClave
        showModal_ReiniciarClave={showModal_ReiniciarClave}
        handleCloseModal_ReiniciarClave={handleCloseModal_ReiniciarClave}
      />

      <ModalStatusServer fetchData={fetchIniciarSesionPOSTData} onlyError />


    </section>
  )
}

export default Login 
import { faUserCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dropdown } from "react-bootstrap"
import { useLang } from "../../../language"
import { eliminarCookies } from "../../../helpers/helpers"

export const DropdownUser = ({ auth, dispatch, logOut, handleShowCambiarPassword }: any) => {

    const Lang = useLang()

    const handleClickLogOut = () => {
        eliminarCookies()
        dispatch(logOut())
    }

    return (
        <Dropdown align="end" className='border-0'>
            <Dropdown.Toggle variant="" id="dropdown-basic" className='d-flex align-items-center border-0'>
                <FontAwesomeIcon icon={faUserCircle} className='me-2 fs-5' />
                <span className="me-2 fs-6">
                    {auth?.user?.nombre_usuario}
                </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className='text-end'>
                <Dropdown.Item 
                    onClick={handleShowCambiarPassword}
                >{Lang('CAMBIAR_CONTRASENIA')}</Dropdown.Item>
                <Dropdown.ItemText className='dropdown-item cursor-pointer' onClick={handleClickLogOut}>{Lang('SALIR')}</Dropdown.ItemText>
            </Dropdown.Menu>
        </Dropdown>
    )
}
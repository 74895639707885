import './LinkTitleTop.scss'

export const LinkTitleTop = ({icon, title}: any) => {
  return (
    <span className='LinkTitleTop d-none d-md-flex align-items-center'>
        <div className='mini-circle'>
            {icon}
        </div> 
        {title}
    </span>
  )
}

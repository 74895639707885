import { receiveCheck, receiveFecha, sendCheck } from "../../../../../adapters/helpers"

var misActividadesProfesionalesAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                var data = {
                    id: item.idpersonaperitacion || '',
                    fechaalta: receiveFecha(item.fechaalta),
                    fechabaja: receiveFecha(item.fechabaja),
                    idpersona: item.idpersona || '',
                    idperitacion: item.idperitacion || '',
                    // publicaweb: item.publicaweb || false,
                    publicaweb: receiveCheck(item.publicaweb, 'publicaweb'),
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idperitacion: item.idperitacion || '',
                    fechaalta: item.fechaalta || '',
                    fechabaja: item.fechabaja || '',
                    publicaweb: sendCheck(item.publicaweb),
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },
    
    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idperitacion: item.idperitacion || '',
                    fechaalta: item.fechaalta || '',
                    fechabaja: item.fechabaja || '',
                    publicaweb: item.publicaweb,
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message || '',
                    status: item.status || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                // 

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },

    },
    
}
export {
    misActividadesProfesionalesAdapters
}
import { faBarsStaggered, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { useLang } from '../../../language';
import { ButtonSpinner } from '../../ButtonSpinner/ButtonSpinner';
import './FilterAdvanced2.css'

function addPropsToReactElement(element: any, props: any) {
    if (React.isValidElement(element)) {
        return React.cloneElement(element, props)
    }
    return element
}

interface IFilterAdvanced {
    children: any;
    simple?: boolean,
    handleSearch?: any,
    fetchData?: any,
    formValuesSearch?: any,
    handleInputChangeSearch?: any,
    resetformValuesSearch?: any,
    handleFormChange?: any,
    defaultValuesSearchSimple?: any,
    defaultValuesSearchAdvanced?: any,
    defaultValuesSearchAlways?: any,
    expand?:any,

}

interface IValuesFilter {
    label?: string,
    showFilter?: boolean,
    setshowFilter?: (boolean: boolean) => boolean,
    children?: any;
    simple?: boolean,
    handleSearch?: any,
    fetchData?: any,
    formValuesSearch?: any,
    handleInputChangeSearch?: any,
    resetformValuesSearch?: any,
    handleFormChange?: any,
    defaultValuesSearchSimple?: any,
    defaultValuesSearchAlways?: any
    defaultValuesSearchAdvanced?: any,
}

const FilterAdvanced2 = ({ children, simple = false, handleSearch, fetchData, formValuesSearch, handleInputChangeSearch, expand = false,
    resetformValuesSearch, handleFormChange, defaultValuesSearchSimple, defaultValuesSearchAdvanced, defaultValuesSearchAlways }: IFilterAdvanced) => {

    const [showFilter, setshowFilter] = useState(expand || simple)

    return (
        <div className="px-sm-3 py-sm-2 card FilterAdvanced2">

            <>
                {
                    children?.map((childElement: any, indexchildElement: number) =>
                        addPropsToReactElement(childElement, {
                            showFilter, setshowFilter, simple, handleSearch,
                            fetchData, formValuesSearch, handleInputChangeSearch,
                            resetformValuesSearch, handleFormChange, defaultValuesSearchSimple, defaultValuesSearchAdvanced,
                            defaultValuesSearchAlways,
                            'key': indexchildElement,
                        })
                    )
                }
            </>

        </div>
    )
}


const FilterAdvancedHead = ({ label, showFilter, setshowFilter = (() => false), children, simple,
    handleSearch, fetchData, formValuesSearch, handleInputChangeSearch, resetformValuesSearch, defaultValuesSearchSimple,
    defaultValuesSearchAdvanced, handleFormChange}: IValuesFilter) => {

        const Lang = useLang()

    const handleMiddleSearch = () => {

        const valuesFinal = { ...formValuesSearch, ...defaultValuesSearchAdvanced}
        
        handleFormChange(valuesFinal)
        handleSearch(null, valuesFinal)
    }

    return (
        <div className="d-flex justify-content-between align-items-start py-2">
            <h5 className='mb-0 h6 d-none d-sm-block'>
                {label ? label : 'Filtro'}
            </h5>
            <div className="container-fluid">
                <div className="row ms-sm-auto ms-sm-0 justify-content-end">
                    <>
                        {
                            addPropsToReactElement(children, { showFilter, formValuesSearch, handleInputChangeSearch })
                        }
                    </>
                    {
                        !showFilter && <div className="col text-nowrap flex-grow-0">
                            <div className="d-flex flex-column align-items-end justify-content-end h-100">
                                <ButtonSpinner
                                    variant="secondary" type="submit" size='sm'
                                    handleSubmit={handleMiddleSearch} fetchDataLoading={[fetchData.loading]}
                                    value={Lang('BUSCAR')} icon={<FontAwesomeIcon icon={faSearch} className='me-1' />}
                                />
                            </div>
                        </div>
                    }

                </div>
            </div>
            {
                !simple && <div onClick={() => setshowFilter(!showFilter)} className='ms-3 p-1 color-secondary' id='btn_show_filter_advance'>
                    <FontAwesomeIcon icon={faBarsStaggered} />
                </div>
            }

        </div>
    )
}

const FilterAdvancedBody = ({ showFilter, children, handleSearch, fetchData, formValuesSearch,
    handleInputChangeSearch, resetformValuesSearch, handleFormChange, defaultValuesSearchSimple, defaultValuesSearchAdvanced,
    defaultValuesSearchAlways }: IValuesFilter) => {

    return (
        <div className='row'>
            {
                showFilter && <>
                    <div className="col-12 animate__animated animate__fadeIn animate__faster mt-2">
                        <div className="row">
                            {
                                addPropsToReactElement(children, {
                                    showFilter, formValuesSearch, handleInputChangeSearch,
                                    handleSearch, fetchData, resetformValuesSearch, handleFormChange, defaultValuesSearchSimple,
                                    defaultValuesSearchAdvanced, defaultValuesSearchAlways
                                })
                            }
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

FilterAdvanced2.Head = FilterAdvancedHead
FilterAdvanced2.Body = FilterAdvancedBody

export default FilterAdvanced2
import logo from '../../../assetsTheme/images/logo.png'

interface INavleft {
    label?: string
}

const NavTop = ({ label }: INavleft) => {

    return (
        <div className='LayoutMain2__navtop--container container py-4 d-none'>
            <section className='LayoutMain2__NavTop d-flex justify-content-between'>
                <img src={logo} alt="" width={100} />
                
            </section>
        </div>
    )
}

export default NavTop
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AuthState {
  auth: any
}

const initialState: AuthState = {
  auth: localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE || '') ? JSON.parse(localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE || '') || '') : {},
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logOut: (state) => {
      state.auth = {}
      localStorage.removeItem(process.env.REACT_APP_MAIN_VARIABLE || '')
      localStorage.removeItem(process.env.REACT_APP_MAIN_VARIABLE + '_IDENTIDADPROPIA_CURRENT')
    },
    logIn: (state, action: PayloadAction<any>) => {
      state.auth = action.payload
      localStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE || '', JSON.stringify(action.payload || '{}') )
    },
  },
})

// Action creators are generated for each case reducer function
export const { logOut, logIn } = authSlice.actions

// export default authSlice.reducer
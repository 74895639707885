import { useAxios } from "../../../../../../hooks/useAxios"
import { MisInscripcionesAdapters } from "./inscripciones.adapter"

const MisInscripcionesServices = {

    GET: () => {

        return useAxios({ 
            method: 'get', url: '/bolsadetrabajo/inscripciones/ofertasempleo', type: 'json', adapters: MisInscripcionesAdapters.GET 
        })
    },

    POST: () => {

        return useAxios({ 
            method: 'post', url: '/personas/actividades-profesionales-current-user/crear', 
            type: 'json', adapters: MisInscripcionesAdapters.POST 
        })
    },

    PATCH: () => {

        return useAxios({ 
            method: 'post', url: '/bolsadetrabajo/inscripciones/ofertasempleo', 
            type: 'json', adapters: MisInscripcionesAdapters.PATCH 
        })
    },

    DELETE: () => {

        return useAxios({ 
            method: 'delete', url: `/bolsadetrabajo/inscripciones/ofertaempleo`, 
            type: 'json', adapters: MisInscripcionesAdapters.DELETE 
        })
    }
}

export {
    MisInscripcionesServices
}
import { useState } from 'react'
import { Form } from 'react-bootstrap'
import { MsgInput } from '../MsgInput/MsgInput'
import { MsgInputServer } from '../MsgInput/MsgInputServer'
import { getMsgInputColor } from '../../helpers/helpers';
import { receiveFechaFormat2 } from '../../adapters/helpers';

interface IValidationValues {
    isOk: boolean
}

interface IfechData {
    data?: any,
    status?: any,
    error?: any,
    loading?: boolean
}


interface Ivalidation {
    [key: string]: IValidationValues
}

interface IInputText {
    label?: any,
    className?: string,
    [name: string]: any,
    // name? : string,
    type?: string,
    value?: string,
    onChange?: ((e?: any) => void) | undefined,
    rows?: any,
    placeholder?: string,
    validation?: Ivalidation,
    fetchData?: IfechData,
    disabled?: boolean,
    asParam?: any,
    style?: object,
    readonly?: boolean,
    min?: number,
    adapter?: string,
    size?: string,
    maxLength?: number,
}

export const InputText = ({ label = '', className = '', name = '', type = 'text', value = '', onChange = () => null, rows,
    placeholder = '', validation = {}, fetchData, disabled = false, asParam = '', style = {},
    readonly = false, min = -1, adapter = '', size, maxLength }: IInputText) => {

    const [errorServer, setErrorServer] = useState(false)

    const handleOnchange = (e: any) => {

        if (validation?.[name])
            validation[name].isOk = true

        if (fetchData?.data)
            fetchData.data.status = 'Success'

        if (fetchData)
            fetchData.error = null

        if (min !== undefined && Number(e.target.value) < Number(min))
            return

        if (errorServer)
            setErrorServer(false)

        onChange(e)
    }

    const propsTextarea: any = {}

    if (asParam) {
        propsTextarea.as = 'textarea'
        propsTextarea.rows = rows
    }

    return (
        <>
            {label && <Form.Label className='fw-500'>{label}</Form.Label>}
            {
                readonly ? <>
                    <p className="mt-0 mb-0">{ type === 'date' ? receiveFechaFormat2(value) : value}</p>
                </> : <>
                    <Form.Control
                        type={type}
                        {...propsTextarea}
                        className={`${className} ${((validation ? getMsgInputColor(validation, name) : false) || errorServer) ? 'is-invalid' : ''}`}
                        name={name}
                        value={value}
                        onChange={handleOnchange}
                        placeholder={placeholder}
                        disabled={disabled}
                        autoComplete='one-time-code'
                        style={style}
                        size={size}
                        maxLength={maxLength}
                    />
                    {validation && <MsgInput obj={validation} name={name} />}
                    <MsgInputServer fetchData={fetchData} name={name} setErrorServer={setErrorServer} errorServer={errorServer} adapter={adapter} />
                </>
            }

        </>
    )
}

import React from 'react'
import { Placeholder } from '../../../../../components/Placeholder/Placeholder'

export const LoaderFormParametro = () => {
    return (
        <div className="px-4 py-3">

            <div className='row'>
                <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                    <Placeholder className='py-2' height='2rem' />
                </div>
                <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                    <Placeholder className='py-2' height='2rem' />
                </div>
                <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                    <Placeholder className='py-2' height='2rem' />
                </div>
                <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                    <Placeholder className='py-2' height='2rem' />
                </div>
                <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                    <Placeholder className='py-2' height='2rem' />
                </div>
                <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                    <Placeholder className='py-2' height='2rem' />
                </div>

                <div className='col-12 mt-0 pt-3 d-flex justify-content-end border-top'>
                    <Placeholder className='py-0 me-0' height='2rem' width='5rem' />
                </div>
            </div>
        </div>
    )
}

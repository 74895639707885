const langTheme = {
    ES: {
        PARRAFO_1_INTRANET: `Para continuar, seleccione el modulo al que desea ingresar. Si tiene dificultades para acceder contactanos al telefono 973 232 253, o envianos un email a cetill@enginyerslleida.cat`,
        MENU_FACTURAS_VENTA: 'Facturas venta',
        MENU_FACTURAS_COMPRA: 'Facturas compra',
        MENU_COBROS: 'Cobros',
        MENU_PAGOS: 'Pagos'
    }
}

export default langTheme;
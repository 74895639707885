import { useState } from 'react'
import { Form } from 'react-bootstrap'
import { MsgInput } from '../MsgInput/MsgInput'
import { MsgInputServer } from '../MsgInput/MsgInputServer'
import { useLang } from '../../language'

export const InputFile = ({
    label, className = '', name, type = 'text', value, onChange, acceptTypes, multiple,
    placeholder, validation = {}, fetchData = {}, disabled, as, style, readonly, min, referencia
}: any) => {

    const Lang = useLang()

    const [errorServer, setErrorServer] = useState(false)
    
    const handleOnchange = (e: any) => {

        if (validation[name])
            validation[name].isOk = true

        if (fetchData.data)
            fetchData.data.status = 'Success'

        fetchData.error = null

        if (min !== undefined && Number(e.target.value) < min)
            return

        if (errorServer)
            setErrorServer(false)

        onChange(e)
    }

    let objData: any = {}

    if(acceptTypes){
        objData.accept = acceptTypes
    }

    if (multiple) {
        objData.multiple = 'multiple'
    }

    return (
        <>
            { label && <Form.Label>{label}</Form.Label>}
            <Form.Control 
                type="file" 
                value={value}
                ref={referencia}
                {...objData}
            />
            {
                multiple && <Form.Text className='d-block'>*{Lang('PUEDE_SELECCIONAR_MULTIPLES_FICHEROS')}</Form.Text>
            }
            { validation && <MsgInput obj={validation} name={name} /> }
            <MsgInputServer fetchData={fetchData} name={name} setErrorServer={setErrorServer} errorServer={errorServer} />
        </>
    )
}

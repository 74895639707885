import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { changeLanguage } from "../../../store/slices/language";
import { useLang } from "../../../language";
import { ParametrosServices } from "../../../services/parametros.service";

export const DropdownLanguage = () => {

    const dispatch = useDispatch()

    const { language } = useSelector((state: RootState) => state.language)

    const Lang = useLang()

    // const [fetchParamsIdiomas, fetchParamsIdiomasData] = ParametrosServices.GET()

    // useEffect(() => {

    //     fetchParamsIdiomas({
    //         params: {
    //             idiomas: true,
    //         }
    //     })

    // }, [])

    const handleClickChangeLanguage = (key: string) => {
    
        dispatch(changeLanguage(key))
        
        window.location.reload()
    }

    return (
        <div className="DropdownLanguage">
            <Dropdown drop='up' className='ms-4'>
                <Dropdown.Toggle size="sm" variant="outline-dark" id="dropdown-basic">
                    <FontAwesomeIcon icon={faGlobe} className='me-2' />
                    <span className='me-2'>{language}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleClickChangeLanguage('ES')}>{Lang('ESPANIOL')}</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleClickChangeLanguage('CA')}>{Lang('CATALAN')}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
};

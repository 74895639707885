import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TicketNuevo from "./nuevo/Index";
import TicketEdit from "./edit/Index";

export const IndexRoute = () => {
    return (
        <>
            <Routes>

                {/* <Route
                    path="nuevo"
                    element={<TicketNuevo />}
                /> */}

                <Route
                    path=":id"
                    element={<TicketEdit />}
                />
                
            </Routes>
        </>
    );
};

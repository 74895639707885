import { useState, useEffect } from 'react'
import { useLang } from '../../../../../../../../language'
import { Form, Table } from 'react-bootstrap'
import { InputChecks } from '../../../../../../../../components/Inputs/InputChecks'
import { cloneDeep, groupBy, isArray } from 'lodash'
import { OfertaServices } from '../../../controllers/ofertas.service'
import { useForm } from '../../../../../../../../hooks/useForm'

export const TableFunciones = ({ data, logica_buscar_interes, logica_buscar_experiencia, idofertaempleo, urlParams,
    fetchCondicionPagosRemesasGETData, fetchMultipleParamBolsaData }: any) => {

    const Lang = useLang()

    const [fetchPersonaEntidadPATCH, fetchPersonaEntidadPATCHData] = OfertaServices.PATCH_FUNCION(idofertaempleo)

    // Inicializando valores de params a funcion

    const [dataAllMatch, setdataAllMatch] = useState([]);

    // Funciones helpers

    const handleChangeCheck = (e: any, name: any, index: number) => {

        const dataTemp: any = cloneDeep(dataAllMatch)

        dataTemp[index][name] = e.target.checked

        fetchPersonaEntidadPATCH({
            body: {
                funcion: dataTemp
            }
        })

        setdataAllMatch(dataTemp)
    }


    useEffect(() => {
        if (fetchMultipleParamBolsaData.status && data) {

            const dataKey: any = groupBy(data, 'idfuncion')

            const dataParamsAdapted = fetchMultipleParamBolsaData?.data?.funciones?.map((item: any) => {
                return {
                    idfuncion: item.id,
                    nombre: item.nombre,
                    experiencia: false,
                    interes: false,
                }
            })

            const dataParamsAdaptedComplete = dataParamsAdapted?.map((item: any) => {
                return {
                    ...item,
                    experiencia: dataKey[item.idfuncion]?.[0]?.experiencia || false,
                    interes: dataKey[item.idfuncion]?.[0]?.interes || false,
                }
            })

            setdataAllMatch(dataParamsAdaptedComplete)

        }
    }, [fetchMultipleParamBolsaData, data]);


    // --------- Logica del buscador


    const [formLogicaFunciones, handleInputLogicaFunciones, handleFormLogicaFunciones, resetFormLogicaFunciones] = useForm({
        logica_buscar_interes: [],
        logica_buscar_experiencia: [],
    })

    const handleChangeCheckLogica = (e: any, name: string) => {

        let listaTemp = cloneDeep(formLogicaFunciones[name])

        if (e.target.checked) {

            listaTemp.push(e.target.name)

        } else {

            listaTemp = listaTemp.filter((item: any) => item !== e.target.name)
        }

        handleFormLogicaFunciones({
            ...formLogicaFunciones,
            [name]: listaTemp
        })

        fetchPersonaEntidadPATCH({
            body: {
                ...formLogicaFunciones,
                logica_buscar_interes: isArray(formLogicaFunciones.logica_buscar_interes) && formLogicaFunciones.logica_buscar_interes?.length > 0 ? 'and' : 'or',
                logica_buscar_experiencia: isArray(formLogicaFunciones.logica_buscar_experiencia) && formLogicaFunciones.logica_buscar_experiencia?.length > 0 ? 'and' : 'or',
                [name]: e.target.checked ? 'and' : 'or',
                funcion: dataAllMatch
            }
        })
    }

    useEffect(() => {

        if (logica_buscar_interes || logica_buscar_experiencia) {

            handleFormLogicaFunciones({
                ...formLogicaFunciones,
                logica_buscar_interes: logica_buscar_interes === 'and' ? ['and'] : [],
                logica_buscar_experiencia: logica_buscar_experiencia === 'and' ? ['and'] : [],
            })
        }

    }, [logica_buscar_interes, logica_buscar_experiencia]);

    return (
        <div className='SeccionFunciones'>
            <div className="d-flex flex-wrap justify-content-between align-items-start mb-3">
                <h5 className="mb-3 mb-lg-0 ">{Lang('FUNCIONES')}</h5>
            </div>

            <div className="row">
                <div className="col-lg-6">

                    <Table className='position-relative'>
                        <thead>
                            <tr>
                                <th>
                                    <small>{Lang('FUNCION')}</small>
                                </th>
                                <th>
                                    <small>{Lang('EXPERIENCIA')}</small>
                                </th>
                                <th>
                                    <small>{Lang('INTERES')}</small>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                <>
                                    {
                                        dataAllMatch?.slice(0, dataAllMatch?.length / 2)?.map((item: any, index: number) => {

                                            return (
                                                <tr key={item.idfuncion}>
                                                    <td className=''>
                                                        <small>{item['nombre']}</small>
                                                    </td>

                                                    <td className='text-center text-nowrap shrink-td' valign='middle'>
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={'publicaweb' + '_' + item.nombre}
                                                            label=''
                                                            name={item.idfuncion}
                                                            onChange={(e: any) => handleChangeCheck(e, 'experiencia', index)}
                                                            autoComplete='one-time-code'
                                                            defaultChecked={item['experiencia']}
                                                            className='d-inline-block'
                                                        />
                                                    </td>

                                                    <td className='text-center text-nowrap shrink-td' valign='middle'>
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={'publicaweb' + '_' + item.nombre}
                                                            label=''
                                                            name={item.idfuncion}
                                                            onChange={(e: any) => handleChangeCheck(e, 'interes', index)}
                                                            autoComplete='one-time-code'
                                                            className='d-inline-block'
                                                            defaultChecked={item['interes']}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {/* {
                                        Object.keys(fetchMultipleParamBolsaData?.data?.funciones)?.length === 0 &&
                                        <tr>
                                            <td colSpan={20} className='text-center py-5 bg-gray-1'>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</td>
                                        </tr>
                                    } */}
                                </>
                            }

                        </tbody>

                    </Table>

                </div>

                <div className="col-lg-6">

                    <Table className='position-relative'>
                        <thead>
                            <tr>
                                <th>
                                    <small>{Lang('FUNCION')}</small>
                                </th>
                                <th>
                                    <small>{Lang('EXPERIENCIA')}</small>
                                </th>
                                <th>
                                    <small>{Lang('INTERES')}</small>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                <>
                                    {
                                        dataAllMatch?.slice(dataAllMatch?.length / 2)?.map((item: any, index: number) => {

                                            return (
                                                <tr key={item.idfuncion}>
                                                    <td className=''>
                                                        <small>{item['nombre']}</small>
                                                    </td>
                                                    <td className='text-center text-nowrap shrink-td' valign='middle'>
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={'publicaweb' + '_' + item.nombre}
                                                            label=''
                                                            name={item.idfuncion}
                                                            onChange={(e: any) => handleChangeCheck(e, 'experiencia', (dataAllMatch?.length / 2) + index)}
                                                            autoComplete='one-time-code'
                                                            className='d-inline-block'
                                                            defaultChecked={item['experiencia']}
                                                        />
                                                    </td>

                                                    <td className='text-center text-nowrap shrink-td' valign='middle'>
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={'publicaweb' + '_' + item.nombre}
                                                            label=''
                                                            name={item.idfuncion}
                                                            onChange={(e: any) => handleChangeCheck(e, 'interes', (dataAllMatch?.length / 2) + index)}
                                                            autoComplete='one-time-code'
                                                            className='d-inline-block'
                                                            defaultChecked={item['interes']}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {/* {
                                        Object.keys(fetchMultipleParamBolsaData?.data?.funciones)?.length === 0 &&
                                        <tr>
                                            <td colSpan={20} className='text-center py-5 bg-gray-1'>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</td>
                                        </tr>
                                    } */}
                                </>
                            }

                        </tbody>

                    </Table>

                </div>

                <div className='mb-3 mt-2 row justify-content-end'>
                    <Form.Group className="mb-0 col flex-grow-0 text-nowrap">
                        <div className="d-flex">
                            <small>
                                <InputChecks
                                    name='logica_buscar_experiencia'
                                    value={formLogicaFunciones.logica_buscar_experiencia}
                                    values={[{ id: 'and', nombre: Lang('REQUERIR_TODAS_EXPERIENCIAS') }]}
                                    classNameContainer='d-inline-block me-0'
                                    onChange={(e: any) => handleChangeCheckLogica(e, 'logica_buscar_experiencia')}
                                />
                            </small>

                            <small className='ps-3'>
                                <InputChecks
                                    name='logica_buscar_interes'
                                    value={formLogicaFunciones.logica_buscar_interes}
                                    values={[{ id: 'and', nombre: Lang('REQUERIR_TODOS_INTERESES') }]}
                                    classNameContainer='d-inline-block me-0'
                                    onChange={(e: any) => handleChangeCheckLogica(e, 'logica_buscar_interes')}
                                />
                            </small>

                        </div>
                    </Form.Group>

                </div>
            </div>

        </div>
    )
}

import { useAxios } from '../../../../../../../../hooks/useAxios';
import { CVsAdminAdapters } from '../adapters/cvadmin.adapter';

const CVsAdminServices = {

    GET_ALL: function () {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/curriculums`, type: 'json', adapters: CVsAdminAdapters.GET_ALL })
    },

    POST: function () {

        return useAxios({ method: 'post', url: `/inscripciones/evento-documentos`, type: 'json', adapters: CVsAdminAdapters.POST })
    },

    PATCH: function (idevento: string) {

        return useAxios({ method: 'patch', url: `/inscripciones/evento-documentos/${idevento}`, type: 'json', adapters: CVsAdminAdapters.PATCH })
    },

    DELETE: function (idvisado: any) {

        return useAxios({ method: 'delete', url: `/inscripciones/evento-documentos/${idvisado}`, type: 'json', adapters: CVsAdminAdapters.DELETE })
    }
}

export {
    CVsAdminServices
}
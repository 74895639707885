import { useAxios } from "../../../../../../../../hooks/useAxios"
import { TipoContratoAdapters, TiposContratoAdapters } from "./tiposcontrato.adapter"

const TiposContratoServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/admin/tipocontrato`, type: 'json', adapters: TiposContratoAdapters.GET })
    }
}

const TipoContratoServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/`, type: 'json', adapters: TipoContratoAdapters.GET })
    },

    POST: function () {

        return useAxios({ method: 'post', url: `/bolsadetrabajo/admin/tipocontrato`, type: 'json', adapters: TipoContratoAdapters.POST })
    },

    PATCH: function (id: string) {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/admin/tipocontrato/${id}`, type: 'json', adapters: TipoContratoAdapters.PATCH })
    },

    DELETE: function (id: string) {

        return useAxios({ method: 'delete', url: `/bolsadetrabajo/admin/tipocontrato/${id}`, type: 'json', adapters: TipoContratoAdapters.DELETE })
    }
}

export {
    TiposContratoServices,
    TipoContratoServices
}

import { Placeholder } from '../../../Placeholder/Placeholder'
import './Loader.css'

export const Loader = () => {
  return (
    <>

      <section className="LayoutMain2__contentright container pb-3 mt-5">
        <nav className='LayoutMain2__navtop d-flex justify-content-between align-items-center'>
        <Placeholder className='' height='50px' width='100%' />

        </nav>
        <main className='LayoutMain2__contentmain'>
        <div className="row">
          <div className="col-12">
          <Placeholder className='my-3 delay-1' height='100px' width='100%' />
          <Placeholder className='my-3 delay-2' height='200px' width='100%' />

          </div>
        </div>
        </main>
      </section>
    </>
  )
}

import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useLang } from '../../../../../../../language'
import { useForm } from '../../../../../../../hooks/useForm'
import { useValidator } from '../../../../../../../hooks/useValidator'
import { getValuesParams, isStatusSucess } from '../../../../../../../helpers/helpers'
import { InputText } from '../../../../../../../components/Inputs'
import { ButtonSpinner } from '../../../../../../../components/ButtonSpinner/ButtonSpinner'
import { ModalStatusServer } from '../../../../../../../components/Modals/ModalStatusServer'
import { SalarioAdapters } from './controllers/salarios.adapter'
import { SalarioServices } from './controllers/salarios.service'

export const Salario = ({ showModal_TipoNoticias, handleCloseModal_TipoNoticias, fetchListado, formValuesSearch }: any) => {

    const Lang = useLang()

    const [formEstadoPersona, handleInputEstadoPersona, handleFormEstadoPersona, resetFormEstadoPersona]
        = useForm(SalarioAdapters.POST.send.schema({}))

    const [valuesValidationEstadoOferta, handleValidationEstadoOferta, handleResetValidationEstadoOferta] = useValidator({
        cantidad: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
        cantidadnumero: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
    })

    // ----------- POST Relacion

    const [fetchTipoNoticiasPOST, fetchTipoNoticiasPOSTData] = SalarioServices.POST()

    const handleSubmitCrear = () => {

        if (handleValidationEstadoOferta(formEstadoPersona)) {
            fetchTipoNoticiasPOST({
                body: formEstadoPersona
            })
        }
    }

    useEffect(() => {
        if (isStatusSucess(fetchTipoNoticiasPOSTData.status)) {
            handleCloseModal_TipoNoticias()

            const urlParams = getValuesParams(formValuesSearch)

            fetchListado({
                params: {
                    ...urlParams
                }
            })
        }
    }, [fetchTipoNoticiasPOSTData])

    // --------------- PATCH Relacion

    const [fetchTipoNoticiasPATCH, fetchTipoNoticiasPATCHData] = SalarioServices.PATCH(showModal_TipoNoticias.payload?.form?.id)

    const handleSubmitEditar = () => {

        fetchTipoNoticiasPATCH({
            body: {
                ...formEstadoPersona,
            }
        })
    }


    useEffect(() => {
        if (isStatusSucess(fetchTipoNoticiasPATCHData.status)) {
            handleCloseModal_TipoNoticias()
            const urlParams = getValuesParams(formValuesSearch)

            fetchListado({
                params: {
                    ...urlParams
                }
            })
        }
    }, [fetchTipoNoticiasPATCHData])

    // ---------- DELETE Relacion

    const [fetchTipoNoticiasDELETE, fetchTipoNoticiasDELETEData] = SalarioServices.DELETE(showModal_TipoNoticias.payload?.form?.id)

    const handleSubmitEliminar = () => {
        fetchTipoNoticiasDELETE()
    }

    useEffect(() => {
        if (isStatusSucess(fetchTipoNoticiasDELETEData.status)) {
            handleCloseModal_TipoNoticias()
            const urlParams = getValuesParams(formValuesSearch)

            fetchListado({
                params: {
                    ...urlParams
                }
            })
        }
    }, [fetchTipoNoticiasDELETEData])

    // Acciones mostrar ocultar modal

    useEffect(() => {

        if (showModal_TipoNoticias.value) {

            if (showModal_TipoNoticias.payload?.form?.id) {
                handleFormEstadoPersona({
                    ...showModal_TipoNoticias.payload?.form
                })
            }

        } else {
            resetFormEstadoPersona()
        }

    }, [showModal_TipoNoticias.value])

    return (
        <>
            <Modal show={showModal_TipoNoticias.value} onHide={handleCloseModal_TipoNoticias} size='lg' id='ModalRegistro'>
                <Modal.Header className='border-bottom-0 py-4'>
                    <Modal.Title className='h5'>
                        {showModal_TipoNoticias.payload.action === 'EDIT' ? Lang('EDITAR_SALARIO') : Lang('NUEVO_SALARIO')}
                    </Modal.Title>
                    <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleCloseModal_TipoNoticias}></button>
                </Modal.Header>
                <Modal.Body className='px-4 py-0'>
                    <Form className='row'>

                        <Form.Group className="mb-3 col-12">
                            <InputText
                                label={`${Lang('TEXTO')}:`}
                                name='cantidad'
                                value={formEstadoPersona.cantidad}
                                onChange={handleInputEstadoPersona}
                                fetchData={fetchTipoNoticiasPOSTData}
                                validation={valuesValidationEstadoOferta}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3 col-lg-4">
                            <InputText
                                label={`${Lang('CANTIDAD')}:`}
                                name='cantidadnumero'
                                type='number'
                                value={formEstadoPersona.cantidadnumero}
                                onChange={handleInputEstadoPersona}
                                fetchData={fetchTipoNoticiasPOSTData}
                                validation={valuesValidationEstadoOferta}
                            />
                        </Form.Group>

                        {/* <Form.Group className="mb-3 col-12">
                            <InputText
                                label={`${Lang('DESCRIPCION')}:`}
                                name='descripcion'
                                asParam='textarea'
                                rows={4}
                                value={formEstadoPersona.descripcion}
                                onChange={handleInputEstadoPersona}
                                fetchData={fetchTipoNoticiasPOSTData}
                                validation={valuesValidationEstadoOferta}
                            />
                        </Form.Group> */}

                    </Form>
                </Modal.Body>
                <Modal.Footer className='border-0 justify-content-between'>
                    {
                        showModal_TipoNoticias.payload?.action === 'EDIT' ? <>
                            <ButtonSpinner
                                variant="danger" type="submit" className="ms-2"
                                handleSubmit={handleSubmitEliminar} fetchDataLoading={[fetchTipoNoticiasDELETEData.loading]}
                                value={Lang('ELIMINAR')} icon={<FontAwesomeIcon icon={faTrash} />}
                            />
                            <ButtonSpinner
                                variant="secondary" type="submit" className="me-2"
                                handleSubmit={handleSubmitEditar} fetchDataLoading={[fetchTipoNoticiasPATCHData.loading]}
                                value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} />}
                            />
                        </> : <>
                            <div></div>
                            <ButtonSpinner
                                variant="secondary" type="submit" className="me-2 ms-auto"
                                handleSubmit={handleSubmitCrear} fetchDataLoading={[fetchTipoNoticiasPOSTData.loading]}
                                value={Lang('CREAR')} icon={<FontAwesomeIcon icon={faSave} />}
                            />
                        </>
                    }
                </Modal.Footer>
            </Modal>

            <ModalStatusServer fetchData={fetchTipoNoticiasPOSTData} />

            <ModalStatusServer fetchData={fetchTipoNoticiasPATCHData} onlySuccess={true} />

            <ModalStatusServer fetchData={fetchTipoNoticiasDELETEData} onlySuccess={true} />

        </>
    )
}

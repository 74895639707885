
import { useAxios } from '../../../../../hooks/useAxios';
import { EspecialidadAdapters, EspecialidadesAdapters } from '../adapters/especialidades.adapter';

const EspecialidadesServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/maestros/tipos-estudios-especialidades`, type: 'json', adapters: EspecialidadesAdapters.GET })
    }
}

const EspecialidadServices = {

    GET: function (id: any) {

        return useAxios({ method: 'get', url: `/maestros/tipos-estudios-especialidades/${id}`, type: 'json', adapters: EspecialidadAdapters.GET })
    },

    POST: function () {

        return useAxios({ method: 'post', url: `/maestros/tipos-estudios-especialidades`, type: 'json', adapters: EspecialidadAdapters.POST })
    },

    PATCH: function (id: any) {

        return useAxios({ method: 'patch', url: `/maestros/tipos-estudios-especialidades/${id}`, type: 'json', adapters: EspecialidadAdapters.PATCH })
    },

    DELETE: function (id:any) {

        return useAxios({ method: 'delete', url: `/maestros/tipos-estudios-especialidades/${id}`, type: 'json', adapters: EspecialidadAdapters.DELETE })
    }
}

export {
    EspecialidadesServices,
    EspecialidadServices,
}
const PlantillasAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idtiponotificacion || '',
                    codtiponotificacion: item.codtiponotificacion || '',
                    descripcion: item.descripcion || '',
                    tipomodulo: item.tipomodulo || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },
}

const PlantillaAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idtiponotificacion || '',
                    asunto: item.asunto || '',
                    codtiponotificacion: item.codtiponotificacion || '',
                    descripcion: item.descripcion || '',
                    idweb: item.idweb || '',
                    logfecha: item.logfecha || '',
                    logidpersona: item.logidpersona || '',
                    marcadores: item.marcadores ? ['marcadores'] : [] || '',
                    confirmacion: item.confirmacion ? ['confirmacion'] : [] || '',
                    mensaje: item.mensaje || '',
                    remitenteemail: item.remitenteemail || '',
                    remitentenombre: item.remitentenombre || '',
                    textolibre: item.textolibre || '',
                    variables: item.variables || [],
                    externa: (typeof item.externa === 'boolean') ? item.externa : '',
                    tipomodulo: item.tipomodulo || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idtiponotificacion || '',
                    asunto: item.asunto || '',
                    codtiponotificacion: item.codtiponotificacion || '',
                    descripcion: item.descripcion || '',
                    idweb: item.idweb || '',
                    logfecha: item.logfecha || '',
                    logidpersona: item.logidpersona || '',
                    confirmacion: item.confirmacion?.[0] ? true : false || false,
                    marcadores: item.marcadores?.[0] ? true : false || false,
                    mensaje: item.mensaje || '',
                    remitenteemail: item.remitenteemail || '',
                    remitentenombre: item.remitentenombre || '',
                    textolibre: item.textolibre || '',
                    camposvariables: item.variables || [],
                    tipomodulo: item.tipomodulo || '',

                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idtiponotificacion || '',
                    asunto: item.asunto || '',
                    codtiponotificacion: item.codtiponotificacion || '',
                    descripcion: item.descripcion || '',
                    idweb: item.idweb || '',
                    logfecha: item.logfecha || '',
                    logidpersona: item.logidpersona || '',
                    confirmacion: item.confirmacion?.[0] ? true : false || false,
                    marcadores: item.marcadores?.[0] ? true : false || false,
                    mensaje: item.mensaje || '',
                    remitenteemail: item.remitenteemail || '',
                    remitentenombre: item.remitentenombre || '',
                    textolibre: item.textolibre || '',
                    camposvariables: item.variables || [],
                    tipomodulo: item.tipomodulo || '',

                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },
}

export {
    PlantillasAdapters,
    PlantillaAdapters,
}
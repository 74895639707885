import { faBook, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLang } from '../../../../../../../../language'
import { ModalNivelIdioma } from './ModalNivelIdioma'
import { useModalData } from '../../../../../../../../hooks/useModalData'
import { Button, Form, Table } from 'react-bootstrap'
import { convertParamToObject } from '../../../../../../../../services/helpers'
import { cloneDeep } from 'lodash'
import { InputChecks } from '../../../../../../../../components/Inputs/InputChecks'
import { OfertaServices } from '../../../controllers/ofertas.service'
import { useForm } from '../../../../../../../../hooks/useForm'

export const TableNivelesIdioma = ({ data, logica_buscar_idioma, fetchInscripcionGET,
    fetchPersonaDatosProfesionalesGET, idofertaempleo, urlParams, fetchCondicionPagosRemesasGETData,
    fetchMultipleParamBolsaData }: any) => {

    const Lang = useLang()

    const [dataModal_NivelIdioma, handleShowModal_NivelIdioma, handleCloseModal_NivelIdioma] = useModalData()

    const [fetchNivelIdiomaPATCH, fetchNivelIdiomaPATCHData] = OfertaServices.PATCH_NIVEL_IDIOMA(idofertaempleo)

    // Funciones helpers

    const [formLogicaIdioma, handleInputLogicaIdioma, handleFormLogicaIdioma, resetFormLogicaIdioma] = useForm({
        logica_buscar_idioma: [],
    })

    const handleChangeCheck = (e: any, name: string) => {

        let listaTemp = cloneDeep(formLogicaIdioma[name])

        if (e.target.checked) {

            listaTemp.push(e.target.name)

        } else {

            listaTemp = listaTemp.filter((item: any) => item !== e.target.name)
        }

        handleFormLogicaIdioma({
            ...formLogicaIdioma,
            [name]: listaTemp
        })

        fetchNivelIdiomaPATCH({
            body: {
                logica_buscar_idioma: e.target.checked ? 'and' : 'or',
                idiomas: data
            }
        })
    }

    useEffect(() => {

        if (logica_buscar_idioma) {

            handleFormLogicaIdioma({
                ...formLogicaIdioma,
                logica_buscar_idioma: logica_buscar_idioma === 'and' ? ['and'] : [],
            })
        }

    }, [logica_buscar_idioma]);

    return (
        <>
            <div className="d-flex flex-wrap justify-content-between align-items-start mb-3">
                <h5 className="mb-3 mb-lg-0 ">{Lang('NIVELES_IDIOMA')}</h5>
                <Button size='sm' onClick={() => handleShowModal_NivelIdioma({
                    idofertaempleo,
                    action: 'CREATE',
                    data,
                })}>
                    <FontAwesomeIcon icon={faPlus} className='me-2' /> {Lang('AGREGAR_NIVEL_IDIOMA')}
                </Button>
            </div>

            <Table responsive className='position-relative mb-0' striped bordered>
                <thead>
                    <tr>
                        <th>
                            <small>{Lang('IDIOMA')}</small>
                        </th>
                        <th>
                            <small>{Lang('NIVEL_IDIOMA')}</small>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        <>
                            {
                                data?.map((item: any, index: number) => {

                                    return (
                                        <tr>
                                            <td className='text-nowrap'>
                                                <small>{convertParamToObject(fetchMultipleParamBolsaData?.data?.['idiomas'])[item.ididioma]}</small>
                                            </td>

                                            <td>
                                                <small>{convertParamToObject(fetchMultipleParamBolsaData?.data?.['nivelidioma'])[item.idnivelidioma]}</small>
                                            </td>

                                            <td className='text-center text-nowrap shrink-td' valign='middle'>

                                                <Button onClick={() => handleShowModal_NivelIdioma({
                                                    idofertaempleo,
                                                    item,
                                                    data,
                                                    action: 'EDIT',
                                                    index
                                                })} size='sm' className='text-nowrap ms-2' >
                                                    <FontAwesomeIcon icon={faBook} />
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                data?.length === 0 &&
                                <tr>
                                    <td colSpan={20} className='text-center py-5 bg-gray-1'>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</td>
                                </tr>
                            }

                        </>

                    }

                </tbody>

            </Table>

            <div className='mb-3 mt-2 row justify-content-end'>
                <Form.Group className="mb-0 col flex-grow-0 text-nowrap">
                    <div className="row">
                        <small>
                            <InputChecks
                                name='logica_buscar_idioma'
                                value={formLogicaIdioma.logica_buscar_idioma}
                                values={[{ id: 'and', nombre: Lang('REQUERIR_TODOS_IDIOMAS') }]}
                                classNameContainer='d-inline-block me-0'
                                onChange={(e: any) => handleChangeCheck(e, 'logica_buscar_idioma')}
                            // fetchData={nuevo ? fetchPersonaPOSTData : fetchPerfilesPATCHData}
                            />
                        </small>

                    </div>
                </Form.Group>
            </div>

            <ModalNivelIdioma
                data={dataModal_NivelIdioma}
                handleClose={handleCloseModal_NivelIdioma}
                fetchPersonaDatosProfesionalesGET={fetchPersonaDatosProfesionalesGET}
                urlParams={urlParams}
                fetchCondicionPagosRemesasGETData={fetchCondicionPagosRemesasGETData}
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                fetchInscripcionGET={fetchInscripcionGET}
                idofertaempleo={idofertaempleo}
                fetchNivelIdiomaPATCH={fetchNivelIdiomaPATCH}
                fetchNivelIdiomaPATCHData={fetchNivelIdiomaPATCHData}
                logica_buscar_idioma={logica_buscar_idioma}
            />

        </>
    )
}

import { Placeholder } from '../../Placeholder/Placeholder'

export const TableAdvancedLoader = () => {
    return (
        <>
            <tr style={{ 'borderWidth': 0 }} className='pe-none'>
                <td colSpan={20}><Placeholder className='px-4 py-2' height='1.2rem' /></td>
            </tr>
            <tr style={{ 'borderWidth': 0 }} className='pe-none'>
                <td colSpan={20}><Placeholder className='px-4 py-2 delay-1' height='1.2rem' /></td>
            </tr>
            <tr style={{ 'borderWidth': 0 }} className='pe-none'>
                <td colSpan={20}><Placeholder className='px-4 py-2 delay-2' height='1.2rem' /></td>
            </tr>
            <tr style={{ 'borderWidth': 0 }} className='pe-none'>
                <td colSpan={20}><Placeholder className='px-4 py-2 delay-3' height='1.2rem' /></td>
            </tr>
            <tr style={{ 'borderWidth': 0 }} className='pe-none'>
                <td colSpan={20}><Placeholder className='px-4 py-2 delay-4' height='1.2rem' /></td>
            </tr>
        </>
    )
}

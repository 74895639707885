import { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { BreadcrumbLine } from '../../../../../../../components/BreadcrumbLine/BreadcrumbLine';
import { useForm } from '../../../../../../../hooks/useForm';
import { useLang } from '../../../../../../../language';
import { isStatusSucess } from '../../../../../../../helpers/helpers';
import { TicketClientMensajeServices, TicketClientServices, TicketsClientMensajesServices } from '../../controllers/tickets.service';
import { TicketClientAdapters } from '../../controllers/tickets.adapter';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullpage';
import 'tinymce/plugins/lists';

import 'tinymce/skins/ui/oxide/skin.min.css';
import { TicketForm } from '../../../../../../../pages/Ticket/TicketForm';
import { LoaderFormPrimary } from '../../../../../../../components/Loaders/LoaderFormPrimary';

const TicketEdit = ({ pathName, fetchEntidadesEmisorasGETData }: any) => {

  let params = useParams();

  const idTicket: string = String(params.id) || ''

  const Lang = useLang()


  const [formTicket, handleInputTicket, handleFormTicket, resetFormTicket] = useForm({
    ...TicketClientAdapters.GET.receive.schema({}),
  })

  const [formTicketMensaje, handleInputTicketMensaje, handleFormTicketMensaje, resetFormTicketMensaje] = useForm({
    listadestinatariosarray: []
  })


  const BreadcrumbLineItems = [
    {
      title: Lang('TICKETS'),
      link: '/ticketing/' + pathName
    },
    {
      title: Lang('NUEVO_TICKET'),
      link: '/ticket'
    }
  ]

  const [fetchTicketGET, fetchTicketGETData] = TicketClientServices.GET(idTicket)

  // const [fetchTicketsMensajesGET, fetchTicketsMensajesGETData] = TicketsClientMensajesServices.GET()

  useEffect(() => {

    fetchTicketGET()

    // fetchTicketsMensajesGET()

  }, [])

  useEffect(() => {

    if (isStatusSucess(fetchTicketGETData.status)) {

      handleFormTicket({
        ...fetchTicketGETData.data
      })

      handleFormTicketMensaje({
        ...formTicketMensaje,
        ticket: fetchTicketGETData.data?.ticket,
      })

    }

  }, [fetchTicketGETData])

  
  const [fetchTicketMensajePOST, fetchTicketMensajePOSTData] = TicketClientMensajeServices.POST()

  const handleSubmitTicketMensajePOST = (body: any) => {
  
    fetchTicketMensajePOST({
      body
    })
  }

  return (

    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className='d-flex justify-content-between align-items-center mb-1 flex-wrap'>
            <BreadcrumbLine BreadcrumbLineItems={BreadcrumbLineItems} />
            <div className='d-flex flex-wrap'>

            </div>
          </div>
        </div>
        
        {
            fetchTicketGETData.loading ?
              <LoaderFormPrimary />
              :
              <TicketForm
                fetchData={fetchTicketGETData.data}
                handleSubmitTicketMensajePOST={handleSubmitTicketMensajePOST}
                fetchTicketMensajePOSTData={fetchTicketMensajePOSTData}
              />
          }

      </div>

    </div>
  )
}

export default TicketEdit;
import { useAxios } from "../../../../../../../../hooks/useAxios"
import { NivelIdiomaAdapters, NivelesIdiomaAdapters } from "./nivelesidioma.adapter"

const NivelesIdiomaServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/admin/nivelidioma`, type: 'json', adapters: NivelesIdiomaAdapters.GET })
    }
}

const NivelIdiomaServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/`, type: 'json', adapters: NivelIdiomaAdapters.GET })
    },

    POST: function () {

        return useAxios({ method: 'post', url: `/bolsadetrabajo/admin/nivelidioma`, type: 'json', adapters: NivelIdiomaAdapters.POST })
    },

    PATCH: function (id: string) {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/admin/nivelidioma/${id}`, type: 'json', adapters: NivelIdiomaAdapters.PATCH })
    },

    DELETE: function (id: string) {

        return useAxios({ method: 'delete', url: `/bolsadetrabajo/admin/nivelidioma/${id}`, type: 'json', adapters: NivelIdiomaAdapters.DELETE })
    }
}

export {
    NivelesIdiomaServices,
    NivelIdiomaServices
}
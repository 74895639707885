import { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { BreadcrumbLine } from '../../../../../../components/BreadcrumbLine/BreadcrumbLine';
import { ModalConfirm } from '../../../../../../components/Modals/ModalConfirm';
import { useLang } from '../../../../../../language';
import { DatosGenerales } from './DatosGenerales';
import { ListMenuRoutes } from '../../../../../../components/ListMenu/ListMenuRoutes';
import { EstadosEventoServices } from '../../../../../../services/statics.service';
import { InformacionAdicional } from './InformacionAdicional';
import { isStatusSucess } from '../../../../../../helpers/helpers';
import { Inscripciones } from './Inscripciones';
import { OfertaServices } from '../controllers/ofertas.service';
import { BusquedaPersonas } from './BusquedaPersonas';

const Evento = ({ pathName }: any) => {

  let params = useParams();

  const idevento: string = String(params.idevento) || ''

  const Lang = useLang()

  // const [formVisado, handleInputVisado, handleFormVisado, resetFormVisado] = useForm({
  //   ...OfertaAdapters.GET.receive.schema({}),
  // })

  const [fetchInscripcionGET, fetchInscripcionGETData] = OfertaServices.GET(idevento)

  const BreadcrumbLineItems = [
    {
      title: Lang('OFERTAS'),
      link: '/bolsa-trabajo-admin/' + pathName
    },
    {
      title: fetchInscripcionGETData?.data?.nombreoferta,
      link: '/'
    }
  ]


  useEffect(() => {

    // resetFormVisado()

    fetchInscripcionGET()

  }, [idevento])

  // useEffect(() => {

  //   if (isStatusSucess(fetchInscripcionGETData.status)) {

  //     handleFormVisado({
  //       ...fetchInscripcionGETData.data
  //     })

  //   }

  // }, [fetchInscripcionGETData])


  const listMenu: any = {
    datosGenerales: {
      title: Lang('DATOS_GENERALES'),
      link: 'datos-generales',
    },
    informacionAdicional: {
      title: Lang('INFORMACION_ADICIONAL'),
      link: 'informacion-adicional',
    },
    busqueda: {
      title: Lang('BUSCADOR'),
      link: 'busqueda',
    },
  }

  const listMenu2: any = {
    datosGenerales: {
      title: Lang('PERSONAS'),
      link: 'inscripciones',
    },
  }

  const staticEstadosEventoServices: any = EstadosEventoServices.GET()

  const [showConfirm_CHANGE_ESTADO, setshowConfirm_CHANGE_ESTADO] = useState<any>({
    value: false,
    payload: {}
  })

  const [fetchInscripcionPATCH, fetchInscripcionPATCHData] = OfertaServices.PATCH_CHANGE_ESTADO(idevento)

  useEffect(() => {

    if (isStatusSucess(fetchInscripcionPATCHData.status)) {

      setshowConfirm_CHANGE_ESTADO({
        value: false,
        payload: {}
      })
      fetchInscripcionGET()
    }

  }, [fetchInscripcionPATCHData])

  const handleChangeEstadoEvento = () => {

    fetchInscripcionPATCH({
      body: {
        estado: showConfirm_CHANGE_ESTADO.payload?.id
      }
    })
  }

  return (

    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className='d-flex justify-content-between align-items-center mb-1 flex-wrap'>
            <BreadcrumbLine BreadcrumbLineItems={BreadcrumbLineItems} />
            <div className='d-flex flex-wrap'>
             
            </div>
          </div>
        </div>

        <div className="col-lg-3 mb-4">

          <ListMenuRoutes values={listMenu} />

          <div className="py-2">

          </div>

          <ListMenuRoutes values={listMenu2} />

        </div>

        <div className="col-lg-9 animate__animated animate__fadeInUp">
          <Routes>

            <Route
              path='datos-generales'
              element={
                <DatosGenerales
                  fetchInscripcionGETData={fetchInscripcionGETData}
                  fetchInscripcionGET={fetchInscripcionGET}
                  idevento={idevento}
                />
              }
            />

            <Route
              path='informacion-adicional'
              element={
                <InformacionAdicional
                  fetchInscripcionGET={fetchInscripcionGET}
                  fetchInscripcionGETData={fetchInscripcionGETData}
                  idofertaempleo={idevento}
                />
              }
            />

            <Route
              path='busqueda'
              element={
                <BusquedaPersonas
                  fetchInscripcionGET={fetchInscripcionGET}
                  fetchInscripcionGETData={fetchInscripcionGETData}
                  idofertaempleo={idevento}
                />
              }
            />

            <Route
              path='inscripciones'
              element={
                <Inscripciones
                  idofertaempleo={idevento}
                  fetchInscripcionEventoGETData={fetchInscripcionGETData}
                />
              }
            />

            <Route
              path="*"
              element={<Navigate to='datos-generales' replace />}
            />
          </Routes>

        </div>
      </div>

      <ModalConfirm
        handleClose={() => setshowConfirm_CHANGE_ESTADO({ value: false, payload: {} })}
        show={showConfirm_CHANGE_ESTADO.value}
        onClickOK={handleChangeEstadoEvento}
        messageHTML={
          <h5 className='text-center fw-normal'>{Lang('PARRAFO_SEGURO_CAMBIAR_ESTADO_EVENTO_A')} {showConfirm_CHANGE_ESTADO.payload?.nombre}?</h5>
        }
        textOnClickOk={Lang('SI_CAMBIAR')}
        fetchData={fetchInscripcionPATCHData}
      />

    </div>
  )
}

export default Evento;
import { Form } from 'react-bootstrap'

export const MsgInput = ({ name, obj }) => {

    return (
        <>
            {
                !obj[name]?.isOk && obj[name]?.msgError.map((elem, index) => {
                    return (
                        <Form.Text key={index} className='text-danger d-block'>{elem}</Form.Text>
                    )
                })
            }
        </>
    )
}

import React, { useEffect, useRef, useState } from "react";
import { ButtonSpinner } from "../../components/ButtonSpinner/ButtonSpinner";
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullpage';
import 'tinymce/plugins/lists';

import 'tinymce/skins/ui/oxide/skin.min.css';
import { Editor } from '@tinymce/tinymce-react';
import { InputChips } from '../../components/Inputs/inputChips';
import { useForm } from "../../hooks/useForm";
import { useLang } from "../../language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faPaperPlane, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Button, Form } from "react-bootstrap";
import { InputFile } from "../../components/Inputs/InputFile";
import { TicketMensaje } from "../../components/Ticket/TicketMensaje/TicketMensaje";
import { ModalStatusServer } from "../../components/Modals/ModalStatusServer";
import { cloneDeep } from "lodash";
import { MsgInput } from "../../components/MsgInput/MsgInput";
import { useValidator } from "../../hooks/useValidator";
import { hasTextContent, isStatusSucess } from "../../helpers/helpers";
import { FilterPersonaTicket } from "../../components/Modals/ModalFilters/FilterPersonaTicket";
import { ModalCorreoNuevo } from "../../components/Ticket/ModalCorreoNuevo/ModalCorreoNuevo";
import { useModalData } from "../../hooks/useModalData";

export const TicketForm = ({ fetchData, handleSubmitTicketMensajePOST, fetchTicketMensajePOSTData, token }: any) => {

    const Lang = useLang()

    const editorRef: any = useRef(null);

    const [formTicket, handleInputTicket, handleFormTicket, resetFormTicket] = useForm({
        ...fetchData,
        ticket_personas_c_c: [
            ...(
                (fetchData?.ticket_personas_c_c || [])?.map((item: any) => {
                    return {
                        id: item.idpersona,
                        name: item.nombre_destinatario,
                    }
                })
            ),
            ...(
                (fetchData?.ticket_personas_no_registradas_c_c || [])?.map((item: any) => {
                    return {
                        id: item.email,
                        name: item.nombre_apellidos,
                    }
                })
            )
        ]
    })

    const [valuesValidationTicket, handleValidationTicket, handleResetValidationTicket] = useValidator({
        descripcion: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
    })

    const inputFileFichero: any = useRef(null)

    const [ticketAbierto, setticketAbierto] = useState(fetchData?.estado !== 'finalizado');

    const handleEditorChange = (content: any) => {

        handleFormTicket({
            ...formTicket,
            descripcion: content
        })
    }

    const handleDestinatariosChange = (data: any) => {

        handleFormTicket({
            ...formTicket,
            ticket_personas_c_c: data
        })
    }


    // Filtro destinatarios

    const [showModal_FiltroPersonasDest, setShowModal_FiltroPersonasDest] = useState({
        value: false,
        payload: {}
    });

    const handleCloseModal_FiltroPersonasDest = () => setShowModal_FiltroPersonasDest({
        value: false,
        payload: {}
    });

    const handleShowModal_FiltroPersonasDest = (payload: any) => setShowModal_FiltroPersonasDest({
        value: true,
        payload
    });

    const [filterPersonaDest, setFilterPersonaDest] = useState({
        id: '',
        nombre: ''
    })

    useEffect(() => {

        if (filterPersonaDest.id) {

            let arrayTemp = formTicket['ticket_personas_c_c']

            arrayTemp.push({
                id: filterPersonaDest.id,
                name: filterPersonaDest.nombre
            })

            const obj = {
                target: {
                    name: 'ticket_personas_c_c',
                    value: arrayTemp
                }
            }

            handleInputTicket(obj)
        }

    }, [filterPersonaDest])

    const [idBtnEnviarMensaje, setidBtnEnviarMensaje] = useState(-1);


    const handleSubmitPOST = (id: number) => {

        const formTicketOnlyValidation = cloneDeep(formTicket)
        formTicketOnlyValidation.descripcion = hasTextContent(formTicketOnlyValidation.descripcion)

        if (handleValidationTicket(formTicketOnlyValidation)) {

            setidBtnEnviarMensaje(id)

            const documents: any = Array.from(inputFileFichero?.current?.files)

            const formTicketTemp: any = cloneDeep({
                ticket: formTicket.ticket,
                asunto: formTicket.ticket,
                descripcion: formTicket.descripcion,
                estado: formTicket.estado,
                idticket: formTicket.idticket,
            })

            if (id === 1) {

                formTicketTemp.estado = 'finalizado'

            } else if (id === 2 && formTicketTemp.estado === 'finalizado') {

                formTicketTemp.estado = 'en_proceso'
            }

            const destinatarios = formTicket?.['ticket_personas_c_c']?.map((item: any) => {

                return {
                    id: item.id,
                    nombre: item.name,
                }

            }) || []

            delete formTicketTemp.listadestinatarios

            let formData = new FormData();

            for (const key in formTicketTemp) {
                formData.append(key, formTicketTemp[key])
            }

            for (const file of documents) {
                formData.append('documents[]', file);
            }

            if (destinatarios.length === 0) {

                formData.append('listadestinatarios[]', '');

            } else {

                for (const item of destinatarios) {
                    formData.append('listadestinatarios[]', JSON.stringify(item));
                }
            }

            handleSubmitTicketMensajePOST(formData)
        }

    }

    useEffect(() => {

        if (isStatusSucess(fetchTicketMensajePOSTData.status)) {

            window.location.reload()
        }

    }, [fetchTicketMensajePOSTData]);

    const [dataModal_CorreoNuevo, handleShow_CorreoNuevo, handleClose_CorreoNuevo] = useModalData()

    return (
        <section>

            <div className="row mt-2">

                <div className="col-12">

                    <div className='d-flex flex-wrap justify-content-between align-items-center mb-0'>

                        <div className="d-flex align-items-start">
                            <div className='d-flex flex-wrap align-items-end'>
                                <h5 className='mb-0'>{formTicket.codigoticket}. {formTicket.ticket}</h5>
                            </div>
                            <span className="ms-3 badge badge bg-secondary" style={{ fontSize: '.9rem' }}>{formTicket.nombre_urgencia}</span>
                        </div>

                        <div>
                            {
                                !ticketAbierto &&
                                <ButtonSpinner
                                    variant="outline-secondary" type="button" className="ms-4"
                                    handleSubmit={() => setticketAbierto(true)} fetchDataLoading={[fetchTicketMensajePOSTData.loading]}
                                    value={Lang('REABRIR_TICKET')} icon={<FontAwesomeIcon icon={faArrowUp} className='me-1' />}
                                />
                            }
                            {
                                formTicket.estado !== 'finalizado' &&
                                <span className="ms-3 badge badge bg-info" style={{ fontSize: '.9rem' }}>{formTicket.nombre_estado}</span>
                            }
                        </div>

                    </div>

                </div>

                <div className="col-12">

                    <div className='mb-0 py-1'>

                        <div className='d-flex flex-wrap align-items-center justify-content-between my-2'>

                            <div className='d-flex flex-wrap align-items-center'>

                                <div className="d-flex me-4">
                                    <label className="fw-500 form-label me-2 mb-0">{Lang('PRODUCTO')}:</label> <span>{formTicket.nombre_producto}</span>
                                </div>
                                <div className="d-flex me-4">
                                    <label className="fw-500 form-label me-2 mb-0">{Lang('PERSONA_REGISTRO')}:</label> <span>{formTicket.personaregistro}</span>
                                </div>
                                <div className="d-flex me-4">
                                    <label className="fw-500 form-label me-2 mb-0">{Lang('ENTIDAD')}:</label> <span>{formTicket.nombre_entidad}</span>
                                </div>
                                <div className="d-flex me-4">
                                    <label className="fw-500 form-label me-2 mb-0">{Lang('TECNICO_ASOCIADO')}:</label> <span>{formTicket.nombre_tecnicoasociado}</span>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div className="col-lg-12 mx-auto">

                    {
                        ticketAbierto &&
                        <div className="p-3 card mb-4 animate__animated animate__fadeInUp animate__faster">

                            <div className="row">

                                <div className="col-12 col-lg-7">
                                    <Form className='row'>

                                        <Form.Group className="mb-3 col-12">

                                            <Form.Label className='fw-500 d-block mb-2'>
                                                <div className='me-auto'>{Lang('MENSAJE')}</div>
                                            </Form.Label>

                                            <Editor
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                // initialValue={dataInicial}
                                                init={{
                                                    skin: false,
                                                    branding: false,
                                                    content_css: false,
                                                    height: 250,
                                                    menubar: false,
                                                    relative_urls: false,
                                                    remove_script_host: false,
                                                    convert_urls: false,
                                                    image_title: true,
                                                    file_picker_callback: function (cb, value, meta) {
                                                        var input = document.createElement('input');
                                                        input.setAttribute('type', 'file');
                                                        input.setAttribute('accept', 'image/*');

                                                        input.onchange = function () {

                                                            var thisTemp: any = this

                                                            var file = thisTemp.files[0];

                                                            var reader: any = new FileReader();
                                                            reader.onload = function () {
                                                                var id = 'blobid' + (new Date()).getTime();

                                                                var blobCache = editorRef.current.editorUpload.blobCache;
                                                                var base64 = reader.result.split(',')[1];
                                                                var blobInfo = blobCache.create(id, file, base64);
                                                                blobCache.add(blobInfo);

                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                            };
                                                            reader.readAsDataURL(file);
                                                        };

                                                        input.click();
                                                    },
                                                    plugins: [
                                                        'link image',
                                                        'table paste',
                                                        'code',
                                                        'fullpage',
                                                        'lists',
                                                    ],
                                                    toolbar:
                                                        'undo redo | formatselect | bold italic forecolor backcolor | \
                                                        alignleft aligncenter alignright alignjustify | \
                                                        bullist numlist outdent indent | removeformat | help code | image',
                                                    paste_word_valid_elements: 'a,table,tbody,tr,td,span,div,style',
                                                    extended_valid_elements: 'style',
                                                    custom_elements: 'style'
                                                }}
                                                value={formTicket.descripcion}
                                                onEditorChange={handleEditorChange}
                                            />

                                            <MsgInput obj={valuesValidationTicket} name='descripcion' />

                                        </Form.Group>

                                    </Form>
                                </div>

                                <div className="col-12 col-lg-5">

                                    <Form className='row'>


                                        <Form.Group className="mb-3 col-12">
                                            <div className="d-flex justify-content-between align-items-end mb-2">
                                                <label className="fw-500 form-label me-2 mb-0">{Lang('DESTINATARIOS')} (CC):</label> <span></span>
                                                {
                                                    formTicket.esPropietario &&
                                                    <Button
                                                        variant="primary"
                                                        onClick={handleShowModal_FiltroPersonasDest}
                                                        size='sm'
                                                        className="ms-auto"
                                                        disabled={!fetchData?.identidad}
                                                    >
                                                        <FontAwesomeIcon icon={faUsers} />
                                                    </Button>
                                                }
                                            </div>

                                            <InputChips
                                                data={formTicket.ticket_personas_c_c}
                                                setData={handleDestinatariosChange}
                                                readonly={!formTicket.esPropietario}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3 col-12">
                                            <div className='mb-0'>
                                                <Form.Label className='fw-500'>{Lang('ADJUNTO')}:</Form.Label>
                                                <InputFile
                                                    name='file'
                                                    // onChange={handleInputEnvioEmail}
                                                    referencia={inputFileFichero}
                                                    multiple
                                                />

                                            </div>

                                        </Form.Group>

                                    </Form>
                                </div>

                                <div className="col-12">
                                    <div className="d-flex justify-content-center mt-2">

                                        {
                                            formTicket.estado !== 'finalizado' &&
                                            <ButtonSpinner
                                                variant="outline-secondary" type="button" className=""
                                                handleSubmit={() => handleSubmitPOST(1)} fetchDataLoading={[idBtnEnviarMensaje === 1 && fetchTicketMensajePOSTData?.loading]}
                                                value={Lang('ENVIAR_CERRAR_TICKET')} icon={<FontAwesomeIcon icon={faPaperPlane} className='me-1' />}
                                            />
                                        }

                                        <ButtonSpinner
                                            variant="secondary" type="button" className=" ms-4"
                                            handleSubmit={() => handleSubmitPOST(2)} fetchDataLoading={[idBtnEnviarMensaje === 2 && fetchTicketMensajePOSTData?.loading]}
                                            value={Lang('ENVIAR_MENSAJE')} icon={<FontAwesomeIcon icon={faPaperPlane} className='me-1' />}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="row justify-content-center mb-4">
                        <div className="col-12 col-lg-10">

                            {
                                (fetchData?.ticket_mensajes || [])?.map((item: any, index: number) => {
                                    return (
                                        <TicketMensaje
                                            asunto={item.asunto}
                                            descripcion={item.descripcion}
                                            remitente={item.remitente?.nombre_remitente || ''}
                                            fecha={item.created_at || ''}
                                            hora={(new Date(item.created_at)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                            key={index}
                                            className={index === 0 && !ticketAbierto ? 'disableLine mt-2' : ''}
                                            ticket_mensaje_documentos={item.ticket_mensaje_documentos || []}
                                            id={item.idticketmensaje || index}
                                        />
                                    )
                                })
                            }

                        </div>
                    </div>

                </div>

            </div>

            <FilterPersonaTicket
                showModal_FiltroPersonas={showModal_FiltroPersonasDest}
                handleCloseModal_FiltroPersonas={handleCloseModal_FiltroPersonasDest}
                setFilterPersona={setFilterPersonaDest}
                idEntidad={fetchData?.identidad}
                token={token}
                handleShow_CorreoNuevo={handleShow_CorreoNuevo}
            />

            <ModalStatusServer fetchData={fetchTicketMensajePOSTData} />

            <ModalCorreoNuevo
                data={dataModal_CorreoNuevo}
                handleClose={handleClose_CorreoNuevo}
                setFilterPersonaDest={setFilterPersonaDest}
            />

        </section>
    )
};

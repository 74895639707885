const langTheme = {
    CA: {
        PARRAFO_1_INTRANET: `Per continuar, seleccioneu el mòdul on voleu introduir. Si teniu dificultats per accedir contacta'ns al telèfon 973 232 253, o envia'ns un email a cetill@enginyerslleida.cat`,
        MENU_FACTURAS_VENTA: 'Factures venda',
        MENU_FACTURAS_COMPRA: 'Factures compra',
        MENU_COBROS: 'Cobraments',
        MENU_PAGOS: 'Pagaments'
    }
}

export default langTheme;
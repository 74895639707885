import { faArrowUpRightFromSquare, faBook, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TableAdvanced from '../../../../../../../../components/Tables/TableAdvanced/TableAdvanced'
import { useLang } from '../../../../../../../../language'
import { useModalData } from '../../../../../../../../hooks/useModalData'
import { Button, Form, Table } from 'react-bootstrap'
import { convertParamToObject } from '../../../../../../../../services/helpers'
import { ModalEstudio } from './ModalEstudio'
import { InputChecks } from '../../../../../../../../components/Inputs/InputChecks'
import { useForm } from '../../../../../../../../hooks/useForm'
import { cloneDeep, isArray } from 'lodash'
import { OfertaServices } from '../../../controllers/ofertas.service'

export const TableEstudios = ({ data, logica_buscar_tipoestudio, logica_buscar_tipoestudioespecialidad, fetchInscripcionGET,
    fetchPersonaDatosProfesionalesGET, idofertaempleo, urlParams, fetchCondicionPagosRemesasGETData, fetchMultipleParamBolsaData,
    fetchEspecialidadesGETData }: any) => {

    const navigate = useNavigate();

    const Lang = useLang()

    const [dataModal_NivelIdioma, handleShowModal_NivelIdioma, handleCloseModal_NivelIdioma] = useModalData()

    const [fetchPersonaEntidadPATCH, fetchPersonaEntidadPATCHData] = OfertaServices.PATCH_TIPO_ESTUDIO(idofertaempleo)

    const [formLogicaEstudios, handleInputLogicaEstudios, handleFormLogicaEstudios, resetFormLogicaEstudios] = useForm({
        logica_buscar_tipoestudio: [],
        logica_buscar_tipoestudioespecialidad: [],
    })

    const handleChangeCheck = (e: any, name: string) => {

        let listaTemp = cloneDeep(formLogicaEstudios[name])

        if (e.target.checked) {

            listaTemp.push(e.target.name)

        } else {

            listaTemp = listaTemp.filter((item: any) => item !== e.target.name)
        }

        handleFormLogicaEstudios({
            ...formLogicaEstudios,
            [name]: listaTemp
        })

        fetchPersonaEntidadPATCH({
            body: {
                ...formLogicaEstudios,
                logica_buscar_tipoestudio: isArray(formLogicaEstudios.logica_buscar_tipoestudio) && formLogicaEstudios.logica_buscar_tipoestudio?.length > 0 ? 'and' : 'or',
                logica_buscar_tipoestudioespecialidad: isArray(formLogicaEstudios.logica_buscar_tipoestudioespecialidad) && formLogicaEstudios.logica_buscar_tipoestudioespecialidad?.length > 0 ? 'and' : 'or',
                [name]: e.target.checked ? 'and' : 'or',
                tiposestudios: data
            }
        })
    }

    useEffect(() => {

        if (logica_buscar_tipoestudio || logica_buscar_tipoestudioespecialidad) {

            handleFormLogicaEstudios({
                ...formLogicaEstudios,
                logica_buscar_tipoestudio: logica_buscar_tipoestudio === 'and' ? ['and'] : [],
                logica_buscar_tipoestudioespecialidad: logica_buscar_tipoestudioespecialidad === 'and' ? ['and'] : [],
            })
        }

    }, [logica_buscar_tipoestudio, logica_buscar_tipoestudioespecialidad]);

    return (
        <>
            <div className="d-flex flex-wrap justify-content-between align-items-start mb-3">
                <h5 className="mb-3 mb-lg-0 ">{Lang('TIPO_ESTUDIO')}</h5>
                <Button size='sm' onClick={() => handleShowModal_NivelIdioma({
                    idofertaempleo,
                    action: 'CREATE',
                    data,
                })}>
                    <FontAwesomeIcon icon={faPlus} className='me-2' /> {Lang('ANADIR_ESTUDIO')}
                </Button>
            </div>

            <Table responsive className='position-relative' striped bordered>
                <thead>
                    <tr>
                        <th>
                            <small>{Lang('ESTUDIO')}</small>
                        </th>
                        <th>
                            <small>{Lang('ESPECIALIDAD')}</small>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        <>
                            {
                                data?.map((item: any, index: number) => {

                                    return (
                                        <tr>
                                            <td className='text-nowrap'>
                                                <small>{convertParamToObject(fetchMultipleParamBolsaData?.data?.['tiposestudios'])[item.idtipoestudio]}</small>
                                            </td>

                                            <td>
                                                <small>{convertParamToObject(fetchMultipleParamBolsaData?.data?.['tiposestudiosespecialidades'])[item.idtipoestudioespecialidad]}</small>
                                            </td>

                                            <td className='text-center text-nowrap shrink-td' valign='middle'>

                                                <Button onClick={() => handleShowModal_NivelIdioma({
                                                    idofertaempleo,
                                                    item,
                                                    data,
                                                    action: 'EDIT',
                                                    index
                                                })} size='sm' className='text-nowrap ms-2' >
                                                    <FontAwesomeIcon icon={faBook} />
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                data?.length === 0 &&
                                <tr>
                                    <td colSpan={20} className='text-center py-5 bg-gray-1'>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</td>
                                </tr>
                            }

                        </>

                    }

                </tbody>

            </Table>

            <div className='mb-3 mt-2 row justify-content-end'>
                <Form.Group className="mb-0 col flex-grow-0 text-nowrap">
                    <div className="d-flex">
                        <small>
                            <InputChecks
                                name='logica_buscar_tipoestudio'
                                value={formLogicaEstudios.logica_buscar_tipoestudio}
                                values={[{ id: 'and', nombre: Lang('REQUERIR_TODOS_ESTUDIOS') }]}
                                classNameContainer='d-inline-block me-0'
                                onChange={(e: any) => handleChangeCheck(e, 'logica_buscar_tipoestudio')}
                            />
                        </small>

                        <small className='ps-3'>
                            <InputChecks
                                name='logica_buscar_tipoestudioespecialidad'
                                value={formLogicaEstudios.logica_buscar_tipoestudioespecialidad}
                                values={[{ id: 'and', nombre: Lang('REQUERIR_TODAS_ESPECIALIDADES') }]}
                                classNameContainer='d-inline-block me-0'
                                onChange={(e: any) => handleChangeCheck(e, 'logica_buscar_tipoestudioespecialidad')}
                            />
                        </small>

                    </div>
                </Form.Group>

            </div>


            <ModalEstudio
                data={dataModal_NivelIdioma}
                handleClose={handleCloseModal_NivelIdioma}
                fetchPersonaDatosProfesionalesGET={fetchPersonaDatosProfesionalesGET}
                urlParams={urlParams}
                fetchCondicionPagosRemesasGETData={fetchCondicionPagosRemesasGETData}
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                fetchInscripcionGET={fetchInscripcionGET}
                fetchEspecialidadesGETData={fetchEspecialidadesGETData}

                fetchPersonaEntidadPATCH={fetchPersonaEntidadPATCH}
                fetchPersonaEntidadPATCHData={fetchPersonaEntidadPATCHData}
            />

        </>
    )
}

import { Navigate, Outlet } from "react-router"

interface IPublicRoute{
    isAuthenticated: boolean
}

export const PublicRoute = ({ isAuthenticated }: IPublicRoute) => {

    return !isAuthenticated ? <Outlet /> : <Navigate to="/" replace />
}

import { useAxios } from "../../../../../../../../hooks/useAxios"
import { SalarioAdapters, SalariosAdapters } from "./salarios.adapter"

const SalariosServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/admin/salario`, type: 'json', adapters: SalariosAdapters.GET })
    }
}

const SalarioServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/`, type: 'json', adapters: SalarioAdapters.GET })
    },

    POST: function () {

        return useAxios({ method: 'post', url: `/bolsadetrabajo/admin/salario`, type: 'json', adapters: SalarioAdapters.POST })
    },

    PATCH: function (id: string) {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/admin/salario/${id}`, type: 'json', adapters: SalarioAdapters.PATCH })
    },

    DELETE: function (id: string) {

        return useAxios({ method: 'delete', url: `/bolsadetrabajo/admin/salario/${id}`, type: 'json', adapters: SalarioAdapters.DELETE })
    }
}

export {
    SalariosServices,
    SalarioServices
}
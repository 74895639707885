import { faBook, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import TableAdvanced from '../../../../../../../components/Tables/TableAdvanced/TableAdvanced'
import { useLang } from '../../../../../../../language'
import { EstadoPersona } from './EstadoPersona'

export const TableEstadosPersona = ({ data, loading, total, perPage, handleFormChange, formValuesSearch, fetchListado, handleInputChangeSearch }: any) => {

    const Lang = useLang()

    const [excluidos, setExcluidos] = useState([])
    const [incluidos, setIncluidos] = useState([])

    const [checkAll, setCheckAll] = useState(false)

    const selectAllItems = () => {
        setCheckAll(true)
        setIncluidos([])
        setExcluidos([])
    }

    const unselectAllItems = () => {
        setCheckAll(false)
        setIncluidos([])
        setExcluidos([])
    }

    const allActions: any = [
        {
            label: <span><FontAwesomeIcon icon={faPlus} className='me-2' />{Lang('NUEVO_ESTADO_PERSONA')}</span>,
            action: () => handleShowModal_TipoNoticias({
                action: 'CREATE',
            }),
        },
    ]

    const allActionsPerLine: any = [
        {
            label: <FontAwesomeIcon icon={faBook} className='' />,
            action: (form: any) => handleShowModal_TipoNoticias({
                action: 'EDIT',
                form,
            }),
            withoutID: true
        }
    ]

    const handleChangeCheckAll = (e: any) => {

        if (e.target.checked) {

            selectAllItems()

        } else {

            unselectAllItems()
        }
    }

    const headersTable = [
        <small>{Lang('NOMBRE')}</small>,
        // <small>{Lang('DESCRIPCION')}</small>,
        ''
    ]

    const dataLineTable = [
        {
            className: '',
            name: 'nombre'
        },
        // {
        //     className: '',
        //     name: 'descripcion'
        // },
    ]

    // Modal nuevo banco

    const [showModal_TipoNoticias, setShowModal_TipoNoticias] = useState({
        value: false,
        payload: {}
    });

    const handleCloseModal_TipoNoticias = () => setShowModal_TipoNoticias({
        value: false,
        payload: {}
    });

    const handleShowModal_TipoNoticias = (payload: object) => setShowModal_TipoNoticias({
        value: true,
        payload
    });

    return (
        <>
            <TableAdvanced>

                <TableAdvanced.Head
                    title={Lang('ESTADOS_PERSONA')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    headers={headersTable}
                    dataLineTable={dataLineTable}
                    data={data}
                    isLoading={loading}

                    excluidos={excluidos}
                    incluidos={incluidos}
                    setExcluidos={setExcluidos}
                    setIncluidos={setIncluidos}

                    checkAll={checkAll}
                    allActionsPerLine={allActionsPerLine}

                    checkMultiples={false}
                    classNameTable='table-shrink-th'
                />

                {/* <TableAdvanced.Footer
                    total={total}
                    handleFormChange={handleFormChange}
                    formValuesSearch={formValuesSearch}
                    perPage={perPage}
                /> */}

            </TableAdvanced>

            <EstadoPersona
                showModal_TipoNoticias={showModal_TipoNoticias}
                handleCloseModal_TipoNoticias={handleCloseModal_TipoNoticias}
                fetchListado={fetchListado}
                formValuesSearch={formValuesSearch}
            />
        </>
    )
}

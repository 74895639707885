import { parseString, receiveCheck, receiveFecha, receiveFechaFormat2, sendCheck, sendMultipart } from "../../../../../../adapters/helpers"

const PersonasBTAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idpersona || '',
                    email: item.email || '',
                    habilitarofertas: item.habilitarofertas || '',
                    idhorario: item.idhorario || '',
                    idsalario: item.idsalario || '',
                    idtipocontrato: item.idtipocontrato || '',
                    nif: item.nif || '',
                    nombrecompleto: item.nombrecompleto || '',
                    pathcv: item.pathcv || '',
                    recibirnotificacionesmail: item.recibirnotificacionesmail || '',
                    telefonomovil: item.telefonomovil || '',
                    titulocv: item.titulocv || '',
                    nombre: item.nombre || '',
                    apellidos: `${item.apellido1 || ''} ${item.apellido2 || ''}`,
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idpersona: item.idpersona || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },


    GET_EXCEL: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    headers: item.headers
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data

                const items = this.schema(dataTemp)


                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}


                const data = {
                    // filtros: item.filtros || '',
                    // lista_ids: item.lista_ids || '',
                    // lista_exclusion: item.lista_exclusion || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },


    GET_BETWEEN_DATES: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = item

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                return {
                    data: {
                        items: dataTemp
                    },
                    status: data?.status
                }
            }
        },
    },

    GET_CHECK_DISPONIBILIDAD: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    cupos_disponibles: item.cupos_disponibles || 0,
                    tarifa: item.tarifa || {},
                    condiciones_pago: item.condiciones_pago || [],
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                return {
                    data: dataTemp,
                    status: data?.status
                }
            }
        },
    },

}


const PersonaBTAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idpersona || '',
                    btpersona: item.btpersona || {},
                    habilitarofertas: receiveCheck(item.btpersona?.habilitarofertas, 'habilitarofertas'),
                    recibirnotificacionesmail: receiveCheck(item.btpersona?.recibirnotificacionesmail, 'recibirnotificacionesmail'),
                    idhorario: item.btpersona?.idhorario || '',
                    idtipocontrato: item.btpersona?.idtipocontrato || '',
                    idsalario: item.btpersona?.idsalario || '',
                    email: item.email || '',
                    estudios: item.estudios || '',
                    fechastatuspersona: item.fechastatuspersona || '',
                    funciones: item.funciones || '',
                    idiomas: item.idiomas || '',
                    nif: item.nif || '',
                    nombre: item.nombre || '',
                    nombre_completo: item.nombre_completo || '',
                    ofertas_empleos: item.ofertas_empleos || '',
                    statuspersona: item.statuspersona || '',
                    telefonomovil: item.telefonomovil || '',
                    url_firma: item.url_firma || '',
                    url_fotografia: item.url_fotografia || '',
                    url_marca: item.url_marca || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp?.[0])

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },

    GET_FICHA: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idpersona || '',
                    idoferta_persona: item.idoferta_persona || '',
                    btpersona: item.btpersona || {},
                    habilitarofertas: receiveCheck(item.btpersona?.habilitarofertas, 'habilitarofertas'),
                    recibirnotificacionesmail: receiveCheck(item.btpersona?.recibirnotificacionesmail, 'recibirnotificacionesmail'),
                    idhorario: item.btpersona?.idhorario || '',
                    idtipocontrato: item.btpersona?.idtipocontrato || '',
                    idsalario: item.btpersona?.idsalario || '',
                    email: item.email || '',
                    estudios: item.estudios || [],
                    fechastatuspersona: item.fechastatuspersona || '',
                    funciones: item.funciones || [],
                    idiomas: item.idiomas || [],
                    nif: item.nif || '',
                    nombre: item.nombre || '',
                    nombrecompleto: item.nombrecompleto || '',
                    ofertas_empleos: item.ofertas_empleos || '',
                    statuspersona: item.statuspersona || '',
                    telefonomovil: item.telefonomovil || '',

                    tipocontrato: item?.tipocontrato?.nombre || '-',
                    salario: item?.salario?.cantidad || '-',
                    horario: item?.horario?.descripcionhorario || '-',
                    pathcv: item?.pathcv || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp?.[0])

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },

    GET_CVS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idcurriculum_persona || '',
                    idpersona: item.idpersona || '',
                    pathcv: item.pathcv || '',
                    preferido: receiveCheck(item.preferido, 'preferido'),
                    titulocv: item.titulocv || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },

    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    habilitarofertas: sendCheck(item.habilitarofertas),
                    recibirnotificacionesmail: sendCheck(item.recibirnotificacionesmail),
                    idhorario: item.idhorario || '',
                    idtipocontrato: item.idtipocontrato || '',
                    idsalario: item.idsalario || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

}

export {
    PersonasBTAdapters,
    PersonaBTAdapters
}
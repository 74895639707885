import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import './BreadcrumbLine.css'

interface IBreadcrumbLineItems {
    BreadcrumbLineItems: Array<any>,
    withoutMb?: Boolean,
    className?: String,
}

export const BreadcrumbLine = ({ BreadcrumbLineItems, withoutMb = false, className = 'mb-3' }: IBreadcrumbLineItems) => {

    const navigate = useNavigate()

    return (
        <div className='d-flex'>
            <Breadcrumb className={`Breadcrumb-inner BreadcrumbLine ${withoutMb ? 'BreadcrumbLine--disable_mb' : '' } ${className} `}>
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                {
                    BreadcrumbLineItems.map((item, index) => {

                        if (index !== (BreadcrumbLineItems.length - 1)) {
                            return (
                                <Breadcrumb.Item onClick={() => navigate(item.link)} key={index}>
                                    {item.title}
                                </Breadcrumb.Item>
                            )
                        }

                        return (
                            <Breadcrumb.Item active key={index}>
                                {item.title}
                            </Breadcrumb.Item>
                        )
                    })
                }

            </Breadcrumb>
        </div >
    )
}
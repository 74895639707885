import { Placeholder } from '../../Placeholder/Placeholder'

import './LoaderLayoutMain1.css'

export const LoaderLayoutMain1 = () => {
  return (
    <>
      <div className='LoaderLayoutMain1'>
        <div className='LoaderLayoutMain1__left'>
          <Placeholder className='p-4 m-2' />
          <Placeholder className='px-4 py-3' height='2rem' />
          <Placeholder className='px-4 py-3' height='2rem' />
          <Placeholder className='px-4 py-3' height='2rem' />
          <Placeholder className='px-4 py-3' height='2rem' />
          <Placeholder className='px-4 py-3' height='2rem' />
        </div>
        <div className='LoaderLayoutMain1__right shadow-none' style={{'backgroundColor': '#F6F6F6'}}>
          <div className="container">
            <div className="row mx-2">
              <div className="col-12 my-4">
                <Placeholder className='' height='2rem' />
              </div>
              <div className="col-lg-5">
                <Placeholder className=' ml-0' height='15rem' />
              </div>
              <div className="col-lg-7">
                <Placeholder className=' ml-0' height='30rem' />
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

import { Navigate, Route, Routes } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faPlus, faTicket } from '@fortawesome/free-solid-svg-icons';
import LayoutMain from '../../../../components/Layouts/LayoutMain1/LayoutMain1';
import { useLang } from '../../../../language';
import { LinkTitleTop } from '../../../../components/Layouts/LayoutMain1/LinkTitleTop/LinkTitleTop';
import Tickets from '../pages/tickets/Index';
import TicketNuevo from '../pages/tickets/ticket/nuevo/Index';
import { IndexRoute } from '../pages/tickets/ticket';
import { ParametrosTicketServices } from '../../controllers/parametrosticket.service';
import { useEffect } from 'react';

export default function ModulesRouterTicketingCliente({ pathRoot }: any) {

    const Lang = useLang()

    const navLinks = [
        {
            icon: <FontAwesomeIcon icon={faPlus} />,
            title: Lang('NUEVO_TICKET'),
            link: `${pathRoot}/nuevo`
        },
        {
            icon: <FontAwesomeIcon icon={faTicket} />,
            title: Lang('TICKETS'),
            link: `${pathRoot}/tickets`
        },
    ]

    const [fetchParametrosGET, fetchParametrosGETData] = ParametrosTicketServices.GET()

    useEffect(() => {

        fetchParametrosGET()

    }, []);

    return (
        <section className='LayoutMain animate__animated animate__fadeIn'>

            <LayoutMain label={<LinkTitleTop icon={<FontAwesomeIcon icon={faComments} />} title={Lang('AREA_TICKETING')} />}>

                <LayoutMain.NavLinks navLinks={navLinks} />

                <LayoutMain.Body>

                    <Routes>

                        <Route
                            path="tickets"
                            element={<Tickets fetchParametrosGETData={fetchParametrosGETData} />}
                        />

                        <Route
                            path="nuevo"
                            element={<TicketNuevo fetchParametrosGETData={fetchParametrosGETData} />}
                        />

                        <Route
                            path="tickets/*"
                            element={<IndexRoute />}
                        />

                        <Route
                            path="*"
                            element={<Navigate to={`${pathRoot}/tickets`} replace />}
                        />
                    </Routes>

                </LayoutMain.Body>

            </LayoutMain>

        </section>
    )
}
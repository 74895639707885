
import { useAxios } from '../../../../../hooks/useAxios';

import { PlantillaAdapters, PlantillasAdapters } from '../adapters/plantillas.adapter';

interface IParamsServices {
    id?: any
}

const PlantillasServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/comunicacion/tipos-notificaciones`, type: 'json', adapters: PlantillasAdapters.GET })
    }
}

const PlantillaServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/comunicacion/tipos-notificaciones`, type: 'json', adapters: PlantillaAdapters.GET })
    },

    POST: function () {

        return useAxios({ method: 'post', url: `/comunicacion/tipos-notificaciones`, type: 'json', adapters: PlantillaAdapters.POST })
    },

    PATCH: function () {

        return useAxios({ method: 'patch', url: `/comunicacion/tipos-notificaciones`, type: 'json', adapters: PlantillaAdapters.PATCH })
    },

    DELETE: function () {

        return useAxios({ method: 'delete', url: `/comunicacion/tipos-notificaciones`, type: 'json', adapters: PlantillaAdapters.DELETE })
    }
}

export {
    PlantillasServices,
    PlantillaServices
}
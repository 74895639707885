import { faBook, faEdit, faExternalLink, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TableAdvanced from '../../../../../../components/Tables/TableAdvanced/TableAdvanced'
import { getDataFile } from '../../../../../../helpers/helpers'
import { useLang } from '../../../../../../language'
import { useModalData } from '../../../../../../hooks/useModalData'
import { ModalFichaOferta } from '../MisOfertas/ModalFichaOferta'

export const TableEventos = ({ data, loading, total, perPage, handleFormChange, formValuesSearch,
    urlParams, fetchPersonasGET, fetchPersonasGETData }: any) => {

    const navigate = useNavigate();

    const [dataOferta, handleShowOferta, handleCloseOferta] = useModalData()

    const Lang = useLang()

    const [excluidos, setExcluidos] = useState([])
    const [incluidos, setIncluidos] = useState([])

    const [checkAll, setCheckAll] = useState(false)

    const selectAllItems = () => {
        setCheckAll(true)
        setIncluidos([])
        setExcluidos([])
    }

    const unselectAllItems = () => {
        setCheckAll(false)
        setIncluidos([])
        setExcluidos([])
    }

    const allActions: any = [
        // {
        //     label: <span className='me-2'><FontAwesomeIcon icon={faCalendar} className='me-2' />{Lang('VER_CALENDARIO')}</span>,
        //     variant: 'outline-primary',
        //     action: () => handleShowModal_VerCalendario({}),
        // },
    ]

    const allActionsPerLine = [
        // {
        //     label: <FontAwesomeIcon icon={faExternalLink} className='' />,
        //     action: (id: any) => window.open(`${process.env.REACT_APP_PUBLIC_URL_PORTAL}/${process.env.REACT_APP_URL_PORTAL_ID_BOLSA}${id}`),
        // },
        {
            label: <FontAwesomeIcon icon={faBook} className='' />,
            variant: 'outline-primary',
            action: (form: any) => handleShowOferta({
                form,
                action: 'EDIT'
            }),
            withoutID: true
        },
    ]

    const handleChangeCheckAll = (e: any) => {

        if (e.target.checked) {

            selectAllItems()

        } else {

            unselectAllItems()
        }
    }

    const headersTable = [
        <small>{Lang('OFERTA')}</small>,
        <small>{Lang('HORARIO')}</small>,
        <small>{Lang('ENTIDAD')}</small>,
        <small className='d-block text-center'>{Lang('ESTADO')}</small>,
        ''
    ]

    const dataLineTable = [
        {
            className: '',
            name: 'nombreoferta'
        },
        {
            className: '',
            name: 'horario'
        },

        {
            name: 'entidad'
        },
        {
            className: 'text-nowrap text-center',
            name: 'estado_oferta'
        },
    ]

    // Modal Crear visado

    const [showModal_VerCalendario, setShowModal_VerCalendario] = useState({
        value: false,
        payload: {}
    });

    const handleCloseModal_VerCalendario = () => setShowModal_VerCalendario({
        value: false,
        payload: {}
    });

    const handleShowModal_VerCalendario = (payload: object) => setShowModal_VerCalendario({
        value: true,
        payload
    });

    return (
        <>

            <TableAdvanced >

                <TableAdvanced.Head
                    title={Lang('LISTA_DE_OFERTAS')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    headers={headersTable}
                    dataLineTable={dataLineTable}
                    data={data}
                    isLoading={loading}

                    excluidos={excluidos}
                    incluidos={incluidos}
                    setExcluidos={setExcluidos}
                    setIncluidos={setIncluidos}

                    checkAll={checkAll}
                    allActionsPerLine={allActionsPerLine}
                    checkMultiples={false}
                />

                <TableAdvanced.Footer
                    total={total}
                    handleFormChange={handleFormChange}
                    formValuesSearch={formValuesSearch}
                    perPage={perPage}
                />

            </TableAdvanced>

            <ModalFichaOferta
                data={dataOferta}
                handleClose={handleCloseOferta}
            />
        </>
    )
}

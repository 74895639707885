
import { useAxios } from '../../../../../../../../hooks/useAxios';
import { InformacionAdicionalAdapters } from '../adapters/informacionAdicional.adapter';

const InformacionAdicionalServices = {

    GET: function (idevento: string) {

        return useAxios({ method: 'get', url: `/inscripciones/evento-idiomas/${idevento}`, type: 'json', adapters: InformacionAdicionalAdapters.GET })
    },

    POST: function () {

        return useAxios({ method: 'post', url: `/inscripciones/evento-idiomas`, type: 'json', adapters: InformacionAdicionalAdapters.POST })
    },

    PATCH: function () {

        return useAxios({ method: 'patch', url: `/inscripciones/evento-idiomas`, type: 'json', adapters: InformacionAdicionalAdapters.PATCH })
    },

    DELETE: function (idvisado: any) {

        return useAxios({ method: 'delete', url: `/inscripciones/evento-idiomas/${idvisado}`, type: 'json', adapters: InformacionAdicionalAdapters.DELETE })
    }
}

export {
    InformacionAdicionalServices
}
import React, { useEffect, useRef, useState } from "react";
import { useLang } from "../../../language";
import { isEmpty } from "lodash";
import { ListFiles } from "../../ListFiles/ListFiles";
import './TicketMensaje.css'
import { Alert, Button } from "react-bootstrap";
import { convertirMinutos, formatFecha } from "../../../helpers/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPencil } from "@fortawesome/free-solid-svg-icons";

export const TicketMensaje = ({ asunto, descripcion, fecha, className = '', observaciones, remitente,
    ticket_mensaje_documentos, onClickDelete, hora, soloadmins, handleShow, totalminutosgestion, id }: any) => {

    const Lang = useLang()

    const maxHeight = 120

    const [isExpanded, setIsExpanded] = useState(false);

    const [isOverflowing, setIsOverflowing] = useState(false);

    const contentRef: any = useRef(null);

    useEffect(() => {

        if (contentRef.current.scrollHeight > maxHeight) {
            setIsOverflowing(true);
        }
    }, [maxHeight]);


    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    const [showButton, setShowButton] = useState(false);
    const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
    const [imageUrl, setImageUrl] = useState(""); // Estado para almacenar la URL de la imagen

    const handleMouseEnter = (e: any) => {
        // Obtener las coordenadas de la imagen

        if (e.target.tagName === "IMG") {

            const rect = e.target.getBoundingClientRect();

            // Obtener las coordenadas del contenedor
            const containerRect = contentRef.current.getBoundingClientRect();

            // Ajustar la posición del botón centrado sobre la imagen
            setButtonPosition({
                top: rect.top - containerRect.top + 20,
                left: rect.left - containerRect.left + (rect.width - 50)
            });

            setImageUrl(e.target.src)
            setShowButton(true);
        }
    };

    const handleMouseLeave = () => {
        setShowButton(false);  // Ocultar el botón al salir del hover
    };

    const handleButtonClick = () => {
        if (imageUrl) {
            const link = document.createElement('a'); // Crear un enlace
            link.href = imageUrl; // Asignar la URL de la imagen
            link.download = 'image.png'; // Asignar un nombre para la descarga
            document.body.appendChild(link); // Añadir el enlace al cuerpo
            link.click(); // Simular el clic en el enlace para iniciar la descarga
            document.body.removeChild(link); // Eliminar el enlace del DOM
        }
    };

    function addPrefixToSelectors(styles: string, prefix: string) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(`<style>${styles}</style>`, 'text/html');
        const styleElement: any = doc.querySelector('style');

        if (!styleElement) return styles; // Si no hay <style>, devuelve el string tal como está.

        const styleSheet: any = new CSSStyleSheet();
        styleSheet.replaceSync(styleElement.textContent);

        let prefixedStyles = '';

        // Iteramos sobre las reglas CSS usando un bucle clásico
        for (let i = 0; i < styleSheet.cssRules.length; i++) {
            const rule = styleSheet.cssRules[i];

            if (rule instanceof CSSStyleRule) {
                // Prefijar el selector
                const prefixedSelector = rule.selectorText
                    .split(',')
                    .map((selector) => `${prefix} ${selector.trim()}`)
                    .join(', ');

                // Concatenar los estilos modificados
                prefixedStyles += `${prefixedSelector} { ${rule.style.cssText} }\n`;
            } else if (rule instanceof CSSFontFaceRule) {
                // Si es una regla @font-face, la dejamos sin prefijo
                prefixedStyles += `@font-face { ${rule.style.cssText} }\n`;
            } else if (rule instanceof CSSMediaRule) {
                // Si es una regla @media, iteramos sus reglas internas usando bucle clásico
                let mediaPrefixedStyles = '';
                for (let j = 0; j < rule.cssRules.length; j++) {
                    const mediaRule = rule.cssRules[j];
                    if (mediaRule instanceof CSSStyleRule) {
                        const prefixedMediaSelector = mediaRule.selectorText
                            .split(',')
                            .map((selector) => `${prefix} ${selector.trim()}`)
                            .join(', ');

                        mediaPrefixedStyles += `${prefixedMediaSelector} { ${mediaRule.style.cssText} }\n`;
                    }
                }
                prefixedStyles += `@media ${rule.conditionText} { ${mediaPrefixedStyles} }\n`;
            } else {
                // Otras at-rules pueden manejarse aquí si es necesario
                prefixedStyles += `${rule.cssText}\n`;
            }
        }

        return `<style>${prefixedStyles}</style>`;
    }

    // Funcion general 

    function extractAndModifyStyles(htmlContent: any, prefix: string) {
        // Regex para encontrar el contenido dentro de <style>...</style>
        const styleRegex = /<style[^>]*>([\s\S]*?)<\/style>/gi;

        // Almacena el contenido de estilo extraído
        let modifiedHtml = htmlContent;
        let match;

        // Extraer los estilos en un bucle mientras se encuentren coincidencias
        while ((match = styleRegex.exec(htmlContent)) !== null) {

            const originalStyles = `<style>${match[1]}</style>`; // Contenido entre <style>...</style>

            // console.log(originalStyles);


            // Modificar el contenido de estilo utilizando la función de callback
            const modifiedStyles = addPrefixToSelectors(originalStyles, prefix);

            // console.log(modifiedStyles);


            // Reemplazar el contenido original de <style> en el HTML
            modifiedHtml = modifiedHtml.replace(originalStyles, modifiedStyles);
        }

        return modifiedHtml;
    }

    return (
        <article className={`TicketMensaje__Item card p-3 mb-4 ${className}`}>

            <div className="d-flex justify-content-between mb-1 flex-wrap">
                <h6 className='h6 fs-115 mb-0'>
                    {
                        soloadmins &&
                        <Alert variant='danger' className='mb-0 me-1 p-1 text-center d-inline-block fs-6'>
                            <small>{Lang('OCULTO')}</small>
                        </Alert>
                    } {asunto}
                </h6>
                <div className='mb-0 ms-auto'>
                    <span className="text-capitalize">{formatFecha(fecha)},</span> <span>{hora}</span> <h6 className="d-inline">{remitente}</h6>
                    {
                        handleShow &&
                        <>
                            <Button size="sm" variant="outline-primary" onClick={handleShow} className="ms-1">
                                <FontAwesomeIcon icon={faPencil} />
                            </Button>
                            <div className="text-end mt-1">
                                <small>{convertirMinutos(totalminutosgestion).horas}Hrs {convertirMinutos(totalminutosgestion).minutos}Mins </small>
                            </div>
                        </>
                    }
                </div>
            </div>

            {
                !isEmpty(ticket_mensaje_documentos) &&

                <div className="col-12 mb-3">
                    <ListFiles
                        isBlob={false}
                        inline={true}
                        list={ticket_mensaje_documentos?.map((item: any) => {
                            return {
                                titulo: item.originalfilename,
                                url: item.document_url,
                                id: item.idticketdocument
                            }
                        }) || []}
                        size='small'
                        onClickDelete={onClickDelete}
                    />
                </div>
            }

            <div>

                    <div
                        ref={contentRef}
                        className={`TicketMensaje__descripcion text ${isExpanded ? "expanded" : "collapsed"}`}
                        style={{ maxHeight: isExpanded ? "none" : `${maxHeight}px` }}
                        onMouseMove={handleMouseEnter}  // Escucha el movimiento del mouse dentro del contenedor
                        onMouseLeave={handleMouseLeave} // Asegura que el hover se quite al salir del contenedor
                    >
                        <div className={id} dangerouslySetInnerHTML={{ __html: extractAndModifyStyles(descripcion, `.${id}`) }} />

                        {showButton && (
                            <button
                                className="btnHref btn btn-outline-primary btn-sm"
                                style={{
                                    position: 'absolute',
                                    top: `${buttonPosition.top}px`,
                                    left: `${buttonPosition.left}px`
                                }}
                                onClick={handleButtonClick}
                            >
                                <FontAwesomeIcon icon={faDownload} />
                            </button>
                        )}
                    </div>

            </div>

            {
                isOverflowing && (
                    <div className="read-more-button d-flex justify-content-center">
                        <Button variant='outline-primary' className="mt-3 mx-0" size="sm" onClick={toggleReadMore}>
                            {isExpanded ? Lang('VER_MENOS') : Lang('VER_MAS')}
                        </Button>
                    </div>
                )
            }

            {
                observaciones &&
                <p className="mb-0 mt-3 TicketMensaje__ItemObservaciones bg-light">
                    <span className="text-wrap">
                        <strong>{Lang('OBSERVACIONES')}</strong>: <span>{observaciones}</span>
                    </span>

                </p>
            }
        </article>
    )
};

import { useAxios } from '../../../../../hooks/useAxios';
import { NoticiaAdapters, NoticiasAdapters } from '../adapters/noticias.adapter';

const NoticiasServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/gestor-contenidos/noticias`, type: 'json', adapters: NoticiasAdapters.GET })
    }
}

const NoticiaServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/gestor-contenidos/noticias`, type: 'json', adapters: NoticiaAdapters.GET })
    },

    POST: function () {

        return useAxios({ method: 'post', url: `/gestor-contenidos/noticias`, type: 'json', adapters: NoticiaAdapters.POST })
    },

    PATCH: function (id: any) {

        return useAxios({ method: 'post', url: `/gestor-contenidos/noticias/${id}/update?_method=PATCH`, type: 'json', adapters: NoticiaAdapters.PATCH })
    },

    DELETE: function (id: any) {

        return useAxios({ method: 'delete', url: `/gestor-contenidos/noticias/${id}`, type: 'json', adapters: NoticiaAdapters.DELETE })
    },

    DELETE_IMAGE: function (id: any) {

        return useAxios({ method: 'delete', url: `/gestor-contenidos/noticias/${id}/imagen`, type: 'json', adapters: NoticiaAdapters.DELETE_IMAGE })
    }
}

export {
    NoticiasServices,
    NoticiaServices
}
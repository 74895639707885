import { receiveFecha, receiveFechaFormat2, sendMultipart } from "../../../../../adapters/helpers"

const NoticiasAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idnoticia || '',
                    tiponoticia: item.tiponoticia?.tiponoticia || '',
                    codigo: item.tiponoticia?.tipo_noticia_clasificacion?.nombre || '',
                    titulo: item.titulo || '',
                    imagen_small: item.imagen_small || '',
                    descr_corta: item.descr_corta || '',
                    descripcion: item.descripcion || '',
                    enlace_externo: item.enlace_externo || '',
                    publicado: item.publicado ? 'Si' : 'No',
                    fecha_registro: receiveFechaFormat2(receiveFecha(item.fecha_registro)),
                    fecha_desde: receiveFechaFormat2(receiveFecha(item.fecha_desde)),
                    fecha_hasta: receiveFechaFormat2(receiveFecha(item.fecha_hasta)),
                    adjuntos: item.adjuntos || [],
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },
}

const NoticiaAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idnoticia || '',
                    tiponoticia: item.tiponoticia?.idtiponoticia || '',
                    idtiponoticia: item.tiponoticia?.idtiponoticia || '',
                    idtiponoticiaclasificacion: item.tiponoticia?.idtiponoticiaclasificacion || '',
                    codigo: item.codigo || '',
                    titulo: item.titulo || '',
                    descr_corta: item.descr_corta || '',
                    descripcion: item.descripcion || '',
                    publicado: item.publicado ? 'true' : 'false',
                    enlace_externo: item.enlace_externo || '',
                    noticia_externa: item.noticia_externa ? 'true' : 'false',
                    imagen: item.imagen || '',
                    imagen_small: item.imagen_small || '',
                    imagen_mini: item.imagen_mini || '',
                    fecha_registro: receiveFecha(item.fecha_registro),
                    fecha_desde: receiveFecha(item.fecha_desde),
                    fecha_hasta: receiveFecha(item.fecha_hasta),
                    adjuntos: item.adjuntos || [],
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status,
                    idnoticia: item.data?.idnoticia
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    tiponoticia: item.tiponoticia || '',
                    titulo: item.titulo || '',
                    descr_corta: item.descr_corta || '',
                    descripcion: item.descripcion || '',
                    publicado: item.publicado === 'true' ? true : false,
                    enlace_externo: item.enlace_externo || '',
                    noticia_externa: item.noticia_externa === 'true' ? true : false,
                    imagen: item.imagen || '',
                    imagen_small: item.imagen_small || '',
                    imagen_mini: item.imagen_mini || '',
                    fecha_registro: item.fecha_registro,
                    fecha_desde: item.fecha_desde,
                    fecha_hasta: item.fecha_hasta,
                    adjuntos: item.adjuntos || [],
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return sendMultipart(items)
            }
        },
    },

    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    tiponoticia: item.tiponoticia || '',
                    titulo: item.titulo || '',
                    descr_corta: item.descr_corta || '',
                    descripcion: item.descripcion || '',
                    publicado: item.publicado === 'true' ? true : false,
                    enlace_externo: item.enlace_externo || '',
                    noticia_externa: item.noticia_externa === 'true' ? true : false,
                    imagen: item.imagen || '',
                    imagen_small: item.imagen_small || '',
                    imagen_mini: item.imagen_mini || '',
                    fecha_registro: item.fecha_registro,
                    fecha_desde: item.fecha_desde,
                    fecha_hasta: item.fecha_hasta,
                    adjuntos: item.adjuntos || [],
                }                

                if ( !data.imagen || typeof data.imagen === 'string') delete data.imagen

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return sendMultipart(items)
            }
        },
    },
    
    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message || '',
                    status: item.status || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                // 

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },

    },
    
    DELETE_IMAGE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message || '',
                    status: item.status || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },

    },
}

export {
    NoticiasAdapters,
    NoticiaAdapters,
}
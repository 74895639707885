
import { useAxios } from '../../../../../hooks/useAxios';
import { EntidadDatosColegiacionAdapters, EntidadDatosGeneralesAdapters, EntidadesAdapters } from '../adapters/entidades.adapter';

interface IParamsServices {
    id?: any
}

const EntidadesServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/entidades`, type: 'json', adapters: EntidadesAdapters.GET })
    },

    GET_EXCEL: function () {

        return useAxios({ method: 'get', url: `/entidades`, type: 'blob', adapters: EntidadesAdapters.GET_EXCEL })
    },
}


const EntidadDatosGeneralesServices = {

    GET: function (id: any) {

        return useAxios({ method: 'get', url: `/entidades/${id}`, type: 'json', adapters: EntidadDatosGeneralesAdapters.GET })
    },

    GET_By_PARAM_ID: function () {

        return useAxios({ method: 'get', url: `/entidades`, type: 'json', adapters: EntidadDatosGeneralesAdapters.GET })
    },
    
    POST: function (id: any) {

        return useAxios({ method: 'post', url: `/entidades`, type: 'json', adapters: EntidadDatosGeneralesAdapters.POST })
    },

    PATCH: function (id: any) {

        return useAxios({ method: 'patch', url: `/entidades/${id}`, type: 'json', adapters: EntidadDatosGeneralesAdapters.PATCH })
    },

    PATCH_REACTIVAR: function (id: any) {

        return useAxios({ method: 'patch', url: `/entidades/${id}/reactivar`, type: 'json', adapters: EntidadDatosGeneralesAdapters.PATCH_REACTIVAR })
    },

    DELETE: function (id: any) {

        return useAxios({ method: 'delete', url: `/entidades/${id}`, type: 'json', adapters: EntidadDatosGeneralesAdapters.DELETE })
    },
}


const EntidadDatosColegiacionServices = {

    GET: function (id:IParamsServices) {

        return useAxios({ method: 'get', url: `/personas/${id}/asociados`, type: 'json', adapters: EntidadDatosColegiacionAdapters.GET })
    },

    PATCH_crear: function (id:IParamsServices) {

        return useAxios({ method: 'patch', url: `/personas/${id}/asociados/crear`, type: 'json', adapters: EntidadDatosColegiacionAdapters.PATCH_crear })
    },

    PATCH_editar: function (id:IParamsServices) {

        return useAxios({ method: 'patch', url: `/personas/${id}/asociados/actualizar`, type: 'json', adapters: EntidadDatosColegiacionAdapters.PATCH_editar })
    },

    DELETE: function (id: any) {

        return useAxios({ method: 'delete', url: `/personas/${id}/asociados/baja`, type: 'json', adapters: EntidadDatosColegiacionAdapters.DELETE })
    },
}

export {
    EntidadesServices,
    EntidadDatosGeneralesServices,
    EntidadDatosColegiacionServices
}
import { useAxios } from "../../../../../hooks/useAxios"
import { misInscripcionesAdapters } from "../adapters/inscripciones.adapter"

var misInscripcionesServices = {

    GET: () => {

        return useAxios({ 
            method: 'get', url: '/inscripciones/mis-inscripciones', type: 'json', adapters: misInscripcionesAdapters.GET 
        })
    },

    GET_MI_CERTIFICADO: function () {

        return useAxios({ 
            method: 'get', 
            url: `/inscripciones/plantillas-certificados`, type: 'blob', adapters: misInscripcionesAdapters.GET_MI_CERTIFICADO 
        })
    },


    POST: () => {

        return useAxios({ 
            method: 'post', url: '/personas/actividades-profesionales-current-user/crear', 
            type: 'json', adapters: misInscripcionesAdapters.POST 
        })
    },

    PATCH: () => {

        return useAxios({ 
            method: 'patch', url: '/personas/actividades-profesionales-current-user', 
            type: 'json', adapters: misInscripcionesAdapters.PATCH 
        })
    },

    DELETE: () => {

        return useAxios({ 
            method: 'delete', url: `/inscripciones/mis-inscripciones`, 
            type: 'json', adapters: misInscripcionesAdapters.DELETE 
        })
    }
}

export {
    misInscripcionesServices
}
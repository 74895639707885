
import { TableNivelesIdioma } from './TableNivelesIdioma';

export const SeccionNivelesIdiomas = ({ idofertaempleo, fetchCondicionPagosRemesasGETData, fetchPersonaBTGETData = {},
    fetchMultipleParamBolsaData, fetchPersonaBTGET }: any) => {

    return (
        <div>
            <TableNivelesIdioma
                data={fetchPersonaBTGETData.data?.idiomas || []}
                loading={false}
                fetchPersonaBTGETData={fetchPersonaBTGETData}
                fetchCondicionPagosRemesasGETData={fetchCondicionPagosRemesasGETData}
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                fetchPersonaBTGET={fetchPersonaBTGET}
            />
        </div>
    )
}

import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cloneDeep } from 'lodash'
import React, { useEffect } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { ButtonSpinner } from '../../../../../components/ButtonSpinner/ButtonSpinner'
import { InputChecks } from '../../../../../components/Inputs/InputChecks'
import { InputSelect } from '../../../../../components/Inputs/InputSelect'
import { getDateInput } from '../../../../../helpers/helpers'
import { useForm } from '../../../../../hooks/useForm'
import { useLang } from '../../../../../language'
import { misInscripcionesAdapters } from '../../controllers/adapters/inscripciones.adapter'
import { misInscripcionesServices } from '../../controllers/services/inscripciones.service'

export const ModalMiActProfesional = ({ showModal_MiActProfesional, handleCloseModal_MiActProfesional,
    fetchActProfesionalesParamData, fetchSuccess }: any) => {

    const Lang = useLang()

    // -------- Formulario principal

    const [
        formPersonaActProfesional, handleInputPersonaActProfesional, handleFormPersonaActProfesional, resetFormPersonaActProfesional
    ] = useForm({
        ...misInscripcionesAdapters.GET.receive.schema({}),
        fechaalta: getDateInput()
    })

    // Funciones helpers

    const handleChangeCheck = (e: any, name: string) => {

        let listaModulosTemp = cloneDeep(formPersonaActProfesional[name])

        if (e.target.checked) {

            listaModulosTemp.push(e.target.name)

        } else {

            listaModulosTemp = listaModulosTemp.filter((item: any) => item !== e.target.name)
        }

        handleFormPersonaActProfesional({
            ...formPersonaActProfesional,
            [name]: listaModulosTemp
        })
    }


    // -------------------- POST Persona Entidad

    const [fetchMiActProfesionalPOST, fetchMiActProfesionalPOSTData] = misInscripcionesServices.POST()

    const handleSubmitCrear = () => {

        fetchMiActProfesionalPOST({
            body: formPersonaActProfesional
        })
    }

    useEffect(() => {
        if (fetchMiActProfesionalPOSTData.status === 201) {
            fetchSuccess()
            handleCloseModal_MiActProfesional()
        }
    }, [fetchMiActProfesionalPOSTData])

    

    useEffect(() => {

        if (showModal_MiActProfesional.value) {

            // if (showModal_MiActProfesional.payload?.idpersonaentidad) {

            //     fetchPersonaActProfesionalGET()
            // }

        } else {
            resetFormPersonaActProfesional()
            // handleResetValidationActProf()
        }
    }, [showModal_MiActProfesional.value])

    return (
        <>
            <Modal show={showModal_MiActProfesional.value} onHide={handleCloseModal_MiActProfesional} size='lg' id='ModalRegistro'>
                <Modal.Header className='border-bottom-0 py-4'>
                    <Modal.Title className='h5'>
                        {showModal_MiActProfesional.payload.action === 'EDIT' ? Lang('EDITAR_ACTIVIDAD_PROFESIONAL') : Lang('NUEVA_ACTIVIDAD_PROFESIONAL')}
                    </Modal.Title>
                    <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleCloseModal_MiActProfesional}></button>
                </Modal.Header>
                <Modal.Body className='px-4 py-0'>
                    <Form className='row'>

                        <Form.Group className="mb-3 col-12 col-sm-6 col-md-7 col-lg-7 col-xl-8 col-xxl-8">
                            <InputSelect
                                label={`${Lang('ACTIVIDAD_PROFESIONAL')}:`}
                                name='idperitacion'
                                value={formPersonaActProfesional.idperitacion}
                                values={fetchActProfesionalesParamData.data || []}
                                onChange={handleInputPersonaActProfesional}
                            // fetchData={fetchMiActProfesionalPOSTData}
                            // validation={valuesValidationActProf}
                            />
                        </Form.Group>

                        <div className="col-12"></div>

                        <Form.Group className="mb-3 col-12 col-sm-6 col-md-5 col-lg-4 col-xl-4 col-xxl-3">
                            <InputChecks
                                name='publicaweb'
                                value={formPersonaActProfesional.publicaweb}
                                values={[{ id: 'publicaweb', nombre: Lang('PUBLICAR_EN_WEB') }]}
                                classNameContainer='d-inline-block me-0'
                                onChange={(e: any) => handleChangeCheck(e, 'publicaweb')}
                            // fetchData={nuevo ? fetchPersonaPOSTData : fetchPerfilesPATCHData}
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer className='border-0 d-flex justify-content-between'>
                    <div></div>
                    <ButtonSpinner
                        variant="secondary" type="submit" className="me-2"
                        handleSubmit={handleSubmitCrear} fetchDataLoading={[fetchMiActProfesionalPOSTData.loading]}
                        value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} />}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

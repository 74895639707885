import { useAxios } from "../../../../../../../../hooks/useAxios"
import { EstadoPersonaAdapters, EstadosPersonaAdapters } from "./estadopersona.adapter"

const EstadosPersonaServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/admin/estadopersona`, type: 'json', adapters: EstadosPersonaAdapters.GET })
    }
}

const EstadoPersonaServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/`, type: 'json', adapters: EstadoPersonaAdapters.GET })
    },

    POST: function () {

        return useAxios({ method: 'post', url: `/bolsadetrabajo/admin/estadopersona`, type: 'json', adapters: EstadoPersonaAdapters.POST })
    },

    PATCH: function (id: string) {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/admin/estadopersona/${id}`, type: 'json', adapters: EstadoPersonaAdapters.PATCH })
    },

    DELETE: function (id: string) {

        return useAxios({ method: 'delete', url: `/bolsadetrabajo/admin/estadopersona/${id}`, type: 'json', adapters: EstadoPersonaAdapters.DELETE })
    }
}

export {
    EstadosPersonaServices,
    EstadoPersonaServices
}
import { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'

import { useForm } from '../../../hooks/useForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { InputText } from '../../../components/Inputs'
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner'
import { useLang } from '../../../language'
import { useValidator } from '../../../hooks/useValidator'

export const ModalCorreoNuevo = ({ data, handleClose, setFilterPersonaDest }: any) => {

    const Lang = useLang()

    const [checkbox, setcheckbox] = useState<any>([])

    const [formCorreoNuevo, handleInputTicketMensaje, handleFormCorreoNuevo, resetFormCorreoNuevo] = useForm({
        nombre: '',
        correo: '',
    })

    const [valuesValidationTicket, handleValidationTicket, handleResetValidationTicket] = useValidator({
        nombre: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
        correo: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
    })

    // PATCH

    const handleSubmitCorreoNuevo = () => {

        if (handleValidationTicket(formCorreoNuevo)) {

            setFilterPersonaDest({
                id: formCorreoNuevo.correo,
                nombre: formCorreoNuevo.nombre
            })

            handleClose()
        }
    }

    useEffect(() => {

        if (data.value) {

        } else {

            resetFormCorreoNuevo()
        }

    }, [data.value])


    return (
        <>
            <Modal show={data.value} onHide={handleClose} id='ModalRegistro' size='sm'>
                <Modal.Header className='border-bottom-0 py-4'>
                    <Modal.Title className='h5'>{Lang('AGREGAR_CORREO_NUEVO')}</Modal.Title>
                    <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleClose}></button>
                </Modal.Header>
                <Modal.Body className='px-4 py-0'>

                    <Form className='row'>

                        <Form.Group className="mb-3 col-12">
                            <InputText
                                label={Lang('NOMBRE') + ': *'}
                                name='nombre'
                                value={formCorreoNuevo.nombre}
                                onChange={handleInputTicketMensaje}
                                // size='sm'
                                validation={valuesValidationTicket}
                            />
                        </Form.Group>

                        <Form.Group className="mb-0 col-12">
                            <InputText
                                label={Lang('EMAIL') + ': *'}
                                name='correo'
                                value={formCorreoNuevo.correo}
                                onChange={handleInputTicketMensaje}
                                // size='sm'
                                validation={valuesValidationTicket}
                            />
                        </Form.Group>

                    </Form>

                </Modal.Body>
                <Modal.Footer className='border-0 d-flex justify-content-between'>
                    <div></div>
                    <ButtonSpinner
                        variant="secondary" type="submit" className="me-2"
                        handleSubmit={handleSubmitCorreoNuevo}
                        value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} />}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

import { faEdit, faPlus, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TableAdvanced from '../../../../../components/Tables/TableAdvanced/TableAdvanced'
import { getDataFile } from '../../../../../helpers/helpers'
import { useLang } from '../../../../../language'
import { useModalData } from '../../../../../hooks/useModalData'
import { FilterPersona } from '../../../../../components/Modals/ModalFilters/FilterPersona'
import { ModalAgregarPersona } from './ModalAgregarPersona'
import ButtonOrderBy from '../../../../../components/ButtonOrderBy/ButtonOrderBy'

export const TablePersonas = ({ data, loading, total, perPage, handleFormChange, formValuesSearch, handleInputChangeSearch,
    urlParams, fetchPersonasGET, fetchPersonasGETData }: any) => {

    const navigate = useNavigate();

    const Lang = useLang()

    // Modal Agregar Persona

    const [dataFilterPersona, handleShowFilterPersona, handleCloseFilterPersona] = useModalData()

    const [excluidos, setExcluidos] = useState([])
    const [incluidos, setIncluidos] = useState([])

    const [checkAll, setCheckAll] = useState(false)

    const selectAllItems = () => {
        setCheckAll(true)
        setIncluidos([])
        setExcluidos([])
    }

    const unselectAllItems = () => {
        setCheckAll(false)
        setIncluidos([])
        setExcluidos([])
    }

    const allActions: any = [
        // {
        //     label: <span className='me-2'><FontAwesomeIcon icon={faCalendar} className='me-2' />{Lang('VER_CALENDARIO')}</span>,
        //     variant: 'outline-primary',
        //     action: () => handleShowModal_VerCalendario({}),
        // },
        {
            label: <span><FontAwesomeIcon icon={faPlus} className='me-2' />{Lang('AGREGAR_PERSONA')}</span>,
            variant: 'primary',
            action: handleShowFilterPersona,
        },
    ]

    const allActionsPerLine = [
        {
            label: <FontAwesomeIcon icon={faEdit} className='' />,
            // action: (id: string) => navigate(`/visados-admin/visados/${id}`),
            action: (id: string) => navigate(`./${id}`),
        },
        // {
        //     variant: 'outline-primary',
        //     label: <FontAwesomeIcon icon={faUsers} className='' />,
        //     // action: (id: string) => navigate(`/visados-admin/visados/${id}`),
        //     action: (id: string) => navigate(`./${id}/inscripciones`),
        // }
    ]

    const handleChangeCheckAll = (e: any) => {

        if (e.target.checked) {

            selectAllItems()

        } else {

            unselectAllItems()
        }
    }

    const headersTable = [
        <small>
            <ButtonOrderBy
                label={Lang('NOMBRE')}
                id='nombre'
                className='text-center'
                handleInputChange={handleInputChangeSearch}
                formValuesSearch={formValuesSearch}
            />
        </small>,
        <small>
            <ButtonOrderBy
                label={Lang('APELLIDOS')}
                id='apellido1'
                className='text-center'
                handleInputChange={handleInputChangeSearch}
                formValuesSearch={formValuesSearch}
            />
        </small>,
        <small>{Lang('NIF')}</small>,
        <small>{Lang('EMAIL')}</small>,
        <small>{Lang('MOVIL')}</small>,
        ''
    ]

    const dataLineTable = [
        {
            className: '',
            name: 'nombre'
        },
        {
            className: '',
            name: 'apellidos'
        },
        {
            name: 'nif'
        },
        {
            name: 'email'
        },
        {
            name: 'telefonomovil'
        },
    ]

    // Descargar Excel

    // const [showConfirm_Excel, setshowConfirm_Excel] = useState(false)

    // const [fetchVisadosGETExcel, fetchVisadosGETExcelData, resetfetchVisadosGETExcel] = InscripcionesServices.GET_EXCEL()

    // useEffect(() => {

    //     if (fetchVisadosGETExcelData.status === 200) {
    //         getDataFile(fetchVisadosGETExcelData, resetfetchVisadosGETExcel, 'Visados', 'xlsx')
    //         setshowConfirm_Excel(false)
    //     }
    // }, [fetchVisadosGETExcelData])

    // const handleClickExcel = () => {

    //     setshowConfirm_Excel(true)

    //     let urlParamsTemp = cloneDeep(urlParams)
    //     delete urlParamsTemp['page']

    //     fetchVisadosGETExcel({
    //         params: {
    //             ...urlParamsTemp,
    //             to_excel: 1
    //         }
    //     })
    // }

    return (
        <>

            <TableAdvanced >

                <TableAdvanced.Head
                    title={Lang('LISTA_DE_PERSONAS')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    headers={headersTable}
                    dataLineTable={dataLineTable}
                    data={data}
                    isLoading={loading}

                    excluidos={excluidos}
                    incluidos={incluidos}
                    setExcluidos={setExcluidos}
                    setIncluidos={setIncluidos}

                    checkAll={checkAll}
                    allActionsPerLine={allActionsPerLine}
                    checkMultiples={false}
                />

                <TableAdvanced.Footer
                    total={total}
                    handleFormChange={handleFormChange}
                    formValuesSearch={formValuesSearch}
                    perPage={perPage}
                />

            </TableAdvanced>

            {/* <ModalVerCalendario
                showModal_VerCalendario={showModal_VerCalendario}
                handleCloseModal_VerCalendario={handleCloseModal_VerCalendario}
            /> */}

            <ModalAgregarPersona
                data={dataFilterPersona}
                handleClose={handleCloseFilterPersona}
            />

        </>
    )
}

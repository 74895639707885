import { useEffect } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useLang } from "../../../../../../../../language";
import { cloneDeep, isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBroom } from "@fortawesome/free-solid-svg-icons";
import { InputChecks } from "../../../../../../../../components/Inputs/InputChecks";

export const BloqueFunciones = ({ dataAllMatchLogica, setdataAllMatchLogica, fetchMultipleParamBolsaData, handleInputChangeSearch, formValuesSearch,
    handleReset_BloqueFunciones, dataAllMatch, setdataAllMatch, fetchOfertaGETData }: any) => {

    const Lang = useLang()


    const handleChangeCheck = (e: any, name: any, index: number) => {

        const dataTemp: any = cloneDeep(dataAllMatch)

        dataTemp[index][name] = e.target.checked

        setdataAllMatch(dataTemp)
    }

    // Inicializando valores de params a funcion

    useEffect(() => {

        if (fetchOfertaGETData?.data && fetchMultipleParamBolsaData?.data) {

            const funcionData = fetchOfertaGETData?.data?.funcion

            if (!isEmpty(funcionData)) {

                const dataParamsAdapted = funcionData?.map((item: any) => {
                    return {
                        idfuncion: item.idfuncion,
                        nombre: item.nombre,
                        experiencia: item.experiencia,
                        interes: item.interes,
                    }
                })

                setdataAllMatch(dataParamsAdapted)

            } else {

                const dataParamsAdapted = fetchMultipleParamBolsaData?.data?.funciones?.map((item: any) => {
                    return {
                        idfuncion: item.id,
                        nombre: item.nombre,
                        experiencia: false,
                        interes: false,
                    }
                })

                setdataAllMatch(dataParamsAdapted)
            }

            setdataAllMatchLogica({
                logica_buscar_interes: fetchOfertaGETData?.data?.logica_buscar_interes === 'and' ? ['and'] : [],
                logica_buscar_experiencia: fetchOfertaGETData?.data?.logica_buscar_experiencia === 'and' ? ['and'] : [],
            })
        }

    }, [fetchOfertaGETData, fetchMultipleParamBolsaData]);

    // ------------- Logica funciones


    const handleChangeCheckLogica = (e: any, name: string) => {

        let listaTemp = cloneDeep(dataAllMatchLogica[name])

        if (e.target.checked) {

            listaTemp.push(e.target.name)

        } else {

            listaTemp = listaTemp.filter((item: any) => item !== e.target.name)
        }

        setdataAllMatchLogica({
            ...dataAllMatchLogica,
            [name]: listaTemp
        })
    }

    return (
        <>

            <div className="row">
                <div className="col-lg-6">

                    <Table className='position-relative'>
                        <thead className="border-0 bg-transparent">
                            <tr>
                                <th>
                                    <small>{Lang('FUNCION')}</small>
                                </th>
                                <th>
                                    <small>{Lang('EXPERIENCIA')}</small>
                                </th>
                                <th>
                                    <small>{Lang('INTERES')}</small>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                <>
                                    {
                                        dataAllMatch?.slice(0, dataAllMatch?.length / 2)?.map((item: any, index: number) => {

                                            return (
                                                <tr key={item.idfuncion}>
                                                    <td className=''>
                                                        <small>{item['nombre']}</small>
                                                    </td>

                                                    <td className='text-center text-nowrap shrink-td' valign='middle'>
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={'publicaweb' + '_' + item.nombre}
                                                            label=''
                                                            name={item.idfuncion}
                                                            onChange={(e: any) => handleChangeCheck(e, 'experiencia', index)}
                                                            autoComplete='one-time-code'
                                                            checked={item['experiencia']}
                                                            className='d-inline-block'
                                                        />
                                                    </td>

                                                    <td className='text-center text-nowrap shrink-td' valign='middle'>
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={'publicaweb' + '_' + item.nombre}
                                                            label=''
                                                            name={item.idfuncion}
                                                            onChange={(e: any) => handleChangeCheck(e, 'interes', index)}
                                                            autoComplete='one-time-code'
                                                            className='d-inline-block'
                                                            checked={item['interes']}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {/* {
                                        Object.keys(fetchMultipleParamBolsaData?.data?.funciones)?.length === 0 &&
                                        <tr>
                                            <td colSpan={20} className='text-center py-5 bg-gray-1'>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</td>
                                        </tr>
                                    } */}
                                </>
                            }

                        </tbody>

                    </Table>

                </div>


                <div className="col-lg-6">

                    <Table className='position-relative'>
                        <thead className="border-0 bg-transparent">
                            <tr>
                                <th>
                                    <small>{Lang('FUNCION')}</small>
                                </th>
                                <th>
                                    <small>{Lang('EXPERIENCIA')}</small>
                                </th>
                                <th>
                                    <small>{Lang('INTERES')}</small>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                <>
                                    {
                                        dataAllMatch?.slice(dataAllMatch?.length / 2)?.map((item: any, index: number) => {

                                            return (
                                                <tr key={item.idfuncion}>
                                                    <td className=''>
                                                        <small>{item['nombre']}</small>
                                                    </td>
                                                    <td className='text-center text-nowrap shrink-td' valign='middle'>
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={'publicaweb' + '_' + item.nombre}
                                                            label=''
                                                            name={item.idfuncion}
                                                            onChange={(e: any) => handleChangeCheck(e, 'experiencia', (dataAllMatch?.length / 2) + index)}
                                                            autoComplete='one-time-code'
                                                            className='d-inline-block'
                                                            checked={item['experiencia']}
                                                        />
                                                    </td>

                                                    <td className='text-center text-nowrap shrink-td' valign='middle'>
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={'publicaweb' + '_' + item.nombre}
                                                            label=''
                                                            name={item.idfuncion}
                                                            onChange={(e: any) => handleChangeCheck(e, 'interes', (dataAllMatch?.length / 2) + index)}
                                                            autoComplete='one-time-code'
                                                            className='d-inline-block'
                                                            checked={item['interes']}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {/* {
                                        Object.keys(fetchMultipleParamBolsaData?.data?.funciones)?.length === 0 &&
                                        <tr>
                                            <td colSpan={20} className='text-center py-5 bg-gray-1'>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</td>
                                        </tr>
                                    } */}
                                </>
                            }

                        </tbody>

                    </Table>

                </div>
            </div>

            <div className="d-flex justify-content-between align-items-start flex-wrap mt-2 pt-3">
                <Button onClick={handleReset_BloqueFunciones} size="sm" variant="outline-secondary">
                    <FontAwesomeIcon icon={faBroom} /> {Lang('LIMPIAR_TODO')}
                </Button>

                <div className='mt-1 row justify-content-end'>
                    <Form.Group className="mb-0 col flex-grow-0 text-nowrap">
                        <div className="d-flex">
                            <small>
                                <InputChecks
                                    name='logica_buscar_experiencia'
                                    value={dataAllMatchLogica.logica_buscar_experiencia}
                                    values={[{ id: 'and', nombre: Lang('REQUERIR_TODAS_EXPERIENCIAS') }]}
                                    classNameContainer='d-inline-block me-0'
                                    onChange={(e: any) => handleChangeCheckLogica(e, 'logica_buscar_experiencia')}
                                />
                            </small>

                            <small className='ps-3'>
                                <InputChecks
                                    name='logica_buscar_interes'
                                    value={dataAllMatchLogica.logica_buscar_interes}
                                    values={[{ id: 'and', nombre: Lang('REQUERIR_TODOS_INTERESES') }]}
                                    classNameContainer='d-inline-block me-0'
                                    onChange={(e: any) => handleChangeCheckLogica(e, 'logica_buscar_interes')}
                                />
                            </small>

                        </div>
                    </Form.Group>

                </div>

            </div>
        </>
    );
};

import { faArrowUpRightFromSquare, faBook, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLang } from '../../../../../../../language'
import { Button, Table } from 'react-bootstrap'
import { ModalNivelIdioma } from './ModalNivelIdioma'
import { useModalData } from '../../../../../../../hooks/useModalData'
import { convertParamToObject } from '../../../../../../../services/helpers'

export const TableNivelesIdioma = ({ data, fetchPersonaBTGET,
    fetchPersonaDatosProfesionalesGET, urlParams, fetchCondicionPagosRemesasGETData, fetchMultipleParamBolsaData }: any) => {

    

    const navigate = useNavigate();

    const Lang = useLang()

    const [dataModal_NivelIdioma, handleShowModal_NivelIdioma, handleCloseModal_NivelIdioma] = useModalData()

    return (
        <>
            <div className="d-flex flex-wrap justify-content-between align-items-start mb-3">
                <h5 className="mb-3 mb-lg-0 ">{Lang('NIVELES_IDIOMA')}</h5>
                <Button size='sm' onClick={() => handleShowModal_NivelIdioma({
                    // idofertaempleo,
                    action: 'CREATE',
                    data,
                })}>
                    <FontAwesomeIcon icon={faPlus} className='me-2' /> {Lang('AGREGAR_NIVEL_IDIOMA')}
                </Button>
            </div>

            <Table responsive className='position-relative' striped bordered>
                <thead>
                    <tr>
                        <th>
                            <small>{Lang('IDIOMA')}</small>
                        </th>
                        <th>
                            <small>{Lang('NIVEL_IDIOMA')}</small>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        <>
                            {
                                data?.map((item: any, index: number) => {

                                    return (
                                        <tr>
                                            <td className='text-nowrap'>
                                                <small>{convertParamToObject(fetchMultipleParamBolsaData?.data?.['idiomas'])[item.ididioma]}</small>
                                            </td>

                                            <td>
                                                <small>{convertParamToObject(fetchMultipleParamBolsaData?.data?.['nivelidioma'])[item.idnivelidioma]}</small>
                                            </td>

                                            <td className='text-center text-nowrap shrink-td' valign='middle'>

                                                <Button onClick={() => handleShowModal_NivelIdioma({
                                                    // idofertaempleo,
                                                    item,
                                                    data,
                                                    action: 'EDIT',
                                                    index
                                                })} size='sm' className='text-nowrap ms-2' >
                                                    <FontAwesomeIcon icon={faBook} />
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                data?.length === 0 &&
                                <tr>
                                    <td colSpan={20} className='text-center py-5 bg-gray-1'>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</td>
                                </tr>
                            }
                        </>
                    }

                </tbody>

            </Table>

            <ModalNivelIdioma
                data={dataModal_NivelIdioma}
                handleClose={handleCloseModal_NivelIdioma}
                fetchPersonaDatosProfesionalesGET={fetchPersonaDatosProfesionalesGET}
                urlParams={urlParams}
                fetchCondicionPagosRemesasGETData={fetchCondicionPagosRemesasGETData}
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                fetchPersonaBTGET={fetchPersonaBTGET}
            />

        </>
    )
}

import { Button, Modal } from 'react-bootstrap';
import { useLang } from '../../language';
import { ButtonSpinner } from '../ButtonSpinner/ButtonSpinner';

import './Modal.scss'

export const ModalConfirm = ({ handleClose, show, messageHTML = '', onClickOK, onClickCancel, fetchData = {}, 
textOnClickOk, disableBtnClose, variantClickOk = 'primary' }: any) => {

    const Lang = useLang()

    return (
        <div>
            <Modal className='ModalStatusServer' show={show} onHide={handleClose}>
                <Modal.Header closeButton className='border-0'>
                    {/* <Modal.Title className='h5'>{status.status}</Modal.Title> */}
                </Modal.Header>
                <Modal.Body className='p-3 p-lg-4'>
                    {messageHTML}
                </Modal.Body>
                <Modal.Footer className='border-0 justify-content-between'>

                    <div>
                        {
                            !disableBtnClose && <Button variant="outline-secondary" onClick={handleClose}>
                                {Lang('CANCELAR')}
                            </Button>
                        }
                    </div>

                    <ButtonSpinner
                        variant={variantClickOk} type="submit" className="me-2"
                        handleSubmit={onClickOK} fetchDataLoading={[fetchData.loading]}
                        value={textOnClickOk}
                    />
                </Modal.Footer>
            </Modal>
        </div>
    )
}

import { receiveCheck, receiveFecha, receiveFechaFormat2, sendCheck } from "../../../../../adapters/helpers"

var misInscripcionesAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                var data = {
                    id: item.idinscripcion || '',
                    nombreevento: item.evento?.nombreevento || '',
                    condicionpago: item.condicion_pago?.condicionpago || '',
                    importe: item.importe ? `${item.importe} €` : '',
                    fecha: receiveFechaFormat2(receiveFecha(item.fecha)),
                    idevento: item.evento?.idevento || '',
                    habilitarcertificado: item.habilitarcertificado || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },

    GET_MI_CERTIFICADO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    headers: item.headers
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}
                

                const data = {
                    // filtros: item.filtros || '',
                    // lista_ids: item.lista_ids || '',
                    // lista_exclusion: item.lista_exclusion || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idperitacion: item.idperitacion || '',
                    fechaalta: item.fechaalta || '',
                    fechabaja: item.fechabaja || '',
                    publicaweb: sendCheck(item.publicaweb),
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },
    
    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idperitacion: item.idperitacion || '',
                    fechaalta: item.fechaalta || '',
                    fechabaja: item.fechabaja || '',
                    publicaweb: item.publicaweb,
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message || '',
                    status: item.status || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                // 

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },

    },
    
}
export {
    misInscripcionesAdapters
}
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { ButtonSpinner } from '../../components/ButtonSpinner/ButtonSpinner'
import { InputText } from '../../components/Inputs'
import { ModalStatusServer } from '../../components/Modals/ModalStatusServer'
import { isStatusSucess } from '../../helpers/helpers'
import { useForm } from '../../hooks/useForm'
import { AuthServices } from '../../services/auth.service'
import { useLang } from '../../language'

export const ModalReiniciarClave = ({ showModal_ReiniciarClave, handleCloseModal_ReiniciarClave, fetchNoticiaGET,
    setvariables, variables, handleFormChangePlantilla, formValuesPlantilla }: any) => {

    const Lang = useLang()

    // Formulario

    const [formRecordar, handleInputRecordar, , resetRecordar] = useForm({
        usuario: '',
    })

    // ------------ POST ReiniciarClave

    const [fetchReiniciarClavePOST, fetchReiniciarClavePOSTData] = AuthServices.POST_REINICIAR_CLAVE()

    const handleSubmitPOST = () => {

        fetchReiniciarClavePOST({
            body: {
                ...formRecordar,
                es_asociado: showModal_ReiniciarClave.payload?.es_asociado
            }
        })
    }

    useEffect(() => {

        if (isStatusSucess(fetchReiniciarClavePOSTData.status)) {

            handleCloseModal_ReiniciarClave()
        }

    }, [fetchReiniciarClavePOSTData])

    useEffect(() => {

        if (!showModal_ReiniciarClave.value) {
            resetRecordar()
        }

    }, [showModal_ReiniciarClave.value]);

    return (
        <>

            <Modal show={showModal_ReiniciarClave.value} onHide={handleCloseModal_ReiniciarClave} id='ModalRegistro'>
                <Modal.Header className='border-bottom-0 py-4'>
                    <Modal.Title className='h5'>
                        {Lang('OLVIDO_SU_CLAVE')}
                    </Modal.Title>
                    <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleCloseModal_ReiniciarClave}></button>
                </Modal.Header>
                <Modal.Body className='py-0'>

                    <div className="row">

                        <div className="col-12">
                            <p>{Lang('PARRAFO_RECUPERAR_CLAVE')}</p>
                        </div>
                        
                        <Form.Group className="col-12">
                            <InputText
                                label={showModal_ReiniciarClave.payload?.es_asociado ? Lang('NUM_COLEGIADO') + ':' : Lang('USUARIOA') + ':'}
                                name='usuario'
                                value={formRecordar.usuario}
                                onChange={handleInputRecordar}
                                fetchData={fetchReiniciarClavePOSTData}
                            />
                        </Form.Group>

                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0 justify-content-end'>
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-3"
                        handleSubmit={handleSubmitPOST} fetchDataLoading={[fetchReiniciarClavePOSTData.loading]}
                        value={Lang('ENVIAR')} icon={<FontAwesomeIcon icon={faPaperPlane} className='me-1' />}
                    />
                </Modal.Footer>
            </Modal >

            <ModalStatusServer fetchData={fetchReiniciarClavePOSTData} />
        </>
    )
}

import { cloneDeep } from "lodash";

export function sendCheck(value: any) {

    return value?.[0] ? true : false
}

export function receiveCheck(value: boolean, name: string) {

    return value ? [`${name}`] : []
}

export function receiveFecha(value: string) {

    if (value?.indexOf('T') !== -1) {
        return value?.split('T')?.[0] || ''

    } else {

        return value?.split(' ')?.[0] || ''
    }
}

export function receiveFechaFormat2(value: string) {

    // Recibiendo ya en formato yyyy-mm-dd

    return value.split('-').reverse().join('/')

}

export function sendFechaFormat2(value: string) {

    // Recibiendo ya en formato yyyy-mm-dd

    return value.split('/').reverse().join('-')

}

export function receiveDoubleBackslash(value: string) {

    return value.replace('\\', '&#92;')
}

export function sendDoubleBackslash(value: string) {

    return value.replace('&#92;', '\\')
}


export function sendMultipart(items: any) {
    let formData = new FormData();

    for (const key in items) {
        formData.append(key, items[key])
    }

    return formData
}


export function clearMultiple(data: any, ...res: any) {

    let dataTemp = cloneDeep(data)

    for (let i = 0; i < res.length; i++) {

        dataTemp = res[i](dataTemp)
    }

    return dataTemp
}

// Helpers para Clear Fields

export function clearFieldsDependentAbona(data: any) {

    const dataTemp = cloneDeep(data)

    if (dataTemp.abona === 'E') {

        if (!dataTemp['idpersona']) delete dataTemp['idpersona']

    } else if (dataTemp.abona === 'P') {

        if (!dataTemp['identidad']) delete dataTemp['identidad']
        if (!dataTemp['identidadsede']) delete dataTemp['identidadsede']
    }

    return dataTemp
}

export function clearFieldsEmptyIdFactura(data: any) {

    const dataTemp = cloneDeep(data)

    if (!dataTemp['idfactura']) delete dataTemp['idfactura']

    return dataTemp
}

export function clearFieldsDependentRemesa(data: any) {

    const dataTemp = cloneDeep(data)

    // Diferente del id remesa

    if (dataTemp.idcondicionpago !== 'f2dfe1b6-72a6-c14a-a18e-ba59af0d05ba') {

        delete dataTemp['cuentacorriente']
        delete dataTemp['iban']
        delete dataTemp['idbanco']
        delete dataTemp['sucursal']
        delete dataTemp['titularcuenta']
        delete dataTemp['digitocontrol']
        delete dataTemp['idcuentabancaria']
    }

    return dataTemp
}


export function clearFieldsEmptyEstadoCobro(data: any) {

    const dataTemp = cloneDeep(data)

    // Diferente del id remesa

    if (dataTemp.estado !== 'C') {

        delete dataTemp['fechacobro']
    }

    return dataTemp
}

export function clearFieldsEmpty_adjunto(data: any) {

    const dataTemp = cloneDeep(data)

    if (!dataTemp['adjunto']) delete dataTemp['adjunto']

    return dataTemp
}

export function clearFieldsEmptyIDIRPF(data: any) {

    const dataTemp = cloneDeep(data)

    if (!dataTemp['idirpf']) delete dataTemp['idirpf']

    return dataTemp
}

// AfterAdapter

export function renderSchema(data: any) {

    const dataTemp = cloneDeep(data)

    for (const key in dataTemp) {
        if (dataTemp[key] === 'REMOVE_FIELD_NULLABLE') delete dataTemp[key]
    }

    return dataTemp
}

// Parsers

const REMOVE_FIELD_NULLABLE = 'REMOVE_FIELD_NULLABLE'

export function parseString(data: any) {

    return String(data) || ''
}

export function parseStringNullable(value: any) {

    if (value === undefined) return undefined

    return parseString(value)
}

export function parseStringRemoveNullable(value: any) {

    if (value === undefined || value === null) return REMOVE_FIELD_NULLABLE

    return parseString(value)
}

export function parseBoolean(data: any) {

    return data === true || data === 'true'
}


import axios from 'axios'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import TableAdvanced from '../../../../../../../components/Tables/TableAdvanced/TableAdvanced'
import { useLang } from '../../../../../../../language'
import { InscripcionesAdminServices } from '../controllers/services/inscripciones.service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faArrowRight, faCheckDouble } from '@fortawesome/free-solid-svg-icons'
import { ModalStatusServer } from '../../../../../../../components/Modals/ModalStatusServer'
import { ButtonSpinner } from '../../../../../../../components/ButtonSpinner/ButtonSpinner'
import { useModalData } from '../../../../../../../hooks/useModalData'
import { cloneDeep } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { isStatusSucess } from '../../../../../../../helpers/helpers'
// import { ModalInscripcion } from './ModalInscripcion'

export const TableBusquedaPersonas = ({ data, loading, setdataExcluidos, setIncluidos, setExcluidos, selectAllItems, unselectAllItems,
    setimportesChangeList, importesChangeList, idofertaempleo, excluidos, incluidos, checkAll, setCheckAll }: any) => {

    const Lang = useLang()

    // const [excluidos, setExcluidos] = useState([])
    // const [incluidos, setIncluidos] = useState([])

    // const [checkAll, setCheckAll] = useState(false)

    // const selectAllItems = () => {
    //     setCheckAll(true)
    //     setIncluidos([])
    //     setExcluidos([])
    // }

    // const unselectAllItems = () => {
    //     setCheckAll(false)
    //     setIncluidos([])
    //     setExcluidos([])
    // }


    const allActions: any = []

    const allActionsPerLine: any = []

    const handleChangeCheckAll = (e: any) => {

        if (e.target.checked) {

            selectAllItems()

        } else {

            unselectAllItems()
        }
    }


    const handleChangeInputLineTable = (e: any) => {

        setimportesChangeList({
            ...importesChangeList,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {

        setdataExcluidos(excluidos)

    }, [excluidos])

    const [headersTable, setheadersTable] = useState<any>([
        <Form.Check type="checkbox" onChange={handleChangeCheckAll} />,
        <small>{Lang('NOMBRE')}</small>,
        <small className=''>
            {Lang('NIF')}
        </small>,
        <small className=''>
            {Lang('EMAIL')}
        </small>,
        <small className=''>
            {Lang('MOVIL')}
        </small>,
    ])

    const dataLineTable = [
        {
            className: 'text-nowrap',
            name: 'nombre_completo',
        },
        {
            className: '',
            name: 'nif',
        },
        {
            className: '',
            name: 'email'
        },
        {
            className: '',
            name: 'telefono1'
        },
    ]

    // Acciones



    // const [fetchDocumento, fetchDocumentoData] = useAxios({ 'get', `/dapcons/${idDapcon}/form/doc-adjuntos-obligatorios/obtener-documento`, '', 'blob'})
    const [fetchDocumentoData, setFetchDocumentoData] = useState<any>({ data: null, loading: null, error: null, status: null });

    const isMounted = useRef(true)

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])

    // POST Agregar persona

    const [fetchAgregarPersonaPOST, fetchAgregarPersonaPOSTData] = InscripcionesAdminServices.POST()

    const handleSubmitInscripcion = () => {

        let bodyTemp = []

        if (checkAll) {

            let excluidosTemp = cloneDeep(excluidos)

            let dataTemp = cloneDeep(data)

            for (let i = 0; i < excluidosTemp.length; i++) {

                for (let j = 0; j < dataTemp.length; j++) {

                    if (dataTemp[j].id === excluidosTemp[i]) {

                        dataTemp.splice(j, 1)
                    }
                }
            }

            bodyTemp = dataTemp?.map((item: any) => {
                return {
                    idpersona: item.id
                }
            })

        } else {
            bodyTemp = incluidos?.map((item: any) => {
                return {
                    idpersona: item
                }
            })
        }

        fetchAgregarPersonaPOST({
            id: idofertaempleo,
            body: {
                datos: bodyTemp
            }
        })
    }

    const navigate = useNavigate()

    useEffect(() => {

        if (isStatusSucess(fetchAgregarPersonaPOSTData.status)) {

            navigate('../inscripciones')
        }

    }, [fetchAgregarPersonaPOSTData]);



    return (
        <>
            <div className="d-flex flex-wrap justify-content-between align-items-start mb-3">
                <div className='d-flex align-items-end'>
                    <h5 className="mb-0 me-2">{Lang('PERSONAS')}</h5>
                    <small>({Lang('SE_HAN_ENCONTRADO')} <strong>{data?.length}</strong> {Lang('RESULTADOS')})</small>
                </div>
                <ButtonSpinner
                    variant="primary" type="submit" size='sm'
                    handleSubmit={handleSubmitInscripcion} fetchDataLoading={[fetchAgregarPersonaPOSTData.loading]}
                    value={Lang('INSCRIBIR_PERSONAS')} icon={<FontAwesomeIcon icon={faCheckDouble} className='me-1' />}
                    disabled={incluidos?.length === 0 && (!checkAll)}
                />
            </div>
            <TableAdvanced>

                <TableAdvanced.Head
                    title={Lang('PERSONAS')}
                    allActions={allActions}
                    hide
                />

                <TableAdvanced.Body
                    headers={headersTable}
                    dataLineTable={dataLineTable}
                    data={data}
                    isLoading={loading}

                    excluidos={excluidos}
                    incluidos={incluidos}
                    setExcluidos={setExcluidos}
                    setIncluidos={setIncluidos}

                    checkAll={checkAll}
                    allActionsPerLine={allActionsPerLine}
                    checkMultiples={true}
                />

                {/* <TableAdvanced.Footer
                    total={total}
                    handleFormChange={handleFormChange}
                    formValuesSearch={formValuesSearch}
                    perPage={perPage}
                /> */}

            </TableAdvanced>

            <ModalStatusServer fetchData={fetchAgregarPersonaPOSTData} />
        </>
    )
}

import { Button, Dropdown, DropdownButton, Form, Table } from 'react-bootstrap'
import { useLang } from '../../../language'
import { TableAdvancedLoader } from './TableAdvancedLoader'

interface ITableAdvancedBody {
    data?: Array<any>,
    checkAll?: boolean,
    excluidos?: Array<number>,
    incluidos?: Array<number>,
    setExcluidos?: (data: any) => void,
    setIncluidos?: (data: any) => void,
    headers?: any,
    dataLineTable?: any,
    allActionsPerLine?: any,
    checkMultiples?: boolean,
    isLoading?: any,
    classNameRow?: any,
    classNameTable?: any,
    striped?: boolean,
}

export const TableAdvancedBody = ({ data = [], checkAll, excluidos = [0], incluidos = [0],
    setExcluidos = (data: any) => [0], setIncluidos = (data: any) => [0], headers, dataLineTable,
    allActionsPerLine, checkMultiples = false, isLoading, classNameRow, classNameTable, striped = true }: ITableAdvancedBody) => {

    const Lang = useLang()

    const handleClickChangeCheck = (e: any, id: any) => {

        if (checkAll) {

            if (e.target.checked) {

                setExcluidos([...excluidos.filter((item: number) => item !== id)])

            } else {

                setExcluidos([...excluidos.filter((item: number) => item !== id), id])
            }

        } else {

            if (e.target.checked) {

                setIncluidos([...incluidos.filter((item: number) => item !== id), id])

            } else {

                setIncluidos([...incluidos.filter((item: number) => item !== id)])
            }
        }
    }

    return (
        <Table responsive bordered striped={striped} className={`fs-6 ${classNameTable || ''}`}>
            <thead>
                <tr>
                    {
                        headers?.map((item: any, indexHeaders: any) => {
                            return (
                                <th key={indexHeaders}>
                                    {item}
                                </th>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody>
                {
                    data?.map((item, index) => {
                        return (
                            <tr key={`lvl1-${index}`} className={`${classNameRow ? classNameRow(item) : ''}`}>
                                {
                                    checkMultiples && <td valign='middle'>
                                        <Form.Check
                                            type="checkbox"
                                            label=""
                                            checked={
                                                ((checkAll && !excluidos?.find((item2: any) => item2 === item.id)) ||
                                                    (!checkAll && incluidos?.find((item2: any) => item2 === item.id))) ? true : false
                                            }
                                            onChange={(e: any) => handleClickChangeCheck(e, item.id)}
                                        />
                                    </td>
                                }

                                {
                                    dataLineTable?.map((item2: any, index2dataLineTable: any) => {

                                        return (
                                            <td key={`lvl2-${index2dataLineTable}`} valign='middle' className={item2['className'] ? item2['className'] : ''}>
                                                {
                                                    item2?.['type'] === 'inputNumber' ? (
                                                        <>
                                                            <Form.Control
                                                                type="number"
                                                                size='sm'
                                                                value={(item.idasociado in item2?.['valuesChange']) ? item2?.['valuesChange'][item.idasociado] : item[item2['name']]}
                                                                onChange={item2['onChange']}
                                                                name={item.idasociado}
                                                            />
                                                        </>
                                                    ) : item2?.['type'] === 'inputCheck' ? (
                                                        <>
                                                            <Form.Check
                                                                type='checkbox'
                                                                label={item.nombre}
                                                                name={item.id}
                                                                onChange={item2['onChange']}
                                                                autoComplete='one-time-code'
                                                                defaultChecked={item[item2['name']] === 'true' || item[item2['name']] === true}
                                                                // checked={false}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <small>
                                                                {
                                                                    item2?.['adapter'] ?
                                                                    (
                                                                        item2?.['adapterFunction'] ?
                                                                        item2?.['adapterFunction'](item[item2['name']], item)
                                                                        :
                                                                        item2?.['adapter'][item[item2['name']]]
                                                                    )
                                                                        :
                                                                        (item2?.['html'] ?
                                                                            <div dangerouslySetInnerHTML={{ __html: item[item2['name']] }}></div>
                                                                            :
                                                                            item[item2['name']])
                                                                }
                                                            </small>
                                                        </>
                                                    )
                                                }
                                            </td>
                                        )
                                    })
                                }
                                {
                                    allActionsPerLine?.length > 0 && (
                                        <>
                                            <td valign='middle' className={`text-center text-nowrap AllActionsPerLine`}>
                                                {
                                                    allActionsPerLine?.map((item2: any, index2: any) => {

                                                        return (
                                                            <div key={index2} className='d-inline-flex ms-2'>
                                                                {
                                                                    (!item2.onlyExistKey || item[item2.onlyExistKey]) &&
                                                                    <>
                                                                        {
                                                                            item2.multipleActions ? (
                                                                                <DropdownButton
                                                                                    align="end"
                                                                                    title={item2.label}
                                                                                    id="dropdown-menu-align-end"
                                                                                    className='border-0'
                                                                                    variant="primary"
                                                                                    size="sm"
                                                                                    key={index2}
                                                                                // disabled={!(checkAll || incluidos?.length > 0)}
                                                                                >
                                                                                    {
                                                                                        item2.multipleActions.map((item3: any, index3: number) => {
                                                                                            return (
                                                                                                <Dropdown.Item key={index3} eventKey={index + '_' + index2} onClick={() => item3.action(item.id)}>{item3.icon} {item3.label}</Dropdown.Item>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </DropdownButton>
                                                                            ) : (
                                                                                <Button
                                                                                    onClick={
                                                                                        () => item2.withoutID ? item2.action(item) : item2.action(item.id)
                                                                                    }
                                                                                    key={index2}
                                                                                    variant={item2.variant ? item2.variant : 'primary'}
                                                                                    className=''
                                                                                    size='sm'>
                                                                                    {item2.label}
                                                                                </Button>
                                                                            )
                                                                        }
                                                                    </>
                                                                }

                                                            </div>
                                                        )
                                                    })
                                                }
                                            </td>
                                        </>
                                    )
                                }
                            </tr>
                        )
                    })
                }
                {
                    !isLoading && data?.length === 0 && <tr>
                        <td colSpan={20} className='text-center py-5'>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</td>
                    </tr>
                }
                {
                    isLoading && <TableAdvancedLoader />
                }
            </tbody>
        </Table>
    )
}

import { receiveCheck, receiveFecha, receiveFechaFormat2, sendCheck, sendMultipart } from "../../../../../../adapters/helpers"

var MisInscripcionesAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                var data = {
                    id: item.idofertaempleo || '',
                    idpersona: item.idpersona || '',
                    email: item.email || '',
                    label__fechaestado: receiveFechaFormat2(receiveFecha(item.fechaestado)),
                    fechaestado: item.fechaestado || '',
                    idcomunicaciondestinatario: item.idcomunicaciondestinatario || '',
                    idcv: item.idcv || '',
                    idestadopersona: item.idestadopersona || '',
                    idofertaempleo: item.idofertaempleo || '',
                    interes: receiveCheck(item.interes, 'interes'),
                    notificarempresa: receiveCheck(item.notificarempresa, 'notificarempresa'),
                    preferido: receiveCheck(item.preferido, 'preferido'),
                    nif: item.nif || '',
                    nombrecompleto: item.nombrecompleto || '',
                    nombreoferta: item.nombreoferta || '',
                    pathcv: item.pathcv || '',
                    telefonomovil: item.telefonomovil || '',
                    label__horario: item.horario?.descripcionhorario || '',
                    label__estadooferta: item.estadooferta?.nombre || '',
                    label__entidad: item.entidad?.nombre || '',
                    fpublicacioninicio: receiveFechaFormat2(receiveFecha(item.fpublicacioninicio)),
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idperitacion: item.idperitacion || '',
                    fechaalta: item.fechaalta || '',
                    fechabaja: item.fechabaja || '',
                    publicaweb: sendCheck(item.publicaweb),
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },
    
    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                // const data = {
                //     ...item,
                //     interes: sendCheck(item.interes),
                //     // titulocv: item.titulocv || '',
                //     preferido: sendCheck(item.preferido),
                //     // archivo: item.archivo || '',
                //     // idcv: item.idcv || '',
                // }

                return item
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return sendMultipart(items)

            }
        },
    },

    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message || '',
                    status: item.status || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                // 

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },

    },
    
}
export {
    MisInscripcionesAdapters
}
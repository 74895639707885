import { clearFieldsEmpty_adjunto } from "./helpers"

const ComunicacionesAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.identidad || '',
                    nombre: item.nombre || '',
                    sedes: item.sedes || [],
                    numeroasociado: item.asociados?.[0]?.numeroasociado || '',
                    dni: item.nif || '',
                    fechabaja: item.asociados?.[0]?.fechabaja?.split(' ')?.[0] || '',
                    fechaalta: item.fechaalta?.split(' ')?.[0] || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },

    POST_ENVIAR_EMAILS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    comunicacion: item.comunicacion || '',
                    asunto: item.asunto || '',
                    tipo: item.tipo || '',
                    idtiponotificacion: item.idtiponotificacion || '',
                    idweb: item.idweb || '',
                    remitentenombre: item.remitentenombre || '',
                    remitenteemail: item.remitenteemail || '',
                    destinatarios: item.destinatarios || [],
                    adjunto: item.adjunto || '',
                }

                return clearFieldsEmpty_adjunto(data)
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    POST_ENVIAR_EMAILS_INTERCOLEGIO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    comunicacion: item.comunicacion || '',
                    asunto: item.asunto || '',
                    idtiponotificacion: item.idtiponotificacion || '',
                    remitentenombre: item.remitentenombre || '',
                    remitenteemail: item.remitenteemail || '',
                    identidadsede: item.identidadsede || '',
                }

                return clearFieldsEmpty_adjunto(data)
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    POST_ENVIAR_EMAILS_MASIVOS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    comunicacion: item.comunicacion || '',
                    asunto: item.asunto || '',
                    tipo: item.tipo || '',
                    idtiponotificacion: item.idtiponotificacion || '',
                    idweb: item.idweb || '',
                    remitentenombre: item.remitentenombre || '',
                    remitenteemail: item.remitenteemail || '',
                    filtros: item.filtros || {},
                    lista_ids: item.lista_ids || {},
                    lista_exclusion: item.lista_exclusion || {},
                    adjunto: item.adjunto || '',
                }

                return clearFieldsEmpty_adjunto(data)

            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },
}

export {
    ComunicacionesAdapters
}
import { useLang } from '../../../../../../../language'
import { FilterSpliterAdvanced } from './Filters'
import { getValuesParams } from '../../../../../../../helpers/helpers'
import { OfertaServices } from '../../controllers/ofertas.service'
import { useEffect, useState } from 'react'
import { TableBusquedaPersonas } from './TableBusquedaPersonas'
import { Button, Table } from 'react-bootstrap'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoaderFormInscripcion } from '../LoaderFormInscripcion'

export const BusquedaPersonas = ({ formData = {}, idofertaempleo, fetchInscripcionEventoGETData }: any) => {

    const Lang = useLang()

    const [fetchPersonasGET, fetchPersonasGETData, resetfetchPersonasGETData] = OfertaServices.GET_BUSCAR_PERSONAS(idofertaempleo)
    
    const [fetchInscripcionGET, fetchInscripcionGETData] = OfertaServices.GET(idofertaempleo)

    const handleSearch = (formValues: any) => {

        // Obtener todos los valores existentes en el form de values Search
        const urlParams: any = getValuesParams(formValues)

        // setUrlByParams(urlParams)

        fetchPersonasGET({
            params: {
                ...urlParams
            }
        })
    }

    const [dataExcluidos, setdataExcluidos] = useState([])

    const [excluidos, setExcluidos] = useState([])
    const [incluidos, setIncluidos] = useState([])

    const [checkAll, setCheckAll] = useState(false)


    const [activeChecks, setactiveChecks] = useState(false)

    const selectAllItems = () => {
        setCheckAll(true)
        setIncluidos([])
        setExcluidos([])
    }

    const unselectAllItems = () => {
        setCheckAll(false)
        setIncluidos([])
        setExcluidos([])
    }

    const handleClickRegresarABuscador = () => {
        unselectAllItems()
        resetfetchPersonasGETData()
    }

    useEffect(() => {
        
        fetchInscripcionGET()

    }, []);
    

    return (
        <div>

            {
                fetchInscripcionGETData?.loading ?

                    <LoaderFormInscripcion />
                    :
                    <>
                        <div className={`animate__animated animate__fadeInUp ${fetchPersonasGETData?.data ? 'd-none' : 'd-block'}`}>
                            <div className='row mt-3 mb-3 '>

                                <div className="col-12 mb-4">
                                    <FilterSpliterAdvanced
                                        handleSearch={handleSearch}
                                        fetchPersonasGETData={fetchPersonasGETData}
                                        fetchOfertaGETData={fetchInscripcionGETData}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className={`animate__animated animate__fadeInUp ${fetchPersonasGETData?.data ? 'd-block' : 'd-none'}`}>

                            <div className="d-flex justify-content-between align-items-start mb-0">
                                <Button className='mb-3' onClick={handleClickRegresarABuscador} variant='outline-primary'>
                                    <FontAwesomeIcon icon={faArrowLeft} /> {Lang('MODIFICAR_BUSQUEDA')}
                                </Button>
                            </div>

                            <div className="row">
                                <div className="col-lg-3">

                                    <div className=''>

                                        <Table className='me-auto' style={{ flex: 0 }} responsive bordered>

                                            <thead>
                                                <tr>
                                                    <th>
                                                        <small>{Lang('RESUMEN_DE_BUSQUEDA')}:</small>
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Object.keys(fetchPersonasGETData?.data?.resumen || {})?.map((key: string) => {
                                                        return (
                                                            <tr className='' key={key}>
                                                                <td>
                                                                    <small>
                                                                        {key}:
                                                                    </small>
                                                                </td>
                                                                <td>
                                                                    <small>
                                                                        <strong>{fetchPersonasGETData?.data?.resumen[key]}</strong>
                                                                    </small>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>

                                    </div>
                                </div>

                                <div className="col-lg-9">
                                    <TableBusquedaPersonas
                                        data={fetchPersonasGETData.data?.datospersonas}
                                        loading={fetchPersonasGETData.loading}
                                        total={fetchPersonasGETData.data?.total}
                                        perPage={fetchPersonasGETData.data?.per_page}

                                        setdataExcluidos={setdataExcluidos}

                                        checkAll={checkAll}
                                        setCheckAll={setCheckAll}
                                        setIncluidos={setIncluidos}
                                        setExcluidos={setExcluidos}
                                        excluidos={excluidos}
                                        incluidos={incluidos}

                                        selectAllItems={selectAllItems}
                                        unselectAllItems={unselectAllItems}
                                        idofertaempleo={idofertaempleo}

                                        estado={fetchInscripcionEventoGETData?.data?.estado}
                                        isGratuito={fetchInscripcionEventoGETData?.data?.gratuito}

                                        activeChecks={activeChecks}
                                        setactiveChecks={setactiveChecks}
                                    />
                                </div>
                            </div>

                        </div>

                    </>
            }

        </div>
    )
}

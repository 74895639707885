import { useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';
import { useLang } from '../../../../../language';
import { BreadcrumbLine } from '../../../../../components/BreadcrumbLine/BreadcrumbLine';
import { ListMenuRoutes } from '../../../../../components/ListMenu/ListMenuRoutes';
import { EstadosOferta } from './Parametros/EstadosOferta';
import { EstadosPersona } from './Parametros/EstadosPersona';
import { Funciones } from './Parametros/Funciones';
import { Horarios } from './Parametros/Horarios';
import { NivelesIdioma } from './Parametros/NivelIdioma';
import { Salarios } from './Parametros/Salarios';
import { TiposContrato } from './Parametros/TipoContrato';

const Parametros = () => {

  const Lang = useLang()

  const BreadcrumbLineItems = [
    {
      title: Lang('PARAMETROS'),
      link: '/parametros'
    }
  ]

  const listMenu: any = {
    estadoOferta: {
      title: Lang('ESTADOS_OFERTA'),
      link: 'estado-oferta'
    },
    estadosPersona: {
      title: Lang('ESTADOS_PERSONA'),
      link: 'estados-persona'
    },
    funciones: {
      title: Lang('FUNCIONES'),
      link: 'funciones'
    },
    horarios: {
      title: Lang('HORARIOS'),
      link: 'horarios'
    },
    nivelesIdioma: {
      title: Lang('NIVELES_IDIOMA'),
      link: 'niveles-idioma'
    },
    salarios: {
      title: Lang('SALARIOS'),
      link: 'salarios'
    },
    tiposContrato: {
      title: Lang('TIPOS_CONTRATO'),
      link: 'tipos-contrato'
    },
  }


  return (

    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className='d-flex justify-content-between align-items-center mb-1 Nav-top-DAPcon'>
            <BreadcrumbLine BreadcrumbLineItems={BreadcrumbLineItems} />
          </div>
        </div>

        <div className="col-lg-3 mb-4">

          {/* <ListMenu values={listMenu} iDactive={iDactive} setiDactive={setiDactive} /> */}
          <ListMenuRoutes values={listMenu} />

        </div>

        <div className="col-lg-9 animate__animated animate__fadeInUp">
          {/* {listMenu[iDactive].component} */}

          <Routes>

            <Route
              path='estado-oferta'
              element={
                <EstadosOferta />
              }
            />

            <Route
              path='estados-persona'
              element={
                <EstadosPersona />
              }
            />

            <Route
              path='funciones'
              element={
                <Funciones />
              }
            />

            <Route
              path='horarios'
              element={
                <Horarios />
              }
            />

            <Route
              path='niveles-idioma'
              element={
                <NivelesIdioma />
              }
            />

            <Route
              path='salarios'
              element={
                <Salarios />
              }
            />

            <Route
              path='tipos-contrato'
              element={
                <TiposContrato />
              }
            />

            <Route
              path="*"
              element={<Navigate to='estado-oferta' replace />}
            />
          </Routes>

        </div>
      </div>
    </div>
  )
}

export default Parametros;
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { InputSelect } from "../../../../../../../../components/Inputs/InputSelect";
import { ParametrosServices } from "../../../../../../../../services/parametros.service";
import { useLang } from "../../../../../../../../language";
import { cloneDeep } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBroom } from "@fortawesome/free-solid-svg-icons";
import { InputChecks } from "../../../../../../../../components/Inputs/InputChecks";

export const BloqueIdiomas = ({ formIdiomasLogica, setformIdiomasLogica, fetchMultipleParamBolsaData, handleInputChangeSearch,
    handleReset_BloqueIdiomas, formIdiomas, setformIdiomas, formValuesSearch, fetchOfertaGETData }: any) => {

    const Lang = useLang()

    const handleOnChangeIdiomas = (e: any, index: number) => {

        const formIdiomasTemp = cloneDeep(formIdiomas)

        formIdiomasTemp[index][e.target.name] = e.target.value

        // setformIdiomas(formIdiomasTemp)

        renderFilaIdiomas(formIdiomasTemp)
    }

    const renderFilaIdiomas = (list: Array<any>) => {

        let formIdiomasTemp = cloneDeep(list)

        formIdiomasTemp = formIdiomasTemp.filter((item: any) => item.ididioma)

        formIdiomasTemp = [...formIdiomasTemp, { ididioma: '', idnivelidioma: '' }]

        setformIdiomas(formIdiomasTemp)
    }

    useEffect(() => {

        if (fetchOfertaGETData?.data) {

            const idiomaData = fetchOfertaGETData?.data?.nivelidiomacompleto

            renderFilaIdiomas(idiomaData?.map((item: any) => {
                return {
                    ididioma: item.ididioma,
                    idnivelidioma: item.idnivelidioma,
                }
            }))

            setformIdiomasLogica({
                idioma_logica: fetchOfertaGETData?.data?.logica_buscar_idioma === 'and' ? ['and'] : [],
            })

        }
    }, [fetchOfertaGETData]);


    const handleChangeCheck = (e: any, name: string) => {

        let listaTemp = cloneDeep(formIdiomasLogica[name])

        if (e.target.checked) {

            listaTemp.push(e.target.name)

        } else {

            listaTemp = listaTemp.filter((item: any) => item !== e.target.name)
        }

        setformIdiomasLogica({
            ...formIdiomasLogica,
            [name]: listaTemp
        })
    }

    return (
        <>
            <Form className='row'>

                {
                    formIdiomas?.map((item: any, index: number) => {
                        return (
                            <>
                                <Form.Group className="mb-3 col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 col-xxl-5">
                                    <InputSelect
                                        label={`${Lang('IDIOMAS')}:`}
                                        name='ididioma'
                                        value={item.ididioma}
                                        values={fetchMultipleParamBolsaData?.data?.['idiomas'] || []}
                                        onChange={(e) => handleOnChangeIdiomas(e, index)}
                                        size="sm"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 col-xxl-5">
                                    <InputSelect
                                        label={`${Lang('NIVEL_IDIOMA')}:`}
                                        name='idnivelidioma'
                                        value={item.idnivelidioma}
                                        values={fetchMultipleParamBolsaData?.data?.['nivelidioma'] || []}
                                        size="sm"
                                        onChange={(e) => handleOnChangeIdiomas(e, index)}
                                    />
                                </Form.Group>

                                <div className="col-12"></div>
                            </>
                        )
                    })
                }

            </Form>

            <div className="d-flex justify-content-between align-items-start flex-wrap mt-2 pt-3">

                <Button onClick={handleReset_BloqueIdiomas} size="sm" variant="outline-secondary">
                    <FontAwesomeIcon icon={faBroom} /> {Lang('LIMPIAR_TODO')}
                </Button>

                <div className='mt-1 row justify-content-end'>
                    <Form.Group className="mb-0 col flex-grow-0 text-nowrap">
                        <div className="row">
                            <small>
                                <InputChecks
                                    name='idioma_logica'
                                    value={formIdiomasLogica.idioma_logica}
                                    values={[{ id: 'and', nombre: Lang('REQUERIR_TODOS_IDIOMAS') }]}
                                    classNameContainer='d-inline-block me-0'
                                    onChange={(e: any) => handleChangeCheck(e, 'idioma_logica')}
                                />
                            </small>

                        </div>
                    </Form.Group>
                </div>
            </div>
        </>
    );
};

import './Placeholder.css'

interface IPlaceholderRectangle {
  height?: string,
  width?: string,
  borderRadius?: string,
  className?: string,
  classNameChild?: string,
}

export const Placeholder = ({ height = '5rem', width, borderRadius = '5px', className = '', classNameChild = '' }: IPlaceholderRectangle) => {
  return (
    <div className={`Placeholder ${className}`} style={{ 'width': width }}>
      <div className={`Placeholder__content ${classNameChild}`} style={{ 'height': height, 'borderRadius': borderRadius }}></div>
    </div>
  )
}

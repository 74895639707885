import { useEffect, useState } from "react";
import { Accordion, Badge, Form } from "react-bootstrap";
import { ButtonSpinner } from "../../../../../../../../components/ButtonSpinner/ButtonSpinner";
import { useLang } from "../../../../../../../../language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { cloneDeep } from "lodash";
import { BloqueIdiomas } from "./BloqueIdiomas";
import { ParametrosServices } from "../../../../../../../../services/parametros.service";
import { BloqueEstudios } from "./BloqueEstudios";
import { BloquePerfilCandidato } from "./BloquePerfilCandidato";
import { BloqueFunciones } from "./BloqueFunciones";
import { EspecialidadesServices } from "../../../../../../../administracion/pages/Parametros/services/especialidades.service";

export const FilterSpliterAdvanced = ({ handleSearch, fetchPersonasGETData, fetchOfertaGETData }: any) => {

    const Lang = useLang()


    // -------- Parametros

    const [fetchMultipleParamBolsa, fetchMultipleParamBolsaData] = ParametrosServices.GET_BOLSA_TRABAJO()

    const [fetchEspecialidadesGET, fetchEspecialidadesGETData] = EspecialidadesServices.GET()

    useEffect(() => {

        fetchMultipleParamBolsa({
            params: {
                nivelidioma: true,
                funciones: true,
                idiomas: true,
                tiposestudios: true,
                // tiposestudiosespecialidades: true,
                tiposContratos: true,
                salarios: true,
                horarios: true,
                order: 'asc',
                // status: 'activos'
            }
        })

        fetchEspecialidadesGET({
            params: {
                limit: 200,
                status: 'activos'
            }
        })

    }, [])


    const [bloquesFiltro, setbloquesFiltro] = useState([
        {
            name: 'puesto_trabajo',
            label: Lang('FORMACION'),
            active: true,
        },
        {
            name: 'perfil_candidato',
            label: Lang('PERFIL_CANDIDATO'),
            active: true,
        },
        {
            name: 'funciones',
            label: Lang('FUNCIONES'),
            active: true,
        },
        {
            name: 'idiomas',
            label: Lang('IDIOMAS'),
            active: true,
        },
    ]);


    const handleChangeCheckFiltro = (e: any, index: number) => {

        const bloquesFiltroTemp = cloneDeep(bloquesFiltro)

        bloquesFiltroTemp[index]['active'] = e.target.checked

        if (!bloquesFiltroTemp[index]['active']) {

            switch (e.target.name) {

                case 'puesto_trabajo':
                    handleReset_BloquePuestoTrabajo()
                    break;

                case 'perfil_candidato':
                    handleReset_BloquePerfilCandidato()
                    break;

                case 'funciones':
                    handleReset_BloqueFunciones()
                    break;

                case 'idiomas':
                    handleReset_BloqueIdiomas()
                    break;

                default:
                    break;
            }

        }

        setbloquesFiltro(bloquesFiltroTemp)
    }

    const handleChangeLogica = (e: any, name1: string, name2: string, set: any) => {

        let names: any = {}

        if (name1) {
            names[name1] = e.target.value
        }

        if (name2) {
            names[name2] = e.target.value
        }

        set({
            ...names,
        })
    }

    // ----------------- Iniciales de estudios


    const [formTiposEstudios, setformTiposEstudios] = useState<any>([
        {
            idtiposestudios: '',
            idtiposestudiosespecialidades: '',
        }
    ]);

    const [formTiposEstudiosLogica, setformTiposEstudiosLogica] = useState<any>(
        {
            'tipoestudio_logica': [],
            'tipoestudioespecialidad_logica': [],
        }
    );

    const handleReset_BloquePuestoTrabajo = () => {

        setformTiposEstudios([
            {
                idtiposestudios: '',
                idtiposestudiosespecialidades: '',
            }
        ])

        setformTiposEstudiosLogica({
            'logica_buscar_tipoestudio': [],
            'tipoestudioespecialidad_logica': [],
        })
    }


    // ------------------ Iniciales de funciones

    const [dataAllMatch, setdataAllMatch] = useState<any>([]);

    const [dataAllMatchLogica, setdataAllMatchLogica] = useState({
        'logica_buscar_experiencia': [],
        'logica_buscar_interes': [],
    });


    const handleReset_BloqueFunciones = () => {

        const dataParamsAdapted = dataAllMatch?.map((item: any) => {
            return {
                idfuncion: item.idfuncion,
                nombre: item.nombre,
                experiencia: false,
                interes: false,
            }
        })

        setdataAllMatch(dataParamsAdapted)

        setdataAllMatchLogica(
            {
                'logica_buscar_experiencia': [],
                'logica_buscar_interes': [],
            }
        )
    }

    // ----------- Iniciales de Idioma


    const [formIdiomas, setformIdiomas] = useState<any>([
        {
            ididioma: '',
            idnivelidioma: '',
        }
    ]);

    const [formIdiomasLogica, setformIdiomasLogica] = useState({
        'idioma_logica': [],
    });

    const handleReset_BloqueIdiomas = () => {

        setformIdiomas([
            {
                ididioma: '',
                idnivelidioma: '',
            }
        ])

        setformIdiomasLogica({
            'idioma_logica': [],
        })
    }

    // -------------------- Iniciales de PerfilCandidato


    const [perfilCandidato, setperfilCandidato] = useState<any>({
        idtipocontrato: '',
        idsalario: '',
        idhorario: '',
    });


    const handleReset_BloquePerfilCandidato = () => {

        setperfilCandidato({
            // idprovincia: '',
            // municipio: '',
            // notificado: '',
            idtipocontrato: '',
            idsalario: '',
            idhorario: '',
        })
    }

    const searchAll = () => {

        const listTipoEstudio = formTiposEstudios?.map((item: any) => item.idtiposestudios)?.filter((item: any) => item)

        const listTipoEstudioEspecialidad = formTiposEstudios?.map((item: any) => item.idtiposestudiosespecialidades)?.filter((item: any) => item)


        const listExperiencia = dataAllMatch?.filter((item: any) => item.experiencia)?.map((item: any) => item.idfuncion)

        const listInteres = dataAllMatch?.filter((item: any) => item.interes)?.map((item: any) => item.idfuncion)

        const resIdiomasValores: any = {}

        for (let i = 0; i < formIdiomas.length; i++) {

            if (formIdiomas[i].ididioma) resIdiomasValores[`idiomas[valores][${i}][idioma]`] = formIdiomas[i].ididioma
            if (formIdiomas[i].idnivelidioma) resIdiomasValores[`idiomas[valores][${i}][nivel]`] = formIdiomas[i].idnivelidioma
        }

        handleSearch({

            // ---- Actuanlizando estudios

            [`tipoestudio[valores]`]: listTipoEstudio,
            [`tipoestudioespecialidad[valores]`]: listTipoEstudioEspecialidad,
            'tipoestudio[logica]': formTiposEstudiosLogica.logica_buscar_tipoestudio?.length > 0 ? 'and' : 'or',
            'tipoestudioespecialidad[logica]': formTiposEstudiosLogica.logica_buscar_tipoestudioespecialidad?.length > 0 ? 'and' : 'or',

            // ---- Actualizando perfil candidato
            // idprovincia: '',
            // municipio: '',
            // notificado: '',
            idtipocontrato: perfilCandidato.idtipocontrato,
            idsalario: perfilCandidato.idsalario,
            idhorario: perfilCandidato.idhorario,

            // ------ Actualizando funciones

            [`interes[valores]`]: listInteres,
            [`experiencia[valores]`]: listExperiencia,
            'interes[logica]': dataAllMatchLogica.logica_buscar_interes?.length > 0 ? 'and' : 'or',
            'experiencia[logica]': dataAllMatchLogica.logica_buscar_experiencia?.length > 0 ? 'and' : 'or',

            // ------ Actualizando idiomas

            // [`idiomas[valores]`]: resIdiomasValores,
            ...resIdiomasValores,
            [`idioma[logica]`]: formIdiomasLogica.idioma_logica?.length > 0 ? 'and' : 'or',
        })

    }

    return (
        <>
            <div className="">
                <h5 className="mb-3">{Lang('BUSCADOR_PERSONAS')}</h5>
                <div className="mb-3 text-center">
                    {
                        bloquesFiltro.map((item: any, index: number) => {
                            return (
                                <Form.Check
                                    type='checkbox'
                                    label={item.label}
                                    name={item.name}
                                    id={item.name}
                                    onChange={(e) => handleChangeCheckFiltro(e, index)}
                                    autoComplete='one-time-code'
                                    // defaultChecked={true}
                                    checked={item.active}
                                    className="d-inline-block mx-2"
                                />
                            )
                        })
                    }

                </div>
            </div>
            <Accordion alwaysOpen>

                <Accordion.Item eventKey="0" className={`animate__animated animate__fadeIn animate__faster ${bloquesFiltro[0].active ? 'd-block' : 'd-none'}`}>
                    <Accordion.Header>
                        <div className="d-flex flex-column w-100">
                            <div className="text-nowrap mb-0 d-flex justify-content-between flex-wrap align-items-center">
                                <span className="fw-bold">{bloquesFiltro[0].label}</span>
                                
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>

                        <BloqueEstudios
                            fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                            fetchEspecialidadesGETData={fetchEspecialidadesGETData}
                            handleReset_BloquePuestoTrabajo={handleReset_BloquePuestoTrabajo}
                            setformTiposEstudios={setformTiposEstudios}
                            formTiposEstudios={formTiposEstudios}
                            fetchOfertaGETData={fetchOfertaGETData}

                            formTiposEstudiosLogica={formTiposEstudiosLogica}
                            setformTiposEstudiosLogica={setformTiposEstudiosLogica}
                        />

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1" className={`animate__animated animate__fadeIn animate__faster ${bloquesFiltro[1].active ? 'd-block' : 'd-none'}`}>
                    <Accordion.Header>
                        <div className="d-flex flex-column">
                            <span className="fw-bold pe-3 text-nowrap mb-2">{bloquesFiltro[1].label}</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>

                        <BloquePerfilCandidato
                            fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                            // fetchProvinciasParamData={fetchProvinciasParamData}
                            handleReset_BloquePerfilCandidato={handleReset_BloquePerfilCandidato}
                            fetchOfertaGETData={fetchOfertaGETData}
                            setperfilCandidato={setperfilCandidato}
                            perfilCandidato={perfilCandidato}
                        />

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2" className={`animate__animated animate__fadeIn animate__faster ${bloquesFiltro[2].active ? 'd-block' : 'd-none'}`}>
                    <Accordion.Header>
                        <div className="d-flex flex-column w-100">
                            <div className="text-nowrap mb-0 d-flex justify-content-between flex-wrap align-items-center">

                                <span className="fw-bold">{bloquesFiltro[2].label}</span>
                                
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>

                        <BloqueFunciones
                            fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                            handleReset_BloqueFunciones={handleReset_BloqueFunciones}
                            setdataAllMatch={setdataAllMatch}
                            fetchOfertaGETData={fetchOfertaGETData}
                            dataAllMatch={dataAllMatch}

                            dataAllMatchLogica={dataAllMatchLogica}
                            setdataAllMatchLogica={setdataAllMatchLogica}
                        />

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3" className={`animate__animated animate__fadeIn animate__faster ${bloquesFiltro[3].active ? 'd-block' : 'd-none'}`}>
                    <Accordion.Header>
                        <div className="d-flex flex-column w-100">
                            <div className="text-nowrap mb-0 d-flex justify-content-between flex-wrap align-items-center">
                                <span className="fw-bold ">{bloquesFiltro[3].label}</span>

                                
                            </div>

                        </div>
                    </Accordion.Header>
                    <Accordion.Body>

                        <BloqueIdiomas
                            fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                            handleReset_BloqueIdiomas={handleReset_BloqueIdiomas}
                            formIdiomas={formIdiomas}
                            setformIdiomas={setformIdiomas}
                            fetchOfertaGETData={fetchOfertaGETData}
                            setformIdiomasLogica={setformIdiomasLogica}
                            formIdiomasLogica={formIdiomasLogica}
                        />

                    </Accordion.Body>
                </Accordion.Item>

                {
                    bloquesFiltro?.filter((item: any) => item.active)?.length === 0 &&
                    <div className="border d-flex justify-content-center align-items-center p-5" style={{ backgroundColor: '#f9f9f9' }}>
                        {Lang('PARRAFO_SELECCIONE_ALMENOS_UN_BLOQUE_BUSQUEDA')}
                    </div>
                }

            </Accordion>
            <div className="mt-3 d-flex justify-content-center">
                <ButtonSpinner
                    variant="secondary" type="submit" size='' className=""
                    handleSubmit={searchAll}
                    fetchDataLoading={[fetchPersonasGETData.loading]}
                    value={Lang('BUSCAR_PERSONAS')} icon={<FontAwesomeIcon icon={faSearch} className='me-1' />}
                />
            </div>
        </>
    );
};

const BagdeAdvanced = ({ children, className = '' }: any) => {

    return (
        <>
            <Badge className={className}>
                <small>{children}</small>
            </Badge>
        </>
    )
}
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { BreadcrumbLine } from '../../../../../components/BreadcrumbLine/BreadcrumbLine';
import FilterAdvanced2 from '../../../../../components/Filter/FilterAdvanced/FilterAdvanced2';
import { getURLQuery, getValuesParams } from '../../../../../helpers/helpers';
import { useLang } from '../../../../../language';
import { TableEventos } from './TableEventos';
import queryString from 'query-string';
import { useForm } from '../../../../../hooks/useForm';
import { FilterFormHead } from './Filters/FilterFormHead';
import { FilterForm } from './Filters/FilterForm';
import { ParametrosServices } from '../../../../../services/parametros.service';
import { OfertasServices } from './controllers/ofertas.service';
import { ButtonSpinner } from '../../../../../components/ButtonSpinner/ButtonSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBroom, faSearch } from '@fortawesome/free-solid-svg-icons';
import { InputText } from '../../../../../components/Inputs';
import { InputSelect } from '../../../../../components/Inputs/InputSelect';
import { Form } from 'react-bootstrap';

const Inscripciones = () => {

  // Variables iniciales

  const Lang = useLang()

  const location = useLocation()

  const BreadcrumbLineItems = [
    {
      title: Lang('OFERTAS'),
      link: '/'
    }
  ]

  const [fetchOfertasEmpleoGET, fetchOfertasEmpleoGETData] = OfertasServices.GET()

  const defaultValuesSearchAlways = {
    nombreevento: '',
    idestadooferta: '',
    idtipocontrato: '',
  }

  const defaultValuesSearchSimple = {
    page: 1,
    limit: '',
    order: '',

  }

  const defaultValuesSearchAdvanced = {
    page: 1,
    limit: '',
    order: '',

    fechainicioinscripcion: '',
    fechafininscripcion: '',
  }

  const defaultValuesSearch = { ...defaultValuesSearchSimple, ...defaultValuesSearchAdvanced, ...defaultValuesSearchAlways }

  const params = queryString.parse(location.search);

  const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
    page: Number(params.page) || defaultValuesSearch.page,
    limit: params.limit || defaultValuesSearch.limit,
    nombreevento: params.nombreevento || defaultValuesSearch.nombreevento,
    order: params.order || defaultValuesSearch.order,

    idtipocontrato: params.idtipocontrato || defaultValuesSearch.idtipocontrato,

    fechainicioinscripcion: params.fechainicioinscripcion || defaultValuesSearch.fechainicioinscripcion,
    fechafininscripcion: params.fechafininscripcion || defaultValuesSearch.fechafininscripcion,
    idestadooferta: params.idestadooferta || defaultValuesSearch.idestadooferta,
  });

  const handleSearch = (e: any, formValues: any) => {

    if (e) {
      e.preventDefault();
    }

    // Obtener todos los valores existentes en el form de values Search
    const urlParams = getValuesParams(formValues)

    // Convertir esos valores del objeto a url amigable
    const query = getURLQuery(urlParams)

    // history.push(`?${query}`);
    window.history.replaceState(null, '', `?${query}`);

    if (formValues.page !== 1) {

      handleFormChange({
        ...formValues,
        page: 1
      });

    } else {

      let formValuesTemp = { ...formValues }

      Object.keys(formValuesTemp).forEach((k) => formValuesTemp[k] === '' && delete formValuesTemp[k]);

      fetchOfertasEmpleoGET({
        params: {
          ...formValuesTemp
        }
      })
    }
  }

  useEffect(() => {

    // Obtener todos los valores existentes en el form de values Search
    const urlParams = getValuesParams(formValuesSearch)

    // Convertir esos valores del objeto a url amigable
    const query = getURLQuery(urlParams)

    window.history.replaceState(null, '', `?${query}`);

    fetchOfertasEmpleoGET({
      params: {
        ...urlParams
      }
    })

  }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit])


  const [fetchParamsMultiple, fetchParamsMultipleData] = ParametrosServices.GET()

  useEffect(() => {

    fetchParamsMultiple({
      params: {
        tiposeventos: true,
        tiposContratos: true,
        estadooferta: true,
        order: 'asc',
      }
    })
  }, [])

  return (

    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className='d-flex justify-content-between align-items-center mb-1 Nav-top-DAPcon'>
            <BreadcrumbLine BreadcrumbLineItems={BreadcrumbLineItems} />
          </div>
        </div>

        <div className="col-12">

          <div className="mb-4">

            <FilterAdvanced2
              handleSearch={handleSearch}
              formValuesSearch={formValuesSearch}
              handleInputChangeSearch={handleInputChangeSearch}
              fetchData={fetchOfertasEmpleoGETData}
              resetformValuesSearch={resetformValuesSearch}

              defaultValuesSearchSimple={defaultValuesSearchSimple}
              defaultValuesSearchAdvanced={defaultValuesSearchAdvanced}
              defaultValuesSearchAlways={defaultValuesSearchAlways}
              handleFormChange={handleFormChange}
              simple={true}
            >

              <FilterAdvanced2.Head label={Lang('BUSCADOR')}>

                <>

                  <Form.Group className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <InputText
                      label={Lang('NOMBRE_OFERTA') + ':'}
                      name='nombreoferta'
                      value={formValuesSearch.nombreoferta}
                      size='sm'
                      onChange={handleInputChangeSearch}
                    />
                  </Form.Group>

                  <Form.Group className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
                    <InputSelect
                      label={`${Lang('ESTADO_OFERTA')}:`}
                      name='idestadooferta'
                      value={formValuesSearch.idestadooferta}
                      size='sm'
                      values={fetchParamsMultipleData.data?.['estadooferta'] || []}
                      onChange={handleInputChangeSearch}
                    />
                  </Form.Group>

                  <Form.Group className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
                    <InputSelect
                      label={`${Lang('TIPO_CONTRATO')}:`}
                      name='idtipocontrato'
                      value={formValuesSearch.idtipocontrato}
                      size='sm'
                      values={fetchParamsMultipleData.data?.['tiposContratos'] || []}
                      onChange={handleInputChangeSearch}
                    />
                  </Form.Group>

                  <div className="col flex-grow-0 text-nowrap">
                    <div className="d-flex align-items-end justify-content-end h-100">
                      <ButtonSpinner
                        variant="outline-secondary" type="submit" className="me-3" size='sm'
                        handleSubmit={resetformValuesSearch} fetchDataLoading={[false]}
                        value={Lang('LIMPIAR')} icon={<FontAwesomeIcon icon={faBroom} />}
                      />
                      <ButtonSpinner
                        variant="secondary" type="submit" size='sm' className=""
                        handleSubmit={(e: any) => handleSearch(e, formValuesSearch)} fetchDataLoading={[fetchOfertasEmpleoGETData.loading]}
                        value={Lang('BUSCAR')} icon={<FontAwesomeIcon icon={faSearch} className='me-1' />}
                      />
                    </div>

                  </div>
                </>
              </FilterAdvanced2.Head>

              <FilterAdvanced2.Body>

                {/* <FilterForm /> */}

              </FilterAdvanced2.Body>

            </FilterAdvanced2>

          </div>


          <div className=" animate__animated animate__fadeInUp">

            <TableEventos
              data={fetchOfertasEmpleoGETData.data?.items}
              loading={fetchOfertasEmpleoGETData.loading}
              total={fetchOfertasEmpleoGETData.data?.total}
              perPage={fetchOfertasEmpleoGETData.data?.per_page}

              formValuesSearch={formValuesSearch}
              handleFormChange={handleFormChange}
              handleInputChangeSearch={handleInputChangeSearch}

              urlParams={getValuesParams(formValuesSearch)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Inscripciones;
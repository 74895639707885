import { useAxios } from '../../../../../hooks/useAxios';

import { 
    PersonaDatosGeneralesAdapters, 
    PersonasAdapters,
    PersonaDatosColegiacionAdapters,
    PersonaAdapters,
} from '../adapters/personas.adapter';

interface IParamsServices {
    id?: any
}

const PersonasServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/personas`, type: 'json', adapters: PersonasAdapters.GET })
    },

    GET_EXCEL: function () {

        return useAxios({ method: 'get', url: `/personas`, type: 'blob', adapters: PersonasAdapters.GET_EXCEL })
    },

    
    GET_TICKETING: function () {

        return useAxios({ method: 'get', url: `/ticketing/tkentidades`, type: 'json', adapters: PersonasAdapters.GET_TICKETING })
    },
}

const PersonaServices = {

    POST: function (id:any) {

        return useAxios({ method: 'post', url: `/personas`, type: 'json', adapters: PersonaAdapters.POST })
    },

    PATCH_CREDENCIALES: function (id:IParamsServices) {

        return useAxios({ method: 'patch', url: `/personas/${id}/asignar-credenciales`, type: 'json', adapters: PersonaAdapters.PATCH_CREDENCIALES })
    },

    PATCH_CAMBIAR_CONTRASENIA: function () {

        return useAxios({ method: 'post', url: `/auth/changePassword`, type: 'json', adapters: PersonaAdapters.PATCH_CAMBIAR_CONTRASENIA })
    },

    PATCH_JUBILACION: function (id:IParamsServices) {

        return useAxios({ method: 'patch', url: `/personas/${id}/fechajubilacion`, type: 'json', adapters: PersonaAdapters.PATCH_JUBILACION })
    },

    
    PATCH_REACTIVAR: function (id:IParamsServices) {

        return useAxios({ method: 'patch', url: `/personas/${id}/reactivar`, type: 'json', adapters: PersonaAdapters.PATCH_REACTIVAR })
    },

    DELETE: function (id:IParamsServices) {

        return useAxios({ method: 'delete', url: `/personas/${id}`, type: 'json', adapters: PersonaAdapters.DELETE })
    }
}

const PersonaDatosGeneralesServices = {

    GET: function (id: any) {

        return useAxios({ method: 'get', url: `/personas/${id}`, type: 'json', adapters: PersonaDatosGeneralesAdapters.GET })
    },
    
    POST: function (id: any) {

        return useAxios({ method: 'post', url: `/personas/${id}`, type: 'json', adapters: PersonaDatosGeneralesAdapters.PATCH })
    },

    PATCH: function (id: any) {

        return useAxios({ method: 'patch', url: `/personas/${id}`, type: 'json', adapters: PersonaDatosGeneralesAdapters.PATCH })
    },

    DELETE: function (id: any) {

        return useAxios({ method: 'delete', url: `/personas/${id}`, type: 'json', adapters: PersonaDatosGeneralesAdapters.DELETE })
    },

    POST_SUBIR_IMAGEN: function (id: any) {

        return useAxios({ method: 'post', url: `/personas/${id}/imagen/subir`, type: 'json', adapters: PersonaDatosGeneralesAdapters.POST_SUBIR_IMAGEN })
    },

    DELETE_IMAGEN: function (id: any) {

        return useAxios({ method: 'delete', url: `/personas/${id}/imagen/eliminar`, type: 'json', adapters: PersonaDatosGeneralesAdapters.DELETE_IMAGEN })
    },
}

const PersonaDatosColegiacionServices = {

    GET: function (id:IParamsServices) {

        return useAxios({ method: 'get', url: `/personas/${id}/asociados`, type: 'json', adapters: PersonaDatosColegiacionAdapters.GET })
    },

    POST: function (id:IParamsServices) {

        return useAxios({ method: 'post', url: `/personas/${id}/asociados/crear`, type: 'json', adapters: PersonaDatosColegiacionAdapters.POST })
    },

    PATCH_editar: function (id:IParamsServices) {

        return useAxios({ method: 'patch', url: `/personas/${id}/asociados/actualizar`, type: 'json', adapters: PersonaDatosColegiacionAdapters.PATCH_editar })
    },

    PATCH_REACTIVAR: function (id:IParamsServices) {

        return useAxios({ method: 'patch', url: `/personas/${id}/asociados/reactivar`, type: 'json', adapters: PersonaDatosColegiacionAdapters.PATCH_REACTIVAR })
    },

    DELETE: function (id: any) {

        return useAxios({ method: 'delete', url: `/personas/${id}/asociados/baja`, type: 'json', adapters: PersonaDatosColegiacionAdapters.DELETE })
    },
}


export {
    PersonasServices,
    PersonaServices,
    PersonaDatosGeneralesServices,
    PersonaDatosColegiacionServices,
}
import { useAxios } from "../../../../../../../../hooks/useAxios"
import { PersonaEntidadAdapters, PersonaEntidadesAdapters } from "../adapters/entidades.persona.adapters"

const PersonaEntidadesServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/personasentidades`, type: 'json', adapters: PersonaEntidadesAdapters.GET })
    },

    GET_MIS_ENTIDADES: function () {

        return useAxios({ method: 'get', url: `/personasentidades-current-user`, type: 'json', adapters: PersonaEntidadesAdapters.GET })
    }
}

const PersonaEntidadServices = {

    GET: function (id: any) {

        return useAxios({ method: 'get', url: `/personasentidades/${id}`, type: 'json', adapters: PersonaEntidadAdapters.GET })
    },

    POST: function () {

        return useAxios({ method: 'post', url: `/personasentidades`, type: 'json', adapters: PersonaEntidadAdapters.POST })
    },

    PATCH: function (id: any) {

        return useAxios({ method: 'patch', url: `/personasentidades/${id}`, type: 'json', adapters: PersonaEntidadAdapters.PATCH })
    },

    DELETE: function (id: any) {

        return useAxios({ method: 'delete', url: `/personasentidades/${id}`, type: 'json', adapters: PersonaEntidadAdapters.DELETE })
    }
}

export {
    PersonaEntidadesServices,
    PersonaEntidadServices
}
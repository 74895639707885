import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import moment from 'moment'

import { Calendar, momentLocalizer} from 'react-big-calendar'
import { cloneDeep } from 'lodash'
import { OfertasServices } from './controllers/ofertas.service'

const localizer = momentLocalizer(moment)

require('moment/locale/ca.js')

export const ModalVerCalendario = ({ showModal_VerCalendario, handleCloseModal_VerCalendario }: any) => {

    const [events, setEvents] = useState<any>([])

    const lang = {
        ca: {
            week: 'Setmana',
            work_week: 'Setmana de treball',
            day: 'Dia',
            month: 'Mes',
            previous: 'Enrere',
            next: 'Després',
            today: 'Avui',
            agenda: 'El Diari',
            sun: 'Domingo',
            months: {
                names: ["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre",""],
                namesAbbr: ["ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic",""]
            },
            showMore: (total: any) => `+${total} més`,
        }
    }

    useEffect(() => {

        if (showModal_VerCalendario.value) {

            getStartAndEndDate(new Date());
        }

    }, [showModal_VerCalendario.value])

    const [fetchDatesEventosGET, fetchDatesEventosGETData] = OfertasServices.GET_BETWEEN_DATES()

    const [startEndDates, setStartEndDates] = useState<any>({});

    const getStartAndEndDate = (date: Date) => {

        const firstDay = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('YYYY-MM-DD');
        const lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format('YYYY-MM-DD');

        setStartEndDates({
            fechainicio: firstDay,
            fechafin: lastDay,
        })
    };

    const onNavigate = useCallback((date: Date) => {
        getStartAndEndDate(date);
    }, []);

    useLayoutEffect(() => {

        if (startEndDates.fechainicio) {

            fetchDatesEventosGET({
                params: startEndDates
            })
            setEvents([])
        }

    }, [startEndDates])

    useEffect(() => {

        if (fetchDatesEventosGETData?.data?.items?.length > 0) {

            const eventosTemp = cloneDeep(fetchDatesEventosGETData?.data?.items)?.map((item: any) => {
                return {
                    start: new Date(item),
                    end: new Date(item),
                    title: '    ',
                }
            })

            setEvents(eventosTemp)
        }

    }, [fetchDatesEventosGETData])

    return (
        <>
            <Modal show={showModal_VerCalendario.value} onHide={handleCloseModal_VerCalendario} dialogClassName='modal-70vw' id='ModalRegistro'>
                <Modal.Header className='border-bottom-0 py-4'>

                </Modal.Header>
                <Modal.Body className='px-4 py-0'>

                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500 }}
                        // onSelectEvent={handleSelectEvent}
                        // onSelectSlot={handleSelectSlot}
                        culture='ca'
                        onNavigate={onNavigate}
                        messages={lang['ca']}
                        views={
                            {
                                month: true
                            }
                        }
                        selectable
                    />

                </Modal.Body>
                <Modal.Footer className='border-0 d-flex justify-content-between'>

                </Modal.Footer>
            </Modal>

        </>
    )
}

import { faBroom, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";
import { ButtonSpinner } from "../../../../../../../components/ButtonSpinner/ButtonSpinner";
import { InputText } from "../../../../../../../components/Inputs";
import { useLang } from "../../../../../../../language";

export const FilterForm = ({ handleSearch, formValuesSearch, handleInputChangeSearch, fetchData, resetformValuesSearch,
  defaultValuesSearchSimple, defaultValuesSearchAdvanced, handleFormChange, defaultValuesSearchAlways, formNamesFilters }: any) => {

  const Lang = useLang()

  const handleMiddleSearch = () => {

    const valuesFinal = { ...formValuesSearch, ...defaultValuesSearchSimple }
    handleFormChange(valuesFinal)
    handleSearch(null, valuesFinal)
  }

  const handleResetClear = () => {

    const valuesFinal = { ...defaultValuesSearchSimple, ...defaultValuesSearchAdvanced, ...defaultValuesSearchAlways }
    handleFormChange(valuesFinal)
  }

  return (
    <>
      <div className="border-bottom mb-3 mt-1"></div>

      <Form.Group className="mb-3 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
        <InputText
          label={`${Lang('FECHA_INICIO_INSCRIPCION')}:`}
          type='date'
          name='fechainicioinscripcion'
          value={formValuesSearch.fechainicioinscripcion}
          onChange={handleInputChangeSearch}
          size='sm'
        />
      </Form.Group>

      <Form.Group className="mb-3 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
        <InputText
          label={`${Lang('FECHA_FIN_INSCRIPCION')}:`}
          type='date'
          name='fechafininscripcion'
          value={formValuesSearch.fechafininscripcion}
          onChange={handleInputChangeSearch}
          size='sm'
        />
      </Form.Group>

      <div className="ms-auto col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mb-3">
        <div className="d-flex align-items-end justify-content-end h-100">
          <ButtonSpinner
            variant="outline-secondary" type="submit" size='sm' className="me-2"
            handleSubmit={handleResetClear} fetchDataLoading={[false]}
            value={Lang('LIMPIAR')} icon={<FontAwesomeIcon icon={faBroom} />}
          />
          <ButtonSpinner
            variant="secondary" type="submit" size='sm' className=""
            handleSubmit={handleMiddleSearch} fetchDataLoading={[fetchData.loading]}
            value={Lang('BUSCAR')} icon={<FontAwesomeIcon icon={faSearch} className='me-1' />}
          />
        </div>

      </div>


    </>
  )
}

import { useEffect, useState } from 'react'
import { useForm } from '../../../../../hooks/useForm';
import { useLang } from '../../../../../language';
import { convertParamToObject } from '../../../../../services/helpers';
import { ParametrosServices } from '../../../../../services/parametros.service';
import { PersonaDatosGeneralesAdapters } from '../../../../administracion/pages/Personas/adapters/personas.adapter';
import { misFacturasServices } from '../../controllers/services/misFacturas.service';
import { TableFacturas } from './TableFacturas';
import queryString from 'query-string';
import { getSelectByKeys, getValuesParams, setUrlByParams } from '../../../../../helpers/helpers';
import { useLocation } from 'react-router-dom';
import FilterAdvanced2 from '../../../../../components/Filter/FilterAdvanced/FilterAdvanced2';
import { Form } from 'react-bootstrap';
import { InputText } from '../../../../../components/Inputs';
import { InputSelect } from '../../../../../components/Inputs/InputSelect';
import { ButtonSpinner } from '../../../../../components/ButtonSpinner/ButtonSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBroom, faSearch } from '@fortawesome/free-solid-svg-icons';
import { EstadoFacturaServices } from '../../../../../services/statics.service';

interface IState {
  data: any,
  loading: any,
  error: any,
  status: any
}

const Index = () => {

  const [fetchFacturasGET, fetchFacturasGETData, resetfetchFacturasGET] = misFacturasServices.GET()

  useEffect(() => {

    fetchFacturasGET()

  }, [])


  const location = useLocation()

  // Parametros

  const [fetchActProfesionalesParam, fetchActProfesionalesParamData] = ParametrosServices.GET()

  useEffect(() => {

    fetchActProfesionalesParam({
      params: {
        actividadesprofesionales: true
      }
    })

  }, [])

  useEffect(() => {
    if (fetchActProfesionalesParamData.status === 200) {

      setactividadesProfParams(convertParamToObject(fetchActProfesionalesParamData.data))
    }
  }, [fetchActProfesionalesParamData])


  const Lang = useLang()

  const [formPersonaDatosGenerales, handleInputPersonaDatosGenerales, handleFormPersonaDatosGenerales
  ] = useForm({
    ...PersonaDatosGeneralesAdapters.GET.receive.schema({}),
  })


  // -------------------- PATCH Persona Entidad

  const [fetchMiActProfesionalPATCH, fetchMiActProfesionalPATCHData] = misFacturasServices.PATCH()

  const handleSubmitEditar = (item: any) => {

    fetchMiActProfesionalPATCH({
      id: item.id + '/actualizar',
      body: item
    })
  }

  useEffect(() => {
    if (fetchMiActProfesionalPATCHData.status === 200) {
      fetchFacturasGET()
      // handleCloseModal_MiActProfesional()
    }
  }, [fetchMiActProfesionalPATCHData])


  // -------------- DELETE

  const [activeId, setactiveId] = useState('')

  const [fetchMiActProfesionalDELETE, fetchMiActProfesionalDELETEData] = misFacturasServices.DELETE()

  const handleSubmitDELETE = (item: any) => {

    setactiveId(item.id)

    fetchMiActProfesionalDELETE({
      id: item.id + '/eliminar',
      body: item
    })
  }

  useEffect(() => {
    if (fetchMiActProfesionalDELETEData.status === 200) {
      fetchFacturasGET()
      setactiveId('')
    }
  }, [fetchMiActProfesionalDELETEData])


  const [actividadesProfParams, setactividadesProfParams] = useState<any>({})


  // Funciones helpers

  const handleChangeCheck = (e: any, item: any, index: number) => {

    // let listaModulosTemp = cloneDeep(formPersonaEntidad[name])

    // if (e.target.checked) {

    //   listaModulosTemp.push(e.target.name)

    // } else {

    //   listaModulosTemp = listaModulosTemp.filter((item: any) => item !== e.target.name)
    // }

    // handleFormPersonaEntidad({
    //   ...formPersonaEntidad,
    //   [name]: listaModulosTemp
    // })

    // fetchFacturasGETData.data.items[index].publicaweb = e.target.checked

    handleSubmitEditar({
      ...item,
      publicaweb: e.target.checked
    })
  }


  const defaultValuesSearchAlways = {
    magic: '',
    estado: '',
    fechafacturadesde: '',
    fechafacturahasta: '',
  }

  const defaultValuesSearchSimple = {
    page: 1,
    limit: '',
    order: 'fechafactura desc',
  }

  const defaultValuesSearchAdvanced = {
    page: 1,
    limit: '',
    order: 'fechafactura desc',

    idpersona: '',
    identidad: '',
    idservicio: '',
    idserviciolinea: '',
    
    tipo: '',
  }

  const defaultValuesSearch = { ...defaultValuesSearchSimple, ...defaultValuesSearchAdvanced, ...defaultValuesSearchAlways }

  const params = queryString.parse(location.search);

  const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
    page: Number(params.page) || defaultValuesSearch.page,
    limit: params.limit || defaultValuesSearch.limit,
    magic: params.magic || defaultValuesSearch.magic,
    order: params.order || defaultValuesSearch.order,

    idpersona: params.idpersona || defaultValuesSearch.idpersona,
    identidad: params.identidad || defaultValuesSearch.identidad,
    idservicio: params.idservicio || defaultValuesSearch.idservicio,
    idserviciolinea: params.idserviciolinea || defaultValuesSearch.idserviciolinea,
    fechafacturadesde: params.fechafacturadesde || defaultValuesSearch.fechafacturadesde,
    fechafacturahasta: params.fechafacturahasta || defaultValuesSearch.fechafacturahasta,
    estado: params.estado || defaultValuesSearch.estado,
    tipo: params.tipo || defaultValuesSearch.tipo,

  });

  const handleSearch = (e: any, formValues: any) => {

    if (e) {
      e.preventDefault();
    }

    // Obtener todos los valores existentes en el form de values Search

    const urlParams: any = getValuesParams(formValues)

    setUrlByParams(urlParams, 'identidadpropia')

    if (formValues.page !== 1) {

      handleFormChange({
        ...formValues,
        page: 1
      });

    } else {

      let formValuesTemp = { ...formValues }

      Object.keys(formValuesTemp).forEach((k) => formValuesTemp[k] === '' && delete formValuesTemp[k]);

      fetchFacturasGET({
        params: {
          ...formValuesTemp
        }
      })
    }
  }

  useEffect(() => {

    // Obtener todos los valores existentes en el form de values Search
    const urlParams = getValuesParams(formValuesSearch)

    setUrlByParams(urlParams, 'identidadpropia')

    fetchFacturasGET({
      params: {
        ...urlParams
      }
    })


  }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit])


  const staticEstadoFactura: any = EstadoFacturaServices.GET()

  return (
    <div className='row my-3'>

      {/* <div className="col-12">
        <h5 className='h6 mb-3 mt-2 border-bottom pb-3'>{Lang('GUIA_DE_PROFESIONALES')}</h5>
      </div> */}

      <div className="col-12">
        <FilterAdvanced2
          handleSearch={handleSearch}
          formValuesSearch={formValuesSearch}
          handleInputChangeSearch={handleInputChangeSearch}
          fetchData={fetchFacturasGETData}
          resetformValuesSearch={resetformValuesSearch}

          defaultValuesSearchSimple={defaultValuesSearchSimple}
          defaultValuesSearchAdvanced={defaultValuesSearchAdvanced}
          defaultValuesSearchAlways={defaultValuesSearchAlways}
          handleFormChange={handleFormChange}
          // expand={true}
          simple={true}
        >

          <FilterAdvanced2.Head label={Lang('BUSCADOR')} >

            <>
              <Form.Group className="col-12 col-sm-6 col-md-7 col-lg-7 col-xl-7 col-xxl-5">
                <InputText
                  label={`${Lang('CADENA')} / ${Lang('NUM_FACTURA')}:`}
                  name='magic'
                  value={formValuesSearch.magic}
                  size='sm'
                  onChange={handleInputChangeSearch}
                />
              </Form.Group>

              <Form.Group className="mb-3 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
                <InputSelect
                  label={Lang('ESTADO') + ':'}
                  name='estado'
                  value={formValuesSearch.estado}
                  onChange={handleInputChangeSearch}
                  size='sm'
                  values={getSelectByKeys(staticEstadoFactura)}
                />
              </Form.Group>

              <Form.Group className="mb-3 col-12 col-sm-6 col-md-5 col-lg-3 col-xl-3 col-xxl-2">
                <InputText
                  label={Lang('FECHA_DESDE') + ':'}
                  type='date'
                  name='fechafacturadesde'
                  value={formValuesSearch.fechafacturadesde}
                  onChange={handleInputChangeSearch}
                  size='sm'
                />
              </Form.Group>

              <Form.Group className="mb-3 col-12 col-sm-6 col-md-5 col-lg-3 col-xl-3 col-xxl-2">
                <InputText
                  label={Lang('FECHA_HASTA') + ':'}
                  type='date'
                  name='fechafacturahasta'
                  value={formValuesSearch.fechafacturahasta}
                  onChange={handleInputChangeSearch}
                  size='sm'
                />
              </Form.Group>
              <div className="col flex-grow-0 text-nowrap mb-lg-3 mb-xxl-0">
                <div className="d-flex align-items-end justify-content-end h-100">
                  <ButtonSpinner
                    variant="outline-secondary" type="submit" className="me-3" size='sm'
                    handleSubmit={resetformValuesSearch} fetchDataLoading={[false]}
                    value={Lang('LIMPIAR')} icon={<FontAwesomeIcon icon={faBroom} />}
                  />
                  <ButtonSpinner
                    variant="secondary" type="submit" size='sm' className=""
                    handleSubmit={(e: any) => handleSearch(e, formValuesSearch)} fetchDataLoading={[fetchFacturasGETData.loading]}
                    value={Lang('BUSCAR')} icon={<FontAwesomeIcon icon={faSearch} className='me-1' />}
                  />
                </div>

              </div>
            </>
          </FilterAdvanced2.Head>

          <FilterAdvanced2.Body>

            {/* <FilterForm /> */}

          </FilterAdvanced2.Body>

        </FilterAdvanced2>

      </div>

      <div className="col-12">

        <div className="row">

          <div className="col-12 animate__animated animate__fadeInUp mt-3">
            <div className="">
              <TableFacturas
                data={fetchFacturasGETData.data?.items}
                loading={fetchFacturasGETData.loading}
                total={fetchFacturasGETData.data?.total}
                perPage={fetchFacturasGETData.data?.per_page}

                formValuesSearch={formValuesSearch}
                handleFormChange={handleFormChange}
                handleInputChangeSearch={handleInputChangeSearch}

                urlParams={getValuesParams(formValuesSearch)}
              />
            </div>
          </div>

          {/* <div className="col-12 text-center mt-3">
            <ButtonSpinner
              variant="secondary" type="submit" className=""
              // handleSubmit={handleSubmitEliminar} fetchDataLoading={[fetchRelacionDELETEData.loading]}
              value={Lang('GUARDAR_CAMBIOS')} icon={<FontAwesomeIcon icon={faSave} />}
            />
          </div> */}
        </div>
      </div>

    </div>
  )
}

export default Index;
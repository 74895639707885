import { faSave } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { useForm } from "../../../hooks/useForm"
import { useValidator } from "../../../hooks/useValidator"
import { useLang } from "../../../language"
import { PersonaAdapters } from "../../../modules/administracion/pages/Personas/adapters/personas.adapter"
import { PersonaServices } from "../../../modules/administracion/pages/Personas/services/personas.service"
import { ButtonSpinner } from "../../ButtonSpinner/ButtonSpinner"
import { InputText } from "../../Inputs"
import { ModalStatusServer } from "../../Modals/ModalStatusServer"


export const ModalCambioContrasenia = ({ showCambiarPassword, handleCloseCambiarPassword }: any) => {

    const Lang = useLang()

    // -------------------------- CambiarPassword

    const [fetchCambiarPasswordPATCH, fetchCambiarPasswordPATCHData] = PersonaServices.PATCH_CAMBIAR_CONTRASENIA()

    const [formCambiarPassword, handleInputCambiarPassword, handleFormCambiarPassword] = useForm({
        ...PersonaAdapters.PATCH_CAMBIAR_CONTRASENIA.send.schema({})
    })

    const handleSubmitCambiarPasswordPatch = () => {

        if (handleValidationCuenta(formCambiarPassword)) {

            fetchCambiarPasswordPATCH({
                body: formCambiarPassword
            })
        }
    }

    useEffect(() => {
        if (fetchCambiarPasswordPATCHData.status === 200) {
            handleCloseCambiarPassword()
        }
    }, [fetchCambiarPasswordPATCHData])

    const [valuesValidationCuenta, handleValidationCuenta, handleResetValidationCuenta] = useValidator({
        actual_password: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
        new_password: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
        new_password_confirmation: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
    })

    useEffect(() => {
        if (!showCambiarPassword.value) {
            handleFormCambiarPassword()
            handleResetValidationCuenta()
        }
    }, [showCambiarPassword.value])


    return (

        <>

<Modal show={showCambiarPassword.value} onHide={handleCloseCambiarPassword}>
            <Modal.Header closeButton>
                <Modal.Title className='h5'>
                    {Lang('CAMBIAR_CONTRASENIA')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row justify-content-center py-3">

                    <Form.Group className="mb-3 col-12 col-sm-8 col-md-8 col-lg-8">
                        <InputText
                            label={`${Lang('CONTRASENIA_ACTUAL')}:`}
                            type='password'
                            name='actual_password'
                            value={formCambiarPassword.actual_password}
                            onChange={handleInputCambiarPassword}
                            validation={valuesValidationCuenta}
                        // fetchData={nuevo ? fetchPersonaPOSTData : fetchPersonaDatosGeneralesPATCHData}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-12 col-sm-8 col-md-8 col-lg-8">
                        <InputText
                            label={`${Lang('NUEVA_CONTRASENIA')}:`}
                            type='password'
                            name='new_password'
                            value={formCambiarPassword.new_password}
                            onChange={handleInputCambiarPassword}
                            validation={valuesValidationCuenta}
                        // fetchData={nuevo ? fetchPersonaPOSTData : fetchPersonaDatosGeneralesPATCHData}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-12 col-sm-8 col-md-8 col-lg-8">
                        <InputText
                            label={`${Lang('CONFIRMAR_NUEVA_CONTRASENIA')}:`}
                            type='password'
                            name='new_password_confirmation'
                            value={formCambiarPassword.new_password_confirmation}
                            onChange={handleInputCambiarPassword}
                            validation={valuesValidationCuenta}
                        // fetchData={nuevo ? fetchPersonaPOSTData : fetchPersonaDatosGeneralesPATCHData}
                        />
                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={handleCloseCambiarPassword}>
                    {Lang('SALIR')}
                </Button>
                <ButtonSpinner
                    variant="secondary" type="submit" className="ms-auto"
                    handleSubmit={handleSubmitCambiarPasswordPatch} fetchDataLoading={[fetchCambiarPasswordPATCHData.loading]}
                    value={Lang('CAMBIAR')} icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                />
            </Modal.Footer>
        </Modal>

        <ModalStatusServer fetchData={fetchCambiarPasswordPATCHData} />
        
        </>
    )
}
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface LanguageState {
  identidadpropiacurrent: string
}

const initialState: LanguageState = {
  identidadpropiacurrent: localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE + '_IDENTIDADPROPIA_CURRENT') || '',
}

export const identidadpropiacurrentSlice = createSlice({
  name: 'identidadpropiacurrent',
  initialState,
  reducers: {
    changeCurrentIdEntidadPropia: (state, action: PayloadAction<string>) => {
      state.identidadpropiacurrent = action.payload
      localStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE + '_IDENTIDADPROPIA_CURRENT', action.payload)

    }
  },
})

// Action creators are generated for each case reducer function
export const { changeCurrentIdEntidadPropia } = identidadpropiacurrentSlice.actions

// export default counterSlice.reducer
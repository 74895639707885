import { useEffect } from "react"
import { useLang } from "../../../../../../../language";
import { useForm } from "../../../../../../../hooks/useForm";
import { getValuesParams } from "../../../../../../../helpers/helpers";
import FilterAdvanced from "../../../../../../../components/Filter/FilterAdvanced/FilterAdvanced";
import { InputText } from "../../../../../../../components/Inputs";
import { ButtonSpinner } from "../../../../../../../components/ButtonSpinner/ButtonSpinner";
import { FuncionesServices } from "./controllers/funciones.service";
import { TableFunciones } from "./TableFunciones";

export const Funciones = ({ label }: any) => {

  const Lang = useLang()

  const [fetchFuncionesGET, fetchFuncionesGETData] = FuncionesServices.GET()

  const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
    page: 1,
    limit: '',
    codbanco: '',
    banco: '',
    bic: '',
    magic: '',
    order: ''
  });

  const handleSearch: any = (e: any) => {

    if (e) {
      e.preventDefault();
    }

    // Obtener todos los valores existentes en el form de values Search
    const urlParams = getValuesParams(formValuesSearch)

    if (formValuesSearch.page !== 1) {

      handleFormChange({
        ...formValuesSearch,
        page: 1
      });

    } else {

      let formValuesSearchTemp = { ...formValuesSearch }

      Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

      fetchFuncionesGET({
        params: {
          ...urlParams
        }
      })
    }
  }

  useEffect(() => {

    // Obtener todos los valores existentes en el form de values Search
    const urlParams = getValuesParams(formValuesSearch)

    fetchFuncionesGET({
      params: {
        ...urlParams
      }
    })

  }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit])


  return (
    <div>
      {/* <FilterAdvanced
        handleSearch={handleSearch}
        formValuesSearch={formValuesSearch}
        handleInputChangeSearch={handleInputChangeSearch}
        fetchData={fetchFuncionesGETData}
        resetformValuesSearch={resetformValuesSearch}
        simple={true}
      >

        <FilterAdvanced.Head label={Lang('BUSCADOR')}>

        </FilterAdvanced.Head>

        <FilterAdvanced.Body>

          <>

            <Form.Group className="mb-3 col">
              <InputText
                label={`${Lang('TIPO_NOTICIA')}:`}
                name='magic'
                size='sm'
                value={formValuesSearch.magic}
                onChange={handleInputChangeSearch}
              />
            </Form.Group>

            <div className="col ms-auto flex-grow-0 text-nowrap mb-3">
              <div className="d-flex align-items-end justify-content-end h-100">
                <ButtonSpinner
                  variant="outline-secondary" type="submit" className="me-3" size='sm'
                  handleSubmit={resetformValuesSearch} fetchDataLoading={[false]}
                  value={Lang('LIMPIAR')} icon={<FontAwesomeIcon icon={faBroom} />}
                />
                <ButtonSpinner
                  variant="secondary" type="submit" size='sm' className=""
                  handleSubmit={handleSearch} fetchDataLoading={[fetchFuncionesGETData.loading]}
                  value={Lang('BUSCAR')} icon={<FontAwesomeIcon icon={faSearch} className='me-1' />}
                />
              </div>

            </div>

          </>

        </FilterAdvanced.Body>

      </FilterAdvanced> */}

      <div className="mt-0">
        <TableFunciones
          data={fetchFuncionesGETData.data?.items}
          loading={fetchFuncionesGETData.loading}
          total={fetchFuncionesGETData.data?.total}
          perPage={fetchFuncionesGETData.data?.per_page}
          formValuesSearch={formValuesSearch}
          handleFormChange={handleFormChange}
          fetchListado={fetchFuncionesGET}
          handleInputChangeSearch={handleInputChangeSearch}
        />
      </div>
    </div>
  )
}

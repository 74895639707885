import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useLang } from '../../../../../../../../language'
import { InputSelect } from '../../../../../../../../components/Inputs/InputSelect'
import { ModalStatusServer } from '../../../../../../../../components/Modals/ModalStatusServer'
import { useValidator } from '../../../../../../../../hooks/useValidator'
import { useForm } from '../../../../../../../../hooks/useForm'
import { ButtonSpinner } from '../../../../../../../../components/ButtonSpinner/ButtonSpinner'
import { OfertaServices } from '../../../controllers/ofertas.service'
import { isStatusSucess } from '../../../../../../../../helpers/helpers'

export const ModalEstudio = ({ data, handleClose, fetchPersonaDatosProfesionalesGET, urlParams,
    fetchCondicionPagosRemesasGETData, fetchMultipleParamBolsaData, fetchInscripcionGET, fetchEspecialidadesGETData }: any) => {

    // -------- variables 

    const Lang = useLang()

    const [valuesValidationEntidades, handleValidationEntidades, handleResetValidationEntidades] = useValidator({
        idtipoestudio: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
        // idtipoestudioespecialidad: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
    })

    // -------- Formulario principal

    const [
        formPersonaEntidad, handleInputPersonaEntidad, handleFormPersonaEntidad, resetFormPersonaEntidad
    ] = useForm({
        idtipoestudio: '',
        idtipoestudioespecialidad: '',
    })

    // -------------------- PATCH Persona Entidad

    const [fetchPersonaEntidadPATCH, fetchPersonaEntidadPATCHData] = OfertaServices.PATCH_TIPO_ESTUDIO(data?.idofertaempleo)

    const handleSubmitCrear = () => {

        if (handleValidationEntidades(formPersonaEntidad)) {

            const dataTemp = [...data.data, { ...formPersonaEntidad }]

            fetchPersonaEntidadPATCH({
                body: {
                    tiposestudios: dataTemp
                }
            })
        }
    }

    const handleSubmitEditar = () => {

        const dataTemp = [...data.data]

        dataTemp.splice(data?.index, 1)

        fetchPersonaEntidadPATCH({
            body: {
                tiposestudios: dataTemp
            }
        })
    }

    useEffect(() => {
        if (isStatusSucess(fetchPersonaEntidadPATCHData.status)) {
            fetchInscripcionGET()
            handleClose()
        }
    }, [fetchPersonaEntidadPATCHData])


    // Acciones al Mostrar / Ocultar la Modal

    useEffect(() => {

        if (data.value) {

            if (data.item?.idtipoestudio) {

                // fetchPersonaEntidadGET()
                handleFormPersonaEntidad({
                    ...data.item,
                })
            }

        } else {
            resetFormPersonaEntidad()
            handleResetValidationEntidades()
        }
    }, [data.value])

    const [listEspecialidades, setListEspecialidades] = useState([])

    useEffect(() => {

        if (formPersonaEntidad.idtipoestudio) {

            const res = fetchEspecialidadesGETData.data?.items?.filter((item: any) => item?.idtipoestudio === formPersonaEntidad.idtipoestudio)?.map((item2: any) => {
                return {
                    id: item2.id,
                    nombre: item2.especialidad
                }
            })

            setListEspecialidades(res || [])

        } else {

            setListEspecialidades([])

            handleFormPersonaEntidad({
                ...formPersonaEntidad,
                idtipoestudioespecialidad: ''
            })
        }

    }, [formPersonaEntidad.idtipoestudio])

    return (
        <>
            <Modal show={data.value} onHide={handleClose} size='lg' id='ModalRegistro'>
                <Modal.Header className='border-bottom-0 py-4'>
                    <Modal.Title className='h5'>
                        {data.action === 'EDIT' ? Lang('AGREGAR_NIVEL_IDIOMA') : Lang('EDITAR_NIVEL_IDIOMA')}
                    </Modal.Title>
                    <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleClose}></button>
                </Modal.Header>

                <Modal.Body className='px-4 py-0'>
                    <Form className='row'>

                        <Form.Group className="mb-3 col-12 col-sm-6 col-md-7 col-lg-5 col-xl-5 col-xxl-5">
                            <InputSelect
                                label={`${Lang('ESTUDIO')}:`}
                                name='idtipoestudio'
                                value={formPersonaEntidad.idtipoestudio}
                                values={fetchMultipleParamBolsaData?.data?.['tiposestudios'] || []}
                                onChange={handleInputPersonaEntidad}
                                // fetchData={data.action === 'CREATE' ? fetchPersonaEntidadPOSTData : fetchPersonaEntidadPATCHData}
                                validation={valuesValidationEntidades}
                                readonly={data.action === 'EDIT'}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3 col-12 col-sm-6 col-md-7 col-lg-5 col-xl-5 col-xxl-5">
                            <InputSelect
                                label={`${Lang('ESPECIALIDAD')}:`}
                                name='idtipoestudioespecialidad'
                                value={formPersonaEntidad.idtipoestudioespecialidad}
                                values={listEspecialidades || []}
                                onChange={handleInputPersonaEntidad}
                                // fetchData={data.action === 'CREATE' ? fetchPersonaEntidadPOSTData : fetchPersonaEntidadPATCHData}
                                validation={valuesValidationEntidades}
                                readonly={data.action === 'EDIT'}
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer className='border-0 d-flex justify-content-between'>
                    {
                        data.action === 'CREATE' ?
                            <>
                                <div></div>
                                <ButtonSpinner
                                    variant="secondary" type="submit" className="me-2"
                                    handleSubmit={handleSubmitCrear} fetchDataLoading={[fetchPersonaEntidadPATCHData.loading]}
                                    value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} />}
                                />
                            </>
                            :
                            <>
                                <ButtonSpinner
                                    variant="danger" type="submit" className="me-2"
                                    handleSubmit={handleSubmitEditar} fetchDataLoading={[fetchPersonaEntidadPATCHData.loading]}
                                    value={Lang('ELIMINAR')} icon={<FontAwesomeIcon icon={faTrash} />}
                                />
                                <div></div>
                            </>
                    }

                </Modal.Footer>
            </Modal>

            {/* <FilterEntidad
                showModal_FiltroEntidades={showModal_FiltroEntidades}
                handleClose_FiltroEntidades={handleClose_FiltroEntidades}
                setFilterEntidad={setFilterEntidad}
            />

            <ModalStatusServer fetchData={fetchPersonaEntidadPOSTData}
            />

            <ModalStatusServer fetchData={fetchPersonaEntidadDELETEData} /> */}

            <ModalStatusServer fetchData={fetchPersonaEntidadPATCHData} />

        </>
    )
}

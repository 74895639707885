import { faEdit, faPlus, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TableAdvanced from '../../../../../components/Tables/TableAdvanced/TableAdvanced'
import { getDataFile } from '../../../../../helpers/helpers'
import { useLang } from '../../../../../language'
import ButtonOrderBy from '../../../../../components/ButtonOrderBy/ButtonOrderBy'
import { TicketsClientServices } from './controllers/tickets.service'

export const TableTickets = ({ data, loading, total, perPage, handleFormChange, formValuesSearch,
    urlParams, fetchPersonasGET, handleInputChangeSearch }: any) => {

    const navigate = useNavigate();

    const Lang = useLang()

    const [excluidos, setExcluidos] = useState([])
    const [incluidos, setIncluidos] = useState([])

    const [checkAll, setCheckAll] = useState(false)

    const selectAllItems = () => {
        setCheckAll(true)
        setIncluidos([])
        setExcluidos([])
    }

    const unselectAllItems = () => {
        setCheckAll(false)
        setIncluidos([])
        setExcluidos([])
    }

    const allActions: any = [
        {
            label: <span className='me-2'><FontAwesomeIcon icon={faPlus} className='me-2' />{Lang('CREAR_TICKET')}</span>,
            variant: 'primary',
            action: () => navigate('../nuevo'),
        },
    ]

    const allActionsPerLine = [
        {
            label: <FontAwesomeIcon icon={faEdit} className='' />,
            // action: (id: string) => navigate(`/visados-admin/visados/${id}`),
            action: (id: string) => navigate(`./${id}`),
        },
    ]

    const handleChangeCheckAll = (e: any) => {

        if (e.target.checked) {

            selectAllItems()

        } else {

            unselectAllItems()
        }
    }

    const headersTable = [
        <small>{Lang('TICKET')}</small>,
        <small>{Lang('PRODUCTO')}</small>,
        <small>{Lang('ENTIDAD')}</small>,
        <small className='d-block text-center'>
            <ButtonOrderBy
                label={Lang('FECHA_REGISTRO')}
                id='fpublicacioninicio'
                className='text-center'
                handleInputChange={handleInputChangeSearch}
                formValuesSearch={formValuesSearch}
            />
        </small>,
        <small className='d-block text-center'>
            <ButtonOrderBy
                label={Lang('FECHA_MODIFICACION')}
                id='fpublicacioninicio'
                className='text-center'
                handleInputChange={handleInputChangeSearch}
                formValuesSearch={formValuesSearch}
            />
        </small>,
        <small className='d-block text-center'>{Lang('ESTADO')}</small>,
        <small className='d-block text-center'>{Lang('URGENCIA')}</small>,
        ''
    ]

    const dataLineTable = [
        {
            className: '',
            name: 'ticket'
        },
        {
            className: '',
            name: 'nombre_producto'
        },
        {
            name: 'nombre_entidad'
        },
        {
            className: 'text-nowrap text-center',
            name: 'label__fechaRegistro'
        },
        {
            className: 'text-nowrap text-center',
            name: 'label__fechaUltimaModificacion'
        },
        {
            name: 'nombre_estado'
        },
        {
            name: 'nombre_urgencia'
        },
    ]

    // Descargar Excel

    const [showConfirm_Excel, setshowConfirm_Excel] = useState(false)

    const [fetchVisadosGETExcel, fetchVisadosGETExcelData, resetfetchVisadosGETExcel] = TicketsClientServices.GET_EXCEL()

    useEffect(() => {

        if (fetchVisadosGETExcelData.status === 200) {
            getDataFile(fetchVisadosGETExcelData, resetfetchVisadosGETExcel, 'Visados', 'xlsx')
            setshowConfirm_Excel(false)
        }
    }, [fetchVisadosGETExcelData])

    const handleClickExcel = () => {

        setshowConfirm_Excel(true)

        let urlParamsTemp = cloneDeep(urlParams)
        delete urlParamsTemp['page']

        fetchVisadosGETExcel({
            params: {
                ...urlParamsTemp,
                to_excel: 1
            }
        })
    }

    return (
        <>

            <TableAdvanced >

                <TableAdvanced.Head
                    title='Tickets'
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    headers={headersTable}
                    dataLineTable={dataLineTable}
                    data={data}
                    isLoading={loading}

                    excluidos={excluidos}
                    incluidos={incluidos}
                    setExcluidos={setExcluidos}
                    setIncluidos={setIncluidos}

                    checkAll={checkAll}
                    allActionsPerLine={allActionsPerLine}
                    checkMultiples={false}
                />

                <TableAdvanced.Footer
                    total={total}
                    handleFormChange={handleFormChange}
                    formValuesSearch={formValuesSearch}
                    perPage={perPage}
                />

            </TableAdvanced>

        </>
    )
}

import { useAxios } from "../../../../../../hooks/useAxios"
import { PersonaColegiadoBTAdapters, PersonasColegiadoBTAdapters } from "./personacolegiadobt.adapter"

const PersonasColegiadoBTServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/admin/persona`, type: 'json', adapters: PersonasColegiadoBTAdapters.GET })
    },

    POST_AGREGAR_PERSONA: function () {

        return useAxios({ method: 'post', url: `/bolsadetrabajo/admin/persona`, type: 'json', adapters: PersonasColegiadoBTAdapters.POST })
    },

    GET_EXCEL: function () {

        return useAxios({ method: 'get', url: `/visados/admin/visados`, type: 'blob', adapters: PersonasColegiadoBTAdapters.GET_EXCEL })
    },

    GET_BETWEEN_DATES: function () {

        return useAxios({ method: 'get', url: `/inscripciones/eventos/fechas-con-eventos/listar`, type: 'json', adapters: PersonasColegiadoBTAdapters.GET_BETWEEN_DATES })
    },

    GET_CHECK_DISPONIBILIDAD: function (idevento: any) {

        return useAxios({ method: 'get', url: `/inscripciones/eventos/${idevento}/check-disponibilidad`, type: 'json', adapters: PersonasColegiadoBTAdapters.GET_CHECK_DISPONIBILIDAD })
    },
}

const PersonaColegiadoBTServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/perfilcolegiado/index`, type: 'json', adapters: PersonaColegiadoBTAdapters.GET })
    },

    PATCH: function () {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/perfilcolegiado/updatedatos`, type: 'json', adapters: PersonaColegiadoBTAdapters.PATCH })
    },

    PATCH_FUNCIONES: function () {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/perfilcolegiado/funciones`, type: 'json', adapters: PersonaColegiadoBTAdapters.PATCH_FUNCIONES })
    },

    PATCH_IDIOMAS: function () {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/perfilcolegiado/idiomas`, type: 'json', adapters: PersonaColegiadoBTAdapters.PATCH_IDIOMAS })
    },

    PATCH_ESTUDIOS: function () {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/perfilcolegiado/tiposestudios`, type: 'json', adapters: PersonaColegiadoBTAdapters.PATCH_ESTUDIOS })
    },
}

export {
    PersonasColegiadoBTServices,
    PersonaColegiadoBTServices
}
import { Form } from 'react-bootstrap'
import { InputText } from '../../../../../../components/Inputs'
import { InputSelect } from '../../../../../../components/Inputs/InputSelect'
import { getSelectByKeys } from '../../../../../../helpers/helpers'
import { useLang } from '../../../../../../language'
import { EstadosEventoServices } from '../../../../../../services/statics.service'

export const FilterFormHead = ({ formValuesSearch, handleInputChangeSearch, showFilter, fetchParamsMultipleData }: any) => {

    const Lang = useLang()

    return (
        <>
            <Form.Group className="col-12 col-sm-6 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                <InputText
                    label={Lang('NOMBRE') + ' / ' + Lang('APELLIDOS')  + ' / ' + Lang('EMAIL')  + ' / ' + Lang('NIF')  + ':'}
                    name='magic'
                    value={formValuesSearch.magic}
                    size='sm'
                    onChange={handleInputChangeSearch}
                />
            </Form.Group>
        </>
    )
}

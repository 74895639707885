import { Form } from 'react-bootstrap'
import { MsgInput } from '../MsgInput/MsgInput'
import { MsgInputServer } from '../MsgInput/MsgInputServer'
import { getMsgInputColor, getMsgInputServerColor } from '../../helpers/helpers';


interface IValidationValues {
    isOk: boolean
}

interface IfechData {
    data?: any,
    status?: any,
    error?: any,
    loading?: boolean
}


interface Ivalidation {
    [key: string]: IValidationValues
}

interface IInputSelect {
    label?: string,
    className?: string,
    [name: string]: any,
    value?: string,
    onChange?: ((e?: any) => void) | undefined,
    placeholder?: string,
    validation?: Ivalidation,
    fetchData?: IfechData,
    disabled?: boolean,
    style?: object,
    readonly?: boolean,
    adapter?: string,
    size?: string,
    withoutLine?: boolean
}


export const InputSelect = ({
    label = '',
    className = '',
    name = '',
    values = [],
    onChange = () => null,
    placeholder = '',
    validation = {},
    fetchData = {},
    disabled,
    value = '',
    readonly = false,
    withoutLine = false,
    size }: IInputSelect) => {

    const handleOnchange = (e: any) => {

        if (validation[name])
            validation[name].isOk = true

        if (fetchData.data)
            fetchData.data.status = 'Success'

        onChange(e)
    }

    const propsSize: any = {}

    if (size) {
        propsSize.size = size
    }

    return (
        <>
            {
                label && <Form.Label className='fw-500'>{label}</Form.Label>
            }
            {
                readonly ? <>
                    <p className="mt-0 mb-0">{values.find((item2: any) => item2.id === value)?.nombre}</p>
                </> : <>
                    <Form.Select
                        className={`${className} ${(validation ? getMsgInputColor(validation, name) : false) || getMsgInputServerColor(validation, name) ? 'is-invalid' : ''}`}
                        name={name}
                        onChange={handleOnchange}
                        placeholder={placeholder}
                        disabled={disabled}
                        value={value}
                        {...propsSize}
                        autoComplete='one-time-code'
                    >
                        {
                            !withoutLine && <option></option>
                        }

                        {
                            values.map((item: any) => {
                                return (
                                    <option key={`${label}-${item.id || item.nombre}`} value={item.id}>{item.nombre}</option>
                                )
                            })
                        }
                    </Form.Select>
                    {
                        validation && <MsgInput obj={validation} name={name} />
                    }
                    <MsgInputServer fetchData={fetchData} name={name} />
                </>
            }

        </>
    )
}

import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useLang } from '../../language';

import './Modal.scss'

export const ModalStatusServer = ({ fetchData, onlySuccess, onlyError, message2 = '' }: any) => {
    
    const [showStatus, setShowStatus] = useState(false);

    const [status, setStatus] = useState<any>({
        status: '',
        message: ''
    });

    const handleCloseStatus = () => {
        setShowStatus(false)
    };

    const handleShowStatus = () => setShowStatus(true);

    const Lang = useLang()

    useEffect(() => {

        if (onlyError) {
            
            if(fetchData.error && fetchData.error?.message !== 'form_error') {
                setStatus({
                    status: fetchData.error?.status,
                    message: <>
                        <p>{fetchData.error?.message}</p>
                        <p>{fetchData.error?.data?.errors}</p>
                    </>
                })
                handleShowStatus()
            }

        } else if (onlySuccess) {

            if(fetchData.data) {
                setStatus({
                    status: fetchData.data?.status,
                    message: fetchData.data?.message || Lang('PARRAFO_OPERACION_EXITO')
                })
                handleShowStatus()
            }

        } else {

            if(fetchData.error && fetchData.error?.message !== 'form_error') {
                setStatus({
                    status: fetchData.error?.status,
                    message: <>
                        <p>{fetchData.error?.message}</p>
                        <p>{fetchData.error?.data?.errors}</p>
                    </>
                })
                handleShowStatus()
            }
            if(fetchData.data) {
                setStatus({
                    status: fetchData.data?.status,
                    message: fetchData.data?.message || Lang('PARRAFO_OPERACION_EXITO')
                })
                handleShowStatus()
            }
        }

    }, [fetchData])

    return (
        <div>
            <Modal className='ModalStatusServer' show={showStatus} onHide={handleCloseStatus}>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'>{status.status}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    {status.message}
                    {message2}
                </Modal.Body>
                <Modal.Footer className='border-0 '>
                    <Button variant="primary" onClick={handleCloseStatus}>
                        {Lang('SALIR')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { Alert, Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullpage';
import 'tinymce/plugins/lists';

import 'tinymce/skins/ui/oxide/skin.min.css';
import { Editor } from '@tinymce/tinymce-react';
import { useLang } from '../../../language'
import { useValidator } from '../../../hooks/useValidator'
import { PlantillaServices, PlantillasServices } from '../../../modules/administracion/pages/Plantillas/services/plantillas.service'
import { ComunicacionesAdapters } from '../../../adapters/comunicaciones.adapter'
import { InputText } from '../../Inputs'
import { ButtonSpinner } from '../../ButtonSpinner/ButtonSpinner'
import { ModalStatusServer } from '../ModalStatusServer'
import { InputSelect } from '../../Inputs/InputSelect'
import { ClasesSeguroServices } from '../../../services/statics.service'
import { useForm } from '../../../hooks/useForm'
import { InputFile } from '../../Inputs/InputFile'
import { isStatusSucess, parseCharactersToHtml, toBase64 } from '../../../helpers/helpers'
import { cloneDeep } from 'lodash'
import { InputRadios } from '../../Inputs/InputRadios'
import { FilterNoticias } from '../ModalFilters/FilterNoticias'
import { LoaderFormModalMailContent, LoaderFormModalMailEnd, LoaderFormModalMailStart } from '../ModalEnvioEmailLoaders/ModalEnvioEmailLoaders'

export const ModalEnvioEmailMasivoOferta = ({ showModal_EnvioEmail, handleCloseModal_EnvioEmail,
    fetchPersonaSegurosGET, urlParams, idpersona, incluidos, excluidos, fetch, fetchData, onlyEmail }: any) => {

    // -------- variables 

    const Lang = useLang()

    // -------- Parametros

    const [valuesValidationSeguro, handleValidationSeguro, handleResetValidationSeguro] = useValidator({
        idtiponotificacion: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
        asunto: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
        mensaje: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
        tipo: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
    })

    const [fetchPlantillasGET, fetchPlantillasGETData] = PlantillasServices.GET()

    // -------- Formulario principal

    const [formEnvioEmail, handleInputEnvioEmail, handleFormEnvioEmail, resetFormEnvioEmail] = useForm({
        ...ComunicacionesAdapters.POST_ENVIAR_EMAILS_MASIVOS.send.schema({}),
        tipo: onlyEmail ? 'E' : 'B',
    })

    // ------------------- GET first time

    useEffect(() => {

        if (isStatusSucess(fetchPlantillasGETData.status)) {

            // Peticion por default

            const objResult = fetchPlantillasGETData.data?.items?.find((item: any) => item.codtiponotificacion === 'tnPlantillaOfertaEmpleoColegiado')

            handleInputEnvioEmail({
                target: {
                    name: 'idtiponotificacion',
                    value: objResult?.id,
                }
            })
        }

    }, [fetchPlantillasGETData])


    // -------------------- GET Persona Entidad

    const [fetchPlantillaGET, fetchPlantillaGETData] = PlantillaServices.GET()

    // -------------------- POST Persona Entidad

    // const [fetchEnviarEmailPOST, fetchEnviarEmailPOSTData] = ComunicacionesServices.POST_ENVIAR_EMAILS_MASIVOS()

    const handleSubmitfetchEnviarEmailPOST = async () => {

        if (handleValidationSeguro(formEnvioEmail)) {

            const urlParamsTemp = cloneDeep(urlParams)
            delete urlParamsTemp.page
            delete urlParamsTemp.idofertaempleo

            let body: any = {}

            body = {
                ...formEnvioEmail,
                comunicacion: formEnvioEmail.mensaje,
                filtros: {
                    ...urlParamsTemp
                },
                lista_ids: incluidos,
                lista_exclusion: excluidos,
            }

            if (inputFileFichero.current?.files[0]) {

                const filebase64 = await toBase64(inputFileFichero.current?.files[0])

                body.adjunto = {
                    base64: filebase64.split('base64,')?.[1],
                    nombre: inputFileFichero.current?.files[0]?.name || Lang('ADJUNTO')
                }
            }

            fetch({
                body
            })
        }
    }

    useEffect(() => {

        if (isStatusSucess(fetchData.status)) {

            handleCloseModal_EnvioEmail()
        }

    }, [fetchData])


    // Acciones al Mostrar / Ocultar la Modal

    useEffect(() => {

        handleFormEnvioEmail({
            ...formEnvioEmail,
            idpersona: showModal_EnvioEmail.payload?.idpersona
        })

        if (showModal_EnvioEmail.value) {

            document.getElementById('ModalEmail')?.parentElement?.removeAttribute('tabindex')

            fetchPlantillasGET({
                params: {
                    limit: 200
                }
            })

            setloaderAll(true)

        } else {

            resetFormEnvioEmail()
            handleResetValidationSeguro()
        }

    }, [showModal_EnvioEmail.value])


    const staticClasesSeguroServices: any = ClasesSeguroServices.GET()

    // const [fetchEspecialidadesGET, fetchEspecialidadesGETData] = EspecialidadesServices.GET()

    // onChange del idtiponotificacion

    useEffect(() => {

        if (formEnvioEmail.idtiponotificacion) {

            fetchPlantillaGET({
                id: formEnvioEmail.idtiponotificacion
            })
        }

    }, [formEnvioEmail.idtiponotificacion])

    useEffect(() => {

        if (fetchPlantillaGETData.data) {
            handleFormEnvioEmail({
                ...formEnvioEmail,
                ...fetchPlantillaGETData.data,
                idtiponotificacion: formEnvioEmail.idtiponotificacion
            })
        }

    }, [fetchPlantillaGETData])


    const handleEditorChange = (content: any) => {

        // console.log('Content was updated:', content);
        // setContentEditor(content);

        const target = {
            name: 'mensaje',
            value: content,
        }
        handleInputEnvioEmail({ target })
    }

    const inputFileFichero: any = useRef(null)

    // ---------- Filtro de personas

    const [showModal_FiltroPersonas, setShowModal_FiltroPersonas] = useState({
        value: false,
        payload: {}
    });

    const handleCloseModal_FiltroPersonas = () => setShowModal_FiltroPersonas({
        value: false,
        payload: {}
    });

    const handleShowModal_FiltroPersonas = (payload: any) => setShowModal_FiltroPersonas({
        value: true,
        payload
    });


    // useEffect(() => {
    //     if (!formCobro.idpersona) {
    //         setFilterPersona({
    //             id: '',
    //             nombre: ''
    //         })
    //     }
    // }, [formCobro.idpersona])


    const [contentClipboard, setContentClipboard] = useState('')



    useEffect(() => {

        // if (condition) {
        //     // const obj = {
        //     //     target: {
        //     //         name: 'idpersona',
        //     //         value: contentClipboard.id
        //     //     }
        //     // }

        //     // handleInputChangeCobro(obj)
        // }

    }, [contentClipboard])

    const editorRef: any = useRef(null);

    const handleClickPostPasteNoticias = () => {

        try {

            if (contentClipboard) {

                editorRef.current.execCommand('mceInsertContent', false, contentClipboard)

                setContentClipboard('')

                navigator.clipboard.writeText('')
            }

        } catch (error) {

            console.log(error);
        }
    }

    const [loaderAll, setloaderAll] = useState(false);

    useEffect(() => {

        if (fetchPlantillaGETData.loading === false && fetchPlantillasGETData.loading === false) {

            setloaderAll(false)
        }

    }, [fetchPlantillaGETData.loading, fetchPlantillasGETData.loading]);


    return (
        <>

            <Modal show={showModal_EnvioEmail.value} onHide={handleCloseModal_EnvioEmail} size='lg' className='modal-w-90' id='ModalEmail'>
                <Modal.Header className='py-2 my-1'>
                    <Modal.Title className='h5'>
                        {Lang('ENVIAR_EMAIL')}
                    </Modal.Title>
                    <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleCloseModal_EnvioEmail}></button>
                </Modal.Header>

                {
                    loaderAll ?
                        <LoaderFormModalMailContent />
                        :
                        <>
                            <Modal.Body className='px-4 pb-0 pt-3'>
                                <Form className='row'>

                                    <div className="col-lg-4">

                                        <div className="row">

                                            {
                                                !onlyEmail &&
                                                <Form.Group className="mb-2 col-12">
                                                    <Form.Label className='fw-500 d-inline-block me-3'>{Lang('TIPO')}:</Form.Label>
                                                    <InputRadios
                                                        className=''
                                                        name='tipo'
                                                        value={formEnvioEmail.tipo}
                                                        values={[
                                                            { id: 'B', nombre: Lang('BOLETIN') },
                                                            { id: 'E', nombre: Lang('EMAIL') },
                                                        ]}
                                                        validation={valuesValidationSeguro}
                                                        onChange={handleInputEnvioEmail}
                                                    />
                                                </Form.Group>
                                            }

                                            <Form.Group className="mb-3 col-12">
                                                <InputSelect
                                                    label={`${Lang('TIPO_PLANTILLA')}: *`}
                                                    name='idtiponotificacion'
                                                    value={formEnvioEmail.idtiponotificacion}
                                                    onChange={handleInputEnvioEmail}
                                                    values={fetchPlantillasGETData.data?.items?.map((item: any) => {
                                                        return {
                                                            id: item.id,
                                                            nombre: item.descripcion
                                                        }
                                                    }) || []}
                                                    validation={valuesValidationSeguro}
                                                // fetchData={showModal_EnvioEmail.payload?.action === 'EDIT' ? fetchPersonaEntidadPATCHData : fetchEnviarEmailPOSTData}
                                                // readonly={showModal_NuevaLinea.payload?.action === 'EDIT' && showModal_NuevaLinea.payload?.disabledOptions}
                                                />
                                            </Form.Group>

                                            {
                                                fetchPlantillaGETData.loading ? <LoaderFormModalMailStart /> :
                                                    <>
                                                        <Form.Group className="col-12 col-sm-6 col-lg-12 mb-3">
                                                            <InputText
                                                                label={Lang('REMITENTE_NOMBRE') + ':'}
                                                                name='remitentenombre'
                                                                value={formEnvioEmail.remitentenombre}
                                                                onChange={handleInputEnvioEmail}
                                                                validation={valuesValidationSeguro}
                                                            // fetchData={nuevo ? fetchPlantillaPOSTData : fetchPlantillaPATCHData}
                                                            />
                                                        </Form.Group>

                                                        <Form.Group className="col-12 col-sm-6 col-lg-12 mb-3">
                                                            <InputText
                                                                label={Lang('REMITENTE_EMAIL') + ':'}
                                                                name='remitenteemail'
                                                                value={formEnvioEmail.remitenteemail}
                                                                onChange={handleInputEnvioEmail}
                                                                validation={valuesValidationSeguro}
                                                            // fetchData={nuevo ? fetchPlantillaPOSTData : fetchPlantillaPATCHData}
                                                            />
                                                        </Form.Group>

                                                        <Form.Group className="col-12 col-sm-12 col-lg-12 mb-3">
                                                            <InputText
                                                                label={Lang('ASUNTO') + ':'}
                                                                name='asunto'
                                                                value={formEnvioEmail.asunto}
                                                                onChange={handleInputEnvioEmail}
                                                            // fetchData={nuevo ? fetchPlantillaPOSTData : fetchPlantillaPATCHData}
                                                            />
                                                        </Form.Group>
                                                    </>

                                            }

                                        </div>

                                    </div>

                                    <div className="col-lg-8">

                                        <div className="row">
                                            {
                                                fetchPlantillaGETData.loading ? <LoaderFormModalMailEnd /> :
                                                    <>

                                                        <div className="col-12"></div>

                                                        <Form.Group className="col-12">
                                                            <Form.Label className='fw-500 d-block mb-3'>
                                                                <div className="d-flex justify-content-between flex-wrap">
                                                                    <span>{Lang('MENSAJE')}</span>
                                                                    <span>
                                                                        {
                                                                            formEnvioEmail.tipo === 'B' &&
                                                                            <Button size='sm' variant='secondary' onClick={handleShowModal_FiltroPersonas}>
                                                                                {Lang('AGREGAR_NOTICIAS')}
                                                                            </Button>
                                                                        }
                                                                    </span>

                                                                </div>
                                                            </Form.Label>

                                                            {
                                                                contentClipboard &&
                                                                <Alert variant='warning' className='py-2'>
                                                                    {Lang('PARRAFO_CLIC_APAREZCAN_NOTICIAS')}
                                                                </Alert>
                                                            }

                                                            <Editor
                                                                onInit={(evt, editor) => editorRef.current = editor}
                                                                // initialValue={dataInicial}
                                                                init={{
                                                                    skin: false,
                                                                    branding: false,
                                                                    content_css: false,
                                                                    height: 400,
                                                                    menubar: false,
                                                                    relative_urls: false,
                                                                    remove_script_host: false,
                                                                    convert_urls: false,
                                                                    image_title: true,
                                                                    file_picker_callback: function (cb, value, meta) {
                                                                        var input = document.createElement('input');
                                                                        input.setAttribute('type', 'file');
                                                                        input.setAttribute('accept', 'image/*');

                                                                        input.onchange = function () {

                                                                            var thisTemp: any = this

                                                                            var file = thisTemp.files[0];

                                                                            var reader: any = new FileReader();
                                                                            reader.onload = function () {
                                                                                var id = 'blobid' + (new Date()).getTime();

                                                                                var blobCache = editorRef.current.editorUpload.blobCache;
                                                                                var base64 = reader.result.split(',')[1];
                                                                                var blobInfo = blobCache.create(id, file, base64);
                                                                                blobCache.add(blobInfo);

                                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                                            };
                                                                            reader.readAsDataURL(file);
                                                                        };

                                                                        input.click();
                                                                    },
                                                                    plugins: [
                                                                        'link image',
                                                                        'table paste',
                                                                        'code',
                                                                        'fullpage',
                                                                        'lists'
                                                                    ],
                                                                    toolbar:
                                                                        'undo redo | formatselect | bold italic forecolor backcolor | \
                                                                alignleft aligncenter alignright alignjustify | \
                                                                bullist numlist outdent indent | removeformat | help code | image',
                                                                    paste_word_valid_elements: 'a,table,tbody,tr,td,span,div,style',
                                                                    extended_valid_elements: 'style',
                                                                    custom_elements: 'style'
                                                                }}
                                                                value={formEnvioEmail.mensaje}
                                                                onEditorChange={handleEditorChange}
                                                                onClick={handleClickPostPasteNoticias}
                                                            />

                                                            <div className="d-flex justify-content-end mt-3 mb-3">
                                                                <ButtonSpinner
                                                                    variant="secondary" type="button" className=""
                                                                    handleSubmit={handleSubmitfetchEnviarEmailPOST} fetchDataLoading={[fetchData.loading]}
                                                                    value={Lang('ENVIAR')} icon={<FontAwesomeIcon icon={faPaperPlane} className='me-1' />}
                                                                />
                                                            </div>

                                                        </Form.Group>

                                                        {
                                                            formEnvioEmail?.variables?.length > 0 &&
                                                            <Form.Group className="col-12 mb-3" controlId="exampleForm.ControlInput7">
                                                                <Form.Label className='fw-500'>{Lang('CODIGOS_ADICIONALES')}</Form.Label>
                                                                <div className='d-flex flex-row flex-wrap'>
                                                                    {
                                                                        formEnvioEmail?.variables?.map((item: any) => {
                                                                            return (
                                                                                <OverlayTrigger
                                                                                    placement='top'
                                                                                    key={item.valor}
                                                                                    overlay={
                                                                                        <Tooltip id={`tooltip-top`}>
                                                                                            <small>{item.descripcion}</small>
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <div className="card px-2 py-1 m-1">
                                                                                        <small className="fw-bold">{parseCharactersToHtml(item.valor)}</small>
                                                                                    </div>
                                                                                </OverlayTrigger>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </Form.Group>
                                                        }

                                                    </>
                                            }
                                        </div>

                                    </div>



                                </Form>
                            </Modal.Body>
                        </>
                }

            </Modal>

            <FilterNoticias
                showModal_FiltroPersonas={showModal_FiltroPersonas}
                handleCloseModal_FiltroPersonas={handleCloseModal_FiltroPersonas}
                setContentClipboard={setContentClipboard}
                params={
                    {
                        order: 'fecha_registro desc'
                    }
                }
            />

            <ModalStatusServer fetchData={fetchData} />
        </>
    )
}

import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { BreadcrumbLine } from '../../../../../../components/BreadcrumbLine/BreadcrumbLine';
import { ListMenuRoutes } from '../../../../../../components/ListMenu/ListMenuRoutes';
import { useLang } from '../../../../../../language';
import { DatosGenerales } from './DatosGenerales';
import { Curriculums } from './Curriculums';
import { InformacionAdicional } from './InformacionAdicional';
import { PersonaBTServices } from '../controllers/personasbt.service';
import { useEffect } from 'react';

const Persona = () => {

  const { id = '' } = useParams()

  const Lang = useLang()

  const BreadcrumbLineItems = [
    {
      title: Lang('PERSONA'),
      link: '/persona'
    }
  ]

  const listMenu: any = {
    datosGenerales: {
      title: Lang('DATOS_GENERALES'),
      link: 'datos-generales'
    },
    informacion_adicional: {
      title: Lang('INFORMACION_ADICIONAL'),
      link: 'informacion-adicional'
    },
    curriculum: {
      title: Lang('CURRICULUM'),
      link: 'curriculum'
    },
  }

  const [fetchPersonaBTGET, fetchPersonaBTGETData] = PersonaBTServices.GET(id)


  useEffect(() => {

    fetchPersonaBTGET()

  }, []);

  return (

    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className='d-flex justify-content-between align-items-center mb-1 Nav-top-DAPcon'>
            <BreadcrumbLine BreadcrumbLineItems={BreadcrumbLineItems} />
          </div>
        </div>

        <div className="col-lg-3 mb-4">

          {/* <ListMenu values={listMenu} iDactive={iDactive} setiDactive={setiDactive} /> */}
          <ListMenuRoutes values={listMenu} />

        </div>

        <div className="col-lg-9 animate__animated animate__fadeInUp">
          {/* {listMenu[iDactive].component} */}

          <Routes>

            <Route
              path='datos-generales'
              element={
                <DatosGenerales
                  idpersona={id}
                  fetchPersonaBTGET={fetchPersonaBTGET}
                  fetchPersonaBTGETData={fetchPersonaBTGETData}
                />
              }
            />

            <Route
              path='informacion-adicional'
              element={
                <InformacionAdicional
                  idpersona={id}
                  fetchPersonaBTGET={fetchPersonaBTGET}
                  fetchPersonaBTGETData={fetchPersonaBTGETData}
                />
              }
            />

            <Route
              path='curriculum'
              element={
                <Curriculums
                  idpersona={id}
                  fetchPersonaBTGET={fetchPersonaBTGET}
                  fetchPersonaBTGETData={fetchPersonaBTGETData}
                />
              }
            />

            <Route
              path="*"
              element={<Navigate to='datos-generales' replace />}
            />
          </Routes>

        </div>
      </div>
    </div>
  )
}

export default Persona;
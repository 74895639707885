import { Placeholder } from "../../Placeholder/Placeholder"

export const LoaderFormModalMailContent = () => {
    return (
        <div className="p-3 mt-3">

            <div className='row'>

                <div className="col-lg-4">
                    <div className="row">
                        <LoaderFormModalMailStart />
                    </div>
                </div>

                <div className="col-lg-8">
                    <div className="row">
                        <LoaderFormModalMailEnd />
                    </div>
                </div>

            </div>
        </div>
    )
}

export const LoaderEnvioEmail = () => {
    return (
        <div className="p-3">

            <div className='row'>
                <div className="mb-3 col-12">
                    <Placeholder className='py-2' height='2rem' />
                </div>
                <div className="mb-3 col-12">
                    <Placeholder className='py-2' height='2rem' />
                </div>
                <div className="mb-3 col-12 col-sm-6">
                    <Placeholder className='py-2' height='2rem' />
                </div>
                <div className="mb-3 col-12 col-sm-6">
                    <Placeholder className='py-2' height='2rem' />
                </div>
                <div className="mb-3 col-12 col-sm-6">
                    <Placeholder className='py-2' height='2rem' />
                </div>
                <div className="mb-3 col-12 col-sm-6">
                    <Placeholder className='py-2' height='2rem' />
                </div>
                <div className="mb-3 col-12 col-sm-6">
                    <Placeholder className='py-2' height='2rem' />
                </div>
                <div className="mb-3 col-12 col-sm-6">
                    <Placeholder className='py-2' height='2rem' />
                </div>
                <div className="mb-3 col-12 col-sm-6">
                    <Placeholder className='py-2' height='2rem' />
                </div>
            </div>
        </div>
    )
}

export const LoaderFormModalMailStart = () => {
    return (
        <div className="col-12">

            <div className='row'>
                <div className="mb-3 col-12">
                    <Placeholder className='py-1' height='2rem' />
                </div>
                <div className="mb-3 col-12">
                    <Placeholder className='py-1 delay-1' height='2rem' />
                </div>
                <div className="mb-3 col-12">
                    <Placeholder className='py-1 delay-2' height='2rem' />
                </div>
                <div className="mb-3 col-12">
                    <Placeholder className='py-1 delay-3' height='2rem' />
                </div>
            </div>
        </div>
    )
}

export const LoaderFormModalMailEnd = () => {
    return (
        <div className="col-12">

            <div className='row'>
                <div className="mb-3 col-12">
                    <Placeholder className='py-1' height='2rem' />
                </div>
                <div className="mb-3 col-12">
                    <Placeholder className='py-1 delay-1' height='15rem' />
                </div>
                <div className="mb-3 col-6">
                    <Placeholder className='py-1 delay-2' height='2rem' />
                </div>
                <div className="mb-3 col-6">
                    <Placeholder className='py-1 delay-3' height='2rem' />
                </div>
            </div>
        </div>
    )
}
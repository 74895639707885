import { receiveFecha, receiveFechaFormat2 } from "../../../../../adapters/helpers"

const EntidadesAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}
                
                const data = {
                    id: item.identidad || '',
                    nombre: item.nombre || '',
                    sedes: item.sedes || [],
                    
                    cifnif: item.cifnif || '',
                    // tipo_entidad: item.tipo_entidad || '',
                    fechaalta: receiveFechaFormat2(receiveFecha(item.fechaalta)),
                    fechabaja: receiveFechaFormat2(receiveFecha(item.fechabaja)),
                    
                    estado: item.fechabaja ? 'Baixa' : 'Alta',
                    fechaestado: item.fechabaja ? receiveFechaFormat2(receiveFecha(item.fechabaja)) : receiveFechaFormat2(receiveFecha(item.fechaalta)),
                    tipoentidad: item.tipoentidad || '',
                    labeltipoentidad: item.tipoentidad?.tipoentidad || '',

                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },

    
    GET_EXCEL: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    headers: item.headers
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}
                

                const data = {
                    // filtros: item.filtros || '',
                    // lista_ids: item.lista_ids || '',
                    // lista_exclusion: item.lista_exclusion || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },
}


const EntidadDatosGeneralesAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.identidad || '',
                    idtipoentidad: item.idtipoentidad || '',
                    nombre: item.nombre || '',
                    nombrecomercial: item.nombrecomercial || '',
                    fechaconstitucion: receiveFecha(item.fechaconstitucion),
                    direccion: item.direccion || '',
                    municipio: item.municipio || '',
                    cp: item.cp || '',
                    tipodocumento: item.tipodocumento || '',
                    cifnif: item.cifnif || '',
                    web: item.web || '',
                    fechaalta: receiveFecha(item.fechaalta),
                    observaciones: item.observaciones || '',
                    idpais: item.idpais || '',
                    idprovincia: item.idprovincia || '',
                    fechabaja: receiveFecha(item.fechabaja),
                    idconvenio: item.idconvenio || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    // id: item.idpersona || '',
                    nombre: item.nombre || '',
                    nombrecomercial: item.nombrecomercial || '',
                    idtipoentidad: item.idtipoentidad || '',
                    fechaconstitucion: item.fechaconstitucion || '',
                    direccion: item.direccion || '',
                    municipio: item.municipio || '',
                    cp: item.cp || '',
                    cifnif: item.cifnif || '',
                    tipodocumento: item.tipodocumento || '',
                    idpais: item.idpais || '',
                    idprovincia: item.idprovincia || '',
                    observaciones: item.observaciones || '',
                    web: item.web || '',
                    idconvenio: item.idconvenio || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },
    
    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    // id: item.idpersona || '',
                    nombre: item.nombre || '',
                    nombrecomercial: item.nombrecomercial || '',
                    idtipoentidad: item.idtipoentidad || '',
                    fechaconstitucion: item.fechaconstitucion || '',
                    direccion: item.direccion || '',
                    municipio: item.municipio || '',
                    cp: item.cp || '',
                    cifnif: item.cifnif || '',
                    tipodocumento: item.tipodocumento || '',
                    idpais: item.idpais || '',
                    idprovincia: item.idprovincia || '',
                    observaciones: item.observaciones || '',
                    web: item.web || '',
                    idconvenio: item.idconvenio || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH_REACTIVAR: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    // id: item.idpersona || '',

                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },


    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },

    POST_SUBIR_IMAGEN: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    // id: item.idpersona || '',
                    imagen: item.imagen || '',
                    tipo: item.tipo || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items:any = this.schema(data)

                let formData = new FormData();

                for (const key in items) {
                    formData.append(key, items[key])
                }

                return formData
            }
        },
    },
    
    DELETE_IMAGEN: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },
}


const EntidadDatosColegiacionAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idpersona: item.idpersona || '',
                    tipoasociado: item.tipoasociado || '',
                    numeroasociado: item.numeroasociado || '',
                    colegio: item.colegio || '',
                    fechabaja: item.fechabaja?.split(' ')?.[0] || '',
                    fechaalta: item.fechaalta?.split(' ')?.[0] || '',
                    fechasolicitud: item.fechasolicitud?.split(' ')?.[0] || '',
                    motivobaja: item.motivobaja || '',
                    idasociado: item.idasociado || '',
                    observaciones: item.observaciones || '',
                    condicion_pago: item.condicion_pago || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                const items = dataTemp?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },

    PATCH_crear: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    tipoasociado: item.tipoasociado || '',
                    fechasolicitud: item.fechasolicitud || '',
                    fechaalta: item.fechaalta || '',
                    numeroasociado: item.numeroasociado || '',
                    idcondicionpago: item.idcondicionpago || '',
                    idtiposcuotas: item.idtiposcuotas || '',
                    observaciones: item.observaciones || '',
                    motivoexentocuota: item.motivoexentocuota || ''
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH_editar: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idasociado: item.idasociado || '',
                    tipoasociado: item.tipoasociado || '',
                    fechasolicitud: item.fechasolicitud || '',
                    fechaalta: item.fechaalta || '',
                    numeroasociado: item.numeroasociado || '',
                    idcondicionpago: item.idcondicionpago || '',
                    idtiposcuotas: item.idtiposcuotas || '',
                    observaciones: item.observaciones || '',
                    motivoexentocuota: item.motivoexentocuota || ''
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },
}



export {
    EntidadesAdapters,
    EntidadDatosGeneralesAdapters,
    EntidadDatosColegiacionAdapters
}
import React, { useEffect } from "react";
import { SeccionNivelesIdiomas } from "./SeccionNivelesIdiomas";

import { SeccionEstudios } from "./SeccionEstudios";
import { SeccionFunciones } from "./SeccionFunciones";
import { ParametrosServices } from "../../../../../../services/parametros.service";
import { LoaderTable } from "../../../../../../components/Loaders/LoaderTable";

export const InformacionAdicional = ({ fetchPersonaBTGETData, fetchPersonaBTGET }: any) => {

  // -------- Parametros

  const [fetchMultipleParamBolsa, fetchMultipleParamBolsaData] = ParametrosServices.GET_BOLSA_TRABAJO()

  useEffect(() => {

    fetchMultipleParamBolsa({
      params: {
        nivelidioma: true,
        funciones: true,
        idiomas: true,
        tiposestudios: true,
        tiposestudiosespecialidades: true,
        order: 'asc',
      }
    })

  }, [])

  return (
    <div className="card p-3">

      {
        (fetchPersonaBTGETData.loading || fetchMultipleParamBolsaData.loading) ?
          <div className="w-100">
            <LoaderTable />
          </div>
          :
          <>
            <div className="mt-4">

              <SeccionNivelesIdiomas
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                // idofertaempleo={idofertaempleo}
                fetchPersonaBTGET={fetchPersonaBTGET}
                fetchPersonaBTGETData={fetchPersonaBTGETData}
              />
            </div>
            <div className="mt-4">

              <SeccionEstudios
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                // idofertaempleo={idofertaempleo}
                fetchPersonaBTGET={fetchPersonaBTGET}
                fetchPersonaBTGETData={fetchPersonaBTGETData}
              />
            </div>

            <div className="mt-4">

              <SeccionFunciones
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                // idofertaempleo={idofertaempleo}
                fetchPersonaBTGET={fetchPersonaBTGET}
                fetchPersonaBTGETData={fetchPersonaBTGETData}
              />
            </div>
          </>
      }

    </div>
  );
};

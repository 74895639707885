
const AuthAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    // id: item.idbanco || '',
                    // codbanco: item.codbanco || '',
                    // banco: item.banco || '',
                    // bic: item.bic || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                return {
                    data: dataTemp,
                    status: data?.status
                }
            }
        },
    },

    POST_INICIAR_SESION: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    usuario: item.usuario || '',
                    password: item.password || '',
                    es_asociado: item.es_asociado || false,
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    POST_REINICIAR_CLAVE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    usuario: item.usuario || '',
                    es_asociado: item.es_asociado || false,
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },
}


export {
    AuthAdapters
}
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cloneDeep } from "lodash";
import { esCorreoValido } from "../../helpers/helpers";

export const InputChips = ({ data = [], setData, readonly = false }: any) => {

    const handleRemoveItem = (id: number) => {

        const dataTemp2 = cloneDeep(data)

        var index = dataTemp2.findIndex((item: any) => item.id === id)

        dataTemp2.splice(index, 1);

        setData(dataTemp2)
    }


    return (
        <div className="InputChips">
            <div className="form-control" style={{minHeight: '38px'}}>
                {
                    data?.map((item: any) => {
                        return (
                            <span 
                                style={{border: '1px solid #ddd'}} 
                                className={`badge bg-light text-dark m-1 d-inline-flex align-items-center text-wrap ${esCorreoValido(item.id) ? 'bg-light-red' : ''}`}
                                key={item.id} 
                                title={esCorreoValido(item.id) ? item.id : ''}
                            >
                                {item.name || item.id} <span className="cursor-pointer" onClick={() => handleRemoveItem(item.id)} >
                                    {
                                        !readonly && <FontAwesomeIcon icon={faTimes} style={{fontSize: '1rem'}} className="ms-1" />
                                    }
                                </span>
                            </span>
                        )
                    })
                }

            </div>

        </div>
    )
};

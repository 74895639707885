import { faCheckCircle, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Table } from 'react-bootstrap';
import { useLang } from '../../../../../../../language';
import { LoaderTrTable } from '../../../../../../../components/Loaders/LoaderTrTable';

export const TableCurriculums = ({ data, loading, total, perPage, handleFormChange, formValuesSearch, fetchCvsGET,
    fetchPersonaDatosProfesionalesGET, idpersona, urlParams, fetchCondicionPagosRemesasGETData, fetchMultipleParamBolsaData }: any) => {

    const Lang = useLang()

    return (
        <>
            <div className="d-flex flex-wrap justify-content-between align-items-start mb-3">
                <h5 className="mb-3 mb-lg-0 ">{Lang('CURRICULUMS')}</h5>
            </div>

            <Table responsive className='position-relative' striped bordered>
                <thead>
                    <tr>
                        <th>
                            <small>{Lang('NOMBRE')}</small>
                        </th>
                        <th>
                            <small>{Lang('PREFERIDO')}</small>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ?
                            <LoaderTrTable />
                            :
                            <>
                                {
                                    data?.map((item: any, index: number) => {

                                        return (
                                            <tr>
                                                <td valign='middle' className='text-nowrap'>
                                                    <small>{item.titulocv}</small>
                                                </td>

                                                <td valign='middle' className='text-center text-nowrap shrink-td'>
                                                    <small className='px-4'>{item.preferido?.length > 0 ? <FontAwesomeIcon icon={faCheckCircle} /> : '' }</small>
                                                </td>

                                                <td className='text-center text-nowrap shrink-td px-4' valign='middle'>
                                                    <Button variant='outline-primary' onClick={() => window.open(item.pathcv)} size='sm' className='text-nowrap' >
                                                        <FontAwesomeIcon icon={faFilePdf} />
                                                    </Button>

                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    data?.length === 0 &&
                                    <tr>
                                        <td colSpan={20} className='text-center py-5 bg-gray-1'>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</td>
                                    </tr>
                                }

                            </>

                    }

                </tbody>

            </Table>

        </>
    )
}

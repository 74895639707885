import { useAxios } from "../../../../../../hooks/useAxios"
import { PersonaBTAdapters, PersonasBTAdapters } from "./personasbt.adapter"

const PersonasBTServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/admin/persona`, type: 'json', adapters: PersonasBTAdapters.GET })
    },

    POST_AGREGAR_PERSONA: function () {

        return useAxios({ method: 'post', url: `/bolsadetrabajo/admin/persona`, type: 'json', adapters: PersonasBTAdapters.POST })
    },

    GET_EXCEL: function () {

        return useAxios({ method: 'get', url: `/visados/admin/visados`, type: 'blob', adapters: PersonasBTAdapters.GET_EXCEL })
    },

    GET_BETWEEN_DATES: function () {

        return useAxios({ method: 'get', url: `/inscripciones/eventos/fechas-con-eventos/listar`, type: 'json', adapters: PersonasBTAdapters.GET_BETWEEN_DATES })
    },

    GET_CHECK_DISPONIBILIDAD: function (idevento: any) {

        return useAxios({ method: 'get', url: `/inscripciones/eventos/${idevento}/check-disponibilidad`, type: 'json', adapters: PersonasBTAdapters.GET_CHECK_DISPONIBILIDAD })
    },
}

const PersonaBTServices = {

    GET: function (id: string) {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/admin/persona/${id}`, type: 'json', adapters: PersonaBTAdapters.GET })
    },

    GET_CVS: function () {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/curriculums`, type: 'json', adapters: PersonaBTAdapters.GET_CVS })
    },

    GET_ONE: function () {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/admin/persona`, type: 'json', adapters: PersonaBTAdapters.GET })
    },

    GET_FICHA: function () {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/fichacolegiado/ofertasempleo`, type: 'json', adapters: PersonaBTAdapters.GET_FICHA })
    },

    PATCH: function (id: string) {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/admin/persona/${id}`, type: 'json', adapters: PersonaBTAdapters.PATCH })
    },
}

export {
    PersonasBTServices,
    PersonaBTServices
}
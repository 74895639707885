import { receiveCheck, sendCheck, sendMultipart } from "../../../../../../adapters/helpers"

const CVsAdapters = {
    
    GET_ALL: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idcurriculum_persona || '',
                    idpersona: item.idpersona || '',
                    pathcv: item.pathcv || '',
                    preferido: receiveCheck(item.preferido, 'preferido'),
                    titulocv: item.titulocv || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idcurriculum_persona || '',
                    idpersona: item.idpersona || '',
                    pathcv: item.pathcv || '',
                    preferido: receiveCheck(item.preferido, 'preferido'),
                    titulocv: item.titulocv || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data                

                const items = dataTemp?.map((item: any) => (this.schema(item)))
                // const items = this.schema(dataTemp)

                return {
                    data: {
                        items
                    },
                    status: data?.status
                }
            }
        },
    },

}

const CVAdapters = {

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    titulocv: item.titulocv || '',
                    preferido: sendCheck(item.preferido),
                    archivo: item.archivo || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return sendMultipart(items)
            }
        },
    },

    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    preferido: sendCheck(item.preferido),
                    titulocv: item.titulocv || '',

                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },
}

export {
    CVsAdapters,
    CVAdapters
}

// Temporal, luego hay que pasarlo al modulo de formacion

import { receiveFecha } from "../../../../../adapters/helpers"

const EspecialidadesAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idtipoestudioespecialidad || '',
                    idtipoestudio: item.idtipoestudio || '',
                    especialidad: item.especialidad || '',
                    idperitacion: item.idperitacion || '',
                    codtipoestudioespecialidad: item.codtipoestudioespecialidad || '',
                    fechabaja: receiveFecha(item.fechabaja),
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },
}


const EspecialidadAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idtipoestudioespecialidad || '',
                    idtipoestudio: item.idtipoestudio || '',
                    especialidad: item.especialidad || '',
                    codtipoestudioespecialidad: item.codtipoestudioespecialidad || '',
                    idperitacion: item.idperitacion || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idtipoestudio: item.idtipoestudio || '',
                    especialidad: item.especialidad || '',
                    codtipoestudioespecialidad: item.codtipoestudioespecialidad || '',
                    idperitacion: item.idperitacion || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idtipoestudio: item.idtipoestudio || '',
                    especialidad: item.especialidad || '',
                    codtipoestudioespecialidad: item.codtipoestudioespecialidad || '',
                    idperitacion: item.idperitacion || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },
}


export {
    EspecialidadesAdapters,
    EspecialidadAdapters,
}
import { ListGroup } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import './ListMenu.scss'

export const ListMenuRoutes = ({ values, iDactive, setiDactive }: any) => {
    return (
        <ListGroup>
            {
                Object.keys(values).map((key: any) => {
                    return (
                        <NavLink
                            key={key}
                            to={values[key].link}
                            // onClick={() => setshowNavLeft(false)}
                            className={({ isActive }) =>
                                isActive ? 'list-group-item active' : 'list-group-item'
                            }
                        >
                            {/* <div style={{ 'width': '1.5rem' }} className="me-2">
                                {item.icon}
                            </div> */}
                            <span>{values[key].title}</span>
                        </NavLink>
                    )
                })
            }
        </ListGroup>
    )
}

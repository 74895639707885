import { Navigate, Route, Routes } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBox, faCog, faFile, faHome, faPlus } from '@fortawesome/free-solid-svg-icons';
import LayoutMain from '../../../../components/Layouts/LayoutMain1/LayoutMain1';
import Parametros from '../pages/Parametros/Index';
import { useLang } from '../../../../language';
import Inicio from '../pages/Inicio/Index';
import { LinkTitleTop } from '../../../../components/Layouts/LayoutMain1/LinkTitleTop/LinkTitleTop';
import Inscripciones from '../pages/Inscripciones/Index';
import Evento from '../pages/Inscripciones/Inscripcion/Index';
import NuevaOferta from '../pages/NuevaOferta/Index';
import Personas from '../pages/Personas/Index';
import Persona from '../pages/Personas/Persona';

export default function ModulesRouterBolsaTrabajo({ pathRoot }: any) {

    const Lang = useLang()

    const navLinks = [
        {
            icon: <FontAwesomeIcon icon={faPlus} />,
            title: Lang('NUEVO_OFERTA'),
            link: `${pathRoot}/nueva-oferta`
        },
        {
            icon: <FontAwesomeIcon icon={faBox} />,
            title: Lang('OFERTAS'),
            link: `${pathRoot}/ofertas`
        },
        {
            icon: <FontAwesomeIcon icon={faBox} />,
            title: Lang('PERSONAS'),
            link: `${pathRoot}/personas`
        },
        {
            icon: <FontAwesomeIcon icon={faCog} />,
            title: Lang('PARAMETROS'),
            link: `${pathRoot}/parametros`,
        },
    ]

    return (
        <section className='LayoutMain animate__animated animate__fadeIn'>

            <LayoutMain label={<LinkTitleTop icon={<FontAwesomeIcon icon={faBook} />} title={Lang('AREA_BOLSA_TRABAJO')} />}>

                <LayoutMain.NavLinks navLinks={navLinks} />

                <LayoutMain.Body>

                    <Routes>

                        <Route
                            path="nueva-oferta"
                            element={<NuevaOferta />}
                        />

                        <Route
                            path="ofertas"
                            element={<Inscripciones />}
                        />

                        <Route
                            path="ofertas/:idevento/*"
                            element={<Evento pathName='ofertas' />}
                        />

                        <Route
                            path="personas"
                            element={<Personas />}
                        />

                        <Route
                            path="personas/:id/*"
                            element={<Persona />}
                        />

                        <Route
                            path="Parametros/*"
                            element={<Parametros />}
                        />

                        <Route
                            path="*"
                            element={<Navigate to={`${pathRoot}/ofertas`} replace />}
                        />
                    </Routes>

                </LayoutMain.Body>

            </LayoutMain>

        </section>
    )
}

import { useAxios } from '../../../../../hooks/useAxios';
import { FacturaAdapters, FacturasAdapters } from '../adapters/facturas.adapter';

interface IParamsServices {
    id?: any
}

const FacturasServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/facturacion/facturas`, type: 'json', adapters: FacturasAdapters.GET })
    },

    GET_EXCEL: function () {

        return useAxios({ method: 'get', url: `/facturacion/facturas`, type: 'blob', adapters: FacturasAdapters.GET_EXCEL })
    },

    POST_EXPORTAR_ASIENTOS_CONTABLES: function () {

        return useAxios({ method: 'post', url: `/facturacion/facturas/exportar-asientos-contables`, type: 'blob', adapters: FacturasAdapters.POST_EXPORTAR_ASIENTOS_CONTABLES })
    },

    POST_PDFS: function () {

        return useAxios({ method: 'post', url: `/facturacion/facturas/pdf`, type: 'blob', adapters: FacturasAdapters.POST_PDFS })
    },

    POST_NOTIFICAR: function () {

        return useAxios({ method: 'post', url: `/facturacion/facturas/notificar`, type: 'json', adapters: FacturasAdapters.POST_NOTIFICAR })
    }
}

const FacturaServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/facturacion/facturas`, type: 'json', adapters: FacturaAdapters.GET })
    },

    POST: function () {

        return useAxios({ method: 'post', url: `/facturacion/facturas`, type: 'json', adapters: FacturaAdapters.POST })
    },

    PATCH: function (id: any) {

        return useAxios({ method: 'patch', url: `/facturacion/facturas/${id}`, type: 'json', adapters: FacturaAdapters.PATCH })
    },

    POST_ABONAR: function (id: any) {

        return useAxios({ method: 'post', url: `/facturacion/facturas/${id}/abonar`, type: 'json', adapters: FacturaAdapters.POST_ABONAR })
    },

    GET_PDF: function () {

        return useAxios({ method: 'get', url: `/facturacion/facturas`, type: 'blob', adapters: FacturaAdapters.GET_PDF })
    },

    DELETE: function (id: any) {

        return useAxios({ method: 'delete', url: `/facturacion/facturas/${id}`, type: 'json', adapters: FacturaAdapters.DELETE })
    },
}

export {
    FacturasServices,
    FacturaServices
}
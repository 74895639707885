import { TableEstudios } from './TableEstudios';

export const SeccionEstudios = ({ idofertaempleo, fetchCondicionPagosRemesasGETData, fetchInscripcionGETData = {},
    fetchMultipleParamBolsaData, fetchInscripcionGET, fetchEspecialidadesGETData }: any) => {
        
    return (
        <div>
            <TableEstudios
                data={fetchInscripcionGETData.data?.tipoestudiocompleto || []}
                loading={false}
                idofertaempleo={idofertaempleo}
                fetchInscripcionGETData={fetchInscripcionGETData}
                fetchCondicionPagosRemesasGETData={fetchCondicionPagosRemesasGETData}
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                fetchInscripcionGET={fetchInscripcionGET}
                fetchEspecialidadesGETData={fetchEspecialidadesGETData}
                logica_buscar_tipoestudio={fetchInscripcionGETData.data?.logica_buscar_tipoestudio}
                logica_buscar_tipoestudioespecialidad={fetchInscripcionGETData.data?.logica_buscar_tipoestudioespecialidad}
            />
        </div>
    )
}

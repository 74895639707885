import { useLang } from '../../../../../../../../language'
import { Table } from 'react-bootstrap'
import { convertParamToObject } from '../../../../../../../../services/helpers'

export const TableNivelesIdioma = ({ data, fetchPersonaBTGET,
    fetchPersonaDatosProfesionalesGET, urlParams, fetchCondicionPagosRemesasGETData, fetchMultipleParamBolsaData }: any) => {

    const Lang = useLang()

    return (
        <>
            <div className="d-flex flex-wrap justify-content-between align-items-start mb-3">
                <h5 className="mb-3 mb-lg-0 ">{Lang('NIVELES_IDIOMA')}</h5>
            </div>

            <Table responsive className='position-relative' striped bordered>
                <thead>
                    <tr>
                        <th>
                            <small>{Lang('IDIOMA')}</small>
                        </th>
                        <th>
                            <small>{Lang('NIVEL_IDIOMA')}</small>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        <>
                            {
                                data?.map((item: any, index: number) => {

                                    return (
                                        <tr>
                                            <td className='text-nowrap'>
                                                <small>{convertParamToObject(fetchMultipleParamBolsaData?.data?.['idiomas'])[item.ididioma]}</small>
                                            </td>

                                            <td>
                                                <small>{convertParamToObject(fetchMultipleParamBolsaData?.data?.['nivelidioma'])[item.idnivelidioma]}</small>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                data?.length === 0 &&
                                <tr>
                                    <td colSpan={20} className='text-center py-5 bg-gray-1'>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</td>
                                </tr>
                            }
                        </>
                    }

                </tbody>

            </Table>

        </>
    )
}

import { receiveCheck, receiveFecha, receiveFechaFormat2, sendCheck } from "../../../../../../../../adapters/helpers"

const PersonaEntidadesAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idpersonaentidad || '',
                    idpersona: item.idpersona || '',
                    identidad: item.identidad || '',
                    personasentidadesfechaalta: receiveFechaFormat2(receiveFecha(item.personasentidadesfechaalta)),
                    personasentidadesfechabaja: receiveFechaFormat2(receiveFecha(item.personasentidadesfechabaja)),
                    telefono1: item.telefono1 || '',
                    telefonomovil: item.telefonomovil || '',
                    fechabaja: receiveFechaFormat2(receiveFecha(item.fechabaja)),
                    visador: item.visador ? 'Si' : 'No',
                    email: item.email || '',
                    identidadsede: item.identidadsede || '',
                    nombresede: item.nombresede || '',
                    direccionsede: item.direccionsede || '',
                    municipiosede: item.municipiosede || '',
                    idpaissede: item.idpaissede || '',
                    cpsede: item.cpsede || '',
                    sedecentral: item.sedecentral || '',
                    observaciones: item.observaciones || '',
                    nombreentidad: item.nombreentidad || '',
                    nombrepersona: item.nombrepersona || '',
                    tipoentidad: item.tipoentidad || '',
                    idrelacion: item.idrelacion || '',
                    relacion: item.relacion || '',

                    persona: `${item.persona?.nombre || ''} ${item.persona?.apellido1 || ''} ${item.persona?.apellido2 || ''} ` || '',
                    persona_nif: item.persona?.nif || ''
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },

}

const PersonaEntidadAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idpersona: item.idpersona || '',
                    idrelacion: item.idrelacion || '',

                    identidad: item.identidad || '',
                    identidadsede: item.identidadsede || '',

                    nombreentidad: item.nombreentidad || '',
                    fechaalta: receiveFecha(item.personasentidadesfechaalta),
                    permisoedicion: receiveCheck(item.permisoedicion, 'permisoedicion'),
                    mostrarencatalogo: receiveCheck(item.mostrarencatalogo, 'mostrarencatalogo'),
                    telefono1: item.telefono1 || '',
                    extension1: item.extension1 || '',
                    telefono2: item.telefono2 || '',
                    extension2: item.extension2 || '',
                    telefonomovil: item.telefonomovil || '',
                    cargo: item.cargo || '',
                    email: item.email || '',
                    idcondicionpago: item.idcondicionpago || '',
                    observaciones: item.observaciones || '',
                    idpersonaentidad: item.idpersonaentidad || '',
                    visador: receiveCheck(item.visador, 'visador'),
                    porcentajeparticipacion: item.porcentajeparticipacion || ''
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp[0])

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idpersona: item.idpersona || '',
                    idrelacion: item.idrelacion || '',
                    identidad: item.identidad || '',
                    identidadsede: item.identidadsede || '',
                    nombreentidad: item.nombreentidad || '',
                    fechaalta: item.fechaalta || '',
                    permisoedicion: sendCheck(item.permisoedicion),
                    mostrarencatalogo: sendCheck(item.mostrarencatalogo),
                    telefono1: item.telefono1 || '',
                    extension1: item.extension1 || '',
                    telefono2: item.telefono2 || '',
                    extension2: item.extension2 || '',
                    telefonomovil: item.telefonomovil || '',
                    cargo: item.cargo || '',
                    email: item.email || '',
                    idcondicionpago: item.idcondicionpago || '',
                    observaciones: item.observaciones || '',
                    idpersonaentidad: item.idpersonaentidad || '',
                    visador: sendCheck(item.visador),
                    porcentajeparticipacion: item.porcentajeparticipacion || ''
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },
    
    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idpersona: item.idpersona || '',
                    idrelacion: item.idrelacion || '',
                    identidad: item.identidad || '',
                    identidadsede: item.identidadsede || '',
                    nombreentidad: item.nombreentidad || '',
                    // fechaalta: item.fechaalta || '', 
                    permisoedicion: sendCheck(item.permisoedicion),
                    mostrarencatalogo: sendCheck(item.mostrarencatalogo),
                    telefono1: item.telefono1 || '',
                    extension1: item.extension1 || '',
                    telefono2: item.telefono2 || '',
                    extension2: item.extension2 || '',
                    telefonomovil: item.telefonomovil || '',
                    cargo: item.cargo || '',
                    email: item.email || '',
                    idcondicionpago: item.idcondicionpago || '',
                    observaciones: item.observaciones || '',
                    idpersonaentidad: item.idpersonaentidad || '',
                    visador: sendCheck(item.visador),
                    porcentajeparticipacion: item.porcentajeparticipacion || ''
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },

}


export {
    PersonaEntidadesAdapters,
    PersonaEntidadAdapters
}
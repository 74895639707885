import { useAxios } from "../../../../../../../../hooks/useAxios"
import { HorarioAdapters, HorariosAdapters } from "./horarios.adapter"

const HorariosServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/admin/horario`, type: 'json', adapters: HorariosAdapters.GET })
    }
}

const HorarioServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/`, type: 'json', adapters: HorarioAdapters.GET })
    },

    POST: function () {

        return useAxios({ method: 'post', url: `/bolsadetrabajo/admin/horario`, type: 'json', adapters: HorarioAdapters.POST })
    },

    PATCH: function (id: string) {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/admin/horario/${id}`, type: 'json', adapters: HorarioAdapters.PATCH })
    },

    DELETE: function (id: string) {

        return useAxios({ method: 'delete', url: `/bolsadetrabajo/admin/horario/${id}`, type: 'json', adapters: HorarioAdapters.DELETE })
    }
}

export {
    HorariosServices,
    HorarioServices
}
import { faBook, faEdit, faExternalLink, faFileExcel, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { InputText } from '../../../../../../components/Inputs'
import { ModalConfirm } from '../../../../../../components/Modals/ModalConfirm'
import { ModalStatusServer } from '../../../../../../components/Modals/ModalStatusServer'
import TableAdvanced from '../../../../../../components/Tables/TableAdvanced/TableAdvanced'
import { getDataFile, isStatusSucess } from '../../../../../../helpers/helpers'
import { useForm } from '../../../../../../hooks/useForm'
import { useLang } from '../../../../../../language'
import { MisInscripcionesServices } from '../controllers/inscripciones.service'
import { ModalEditarInscripcion } from './ModalEditarInscripcion'
import { ModalFichaOferta } from './ModalFichaOferta'
import { useModalData } from '../../../../../../hooks/useModalData'

export const TableMisInscripciones = ({ data, loading, total, perPage, handleFormChange, formValuesSearch, urlParams,
    fetchMisInscripcionesGET, fetchMultipleParamBolsaData, idofertaempleo }: any) => {

    const navigate = useNavigate();

    const [dataOferta, handleShowOferta, handleCloseOferta] = useModalData()

    const Lang = useLang()

    const [excluidos, setExcluidos] = useState([])
    const [incluidos, setIncluidos] = useState([])

    const [checkAll, setCheckAll] = useState(false)

    const selectAllItems = () => {
        setCheckAll(true)
        setIncluidos([])
        setExcluidos([])
    }

    const unselectAllItems = () => {
        setCheckAll(false)
        setIncluidos([])
        setExcluidos([])
    }

    const allActions: any = [
    ]

    const allActionsPerLine = [
        {
            label: <FontAwesomeIcon icon={faBook} className='' />,
            variant: 'outline-primary',
            action: (form: any) => handleShowOferta({
                form,
                action: 'EDIT'
            }),
            withoutID: true
        },
        {
            label: <FontAwesomeIcon icon={faEdit} className='' />,
            action: (form: any) => handleShowModal_EditarInscripcion({
                form,
                action: 'EDIT'
            }),
            withoutID: true
        },
    ]

    const handleChangeCheckAll = (e: any) => {

        if (e.target.checked) {

            selectAllItems()

        } else {

            unselectAllItems()
        }
    }
    const headersTable = [
        <small>{Lang('OFERTA')}</small>,
        <small>{Lang('ENTIDAD')}</small>,
        <small>{Lang('HORARIO')}</small>,
        <small className='d-block text-center'>{Lang('FECHA_CREACION')}</small>,
        <small className='d-block text-center'>{Lang('ESTADO')}</small>,
        ''
    ]

    const dataLineTable = [
        {
            className: '',
            name: 'nombreoferta'
        },
        {
            name: 'label__entidad'
        },
        {
            className: '',
            name: 'label__horario'
        },
        {
            className: 'text-nowrap text-center',
            name: 'fpublicacioninicio'
        },
        {
            className: 'text-nowrap text-center',
            name: 'label__estadooferta'
        },
    ]

    // ---------- Cambiar condicion de pago

    const [showConfirm_DELETE, setshowConfirm_DELETE] = useState<any>({
        value: false,
        payload: {}
    })

    const [fetchInscripcionDELETE, fetchInscripcionDELETEData] = MisInscripcionesServices.DELETE()

    useEffect(() => {

        if (isStatusSucess(fetchInscripcionDELETEData.status)) {
            setshowConfirm_DELETE({
                value: false,
                payload: {}
            })
            fetchMisInscripcionesGET({
                params: urlParams
            })
        }
    }, [fetchInscripcionDELETEData])

    const [formAnular, handleInputChangeAnular, handleFormChangeAnular, resetformAnular] = useForm({
        observaciones: ''
    });

    const handleSubmitAnular = (id: any) => {

        fetchInscripcionDELETE({
            id: id + '/anular',
            body: formAnular
        })
    }

    // Crear editar inscripcion

    const [showModal_EditarInscripcion, setShowModal_EditarInscripcion] = useState({
        value: false,
        payload: {}
    });

    const handleCloseModal_EditarInscripcion = () => setShowModal_EditarInscripcion({
        value: false,
        payload: {}
    });

    const handleShowModal_EditarInscripcion = (payload: object) => setShowModal_EditarInscripcion({
        value: true,
        payload
    });

    return (
        <>
            <TableAdvanced>

                <TableAdvanced.Head
                    title={Lang('LISTA_DE_OFERTAS')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    headers={headersTable}
                    dataLineTable={dataLineTable}
                    data={data}
                    isLoading={loading}

                    excluidos={excluidos}
                    incluidos={incluidos}
                    setExcluidos={setExcluidos}
                    setIncluidos={setIncluidos}

                    checkAll={checkAll}
                    allActionsPerLine={allActionsPerLine}
                    checkMultiples={false}
                />

                <TableAdvanced.Footer
                    total={total}
                    handleFormChange={handleFormChange}
                    formValuesSearch={formValuesSearch}
                    perPage={perPage}
                />
            </TableAdvanced>

            <ModalConfirm
                handleClose={() => setshowConfirm_DELETE({
                    value: false,
                    payload: {}
                })}
                show={showConfirm_DELETE.value}
                onClickOK={() => handleSubmitAnular(showConfirm_DELETE.payload?.id)}
                messageHTML={
                    <>
                        <h5 className='text-center fw-normal mb-4'>{Lang('PARRAFO_SEGURO_ANULAR_INSCRIPCION')}</h5>
                        <div className="row justify-content-center">
                            <Form.Group className="mb-3 col-12">
                                <InputText
                                    label={`${Lang('OBSERVACIONES')}:`}
                                    name='observaciones'
                                    asParam='textarea'
                                    rows={3}
                                    value={formAnular.observaciones}
                                    onChange={handleInputChangeAnular}
                                />
                            </Form.Group>
                        </div>
                    </>
                }
                textOnClickOk={Lang('ANULAR')}
                variantClickOk='danger'
                fetchData={fetchInscripcionDELETEData}
            />

            <ModalEditarInscripcion
                data={showModal_EditarInscripcion}
                handleClose={handleCloseModal_EditarInscripcion}
                idofertaempleo={idofertaempleo}
                fetchMisInscripcionesGET={fetchMisInscripcionesGET}
                urlParams={urlParams}
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
            />

            <ModalFichaOferta 
                data={dataOferta}
                handleClose={handleCloseOferta}
            />

            <ModalStatusServer fetchData={fetchInscripcionDELETEData} />

        </>

    )
}

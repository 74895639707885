import { faSave, faTrash, faUser, faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { useLang } from '../../../../../../../language'
import { Alert, Badge, Form, InputGroup } from 'react-bootstrap'
import { ModalConfirm } from '../../../../../../../components/Modals/ModalConfirm'
import { useNavigate } from 'react-router-dom'
import { InputSelect } from '../../../../../../../components/Inputs/InputSelect'
import { InputText } from '../../../../../../../components/Inputs'
import { ButtonSpinner } from '../../../../../../../components/ButtonSpinner/ButtonSpinner'
import { ParametrosServices } from '../../../../../../../services/parametros.service'
import { EstadosEventoServices } from '../../../../../../../services/statics.service'
import { useForm } from '../../../../../../../hooks/useForm'
import { useValidator } from '../../../../../../../hooks/useValidator'
import { isStatusSucess } from '../../../../../../../helpers/helpers'
import { InputRadios } from '../../../../../../../components/Inputs/InputRadios'
import { LoaderFormInscripcion } from '../LoaderFormInscripcion'
import { ModalStatusServer } from '../../../../../../../components/Modals/ModalStatusServer'
import { FilterEntidad } from '../../../../../../../components/Modals/ModalFilters/FilterEntidad'
import { FilterPersona } from '../../../../../../../components/Modals/ModalFilters/FilterPersona'
import { FilterEntidadPersona } from '../../../../../../../components/Modals/ModalFilters/FilterEntidadPersona'
import { FilterPersonaEntidad } from '../../../../../../../components/Modals/ModalFilters/FilterPersonaEntidad'
import { OfertaServices } from '../../controllers/ofertas.service'
import { OfertaAdapters } from '../../controllers/ofertas.adapter'
import { MsgInput } from '../../../../../../../components/MsgInput/MsgInput'

import { Editor } from '@tinymce/tinymce-react';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/plugins/lists';

import 'tinymce/skins/ui/oxide/skin.min.css';
import { convertParamToObject } from '../../../../../../../services/helpers'
import { MsgInputServer } from '../../../../../../../components/MsgInput/MsgInputServer'
import { ListFiles } from '../../../../../../../components/ListFiles/ListFiles'
import { InputFile } from '../../../../../../../components/Inputs/InputFile'
import InputImage from '../../../../../../../components/Inputs/InputImage'
import { ModalCropImage } from '../../../../../../../components/Modals/ModalCropImage/ModalCropImage'

export const DatosGenerales = ({ formData = {}, idevento, fetchSuccess, fetchInscripcionGETData, fetchInscripcionGET }: any) => {

    const Lang = useLang()

    const [fetchMultipleParamBolsa, fetchMultipleParamBolsaData] = ParametrosServices.GET_BOLSA_TRABAJO()

    useEffect(() => {

        fetchMultipleParamBolsa({
            params: {
                funciones: true,
                tiposContratos: true,
                estadooferta: true,
                salarios: true,
                horarios: true,
                order: 'asc',
            }
        })

    }, [])

    const [formDatosGenerales, handleInputDatosGenerales, handleFormDatosGenerales, resetDatosGenerales] = useForm({
        ...OfertaAdapters.GET.receive.schema({}),
        idpais: process.env.REACT_APP_ID_PAIS_SPAIN,
    })

    const [valuesValidationEvento, handleValidationEvento, handleResetValidationEvento] = useValidator({
        idpersona: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
        nombreoferta: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
        identidad: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
    })

    useEffect(() => {

        if (idevento && isStatusSucess(fetchInscripcionGETData.status) && isStatusSucess(fetchMultipleParamBolsaData.status)) {

            handleFormDatosGenerales({
                ...fetchInscripcionGETData.data
            })

            handleFormAdjunto({
                tituloadjunto: fetchInscripcionGETData.data.tituloadjunto,
                idadjunto: fetchInscripcionGETData.data.idadjunto,
                pathadjunto: fetchInscripcionGETData.data.pathadjunto,
            })

            setFilterEntidad({
                id: fetchInscripcionGETData.data?.entidad?.identidad,
                nombre: fetchInscripcionGETData.data?.entidad?.nombre,
                sedes: []
            })

            setFilterPersona({
                id: fetchInscripcionGETData.data?.encargadooferta?.idpersona,
                nombre: fetchInscripcionGETData.data?.encargadooferta?.nombre_completo
            })
        }
    }, [fetchInscripcionGETData, fetchMultipleParamBolsaData])

    const navigate = useNavigate()

    // // --------------- POST Evento

    const [fetchInscripcionPOST, fetchInscripcionPOSTData] = OfertaServices.POST()

    const handleSubmitEventoPOST = () => {

        if (handleValidationEvento(formDatosGenerales)) {
            fetchInscripcionPOST({
                body: {
                    ...formDatosGenerales,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSucess(fetchInscripcionPOSTData.status)) {

            const idofertaempleo = fetchInscripcionPOSTData.data?.data?.idofertaempleo

            resetDatosGenerales()

            navigate(`/bolsa-trabajo-admin/ofertas/${idofertaempleo}`)
        }
    }, [fetchInscripcionPOSTData])


    // // --------------- PATCH Evento

    const [fetchInscripcionPATCH, fetchInscripcionPATCHData] = OfertaServices.PATCH(idevento)

    const handleSubmitEditar = () => {

        if (handleValidationEvento(formDatosGenerales)) {

            fetchInscripcionPATCH({
                body: {
                    ...formDatosGenerales,
                }
            })
        }

        // resetDatosGenerales()
    }

    useEffect(() => {

        if (isStatusSucess(fetchInscripcionPATCHData.status)) {

            resetDatosGenerales()

            fetchInscripcionGET()
        }
    }, [fetchInscripcionPATCHData])


    // ------------- DELETE Evento

    const [fetchInscripcionDELETE, fetchInscripcionDELETEData] = OfertaServices.DELETE(idevento)

    const [showConfirm_DELETE, setshowConfirm_DELETE] = useState(false)

    useEffect(() => {

        if (isStatusSucess(fetchInscripcionDELETEData.status)) {
            setshowConfirm_DELETE(false)
            fetchInscripcionGET()
        }
    }, [fetchInscripcionDELETEData])

    const handleLineaFacturaDELETE = () => {

        fetchInscripcionDELETE()
    }

    // ------------- Filtro Entidades

    const [showModal_FiltroEntidades, setShowModal_FiltroEntidades] = useState({
        value: false,
        payload: {}
    });

    const handleCloseModal_FiltroEntidades = () => setShowModal_FiltroEntidades({
        value: false,
        payload: {}
    });

    const handleShowModal_FiltroEntidades = (payload: any) => setShowModal_FiltroEntidades({
        value: true,
        payload
    });

    const [filterEntidad, setFilterEntidad] = useState({
        id: '',
        nombre: '',
        sedes: []
    })

    useEffect(() => {

        const obj = {
            target: {
                name: 'identidad',
                value: filterEntidad.id
            }
        }

        handleInputDatosGenerales(obj)

    }, [filterEntidad])

    useEffect(() => {
        if (!formDatosGenerales.identidad) {
            setFilterEntidad({
                id: '',
                nombre: '',
                sedes: []
            })
        }
    }, [formDatosGenerales.identidad])


    // Crear nueva colegiatura

    const [showModal_FiltroPersonas, setShowModal_FiltroPersonas] = useState({
        value: false,
        payload: {}
    });

    const handleCloseModal_FiltroPersonas = () => setShowModal_FiltroPersonas({
        value: false,
        payload: {}
    });

    const handleShowModal_FiltroPersonas = (payload: any) => setShowModal_FiltroPersonas({
        value: true,
        payload
    });

    const [filterPersona, setFilterPersona] = useState({
        id: '',
        nombre: ''
    })

    useEffect(() => {

        const obj = {
            target: {
                name: 'idpersona',
                value: filterPersona.id
            }
        }

        handleInputDatosGenerales(obj)

    }, [filterPersona])

    useEffect(() => {
        if (!formDatosGenerales.idpersona) {
            setFilterPersona({
                id: '',
                nombre: ''
            })
        }
    }, [formDatosGenerales.idpersona])


    const handleEditorChange = (content: any, name: string) => {

        const target = {
            name,
            value: content,
        }
        handleInputDatosGenerales({ target })
    }

    const editorRef: any = useRef(null);
    const editorRef2: any = useRef(null);

    const inputFileFichero: any = useRef(null)

    // ------------------------ Adjunto

    const [formAdjunto, handleInputAdjunto, handleFormAdjunto, resetAdjunto] = useForm({
        tituloadjunto: '',
        adjunto: '',
        pathadjunto: '',
        idadjunto: '',
    })

    const [fetchAdjuntoOfertaPOST, fetchAdjuntoOfertaPOSTData] = OfertaServices.POST_SUBIR_ADJUNTO(idevento)

    const handleSubmitAdjuntoPOST = () => {

        fetchAdjuntoOfertaPOST({
            body: {
                ...formAdjunto,
                adjunto: inputFileFichero.current?.files[0],
            }
        })
    }

    useEffect(() => {

        if (isStatusSucess(fetchAdjuntoOfertaPOSTData.status)) {

            fetchInscripcionGET()
        }

    }, [fetchAdjuntoOfertaPOSTData]);

    const [fetchAdjuntoOfertaDELETE, fetchAdjuntoOfertaDELETEData] = OfertaServices.DELETE_ADJUNTO()

    const handleDeleteAdjunto = () => {

        fetchAdjuntoOfertaDELETE({
            id: formAdjunto.idadjunto
        })
    }


    useEffect(() => {

        if (isStatusSucess(fetchAdjuntoOfertaDELETEData.status)) {

            fetchInscripcionGET()
        }

    }, [fetchAdjuntoOfertaDELETEData]);


    // Modal Rercortar imagen

    const [InputFileImage, setInputFileImage] = useState([])

    const [showModalCropImage, setShowModalCropImage] = useState({
        value: false,
        payload: null
    });

    const handleCloseModalCropImage = () => setShowModalCropImage({
        value: false,
        payload: null
    })

    const handleShowModalCropImage = (payload: any) => setShowModalCropImage({
        value: true,
        payload
    });

    const handleChangeInputImage = (e: any) => {
        if (e.target.files.length > 0) {
            handleShowModalCropImage(e.target.files[0])
        }
    }

    
    const [fetchOfertaImagenPATCH, fetchOfertaImagenPATCHData] = OfertaServices.POST_SUBIR_ADJUNTO(idevento)


    const handleSubirImagen = (tipo: any, file: any) => {

        fetchOfertaImagenPATCH({
            body: {
                tipo,
                adjunto: file
            }
        })
    }

    useEffect(() => {
        if (isStatusSucess(fetchOfertaImagenPATCHData.status)) {
            handleCloseModalCropImage()
            fetchInscripcionGET()

        }
    }, [fetchOfertaImagenPATCHData])

    // ----------- Eliminar oferta

    const [fetchOfertaImagenDELETE, fetchOfertaImagenDELETEData] = OfertaServices.DELETE_ADJUNTO()

    useEffect(() => {
        if (isStatusSucess(fetchOfertaImagenDELETEData.status)) {
            handleCloseModalCropImage()
            fetchInscripcionGET()
            setInputFileImage([])
            resetDatosGenerales()
        }
    }, [fetchOfertaImagenDELETEData])

    const handleEliminarImagen = (tipo: any) => {

        console.log('formDatosGenerales', formDatosGenerales);

        fetchOfertaImagenDELETE({
            id: formDatosGenerales.id_image
        })
    }

    return (
        <div>
            <div className='d-flex align-items-center justify-content-between'>
                <h5 className='mb-0'>{Lang('DATOS_GENERALES')}</h5>
                <div className='d-flex'>
                </div>
            </div>

            {
                idevento && (fetchInscripcionGETData.loading || fetchMultipleParamBolsaData.loading) ?
                    <div className='mt-3 '>
                        <LoaderFormInscripcion />
                    </div>
                    :

                    <div className={`${false ? 'card-danger' : ''} p-3 card mt-3 mb-4`}>
                        <div className="row">
                            {
                                idevento &&
                                <div className="col-12">
                                    <div className="d-flex flex-wrap justify-content-between align-items-center mb-3 pb-3 border-bottom ">
                                        <h5 className='h6 my-0'>{Lang('DATOS_GENERALES')} {formDatosGenerales.fechabaja && <Badge bg="danger" className='ms-2'>{Lang('BAJA')}</Badge>}</h5>
                                        <Alert variant='info' className='my-0 py-1'>
                                            {convertParamToObject(fetchMultipleParamBolsaData?.data?.['estadooferta'])?.[fetchInscripcionGETData?.data?.idestadooferta]}
                                        </Alert>
                                    </div>
                                </div>
                            }

                            <div className="col-12">

                                <div className="row">

                                    <Form.Group className="mb-3 col-12">
                                        <InputText
                                            label={Lang('NOMBRE_OFERTA') + ': *'}
                                            name='nombreoferta'
                                            value={formDatosGenerales.nombreoferta}
                                            onChange={handleInputDatosGenerales}
                                            validation={valuesValidationEvento}
                                            fetchData={idevento ? fetchInscripcionPATCHData : fetchInscripcionPOSTData}
                                        />
                                    </Form.Group>

                                    <div className="col-lg-8">

                                        <div className="row">
                                            <Form.Group className="mb-3 col-12 col-sm-6 col-md-6 col-lg-6">
                                                <Form.Label className='fw-500'>{Lang('ENTIDAD')}</Form.Label>
                                                <InputGroup>
                                                    <InputText
                                                        name='entidad'
                                                        value={filterEntidad.nombre}
                                                        onChange={() => setFilterEntidad({ id: '', nombre: '', sedes: [] })}
                                                    />
                                                    <InputGroup.Text id="basic-addon2" className='btn btn-secondary' onClick={handleShowModal_FiltroEntidades}>
                                                        <FontAwesomeIcon icon={faWarehouse} className='' />
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                {
                                                    // !valuesValidationEntidad.identidad.isOk && <Form.Text className='text-danger d-block'>{Lang('VALIDACION_ENTIDAD_OBLIGATORIA')}</Form.Text>
                                                }
                                            </Form.Group>

                                            <Form.Group className="mb-3 col-12 col-sm-6 col-md-6 col-lg-6">
                                                <Form.Label className='fw-500'>{Lang('PERSONA')}</Form.Label>
                                                <InputGroup>
                                                    <InputText
                                                        name='persona'
                                                        value={filterPersona.nombre}
                                                        onChange={() => setFilterPersona({ id: '', nombre: '' })}
                                                    />
                                                    <InputGroup.Text id="basic-addon2" className='btn btn-secondary' onClick={handleShowModal_FiltroPersonas}>
                                                        <FontAwesomeIcon icon={faUser} className='' />
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <MsgInputServer fetchData={fetchInscripcionPOSTData} name='idpersona' />
                                                <MsgInputServer fetchData={fetchInscripcionPATCHData} name='idpersona' />
                                            </Form.Group>

                                            {/* {
                                    !valuesValidationEvento.idpersona.isOk && <Form.Text className='text-danger d-block'>{Lang('VALIDACION_PERSONA_OBLIGATORIA')}</Form.Text>
                                } */}

                                            <Form.Group className="mb-3 col-12 col-sm-6 col-md-6">
                                                <Form.Label className='fw-500 d-block'>{Lang('MENCIONAR_NOMBRE_EMPRESA')}: *</Form.Label>
                                                <InputRadios
                                                    className=''
                                                    name='pub_nom_emp'
                                                    value={formDatosGenerales.pub_nom_emp}
                                                    values={[
                                                        { id: 'true', nombre: 'Si' },
                                                        { id: 'false', nombre: 'No' },
                                                    ]}
                                                    onChange={handleInputDatosGenerales}
                                                    fetchData={idevento ? fetchInscripcionPATCHData : fetchInscripcionPOSTData}
                                                    validation={valuesValidationEvento}
                                                />
                                            </Form.Group>


                                            <Form.Group className="mb-3 col-12 col-sm-6 col-md-6">
                                                <InputSelect
                                                    label={`${Lang('ESTADO_OFERTA')}:`}
                                                    name='idestadooferta'
                                                    value={formDatosGenerales.idestadooferta}
                                                    values={fetchMultipleParamBolsaData.data?.['estadooferta'] || []}
                                                    onChange={handleInputDatosGenerales}
                                                    fetchData={idevento ? fetchInscripcionPATCHData : fetchInscripcionPOSTData}
                                                    validation={valuesValidationEvento}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3 col-12 col-md-6">
                                                <InputText
                                                    label={Lang('FECHA_PUBLICACION_INICIO')}
                                                    type='date'
                                                    name='fpublicacioninicio'
                                                    value={formDatosGenerales.fpublicacioninicio}
                                                    onChange={handleInputDatosGenerales}
                                                    validation={valuesValidationEvento}
                                                    fetchData={idevento ? fetchInscripcionPATCHData : fetchInscripcionPOSTData}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3 col-12 col-md-6">
                                                <InputText
                                                    label={Lang('FECHA_PUBLICACION_FIN')}
                                                    type='date'
                                                    name='fpublicacionfin'
                                                    value={formDatosGenerales.fpublicacionfin}
                                                    onChange={handleInputDatosGenerales}
                                                    validation={valuesValidationEvento}
                                                    fetchData={idevento ? fetchInscripcionPATCHData : fetchInscripcionPOSTData}
                                                />
                                            </Form.Group>

                                        </div>
                                    </div>

                                    <div className="col-lg-4">

                                        <div className="row">
                                            <Form.Group className="mb-3 col-12">
                                                <Form.Label className='fw-500'>{Lang('IMAGEN_PORTADA')}:</Form.Label>
                                                {
                                                    !idevento ?
                                                        <Alert variant='danger' className='mb-0 p-3 text-center'>
                                                            <small>{Lang('ALERT_OPCION_DISPONIBLE_LUEGO_CREAR_OFERTA')}</small>
                                                        </Alert> : <InputImage
                                                            width='100%'
                                                            height='13rem'
                                                            onChange={handleChangeInputImage}
                                                            InputFile={InputFileImage}
                                                            setInputFile={setInputFileImage}
                                                            urlImage={formDatosGenerales.path_image}
                                                            handleEliminarImagen={() => handleEliminarImagen('fotografia')}
                                                        />
                                                }
                                            </Form.Group>
                                        </div>
                                    </div>

                                    <div className="col-12 py-3 py-lg-4">
                                        <div className="border-top"></div>
                                    </div>

                                    <Form.Group className="mb-3 col-12 col-sm-6 col-md-5 col-lg-4">
                                        <InputSelect
                                            label={`${Lang('TIPO_CONTRATO')}:`}
                                            name='idtipocontrato'
                                            value={formDatosGenerales.idtipocontrato}
                                            values={fetchMultipleParamBolsaData.data?.['tiposContratos'] || []}
                                            onChange={handleInputDatosGenerales}
                                            fetchData={idevento ? fetchInscripcionPATCHData : fetchInscripcionPOSTData}
                                            validation={valuesValidationEvento}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-12 col-md-5 col-lg-4">
                                        <InputText
                                            label={Lang('NUM_VACANTES')}
                                            type='number'
                                            name='numerovacantes'
                                            value={formDatosGenerales.numerovacantes}
                                            onChange={handleInputDatosGenerales}
                                            validation={valuesValidationEvento}
                                            fetchData={idevento ? fetchInscripcionPATCHData : fetchInscripcionPOSTData}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-12 col-sm-6 col-md-5 col-lg-4">
                                        <InputSelect
                                            label={`${Lang('SALARIO')}:`}
                                            name='idsalario'
                                            value={formDatosGenerales.idsalario}
                                            values={fetchMultipleParamBolsaData.data?.['salarios'] || []}
                                            onChange={handleInputDatosGenerales}
                                            fetchData={idevento ? fetchInscripcionPATCHData : fetchInscripcionPOSTData}

                                            validation={valuesValidationEvento}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-12 col-sm-6 col-md-5 col-lg-4">
                                        <InputSelect
                                            label={`${Lang('HORARIO')}:`}
                                            name='idhorario'
                                            value={formDatosGenerales.idhorario}
                                            values={fetchMultipleParamBolsaData.data?.['horarios'] || []}
                                            onChange={handleInputDatosGenerales}
                                            fetchData={idevento ? fetchInscripcionPATCHData : fetchInscripcionPOSTData}
                                            validation={valuesValidationEvento}
                                        />
                                    </Form.Group>

                                    <div className="col-12"></div>

                                    <Form.Group className="col-12 mb-4">
                                        <Form.Label className='fw-500'>{`${Lang('DESCRIPCION')}:`}</Form.Label>

                                        <Editor
                                            onInit={(evt, editor) => editorRef.current = editor}
                                            // initialValue={dataInicial}
                                            init={{
                                                skin: false,
                                                branding: false,
                                                content_css: false,
                                                height: 300,
                                                menubar: false,
                                                relative_urls: false,
                                                remove_script_host: false,
                                                convert_urls: false,
                                                image_title: true,
                                                file_picker_callback: function (cb, value, meta) {
                                                    var input = document.createElement('input');
                                                    input.setAttribute('type', 'file');
                                                    input.setAttribute('accept', 'image/*');

                                                    input.onchange = function () {

                                                        var thisTemp: any = this

                                                        var file = thisTemp.files[0];

                                                        var reader: any = new FileReader();
                                                        reader.onload = function () {
                                                            var id = 'blobid' + (new Date()).getTime();

                                                            var blobCache = editorRef.current.editorUpload.blobCache;
                                                            var base64 = reader.result.split(',')[1];
                                                            var blobInfo = blobCache.create(id, file, base64);
                                                            blobCache.add(blobInfo);

                                                            cb(blobInfo.blobUri(), { title: file.name });
                                                        };
                                                        reader.readAsDataURL(file);
                                                    };

                                                    input.click();
                                                },
                                                plugins: [
                                                    'link image',
                                                    'table paste',
                                                    'code',
                                                    'lists',
                                                ],
                                                toolbar:
                                                    'undo redo | formatselect | bold italic underline backcolor forecolor | \
                                                            alignleft aligncenter alignright alignjustify | \
                                                            bullist numlist outdent indent | removeformat | help code | image | table'
                                            }}
                                            value={formDatosGenerales.oferta}
                                            onEditorChange={(e) => handleEditorChange(e, 'oferta')}

                                        />
                                        <MsgInput obj={valuesValidationEvento} name='oferta' />

                                    </Form.Group>

                                    <Form.Group className="col-12 mb-4">
                                        <Form.Label className='fw-500'>{`${Lang('FUNCIONES')}:`}</Form.Label>

                                        <Editor
                                            // initialValue={dataInicial}
                                            onInit={(evt, editor) => editorRef2.current = editor}
                                            init={{
                                                skin: false,
                                                branding: false,
                                                content_css: false,
                                                height: 300,
                                                menubar: false,
                                                relative_urls: false,
                                                remove_script_host: false,
                                                convert_urls: false,
                                                image_title: true,
                                                file_picker_callback: function (cb, value, meta) {
                                                    var input = document.createElement('input');
                                                    input.setAttribute('type', 'file');
                                                    input.setAttribute('accept', 'image/*');

                                                    input.onchange = function () {

                                                        var thisTemp: any = this

                                                        var file = thisTemp.files[0];

                                                        var reader: any = new FileReader();
                                                        reader.onload = function () {
                                                            var id = 'blobid' + (new Date()).getTime();

                                                            var blobCache = editorRef2.current.editorUpload.blobCache;
                                                            var base64 = reader.result.split(',')[1];
                                                            var blobInfo = blobCache.create(id, file, base64);
                                                            blobCache.add(blobInfo);

                                                            cb(blobInfo.blobUri(), { title: file.name });
                                                        };
                                                        reader.readAsDataURL(file);
                                                    };

                                                    input.click();
                                                },
                                                plugins: [
                                                    'link image',
                                                    'table paste',
                                                    'code',
                                                    'lists',
                                                ],
                                                toolbar:
                                                    'undo redo | formatselect | bold italic underline backcolor forecolor | \
                                                            alignleft aligncenter alignright alignjustify | \
                                                            bullist numlist outdent indent | removeformat | help code | image | table'
                                            }}
                                            value={formDatosGenerales.funcionesoferta}
                                            onEditorChange={(e) => handleEditorChange(e, 'funcionesoferta')}

                                        />
                                        <MsgInput obj={valuesValidationEvento} name='funcionesoferta' />

                                    </Form.Group>

                                    {
                                        idevento ?
                                            <div className="mb-4 col-12">
                                                <div className='card p-2 p-lg-3'>
                                                    <div className="row">
                                                        {
                                                            formAdjunto.pathadjunto ?
                                                                <Form.Group className="col-12">
                                                                    <Form.Label className='fw-500'>{Lang('FICHERO_ADJUNTO')}:</Form.Label>
                                                                    <ListFiles
                                                                        isBlob={false}
                                                                        list={[
                                                                            {
                                                                                titulo: formAdjunto.tituloadjunto,
                                                                                url: formAdjunto.pathadjunto,
                                                                            }
                                                                        ]}
                                                                        onClickDelete={() => handleDeleteAdjunto()}
                                                                    />
                                                                </Form.Group>
                                                                :
                                                                <>
                                                                    <div className="mb-1 col-12">
                                                                        <div className="row">
                                                                            <Form.Group className="mb-3 col-12">
                                                                                <InputText
                                                                                    label={Lang('NOMBRE_ADJUNTO') + ': *'}
                                                                                    name='tituloadjunto'
                                                                                    value={formAdjunto.tituloadjunto}
                                                                                    onChange={handleInputAdjunto}
                                                                                // validation={valuesValidationEvento}
                                                                                // fetchData={idevento ? fetchInscripcionPATCHData : fetchInscripcionPOSTData}
                                                                                />
                                                                            </Form.Group>
                                                                            <Form.Group className="col">
                                                                                <Form.Label className='fw-500'>{Lang('FICHERO_ADJUNTO')}:</Form.Label>
                                                                                <InputFile
                                                                                    name='file'
                                                                                    onChange={handleInputAdjunto}
                                                                                    referencia={inputFileFichero}
                                                                                    acceptTypes='.pdf'
                                                                                />
                                                                            </Form.Group>
                                                                            <div className="col flex-grow-0 d-flex align-items-end">
                                                                                <ButtonSpinner
                                                                                    variant="outline-primary" type="button" className="text-nowrap"
                                                                                    handleSubmit={handleSubmitAdjuntoPOST} fetchDataLoading={[fetchAdjuntoOfertaPOSTData.loading]}
                                                                                    value={Lang('SUBIR')} icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <Form.Group className="mb-3 col-12">
                                                    <Form.Label className='fw-500'>{`${Lang('FICHERO_ADJUNTO')}:`}</Form.Label>
                                                    <Alert variant='danger' className='mb-0 p-3 text-center'>
                                                        <small>{Lang('ALERT_OPCION_DISPONIBLE_LUEGO_CREAR_OFERTA')}</small>
                                                    </Alert>
                                                </Form.Group>
                                            </>
                                    }

                                    <Form.Group className="col-12">
                                        <InputText
                                            label={`${Lang('OBSERVACIONES')} (ús intern):`}
                                            name='observaciones'
                                            asParam='textarea'
                                            rows={3}
                                            value={formDatosGenerales.observaciones}
                                            onChange={handleInputDatosGenerales}
                                            validation={valuesValidationEvento}
                                            fetchData={idevento ? fetchInscripcionPATCHData : fetchInscripcionPOSTData}
                                        />
                                    </Form.Group>

                                    <div className='col-12 mt-4 pt-3 d-flex justify-content-between border-top'>
                                        {
                                            idevento ?
                                                <>
                                                    <ButtonSpinner
                                                        variant="danger" type="button" className=""
                                                        handleSubmit={() => setshowConfirm_DELETE(true)} fetchDataLoading={[false]}
                                                        value={Lang('DAR_DE_BAJA')} icon={<FontAwesomeIcon icon={faTrash} className='me-1' />}
                                                    />
                                                    <ButtonSpinner
                                                        variant="secondary" type="button" className=""
                                                        handleSubmit={handleSubmitEditar} fetchDataLoading={[fetchInscripcionPATCHData.loading]}
                                                        value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                                    />
                                                </> :
                                                <>
                                                    <div></div>
                                                    <ButtonSpinner
                                                        variant="secondary" type="button" className=""
                                                        handleSubmit={handleSubmitEventoPOST} fetchDataLoading={[fetchInscripcionPOSTData.loading]}
                                                        value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                                    />
                                                </>
                                        }

                                    </div>

                                    <ModalConfirm
                                        handleClose={() => setshowConfirm_DELETE(false)}
                                        show={showConfirm_DELETE}
                                        onClickOK={handleLineaFacturaDELETE}
                                        messageHTML={
                                            <h5 className='text-center fw-normal'>{Lang('PARRAFO_SEGURO_ELIMINAR_EVENTO')}</h5>
                                        }
                                        textOnClickOk='Sí, eliminar'
                                        fetchData={fetchInscripcionDELETEData}
                                    />

                                </div>

                            </div>

                        </div>

                    </div>
            }

            <ModalStatusServer fetchData={fetchInscripcionPATCHData} />

            <ModalStatusServer fetchData={fetchInscripcionPOSTData} />

            {
                formDatosGenerales.identidad ?
                    <FilterEntidadPersona
                        showModal_Filtro={showModal_FiltroPersonas}
                        handleCloseModal_Filtro={handleCloseModal_FiltroPersonas}
                        setFilter={setFilterPersona}
                        params={
                            {
                                identidad: formDatosGenerales.identidad,
                                statuspersonaentidad: 'activos'
                            }
                        }
                    />
                    : <FilterPersona
                        showModal_FiltroPersonas={showModal_FiltroPersonas}
                        handleCloseModal_FiltroPersonas={handleCloseModal_FiltroPersonas}
                        setFilterPersona={setFilterPersona}
                        params={
                            {
                                statuspersona: 'vigente'
                            }
                        }
                    />
            }

            {
                formDatosGenerales.idpersona ?
                    <FilterPersonaEntidad
                        showModal_Filtro={showModal_FiltroEntidades}
                        handleCloseModal_Filtro={handleCloseModal_FiltroEntidades}
                        setFilter={setFilterEntidad}
                        params={
                            {
                                idpersona: formDatosGenerales.idpersona,
                                statuspersonaentidad: 'activos'
                            }
                        }
                    /> :
                    <FilterEntidad
                        showModal_FiltroEntidades={showModal_FiltroEntidades}
                        handleCloseModal_FiltroEntidades={handleCloseModal_FiltroEntidades}
                        setFilterEntidad={setFilterEntidad}
                        params={
                            {
                                status: 'activos'
                            }
                        }
                    />
            }

            <ModalStatusServer fetchData={fetchAdjuntoOfertaPOSTData} />

            <ModalStatusServer fetchData={fetchAdjuntoOfertaDELETEData} />

            
            <ModalStatusServer fetchData={fetchOfertaImagenPATCHData} />

            <ModalStatusServer fetchData={fetchOfertaImagenDELETEData} />

            <ModalCropImage
                show={showModalCropImage}
                handleClose={handleCloseModalCropImage}
                height='500px'
                setInputFile={setInputFileImage}
                handleSubirImagen={handleSubirImagen}
                tipo='fotografia'
                escalas={[
                    { x: 10, y: 10, label: 'Cuadrado' }
                ]}
            />

        </div>
    )
}

import { faBroom, faSearch, faUser, faWarehouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, InputGroup } from "react-bootstrap";
import { ButtonSpinner } from "../../../../../../components/ButtonSpinner/ButtonSpinner";
import { InputText } from "../../../../../../components/Inputs";
import { useLang } from "../../../../../../language";
import { FilterPersona } from "../../../../../../components/Modals/ModalFilters/FilterPersona";
import { FilterEntidad } from "../../../../../../components/Modals/ModalFilters/FilterEntidad";
import { useEffect, useState } from "react";
import { InputSelect } from "../../../../../../components/Inputs/InputSelect";

export const FilterForm = ({ handleSearch, formValuesSearch, handleInputChangeSearch, fetchData, resetformValuesSearch,
  defaultValuesSearchSimple, defaultValuesSearchAdvanced, handleFormChange, defaultValuesSearchAlways, formNamesFilters }: any) => {

  const Lang = useLang()

  // Crear nueva colegiatura

  const [showModal_FiltroPersonas, setShowModal_FiltroPersonas] = useState({
    value: false,
    payload: {}
  });

  const handleCloseModal_FiltroPersonas = () => setShowModal_FiltroPersonas({
    value: false,
    payload: {}
  });

  const handleShowModal_FiltroPersonas = (payload: any) => setShowModal_FiltroPersonas({
    value: true,
    payload
  });

  const [filterPersona, setFilterPersona] = useState({
    id: '',
    nombre: ''
  })

  useEffect(() => {

    const obj = {
      target: {
        name: 'idtecnicoasociado',
        value: filterPersona.id
      }
    }

    handleInputChangeSearch(obj)

  }, [filterPersona])

  useEffect(() => {
    if (!formValuesSearch.idtecnicoasociado) {
      setFilterPersona({
        id: '',
        nombre: ''
      })
    }
  }, [formValuesSearch.idtecnicoasociado])


  // ------------- Filtro Entidades

  const [showModal_FiltroEntidades, setShowModal_FiltroEntidades] = useState({
    value: false,
    payload: {}
  });

  const handleCloseModal_FiltroEntidades = () => setShowModal_FiltroEntidades({
    value: false,
    payload: {}
  });

  const handleShowModal_FiltroEntidades = (payload: any) => setShowModal_FiltroEntidades({
    value: true,
    payload
  });

  const [filterEntidad, setFilterEntidad] = useState({
    id: '',
    nombre: ''
  })

  useEffect(() => {

    const obj = {
      target: {
        name: 'identidad',
        value: filterEntidad.id
      }
    }

    handleInputChangeSearch(obj)

  }, [filterEntidad])

  useEffect(() => {
    if (!formValuesSearch.identidad) {
      setFilterEntidad({
        id: '',
        nombre: ''
      })
    }
  }, [formValuesSearch.identidad])


  const handleMiddleSearch = () => {

    const valuesFinal = { ...formValuesSearch, ...defaultValuesSearchSimple }
    handleFormChange(valuesFinal)
    handleSearch(null, valuesFinal)
  }

  const handleResetClear = () => {

    const valuesFinal = { ...defaultValuesSearchSimple, ...defaultValuesSearchAdvanced, ...defaultValuesSearchAlways }
    handleFormChange(valuesFinal)
  }

  return (
    <>
      <div className="border-bottom mb-3 mt-1"></div>
      

      <Form.Group className="mb-3 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
        <InputText
          label={`${Lang('FECHA_REGISTRO_DESDE')}:`}
          type='date'
          name='fechainicioinscripcion'
          value={formValuesSearch.fechainicioinscripcion}
          onChange={handleInputChangeSearch}
          size='sm'
        />
      </Form.Group>

      <Form.Group className="mb-3 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
        <InputText
          label={`${Lang('FECHA_REGISTRO_HASTA')}:`}
          type='date'
          name='fechafininscripcion'
          value={formValuesSearch.fechafininscripcion}
          onChange={handleInputChangeSearch}
          size='sm'
        />
      </Form.Group>

      <div className="ms-auto col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mb-3">
        <div className="d-flex align-items-end justify-content-end h-100">
          <ButtonSpinner
            variant="outline-secondary" type="submit" size='sm' className="me-2"
            handleSubmit={handleResetClear} fetchDataLoading={[false]}
            value={Lang('LIMPIAR')} icon={<FontAwesomeIcon icon={faBroom} />}
          />
          <ButtonSpinner
            variant="secondary" type="submit" size='sm' className=""
            handleSubmit={handleMiddleSearch} fetchDataLoading={[fetchData.loading]}
            value={Lang('BUSCAR')} icon={<FontAwesomeIcon icon={faSearch} className='me-1' />}
          />
        </div>

      </div>

      <FilterPersona
        showModal_FiltroPersonas={showModal_FiltroPersonas}
        handleCloseModal_FiltroPersonas={handleCloseModal_FiltroPersonas}
        setFilterPersona={setFilterPersona}
      />

      <FilterEntidad
        showModal_FiltroEntidades={showModal_FiltroEntidades}
        handleCloseModal_FiltroEntidades={handleCloseModal_FiltroEntidades}
        setFilterEntidad={setFilterEntidad}
      />


    </>
  )
}

import { useEffect, useRef, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useModalData } from '../../../../../../../hooks/useModalData'
import { useLang } from '../../../../../../../language'
import { ButtonSpinner } from '../../../../../../../components/ButtonSpinner/ButtonSpinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { InputText } from '../../../../../../../components/Inputs'
import { ModalStatusServer } from '../../../../../../../components/Modals/ModalStatusServer'
import { InscripcionesAdminServices } from '../controllers/services/inscripciones.service'
import { useForm } from '../../../../../../../hooks/useForm'
import { isStatusSucess } from '../../../../../../../helpers/helpers'
import { InputSelect } from '../../../../../../../components/Inputs/InputSelect'
import { InputChecks } from '../../../../../../../components/Inputs/InputChecks'
import { cloneDeep } from 'lodash'
import { InputFile } from '../../../../../../../components/Inputs/InputFile'
import { CVsAdminServices } from '../controllers/services/cvadmin.service'
import { LoaderFormParametro } from '../LoaderFormParametro'

export const ModalEditarInscripcion = ({ data, handleClose, idofertaempleo, fetchInscripcionesGET, urlParams,
    fetchMultipleParamBolsaData }: any) => {

    const Lang = useLang()
    
    const inputFileFichero: any = useRef(null)

    // Modal Crear visado

    const [dataFilterPersona, handleShowFilterPersona, handleCloseFilterPersona] = useModalData()

    const [formInscripcion, handleInputInscripcion, handleFormInscripcion, resetFormInscripcion] = useForm({
        idpersona: '',
        interes: [],
        notificarempresa: [],
        preferido: [],
    })

    const [filterPersona, setFilterPersona] = useState({
        id: '',
        nombre: ''
    })

    useEffect(() => {

        const obj = {
            target: {
                name: 'idpersona',
                value: filterPersona.id
            }
        }

        handleInputInscripcion(obj)

    }, [filterPersona])

    useEffect(() => {
        if (!formInscripcion.idpersona) {
            setFilterPersona({
                id: '',
                nombre: ''
            })
        }
    }, [formInscripcion.idpersona])


    // --------------- POST Agregar persona

    const [fetchAgregarPersonaPATCH, fetchAgregarPersonaPATCHData] = InscripcionesAdminServices.PATCH()

    const handleSubmitEditarPersonaPATCH = () => {

        fetchAgregarPersonaPATCH({
            id: idofertaempleo + '/update',
            body: {
                ...formInscripcion,
                archivo: inputFileFichero.current?.files?.[0],
            }
        })
    }

    useEffect(() => {

        if (isStatusSucess(fetchAgregarPersonaPATCHData.status)) {

            fetchInscripcionesGET({
                params: {
                    ...urlParams
                }
            })
            handleClose()
        }

    }, [fetchAgregarPersonaPATCHData]);


    // Funciones helpers

    const handleChangeCheck = (e: any, name: string) => {

        let listaModulosTemp = cloneDeep(formInscripcion[name])

        if (e.target.checked) {

            listaModulosTemp.push(e.target.name)

        } else {

            listaModulosTemp = listaModulosTemp.filter((item: any) => item !== e.target.name)
        }

        handleFormInscripcion({
            ...formInscripcion,
            [name]: listaModulosTemp
        })
    }
    
    // --------------- DELETE Agregar persona

    const [fetchAgregarPersonaDELETE, fetchAgregarPersonaDELETEData] = InscripcionesAdminServices.DELETE()

    const handleSubmitEditarPersonaDELETE = () => {

        fetchAgregarPersonaDELETE({
            id: idofertaempleo + '/delete',
            params: {
                idpersona: formInscripcion.idpersona
            }
        })
    }

    useEffect(() => {

        if (isStatusSucess(fetchAgregarPersonaDELETEData.status)) {

            fetchInscripcionesGET({
                params: {
                    ...urlParams
                }
            })
            handleClose()
        }

    }, [fetchAgregarPersonaDELETEData]);

    const [fetchCVsPersonaGET, fetchCVsPersonaGETData] = CVsAdminServices.GET_ALL()

    useEffect(() => {

        if (data.value) {

            handleFormInscripcion({
                ...data.payload?.form,
                idpersona: data.payload?.form?.id,
            })

            fetchCVsPersonaGET({
                params: {
                    idpersona: data.payload?.form?.id
                }
            })

        } else {

            resetFormInscripcion()
        }

    }, [data.value])

    return (
        <>
            <Modal show={data.value} onHide={handleClose} size='lg'>
                <Modal.Header className='border-bottom-0 py-4'>
                    <Modal.Title className='h5'>{Lang('EDITAR_INSCRIPCION')}</Modal.Title>
                    <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleClose}></button>
                </Modal.Header>
                {
                    fetchCVsPersonaGETData?.loading ?
                        <LoaderFormParametro />
                        :
                        <>

                            <Modal.Body className='px-4 py-0'>

                                <div className="row">

                                    <Form.Group className="mb-3 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4">
                                        <InputSelect
                                            label={Lang('ESTADO') + ':'}
                                            name='idestadopersona'
                                            value={formInscripcion.idestadopersona}
                                            onChange={handleInputInscripcion}
                                            values={fetchMultipleParamBolsaData.data?.['estadopersona'] || []}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mt-3 col flex-grow-0 text-nowrap me-2">
                                        <InputChecks
                                            name='notificarempresa'
                                            value={formInscripcion.notificarempresa}
                                            values={[
                                                {
                                                    id: 'notificarempresa',
                                                    nombre: Lang('VISIBLE_POR_EMPRESA')
                                                }
                                            ]}
                                            classNameContainer='d-block me-0 mb-1 mt-3 pt-1'
                                            onChange={(e: any) => handleChangeCheck(e, 'notificarempresa')}
                                        // fetchData={nuevo ? fetchPersonaPOSTData : fetchPerfilesPATCHData}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-12">
                                        <InputText
                                            label={`${Lang('OBSERVACIONES')} (ús intern):`}
                                            name='observacion_admin'
                                            asParam={'textarea'}
                                            rows={3}
                                            value={formInscripcion.observacion_admin}
                                            onChange={handleInputInscripcion}
                                        />
                                    </Form.Group>

                                </div>

                            </Modal.Body>
                            <Modal.Footer className='border-0 d-flex justify-content-between mt-2'>
                                <ButtonSpinner
                                    variant="danger" type="submit" className="me-2"
                                    handleSubmit={handleSubmitEditarPersonaDELETE} fetchDataLoading={[fetchAgregarPersonaDELETEData.loading]}
                                    value={Lang('ELIMINAR_INSCRIPCION')} icon={<FontAwesomeIcon icon={faTrash} />}
                                />
                                <ButtonSpinner
                                    variant="secondary" type="submit" className="me-2"
                                    handleSubmit={handleSubmitEditarPersonaPATCH} fetchDataLoading={[fetchAgregarPersonaPATCHData.loading]}
                                    value={Lang('AGREGAR')} icon={<FontAwesomeIcon icon={faSave} />}
                                />
                            </Modal.Footer>
                        </>

                }
            </Modal>

            <ModalStatusServer fetchData={fetchAgregarPersonaPATCHData} />
            
            <ModalStatusServer fetchData={fetchAgregarPersonaDELETEData} />
        </>
    )
}

import { useEffect, useState } from 'react'
import { Form, InputGroup, Modal } from 'react-bootstrap'
import { useModalData } from '../../../../../../../hooks/useModalData'
import { useLang } from '../../../../../../../language'
import { ButtonSpinner } from '../../../../../../../components/ButtonSpinner/ButtonSpinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faUser } from '@fortawesome/free-solid-svg-icons'
import { FilterPersona } from '../../../../../../../components/Modals/ModalFilters/FilterPersona'
import { InputText } from '../../../../../../../components/Inputs'
import { ModalStatusServer } from '../../../../../../../components/Modals/ModalStatusServer'
import { InscripcionesAdminServices } from '../controllers/services/inscripciones.service'
import { useForm } from '../../../../../../../hooks/useForm'
import { isStatusSucess } from '../../../../../../../helpers/helpers'
import { MsgInputServer } from '../../../../../../../components/MsgInput/MsgInputServer'

export const ModalAgregarInscripcion = ({ data, handleClose, idofertaempleo, fetchInscripcionesGET, urlParams }: any) => {

    const Lang = useLang()

    // Modal Crear visado

    const [dataFilterPersona, handleShowFilterPersona, handleCloseFilterPersona] = useModalData()

    const [formPersona, handleInputPersona, handleFormPersona, resetFormPersona] = useForm({
        idpersona: ''
    })

    const [filterPersona, setFilterPersona] = useState({
        id: '',
        nombre: ''
    })

    useEffect(() => {

        const obj = {
            target: {
                name: 'idpersona',
                value: filterPersona.id
            }
        }

        handleInputPersona(obj)

    }, [filterPersona])

    useEffect(() => {
        if (!formPersona.idpersona) {
            setFilterPersona({
                id: '',
                nombre: ''
            })
        }
    }, [formPersona.idpersona])


    // POST Agregar persona

    const [fetchAgregarPersonaPOST, fetchAgregarPersonaPOSTData] = InscripcionesAdminServices.POST()

    const handleSubmitAgregarPersonaPOST = () => {

        fetchAgregarPersonaPOST({
            id: idofertaempleo,
            body: {
                datos: [
                    formPersona
                ]
            }
        })
    }

    useEffect(() => {

        if (isStatusSucess(fetchAgregarPersonaPOSTData.status)) {

            fetchInscripcionesGET({
                params: {
                    ...urlParams
                }
            })
            handleClose()
        }

    }, [fetchAgregarPersonaPOSTData]);


    useEffect(() => {

        if (data.value) {


        } else {

            resetFormPersona()
        }

    }, [data.value])

    return (
        <>
            <Modal show={data.value} onHide={handleClose} id='ModalRegistro'>
                <Modal.Header className='border-bottom-0 py-4'>
                    <Modal.Title className='h6'>{Lang('AGREGAR_PERSONA')}</Modal.Title>
                    <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleClose}></button>
                </Modal.Header>
                <Modal.Body className='px-4 py-0'>

                    <Form.Group className="mb-3 col-12 col-sm-12 col-md-12 col-lg-12">
                        <Form.Label className='fw-500'>{Lang('PERSONA')}</Form.Label>
                        <InputGroup>
                            <InputText
                                name='persona'
                                value={filterPersona.nombre}
                                onChange={() => setFilterPersona({ id: '', nombre: '' })}
                            // validation={valuesValidationWizard1}
                            />
                            <InputGroup.Text id="basic-addon2" className='btn btn-secondary' onClick={() => handleShowFilterPersona()}>
                                <FontAwesomeIcon icon={faUser} className='' />
                            </InputGroup.Text>
                        </InputGroup>
                        <MsgInputServer fetchData={fetchAgregarPersonaPOSTData} name='datos.0.idpersona' />
                        {/* {
                    !valuesValidationWizard1.idpersona.isOk && <Form.Text className='text-danger d-block'>{Lang('VALIDACION_PERSONA_OBLIGATORIA')}</Form.Text>
                } */}

                    </Form.Group>

                </Modal.Body>
                <Modal.Footer className='border-0 d-flex justify-content-between'>
                    <div></div>
                    <ButtonSpinner
                        variant="secondary" type="submit" className="me-2"
                        handleSubmit={handleSubmitAgregarPersonaPOST} fetchDataLoading={[fetchAgregarPersonaPOSTData.loading]}
                        value={Lang('AGREGAR')} icon={<FontAwesomeIcon icon={faSave} />}
                    />
                </Modal.Footer>
            </Modal>

            <FilterPersona
                showModal_FiltroPersonas={dataFilterPersona}
                handleCloseModal_FiltroPersonas={handleCloseFilterPersona}
                setFilterPersona={setFilterPersona}
                params={
                    {
                        tipopersona: 'colegiado',
                        statuspersona: 'vigente'
                    }
                }
            />

            <ModalStatusServer fetchData={fetchAgregarPersonaPOSTData} />
        </>
    )
}

export function convertParamToObject(list: Array<any>) {
    
    const obj: any = {}

    for (let i = 0; i < list?.length; i++) {
        obj[list[i]['id']] = list[i]['nombre']
    }

    return obj
}

export function convertStaticServiceToArray(staticServices: any) {

    return Object.keys(staticServices).map((key: any) => {
        return {
            id: key,
            nombre: staticServices[key]
        }
    })
}


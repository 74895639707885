import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react'
import { Button, Form, Table } from 'react-bootstrap';
import { ButtonSpinner } from '../../../../../components/ButtonSpinner/ButtonSpinner';
import { OverlayLoader } from '../../../../../components/OverlayLoader/OverlayLoader';
import { Placeholder } from '../../../../../components/Placeholder/Placeholder';
import { useForm } from '../../../../../hooks/useForm';
import { useLang } from '../../../../../language';
import { convertParamToObject } from '../../../../../services/helpers';
import { ParametrosServices } from '../../../../../services/parametros.service';
import { PersonaDatosGeneralesAdapters } from '../../../../administracion/pages/Personas/adapters/personas.adapter';
import { misInscripcionesServices } from '../../controllers/services/inscripciones.service';
import { ModalMiActProfesional } from './ModalMiActProfesional';
import { TableMisInscripciones } from './TableMisInscripciones';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { getURLQuery, getValuesParams } from '../../../../../helpers/helpers';

interface IState {
  data: any,
  loading: any,
  error: any,
  status: any
}

const Index = () => {

  const [fetchMisInscripcionesGET, fetchMisInscripcionesGETData, resetFetchMisInscripcionesGET ] = misInscripcionesServices.GET()

  // useEffect(() => {

  //   fetchMisInscripcionesGET()

  // }, [])

  const location = useLocation()

  const {
    page: pageParam = 1,
    order: orderParam = 'fecha desc',
    limit: limitParam = '',
    magic: magicParam = '',

  } = queryString.parse(location.search);

  const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
    page: pageParam,
    order: orderParam,
    limit: limitParam,
    magic: magicParam,

  });

  const handleSearch = (e: any) => {

    if (e) {
      e.preventDefault();
    }

    // Obtener todos los valores existentes en el form de values Search
    const urlParams = getValuesParams(formValuesSearch)



    // Convertir esos valores del objeto a url amigable
    const query = getURLQuery(urlParams)

    // history.push(`?${query}`);
    // navigate(`?${query}`)
    window.history.replaceState(null, '', `?${query}`);

    if (formValuesSearch.page !== 1) {

      handleFormChange({
        ...formValuesSearch,
        page: 1
      });

    } else {

      let formValuesSearchTemp = { ...formValuesSearch }

      Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

      fetchMisInscripcionesGET({
        params: {
          ...urlParams
        }
      })
    }
  }

  useEffect(() => {

    // Obtener todos los valores existentes en el form de values Search
    const urlParams = getValuesParams(formValuesSearch)

    // Convertir esos valores del objeto a url amigable
    const query = getURLQuery(urlParams)

    // navigate(`?${query}`)
    window.history.replaceState(null, '', `?${query}`);

    fetchMisInscripcionesGET({
      params: {
        ...urlParams
      }
    })


  }, [formValuesSearch.page, formValuesSearch.order_by])



  // Parametros

  const [fetchActProfesionalesParam, fetchActProfesionalesParamData] = ParametrosServices.GET()

  useEffect(() => {

    fetchActProfesionalesParam({
      params: {
        actividadesprofesionales: true
      }
    })

  }, [])

  useEffect(() => {
    if (fetchActProfesionalesParamData.status === 200) {

      setactividadesProfParams(convertParamToObject(fetchActProfesionalesParamData.data))
    }
  }, [fetchActProfesionalesParamData])


  const Lang = useLang()

  const [formPersonaDatosGenerales, handleInputPersonaDatosGenerales, handleFormPersonaDatosGenerales
  ] = useForm({
    ...PersonaDatosGeneralesAdapters.GET.receive.schema({}),
  })


  // -------------------- PATCH Persona Entidad

  const [fetchMiActProfesionalPATCH, fetchMiActProfesionalPATCHData] = misInscripcionesServices.PATCH()

  const handleSubmitEditar = (item: any) => {

    fetchMiActProfesionalPATCH({
      id: item.id + '/actualizar',
      body: item
    })
  }

  useEffect(() => {
    if (fetchMiActProfesionalPATCHData.status === 200) {
      fetchMisInscripcionesGET()
      // handleCloseModal_MiActProfesional()
    }
  }, [fetchMiActProfesionalPATCHData])


  // -------------- DELETE

  // const [activeId, setactiveId] = useState('')

  // const [fetchMiActProfesionalDELETE, fetchMiActProfesionalDELETEData] = misInscripcionesServices.DELETE()

  // const handleSubmitDELETE = (item: any) => {

  //   setactiveId(item.id)

  //   fetchMiActProfesionalDELETE({
  //     id: item.id + '/eliminar',
  //     body: item
  //   })
  // }

  // useEffect(() => {
  //   if (fetchMiActProfesionalDELETEData.status === 200) {
  //     fetchMisInscripcionesGET()
  //     setactiveId('')
  //   }
  // }, [fetchMiActProfesionalDELETEData])


  const [actividadesProfParams, setactividadesProfParams] = useState<any>({})


  // Crear nueva colegiatura

  const [showModal_MiActProfesional, setShowModal_MiActProfesional] = useState({
    value: false,
    payload: {}
  });

  const handleCloseModal_MiActProfesional = () => setShowModal_MiActProfesional({
    value: false,
    payload: {}
  });

  const handleShowModal_MiActProfesional = (payload: object) => setShowModal_MiActProfesional({
    value: true,
    payload
  });


  // Funciones helpers

  const handleChangeCheck = (e: any, item: any, index: number) => {

    // let listaModulosTemp = cloneDeep(formPersonaEntidad[name])

    // if (e.target.checked) {

    //   listaModulosTemp.push(e.target.name)

    // } else {

    //   listaModulosTemp = listaModulosTemp.filter((item: any) => item !== e.target.name)
    // }

    // handleFormPersonaEntidad({
    //   ...formPersonaEntidad,
    //   [name]: listaModulosTemp
    // })

    // fetchMisInscripcionesGETData.data.items[index].publicaweb = e.target.checked

    handleSubmitEditar({
      ...item,
      publicaweb: e.target.checked
    })
  }

  return (
    <div className='row my-3'>

      {/* <div className="col-12">
        <h5 className='h6 mb-3 mt-2 border-bottom pb-3'>{Lang('GUIA_DE_PROFESIONALES')}</h5>
      </div> */}

      <div className="col-12">

        <div className="row">

          <TableMisInscripciones

            data={fetchMisInscripcionesGETData.data?.items}
            loading={fetchMisInscripcionesGETData.loading}
            total={fetchMisInscripcionesGETData.data?.total}
            perPage={fetchMisInscripcionesGETData.data?.per_page}
            formValuesSearch={formValuesSearch}
            handleFormChange={handleFormChange}
            urlParams={getValuesParams(formValuesSearch)}

            fetchMisInscripcionesGET={fetchMisInscripcionesGET}

          />

        </div>
      </div>


      <ModalMiActProfesional
        showModal_MiActProfesional={showModal_MiActProfesional}
        handleCloseModal_MiActProfesional={handleCloseModal_MiActProfesional}
        fetchActProfesionalesParamData={fetchActProfesionalesParamData}
        fetchSuccess={fetchMisInscripcionesGET}
      />

    </div>
  )
}

export default Index;
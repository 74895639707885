import { useEffect } from 'react'
import { CVsServices } from "../controllers/cv.service"
import { TableCurriculums } from "./TableCurriculums"


export const Curriculums = ({ idofertaempleo, fetchCondicionPagosRemesasGETData,
    fetchMultipleParamBolsaData }: any) => {

    const [fetchMisCurriculumsGET, fetchMisCurriculumsGETData, resetFetchMisCurriculumsGET] = CVsServices.GET()

    useEffect(() => {

        fetchMisCurriculumsGET()

    }, []);

    return (
        <div>
            <TableCurriculums
                data={fetchMisCurriculumsGETData?.data?.items || []}
                loading={fetchMisCurriculumsGETData.loading}
                idofertaempleo={idofertaempleo}
                fetchCondicionPagosRemesasGETData={fetchCondicionPagosRemesasGETData}
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                fetchMisCurriculumsGET={fetchMisCurriculumsGET}
            />
        </div>
    )
}

import { faArrowLeft, faBook, faBox, faBriefcase, faCircleQuestion, faCogs, faComments, faFileInvoiceDollar, faFileSignature, faScrewdriverWrench, faTicket, faUser, faUserGroup, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import logo from '../../assetsTheme/images/logo.png'

import { useNavigate } from 'react-router-dom';

import '../../assetsTheme/css/pages/PageApp.scss'
import { Button } from 'react-bootstrap'
import { useLang } from '../../language'
import { RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { logOut } from '../../store/slices/auth'
import { AuthServices } from '../../services/auth.service'
import { useForm } from '../../hooks/useForm'
import { Loader } from './Loader'
import { filterModulosAdministrador, isAdminAres, isStatusSucess } from '../../helpers/helpers'
import { DropdownUser } from '../../components/Layouts/DropdownUser'
import { ModalCambioContrasenia } from '../../components/Layouts/ModalCambioContrasenia'
import { DropdownLanguage } from '../../components/Layouts/DropdownLanguage/DropdownLanguage';

export const Apps = () => {

    const navigate = useNavigate();

    const Lang = useLang()

    const handleClickIrModule = (url: string, state: any) => navigate(`/${url}`, { state: state })

    const ModulesAdmin: any = [
        {
            icon: <FontAwesomeIcon icon={faCogs} />,
            title: Lang('ADMINISTRACION'),
            link: 'administracion',
            alias: 'administracion',
            active: true,
        },
        {
            icon: <FontAwesomeIcon icon={faBriefcase} />,
            title: Lang('BOLSA_DE_TRABAJO'),
            link: 'bolsa-trabajo-admin',
            alias: 'admin_bolsa_trabajo',
            active: true,
        },
        {
            icon: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
            title: Lang('FACTURACION'),
            link: 'facturacion',
            alias: 'admin_facturacion',
            active: true,
        },
        {
            icon: <FontAwesomeIcon icon={faBox} />,
            title: Lang('GESTOR_DE_CONTENIDOS'),
            link: 'gestor-contenidos',
            alias: 'admin_gestor_contenidos',
            active: true,
        },
        {
            icon: <FontAwesomeIcon icon={faBook} />,
            title: Lang('INSCRIPCIONES'),
            link: 'inscripciones-admin',
            alias: 'admin_inscripciones',
            active: true,
        },
        {
            icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
            title: Lang('ALQUILER_DE_APARATOS'),
            link: 'alquiler-aparatos-admin',
            alias: 'admin_alquiler_aparatos',
            active: true,
        },
        {
            icon: <FontAwesomeIcon icon={faFileSignature} />,
            title: Lang('VISADOS'),
            link: 'visados-admin',
            alias: 'admin_visados',
            active: true,
        },
        {
            icon: <FontAwesomeIcon icon={faComments} />,
            title: 'Ticketing',
            link: 'ticketing-admin',
            alias: 'admin_ticketing',
            active: true,
        },

    ]

    const ModulesCliente: any = [
        {
            icon: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
            title: Lang('FACTURACION'),
            link: 'facturacion',
            alias: 'facturacion',
            active: true,
        },
        {
            icon: <FontAwesomeIcon icon={faUserGroup} />,
            title: Lang('BOLSA_DE_TRABAJO_EMPRESA'),
            link: 'bolsa-trabajo-empresa',
            alias: 'admin_bolsa_trabajo_empresa',
            active: true,
        },
        {
            icon: <FontAwesomeIcon icon={faBriefcase} />,
            title: Lang('BOLSA_DE_TRABAJO'),
            link: 'bolsa-trabajo',
            alias: 'bolsa_trabajo',
            active: true,
        },
        {
            icon: <FontAwesomeIcon icon={faUsers} />,
            title: Lang('GUIA_DE_PROFESIONALES'),
            link: 'guia-profesionales',
            alias: 'guia_profesionales',
            active: true,
        },
        {
            icon: <FontAwesomeIcon icon={faBook} />,
            title: Lang('INSCRIPCIONES'),
            link: 'inscripciones',
            alias: 'inscripciones',
            active: true,
        },
        // {
        //     icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
        //     title: Lang('ALQUILER_DE_APARATOS'),
        //     link: 'alquiler-aparatos',
        //     alias: 'alquiler_aparatos',
        //     active: true,
        // },
        {
            icon: <FontAwesomeIcon icon={faFileSignature} />,
            title: Lang('VISADOS'),
            link: 'visados',
            alias: 'visados',
            active: true,
        },
        {
            icon: <FontAwesomeIcon icon={faUser} />,
            title: Lang('MIS_DATOS'),
            alias: true,
            link: 'mis-datos',
            active: true,
        },
        {
            icon: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
            title: Lang('MIS_FACTURAS'),
            alias: true,
            link: 'mis-facturas',
            active: true,
        },
        {
            icon: <FontAwesomeIcon icon={faComments} />,
            title: 'Ticketing',
            link: 'ticketing',
            alias: 'ticketing',
            active: true,
        },
    ]

    const dispatch = useDispatch()


    const { auth } = useSelector((state: RootState) => state.auth)

    // Modulos

    const [formModulos, handleInputModulos, handleFormModulos] = useForm({
        modulos: []
    })

    const [fetchModulosGET, fetchModulosGETData] = AuthServices.GET()

    useEffect(() => {
        fetchModulosGET()
    }, [])

    useEffect(() => {

        if (isStatusSucess(fetchModulosGETData.status)) {

            setexistModulosAdmin(filterModulosAdministrador(fetchModulosGETData.data)?.length > 0)
        }

    }, [fetchModulosGETData])

    const [existModulosAdmin, setexistModulosAdmin] = useState(false)

    const [areaAdmin, setAreaAdmin] = useState(false)

    useEffect(() => {

        setAreaAdmin(existModulosAdmin)

    }, [existModulosAdmin])

    // Modal Cambiar credenciales

    const [showCambiarPassword, setShowCambiarPassword] = useState({
        value: false,
        payload: null
    });

    const handleCloseCambiarPassword = () => setShowCambiarPassword({
        value: false,
        payload: null
    })

    const handleShowCambiarPassword = (payload: any) => setShowCambiarPassword({
        value: true,
        payload
    });

    return (
        <section id='Apps' className='min-vh-100 d-flex flex-column px-2 px-lg-5  animate__animated animate__fadeIn animate__faster'>
            <header className='py-4'>
                <div className="d-flex justify-content-between align-items-start">
                    <img src={logo} alt="logo" className='Apps__logo' />
                    <div className="d-flex align-items-center" id=''>

                        <DropdownUser
                            auth={auth}
                            dispatch={dispatch}
                            logOut={logOut}
                            handleShowCambiarPassword={handleShowCambiarPassword}
                        />
                    </div>
                </div>
            </header>
            <section id='Apps_links' className='mb-auto mt-4 pt-3'>
                <div className="container-fluid">

                    <div className="row justify-content-center mb-2 mb-lg-4">
                        <div className="col-lg-8 text-center">

                            <h4 className='mb-4'> {process.env.REACT_APP_MAIN_VARIABLE !== 'USER_ARES' ? Lang('INTRANET_DE_ENGINYERS') : 'ARES S.COOP'}</h4>
                            {
                                process.env.REACT_APP_MAIN_VARIABLE !== 'USER_ARES' &&
                                <p>{Lang('PARRAFO_1_INTRANET')}</p>
                            }
                        </div>
                    </div>
                    {
                        fetchModulosGETData.loading ?
                            <Loader /> :
                            <>
                                {
                                    areaAdmin && existModulosAdmin && <div className="row mb-2 mb-lg-4 justify-content-center animate__animated animate__fadeInUpModules">
                                        {
                                            ModulesAdmin.map((item: any, index: number) => {

                                                if (!fetchModulosGETData.data?.find((item2: any) => item2.alias === item.alias)) {
                                                    return null
                                                }

                                                return (
                                                    <div key={'admin-' + item.link}
                                                        className={`ButtonModule ButtonModule__Admin ${!item.active ? 'ButtonModule--disabled' : ''}`} onClick={() => handleClickIrModule(item.link, areaAdmin)}>
                                                        <div className='ButtonModule__icon'>
                                                            {item.icon}
                                                        </div>
                                                        <h5 className='ButtonModule__title text-center'>
                                                            {item.title}
                                                        </h5>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                                {
                                    !areaAdmin && <div className="row mb-2 mb-lg-4 justify-content-center animate__animated animate__fadeInUpModules">
                                        {
                                            ModulesCliente.map((item: any, index: number) => {

                                                if (!fetchModulosGETData.data?.find((item2: any) => item2.alias === item.alias)) {
                                                    return null
                                                }

                                                return (
                                                    <div key={'cliente-' + item.link}
                                                        className={`ButtonModule ${!item.active ? 'ButtonModule--disabled' : ''}`} onClick={() => handleClickIrModule(item.link, areaAdmin)}>
                                                        <div className='ButtonModule__icon'>
                                                            {item.icon}
                                                        </div>
                                                        <h5 className='ButtonModule__title text-center'>
                                                            {item.title}
                                                        </h5>
                                                    </div>
                                                )
                                            })
                                        }

                                        <div className={`ButtonModule`} onClick={() => handleClickIrModule('mis-datos', areaAdmin)}>
                                            <div className='ButtonModule__icon'>
                                                {<FontAwesomeIcon icon={faUser} />}
                                            </div>
                                            <h5 className='ButtonModule__title text-center'>
                                                {Lang('MIS_DATOS')}
                                            </h5>
                                        </div>

                                        <div className={`ButtonModule`} onClick={() => handleClickIrModule('mis-facturas', areaAdmin)}>
                                            <div className='ButtonModule__icon'>
                                                {<FontAwesomeIcon icon={faFileInvoiceDollar} />}
                                            </div>
                                            <h5 className='ButtonModule__title text-center'>
                                                {Lang('MIS_FACTURAS')}
                                            </h5>
                                        </div>

                                    </div>
                                }
                            </>
                    }

                </div>
            </section>
            <footer className='py-4'>
                <div className="d-flex align-items-center justify-content-center justify-content-between">
                    <div className='d-flex'>
                        <a href={process.env.REACT_APP_PUBLIC_URL_PORTAL} className='text-decoration-none link-gray'>
                            <FontAwesomeIcon icon={faArrowLeft} className='me-2' /> {Lang('REGRESAR_AL_PORTAL')}
                        </a>
                        {
                            isAdminAres(auth?.user?.perfiles) &&
                            <DropdownLanguage />
                        }
                    </div>
                    <div>
                        {
                            existModulosAdmin && <Button size='sm' variant={areaAdmin ? 'outline-primary' : 'outline-secondary'}
                                onClick={() => setAreaAdmin(!areaAdmin)}>
                                {
                                    areaAdmin ? Lang('IR_AREA_CLIENTE') : Lang('IR_AREA_ADMINISTRACION')
                                }

                            </Button>
                        }

                    </div>
                </div>
            </footer>
            <ModalCambioContrasenia
                showCambiarPassword={showCambiarPassword}
                handleCloseCambiarPassword={handleCloseCambiarPassword}
            />
        </section>
    )
}

import { parseString } from "../../../../../../../../adapters/helpers"

const InformacionAdicionalAdapters = {
    
    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.ideventoidioma || '',
                    descripcion: item.descripcion || '',
                    direccion: item.direccion || '',
                    dirigidoa: item.dirigidoa || '',
                    eventoidioma: item.eventoidioma || '',
                    idevento: item.idevento || '',
                    ididioma: item.ididioma || '',
                    logfecha: item.logfecha || '',
                    logidpersona: item.logidpersona || '',
                    lugar: item.lugar || '',
                    objetivo: item.objetivo || '',
                    ponentes: item.ponentes || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp?.[0])

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idtipoevento: item.idtipoevento || '',
                    aforo: item.aforo || '',
                    creditos: item.creditos || '',
                    estado: item.estado || '',
                    nombreevento: item.nombreevento || '',
                    gratuito: parseString(item.gratuito),
                    fechainicioinscripcion: item.fechainicioinscripcion || '',
                    fechafininscripcion: item.fechafininscripcion || '',
                    observaciones: item.observaciones || '',
                    eventoidioma: item.eventoidioma || [],
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    ideventoidioma: item.id || '',
                    descripcion: item.descripcion || '',
                    direccion: item.direccion || '',
                    dirigidoa: item.dirigidoa || '',
                    eventoidioma: item.eventoidioma || '',
                    idevento: item.idevento || '',
                    ididioma: item.ididioma || '',
                    logfecha: item.logfecha || '',
                    logidpersona: item.logidpersona || '',
                    lugar: item.lugar || '',
                    objetivo: item.objetivo || '',
                    ponentes: item.ponentes || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },
}

export {
    InformacionAdicionalAdapters
}
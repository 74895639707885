import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { PaginationT } from '../../PaginationT/PaginationT'
import { useLang } from '../../../language'

interface ITableAdvancedFooter {
  total: any,
  handleFormChange: any,
  formValuesSearch: any,
  perPage: any,
  onlyOnePage?: any
}

export const TableAdvancedFooter = ({ total, handleFormChange, formValuesSearch, perPage, onlyOnePage }: ITableAdvancedFooter) => {

  const [showAll, setshowAll] = useState(false)

  const Lang = useLang()

  return (
    <div className='d-flex flex-column flex-lg-row justify-content-between align-items-center overflow-auto mb-4'>
      <div className='flex-1 text-start'>
        <small><strong>{Lang('TOTAL')}:</strong> {total} {Lang('ELEMENTOS')}</small>
      </div>
      <div className="flex-1 text-center">
        <PaginationT
          total={total}
          handleFormChange={handleFormChange}
          formValuesSearch={formValuesSearch}
          perPage={perPage}
        />
      </div>
      <div className='flex-1 text-end'>
        {/* {
          !onlyOnePage && <Button size='sm' className='' variant='outline-primary' onClick={() => {

            if (showAll) {
  
              const {limit, ...formValuesSearchT} = formValuesSearch
              handleFormChange({
                ...formValuesSearchT,
                page: 1,
              })
            } else {
              handleFormChange({
                ...formValuesSearch,
                limit: total,
                page: 1,
              })
            }
            setshowAll(!showAll)
            
          }}>
            {
              !showAll ? 'Mostrar Tots' : 'Paginar tots'
            }
          </Button>
        } */}
        
      </div>
    </div>
  )
}

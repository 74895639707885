import { useState } from 'react'
import { ModalEnvioEmailMasivoOferta } from '../../../../../../../components/Modals/ModalEnvioEmailMasivoOferta';
import { useLang } from '../../../../../../../language';
import { ButtonSpinner } from '../../../../../../../components/ButtonSpinner/ButtonSpinner';

const WizardEmail = ({ checkAll, incluidos, urlParams, excluidos, fetch, fetchData, onlyEmail }: any) => {

  const Lang = useLang()

  // -------------- Modal envio de email

  const [showModal_EnvioEmail, setShowModal_EnvioEmail] = useState({
    value: false,
    payload: {}
  });

  const handleCloseModal_EnvioEmail = () => setShowModal_EnvioEmail({
    value: false,
    payload: {}
  });

  const handleShowModal_EnvioEmail = (payload: object) => setShowModal_EnvioEmail({
    value: true,
    payload
  });

  return (

    <div className='p-3 p-lg-3 card' style={{ 'backgroundColor': '#F8F8F8' }}>

      <div className="row justify-content-center">
        <div className="col-12 col-lg-12 col-xl-12 col-xxl-12">
          <p className="text-center mb-3 mb-lg-3 fw-500 h6">{Lang('ENVIAR_EMAIL')}</p>

          <div className='d-flex flex-wrap justify-content-between align-items-center'>
            <p className='m-0'>
              <small>{Lang('PARRAFO_SELECCIONAR_ALMENOS_1')}</small>
            </p>

            <ButtonSpinner
              variant="primary" type="button" className="ms-3 px-3"
              handleSubmit={() => handleShowModal_EnvioEmail({})}
              fetchDataLoading={[false]}
              value={Lang('CONTINUAR')}
              disabled={!(checkAll || incluidos.length > 0)}
            />
          </div>

          <ModalEnvioEmailMasivoOferta
            showModal_EnvioEmail={showModal_EnvioEmail}
            handleCloseModal_EnvioEmail={handleCloseModal_EnvioEmail}
            urlParams={urlParams}
            incluidos={incluidos}
            excluidos={excluidos}
            fetch={fetch}
            fetchData={fetchData}
            onlyEmail={onlyEmail}
          />
        </div>
      </div>

    </div>
  )

}

export default WizardEmail;

const ParametrosTicketAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    tkentidades: item.tkentidades || [],
                    tkestados: item.tkestados || {},
                    tkproductos: item.tkproductos || [],
                    tktecnicos: item.tktecnicos || [],
                    tkurgencias: item.tkurgencias || {},
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                console.log(dataTemp);
                

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },
}

export {
    ParametrosTicketAdapters,
}
import { useEffect, useRef, useState } from 'react'
import { Alert, Button, Form, Modal, Table } from 'react-bootstrap'
import { useModalData } from '../../../../../../../hooks/useModalData'
import { useLang } from '../../../../../../../language'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { InputText } from '../../../../../../../components/Inputs'
import { ModalStatusServer } from '../../../../../../../components/Modals/ModalStatusServer'
import { InscripcionesAdminServices } from '../controllers/services/inscripciones.service'
import { useForm } from '../../../../../../../hooks/useForm'
import { isStatusSucess } from '../../../../../../../helpers/helpers'
import { cloneDeep } from 'lodash'
import { LoaderFormParametro } from '../LoaderFormParametro'
import { PersonaBTServices } from '../../../Personas/controllers/personasbt.service'
import { ListFiles } from '../../../../../../../components/ListFiles/ListFiles'
import { useNavigate } from 'react-router-dom'

export const ModalFichaPersona = ({ data = {}, handleClose, idofertaempleo, fetchInscripcionesGET, urlParams,
    fetchMultipleParamBolsaData }: any) => {

    const Lang = useLang()

    const inputFileFichero: any = useRef(null)

    // Modal Crear visado

    const [dataFilterPersona, handleShowFilterPersona, handleCloseFilterPersona] = useModalData()

    const [formInscripcion, handleInputInscripcion, handleFormInscripcion, resetFormInscripcion] = useForm({
        idpersona: '',
        interes: [],
        notificarempresa: [],
        preferido: [],
    })

    const [filterPersona, setFilterPersona] = useState({
        id: '',
        nombre: ''
    })

    useEffect(() => {

        const obj = {
            target: {
                name: 'idpersona',
                value: filterPersona.id
            }
        }

        handleInputInscripcion(obj)

    }, [filterPersona])

    useEffect(() => {
        if (!formInscripcion.idpersona) {
            setFilterPersona({
                id: '',
                nombre: ''
            })
        }
    }, [formInscripcion.idpersona])


    // --------------- POST Agregar persona

    const [fetchAgregarPersonaPATCH, fetchAgregarPersonaPATCHData] = InscripcionesAdminServices.PATCH()

    const handleSubmitEditarPersonaPATCH = () => {

        fetchAgregarPersonaPATCH({
            id: idofertaempleo + '/update',
            body: {
                ...formInscripcion,
                archivo: inputFileFichero.current?.files?.[0],
            }
        })
    }

    useEffect(() => {

        if (isStatusSucess(fetchAgregarPersonaPATCHData.status)) {

            fetchInscripcionesGET({
                params: {
                    ...urlParams
                }
            })
            handleClose()
        }

    }, [fetchAgregarPersonaPATCHData]);


    // Funciones helpers

    const handleChangeCheck = (e: any, name: string) => {

        let listaModulosTemp = cloneDeep(formInscripcion[name])

        if (e.target.checked) {

            listaModulosTemp.push(e.target.name)

        } else {

            listaModulosTemp = listaModulosTemp.filter((item: any) => item !== e.target.name)
        }

        handleFormInscripcion({
            ...formInscripcion,
            [name]: listaModulosTemp
        })
    }

    // --------------- DELETE Agregar persona

    const [fetchAgregarPersonaDELETE, fetchAgregarPersonaDELETEData] = InscripcionesAdminServices.DELETE()

    const handleSubmitEditarPersonaDELETE = () => {

        fetchAgregarPersonaDELETE({
            id: idofertaempleo + '/delete',
            params: {
                idpersona: formInscripcion.idpersona
            }
        })
    }

    useEffect(() => {

        if (isStatusSucess(fetchAgregarPersonaDELETEData.status)) {

            fetchInscripcionesGET({
                params: {
                    ...urlParams
                }
            })
            handleClose()
        }

    }, [fetchAgregarPersonaDELETEData]);

    // const [fetchCVsPersonaGET, fetchCVsPersonaGETData] = CVsAdminServices.GET_ALL()
    const [fetchPersonaBTGET, fetchPersonaBTGETData] = PersonaBTServices.GET_FICHA()

    useEffect(() => {

        if (data.value) {

            // handleFormInscripcion({
            //     ...data?.form,
            //     idpersona: data?.form?.id,
            // })

            fetchPersonaBTGET({
                id: data?.form?.idoferta_persona
            })

        } else {

            resetFormInscripcion()
        }

    }, [data.value])

    useEffect(() => {

        if (isStatusSucess(fetchPersonaBTGETData.status)) {
            handleFormInscripcion({
                ...fetchPersonaBTGETData.data,
                idcv: data?.form?.idcv
            })
        }

    }, [fetchPersonaBTGETData]);

    const navigate = useNavigate()

    return (
        <>
            <Modal show={data.value} onHide={handleClose} size='lg'>
                <Modal.Header className='border-bottom-0 py-4'>
                    <Modal.Title className='h5'>
                        {formInscripcion.nombrecompleto ? `${formInscripcion.nombrecompleto} (${formInscripcion.nif}) ` : ''}
                    </Modal.Title>
                    <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleClose}></button>
                </Modal.Header>
                {
                    fetchPersonaBTGETData?.loading ?
                        <LoaderFormParametro />
                        :
                        <>
                            <Modal.Body className='px-4 py-0'>

                                <div className="row">

                                    <div className="col-12 mt-0">
                                        <div className="d-flex justify-content-between align-items-end flex-wrap border-bottom pb-2 mb-3">
                                            <h4 className='h6 my-0'>{Lang('DATOS_GENERALES')}</h4>
                                            <div className="">
                                                {
                                                    formInscripcion.pathcv ?
                                                        <ListFiles
                                                            isBlob={false}
                                                            list={[
                                                                {
                                                                    titulo: Lang('CURRICULUM'),
                                                                    url: formInscripcion.pathcv,
                                                                }
                                                            ]}
                                                        />
                                                        :
                                                        <Alert variant='info' className="d-inline-flex justify-content-center py-1 m-0 w-100">
                                                            <strong>
                                                                <small>{Lang('NO_EXISTE_CV_ASOCIADO_A_OFERTA')}</small>
                                                            </strong>
                                                        </Alert>
                                                }
                                            </div>
                                        </div>

                                    </div>

                                    <Form.Group className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                        <InputText
                                            label={Lang('EMAIL') + ':'}
                                            name='email'
                                            value={formInscripcion.email}
                                            readonly
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <InputText
                                            label={Lang('MOVIL') + ':'}
                                            name='telefonomovil'
                                            value={formInscripcion.telefonomovil}
                                            readonly
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                                        <InputText
                                            label={`${Lang('TIPO_CONTRATO')}:`}
                                            name='tipocontrato'
                                            value={formInscripcion.tipocontrato}
                                            readonly
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-12 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                        <InputText
                                            label={`${Lang('SALARIO')}:`}
                                            name='salario'
                                            value={formInscripcion.salario}
                                            readonly
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                                        <InputText
                                            label={`${Lang('HORARIO')}:`}
                                            name='horario'
                                            value={formInscripcion.horario}
                                            readonly
                                        />
                                    </Form.Group>

                                    <div className="col-12 mt-3">
                                        <h4 className='h6 pb-2'>{Lang('IDIOMAS')}</h4>
                                    </div>

                                    <Table responsive className='position-relative mb-0' bordered>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <small>{Lang('IDIOMA')}</small>
                                                </th>
                                                <th>
                                                    <small>{Lang('NIVEL_IDIOMA')}</small>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                <>
                                                    {
                                                        formInscripcion.idiomas?.map((item: any, index: number) => {

                                                            return (
                                                                <tr key={index}>
                                                                    <td className='text-nowrap'>
                                                                        <small>{item.idioma}</small>
                                                                    </td>
                                                                    <td>
                                                                        <small>{item.nivelidioma}</small>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        formInscripcion.idiomas?.length === 0 &&
                                                        <tr>
                                                            <td colSpan={20} className='text-center py-5 bg-gray-1'>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</td>
                                                        </tr>
                                                    }
                                                </>
                                            }
                                        </tbody>
                                    </Table>

                                    <div className="col-12 mt-3">
                                        <h4 className='h6 pb-2'>{Lang('TIPO_ESTUDIO')}</h4>
                                    </div>

                                    <Table responsive className='position-relative' bordered>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <small>{Lang('ESTUDIO')}</small>
                                                </th>
                                                <th>
                                                    <small>{Lang('ESPECIALIDAD')}</small>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                <>
                                                    {
                                                        formInscripcion.estudios?.map((item: any, index: number) => {

                                                            return (
                                                                <tr key={index}>
                                                                    <td className='text-nowrap'>
                                                                        <small>{item.tipoestudio}</small>
                                                                    </td>
                                                                    <td>
                                                                        <small>{item.especialidad}</small>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        formInscripcion.estudios?.length === 0 &&
                                                        <tr>
                                                            <td colSpan={20} className='text-center py-5 bg-gray-1'>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</td>
                                                        </tr>
                                                    }
                                                </>
                                            }
                                        </tbody>
                                    </Table>

                                    <div className="col-12">
                                        <h4 className='h6 pb-2'>{Lang('FUNCIONES')}</h4>
                                    </div>

                                    <div className="col-lg-12">

                                        <Table className='position-relative' responsive bordered>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <small>{Lang('FUNCION')}</small>
                                                    </th>
                                                    <th>
                                                        <small>{Lang('EXPERIENCIA')}</small>
                                                    </th>
                                                    <th>
                                                        <small>{Lang('INTERES')}</small>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    <>
                                                        {
                                                            formInscripcion.funciones?.filter((itemP: any) => itemP.experiencia || itemP.interes)?.map((item: any, index: number) => {

                                                                return (
                                                                    <tr key={item.idfuncion}>
                                                                        <td className=''>
                                                                            <small>{item['nombre']}</small>
                                                                        </td>

                                                                        <td className='text-center text-nowrap shrink-td' valign='middle'>
                                                                            {item['experiencia'] ? 'Si' : ''}
                                                                        </td>

                                                                        <td className='text-center text-nowrap shrink-td' valign='middle'>
                                                                            {item['interes'] ? 'Si' : ''}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                }

                                                {
                                                    formInscripcion.funciones?.filter((itemP: any) => itemP.experiencia || itemP.interes)?.length === 0 &&
                                                    <tr>
                                                        <td colSpan={20} className='text-center py-5 bg-gray-1'>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</td>
                                                    </tr>
                                                }

                                            </tbody>

                                        </Table>

                                    </div>

                                </div>
                            </Modal.Body>
                            <Modal.Footer className='border-0 d-flex justify-content-end mt-0'>
                                <Button onClick={() => navigate(`/bolsa-trabajo-admin/personas/${formInscripcion.id}`)} className="" size='sm'>
                                    {Lang('IR_A_FICHA_PERSONA')} <FontAwesomeIcon icon={faExternalLink} className='ms-1' />
                                </Button>
                            </Modal.Footer>
                        </>

                }
            </Modal>

            <ModalStatusServer fetchData={fetchAgregarPersonaPATCHData} />

            <ModalStatusServer fetchData={fetchAgregarPersonaDELETEData} />
        </>
    )
}

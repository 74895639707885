import { faArrowUpRightFromSquare, faBook, faCheckCircle, faEdit, faFilePdf, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import { ModalCurriculum } from './ModalCurriculum';
import { useLang } from '../../../../../../language';
import { useModalData } from '../../../../../../hooks/useModalData';
import { LoaderTable } from '../../../../../../components/Loaders/LoaderTable';
import { LoaderTrTable } from '../../../../../../components/Loaders/LoaderTrTable';
export const TableCurriculums = ({ data, loading, total, perPage, handleFormChange, formValuesSearch, fetchMisCurriculumsGET,
    fetchPersonaDatosProfesionalesGET, idofertaempleo, urlParams, fetchCondicionPagosRemesasGETData, fetchMultipleParamBolsaData }: any) => {

    

    const navigate = useNavigate();

    const Lang = useLang()

    const [dataModal_NivelIdioma, handleShowModal_NivelIdioma, handleCloseModal_NivelIdioma] = useModalData()

    return (
        <>
            <div className="d-flex flex-wrap justify-content-between align-items-start mb-3">
                <h5 className="mb-3 mb-lg-0 ">{Lang('CURRICULUMS')}</h5>
                <Button size='sm' onClick={() => handleShowModal_NivelIdioma({
                    idofertaempleo,
                    action: 'CREATE',
                    data,
                })}>
                    <FontAwesomeIcon icon={faPlus} className='me-2' /> {Lang('AGREGAR_CURRICULUM')}
                </Button>
            </div>

            <Table responsive className='position-relative' striped bordered>
                <thead>
                    <tr>
                        <th>
                            <small>{Lang('NOMBRE')}</small>
                        </th>
                        <th>
                            <small>{Lang('PREFERIDO')}</small>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ?
                            <LoaderTrTable />
                            :
                            <>
                                {
                                    data?.map((item: any, index: number) => {

                                        return (
                                            <tr>
                                                <td valign='middle' className='text-nowrap'>
                                                    <small>{item.titulocv}</small>
                                                </td>

                                                <td valign='middle' className='text-center text-nowrap shrink-td'>
                                                    <small className='px-4'>{item.preferido?.length > 0 ? <FontAwesomeIcon icon={faCheckCircle} /> : '' }</small>
                                                </td>

                                                <td className='text-center text-nowrap shrink-td' valign='middle'>
                                                    <Button variant='outline-primary' onClick={() => window.open(item.pathcv)} size='sm' className='text-nowrap me-3' >
                                                        <FontAwesomeIcon icon={faFilePdf} />
                                                    </Button>

                                                    <Button onClick={() => handleShowModal_NivelIdioma({
                                                        idofertaempleo,
                                                        item,
                                                        data,
                                                        action: 'EDIT',
                                                        index
                                                    })} size='sm' className='text-nowrap' >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    data?.length === 0 &&
                                    <tr>
                                        <td colSpan={20} className='text-center py-5 bg-gray-1'>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</td>
                                    </tr>
                                }

                            </>

                    }

                </tbody>

            </Table>

            <ModalCurriculum
                data={dataModal_NivelIdioma}
                handleClose={handleCloseModal_NivelIdioma}
                fetchPersonaDatosProfesionalesGET={fetchPersonaDatosProfesionalesGET}
                urlParams={urlParams}
                fetchCondicionPagosRemesasGETData={fetchCondicionPagosRemesasGETData}
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                fetchMisCurriculumsGET={fetchMisCurriculumsGET}
            />

        </>
    )
}

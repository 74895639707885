import { useEffect } from 'react'
import { getValuesParams, isStatusSucess } from '../../../../../../../../helpers/helpers';
import { TableNivelesIdioma } from './TableNivelesIdioma';
import { useForm } from '../../../../../../../../hooks/useForm';
import { Loader } from '../../../../../../../../components/Layouts/LayoutMain2/Loader/Loader';

export const SeccionNivelesIdiomas = ({ idofertaempleo, fetchCondicionPagosRemesasGETData, fetchInscripcionGETData = {},
    fetchMultipleParamBolsaData, fetchInscripcionGET }: any) => {

    return (
        <div>
            <TableNivelesIdioma
                data={fetchInscripcionGETData.data?.nivelidiomacompleto || []}
                loading={false}
                idofertaempleo={idofertaempleo}
                fetchInscripcionGETData={fetchInscripcionGETData}
                fetchCondicionPagosRemesasGETData={fetchCondicionPagosRemesasGETData}
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                fetchInscripcionGET={fetchInscripcionGET}
                logica_buscar_idioma={fetchInscripcionGETData.data?.logica_buscar_idioma}
            />
        </div>
    )
}

import React, { useEffect } from "react";
import { SeccionNivelesIdiomas } from "./SeccionNivelesIdiomas";
import { ParametrosServices } from "../../../../../../../services/parametros.service";
import { Loader } from "../../../../../../../pages/Index/Loader";
import { LoaderTable } from "../../../../../../../components/Loaders/LoaderTable";
import { SeccionEstudios } from "./SeccionEstudios";
import { SeccionFunciones } from "./SeccionFunciones";
import { EspecialidadesServices } from "../../../../../../administracion/pages/Parametros/services/especialidades.service";

export const InformacionAdicional = ({ fetchInscripcionGET, idofertaempleo, fetchInscripcionGETData }: any) => {

  // -------- Parametros

  const [fetchMultipleParamBolsa, fetchMultipleParamBolsaData] = ParametrosServices.GET_BOLSA_TRABAJO()
  const [fetchEspecialidadesGET, fetchEspecialidadesGETData] = EspecialidadesServices.GET()

  useEffect(() => {

    fetchMultipleParamBolsa({
      params: {
        nivelidioma: true,
        funciones: true,
        idiomas: true,
        tiposestudios: true,
        tiposestudiosespecialidades: true,
        order: 'asc',
      }
    })

    fetchEspecialidadesGET({
      params: {
          limit: 200,
          status: 'activos'
      }
  })

  }, [])

  return (
    <div>
      {
        (fetchInscripcionGETData.loading || fetchMultipleParamBolsaData.loading) ?
          <div className="card p-3">
            <div>
            <LoaderTable />
            </div>
          </div>
          :
          <>
            <div className="mt-4">

              <SeccionNivelesIdiomas
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                idofertaempleo={idofertaempleo}
                fetchInscripcionGET={fetchInscripcionGET}
                fetchInscripcionGETData={fetchInscripcionGETData}
              />
            </div>
            <div className="mt-4">

              <SeccionEstudios
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                idofertaempleo={idofertaempleo}
                fetchInscripcionGET={fetchInscripcionGET}
                fetchInscripcionGETData={fetchInscripcionGETData}
                fetchEspecialidadesGETData={fetchEspecialidadesGETData}
              />
            </div>

            <div className="my-4">

              <SeccionFunciones
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                idofertaempleo={idofertaempleo}
                fetchInscripcionGET={fetchInscripcionGET}
                fetchInscripcionGETData={fetchInscripcionGETData}
              />
            </div>
          </>
      }

    </div>
  );
};

import { faBook, faCogs, faEnvelope, faFileExcel, faFilePdf, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useLang } from '../../../../../language'
import ButtonOrderBy from '../../../../../components/ButtonOrderBy/ButtonOrderBy'
import TableAdvanced from '../../../../../components/Tables/TableAdvanced/TableAdvanced'
import { FacturaServices } from '../../../pages/Facturas/services/facturas.service'
import { ModalStatusServer } from '../../../../../components/Modals/ModalStatusServer'
import { ModalConfirm } from '../../../../../components/Modals/ModalConfirm'
import { getDataFile, isStatusSucess } from '../../../../../helpers/helpers'
import { misFacturasServices } from '../../controllers/services/misFacturas.service'

export const TableFacturas = ({ data, loading, total, perPage, handleFormChange, formValuesSearch,
    urlParams, handleInputChangeSearch, fetchFacturasGET }: any) => {

    const Lang = useLang()
    const navigate = useNavigate();

    const [excluidos, setExcluidos] = useState([])
    const [incluidos, setIncluidos] = useState([])

    const [checkAll, setCheckAll] = useState(false)

    const selectAllItems = () => {
        setCheckAll(true)
        setIncluidos([])
        setExcluidos([])
    }

    const unselectAllItems = () => {
        setCheckAll(false)
        setIncluidos([])
        setExcluidos([])
    }


    const allActions: any = [
        {
            label: <span className='me-2'><FontAwesomeIcon icon={faFileExcel} className='me-2' />{Lang('DESCARGAR_EXCEL')}</span>,
            variant: 'outline-success',
            action: () => handleClickExcel(),
            withoutID: true,
            tooltip: {
                placement: 'top',
                text: Lang('PARRAFO_USARAN_ELEMENTOS_SELECCIONADOS')
            }
        },
    ]

    const allActionsPerLine = [
        {
            label: <FontAwesomeIcon icon={faFilePdf} />,
            action: (id: any) => handlefetchFacturasPDFsUnico(id),
        },
    ]

    const handleChangeCheckAll = (e: any) => {

        if (e.target.checked) {

            selectAllItems()

        } else {

            unselectAllItems()
        }
    }

    const headersTable = [
        <small>{Lang('NUM_FACTURA')}</small>,
        <small>{Lang('ABONA')}</small>,
        <small>
            <ButtonOrderBy
                label={Lang('FECHA_FACTURA')}
                id='fechafactura'
                className='text-center'
                handleInputChange={handleInputChangeSearch}
                formValuesSearch={formValuesSearch}
            />
        </small>,
        <small>{Lang('ESTADO')}</small>,
        <small>{Lang('IMPORTE')}</small>,
        <small>{Lang('IMPORTE_BASE')}</small>,
        <small>{Lang('IMPORTE_IVA')}</small>,
        ''
    ]

    const dataLineTable = [
        {
            className: '',
            name: 'numerofactura'
        },
        {
            className: 'min-w-20rem',
            name: 'abona'
        },
        {
            className: 'text-nowrap text-center',
            name: 'fechafactura'
        },
        {
            className: '',
            name: 'estado'
        },
        {
            className: 'text-nowrap',
            name: 'importe__label'
        },
        {
            className: 'text-nowrap',
            name: 'importe_base'
        },
        {
            className: 'text-nowrap',
            name: 'importe_iva'
        },
    ]

    // Acciones

    // Crear nueva factura / proforma

    const [showModal_NuevaFactura, setShowModal_NuevaFactura] = useState({
        value: false,
        payload: {}
    });

    const handleCloseModal_NuevaFactura = (payload: object) => setShowModal_NuevaFactura({
        value: false,
        payload: {}
    });

    const handleShowModal_NuevaFactura = (payload: object) => setShowModal_NuevaFactura({
        value: true,
        payload
    });

    // PDF UNICO

    const [showConfirm_PDFs, setshowConfirm_PDFs] = useState(false)

    const [fetchFacturasPDF, fetchFacturasPDFData, handleResetFacturaPDFsData] = FacturaServices.GET_PDF()

    const handlefetchFacturasPDFsUnico = (id: any) => {

        setshowConfirm_PDFs(true)

        fetchFacturasPDF({
            id: id + '/pdf'
        })
    }

    useEffect(() => {
        if (fetchFacturasPDFData.data) {
            // 2. Create blob link to download
            const docData = fetchFacturasPDFData.data

            const filename = docData?.headers?.['content-disposition']?.split('filename=')?.[1] || 'default.pdf'
            const blobData = docData?.data

            const instanceBlob = new Blob([blobData])

            const date = new Date()

            const url = window.URL.createObjectURL(instanceBlob);
            const link: any = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}_${filename}`);
            // 3. Append to html page
            document.body.appendChild(link);

            // 4. Force download
            link.click();

            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);

            handleResetFacturaPDFsData()
            setshowConfirm_PDFs(false)
        }
    }, [fetchFacturasPDFData])



    // Descargar Excel

    const [showConfirm_Excel, setshowConfirm_Excel] = useState(false)

    const [fetchFacturasGETExcel, fetchFacturasGETExcelData] = misFacturasServices.GET_EXCEL()

    useEffect(() => {

        if (isStatusSucess(fetchFacturasGETExcelData.status)) {
            getDataFile(fetchFacturasGETExcelData, handleResetFacturaPDFsData, 'Facturas', 'xlsx')
            setshowConfirm_Excel(false)
        }
    }, [fetchFacturasGETExcelData])

    const handleClickExcel = () => {

        setshowConfirm_Excel(true)

        let urlParamsTemp = cloneDeep(urlParams)
        delete urlParamsTemp['page']

        fetchFacturasGETExcel({
            params: {
                ...urlParamsTemp,
                to_excel: 1
            }
        })
    }

    return (
        <>

            <TableAdvanced>

                <TableAdvanced.Head
                    title={Lang('LISTA_DE_FACTURAS')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    headers={headersTable}
                    dataLineTable={dataLineTable}
                    data={data}
                    isLoading={loading}

                    excluidos={excluidos}
                    incluidos={incluidos}
                    setExcluidos={setExcluidos}
                    setIncluidos={setIncluidos}

                    checkAll={checkAll}
                    allActionsPerLine={allActionsPerLine}
                />

                <TableAdvanced.Footer
                    total={total}
                    handleFormChange={handleFormChange}
                    formValuesSearch={formValuesSearch}
                    perPage={perPage}
                />

            </TableAdvanced>

            <ModalStatusServer fetchData={fetchFacturasPDFData} onlyError={true} />

            <ModalConfirm
                handleClose={() => setshowConfirm_PDFs(false)}
                show={showConfirm_PDFs}
                // onClickOK={handleLineaFacturaDELETE}
                messageHTML={
                    <h5 className='text-center fw-normal h5'>{Lang('DESCARGAR_PDFS')}</h5>
                }
                textOnClickOk={Lang('DESCARGANDO')}
                fetchData={fetchFacturasPDFData}
                disableBtnClose
            />


            <ModalConfirm
                handleClose={() => setshowConfirm_Excel(false)}
                show={showConfirm_Excel}
                // onClickOK={handleLineaFacturaDELETE}
                messageHTML={
                    <h5 className='text-center fw-normal h5'>{Lang('DESCARGAR_EXCEL')}</h5>
                }
                textOnClickOk={Lang('DESCARGANDO')}
                fetchData={fetchFacturasGETExcelData}
                disableBtnClose
            />
        </>
    )
}

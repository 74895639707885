import { parseBoolean, parseString, receiveFecha, receiveFechaFormat2, sendMultipart } from "../../../../../../adapters/helpers"

const TicketsClientAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idticket || '',
                    codigoticket: item.codigoticket || '',
                    label__fechaFinIntervencion: receiveFechaFormat2(receiveFecha(item.fechaFinIntervencion)),
                    label__fechaInicioIntervencion: receiveFechaFormat2(receiveFecha(item.fechaInicioIntervencion)),
                    label__fechaRegistro: receiveFechaFormat2(receiveFecha(item.fechaRegistro)),
                    label__fechaUltimaModificacion: receiveFechaFormat2(receiveFecha(item.fechaUltimaModificacion)),
                    identidad: item.identidad || '',
                    idpersonaregistro: item.idpersonaregistro || '',
                    idtecnicoasociado: item.idtecnicoasociado || '',
                    idticket: item.idticket || '',
                    producto: item.producto || '',
                    // ticket: item.ticket || '',
                    ticket: `${(item.codigoticket || '')}. ${(item.ticket || '')}`,
                    tipoIncidencia: item.tipoIncidencia || '',
                    totalminutosgestion: item.totalminutosgestion || '0',
                    urgencia: item.urgencia || '',
                    nombre_entidad: item.nombre_entidad || '',
                    nombre_producto: item.nombre_producto || '',
                    nombre_urgencia: item.nombre_urgencia || '',
                    personaregistro: item.personaregistro || '',
                    nombre_estado: item.nombre_estado || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },

    GET_EXCEL: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    headers: item.headers
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}
                

                const data = {
                    // filtros: item.filtros || '',
                    // lista_ids: item.lista_ids || '',
                    // lista_exclusion: item.lista_exclusion || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

}

const TicketClientAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idticket || '',
                    codigoticket: item.codigoticket || '',
                    fechaFinIntervencion: item.fechaFinIntervencion || '',
                    fechaInicioIntervencion: item.fechaInicioIntervencion || '',
                    fechaRegistro: item.fechaRegistro || '',
                    fechaUltimaModificacion: item.fechaUltimaModificacion || '',
                    identidad: item.identidad || '',
                    idpersonaregistro: item.idpersonaregistro || '',
                    idtecnicoasociado: item.idtecnicoasociado || '',
                    idticket: item.idticket || '',
                    idtkproducto: item.idtkproducto || '',
                    producto: item.producto || '',
                    ticket: item.ticket || '',
                    ticket_mensajes: item.ticket_mensajes || [],
                    ticket_documents: item.ticket_documents || [],
                    ticket_personas_c_c: item.ticket_personas_c_c || [],
                    ticket_personas_no_registradas_c_c: item.ticket_personas_no_registradas_c_c || [],
                    tipoIncidencia: item.tipoIncidencia || '',
                    totalminutosgestion: item.totalminutosgestion || '',
                    urgencia: item.urgencia || '',
                    nombre_entidad: item.nombre_entidad || '',
                    nombre_producto: item.nombre_producto || '',
                    nombre_urgencia: item.nombre_urgencia || '',
                    nombre_tecnicoasociado: item.tecnicoasociado || '',
                    estado: item.estado || '',
                    nombre_estado: item.nombre_estado || '',
                    personaregistro: item.personaregistro || '',
                    tecnicoasociado: item.tecnicoasociado || '',
                    esPropietario: item.esPropietario || false,
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },
    
    GET_BUSCAR_PERSONAS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    datospersonas: item.datospersonas?.map((item2: any) => {
                        return {...item2, id: item2.idpersona}
                    }) || [],
                    resumen: item.resumen || {},
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idtecnicoasociado: item.idtecnicoasociado || '',
                    idpersonaregistro: item.idpersonaregistro || '',
                    identidad: item.identidad || '',
                    ticket: item.ticket || '',
                    tipoIncidencia: item.tipoIncidencia || '',
                    producto: item.producto || '',
                    urgencia: item.urgencia || '',
                    totalminutosgestion: item.totalminutosgestion || '',
                    descripcion: item.descripcion || '',
                    comunicar: item.comunicar || false,
                    'documents[]': item['documents[]'] || [],
                    'listadestinatarios[]': item['listadestinatarios[]'] || [],
                }

                return data
            },

            adapter: function (data: any) {

                const items = data

                return items
            }
        },
    },

    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    nombreoferta: item.nombreoferta || '',
                    idpais: item.idpais || '',
                    idpersona: item.idpersona || '',
                    identidad: item.identidad || '',
                    pub_nom_emp: parseBoolean(item.pub_nom_emp),
                    observaciones: item.observaciones || '',
                    idtipocontrato: item.idtipocontrato || '',
                    idestadooferta: item.idestadooferta || '',
                    categoriaoferta: item.categoriaoferta || '',
                    numerovacantes: item.numerovacantes || '',
                    idsalario: item.idsalario || '',
                    fpublicacioninicio: item.fpublicacioninicio || '',
                    fpublicacionfin: item.fpublicacionfin || '',
                    idhorario: item.idhorario || '',
                    oferta: item.oferta || '',
                    funcionesoferta: item.funcionesoferta || '',
                    ticket: item.ticket || '',
                    
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH_CHANGE_ESTADO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    estado: item.estado || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },

    PATCH_IMAGEN: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    imagen: item.imagen || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return sendMultipart(items)
            }
        },
    },

    DELETE_IMAGEN: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },
    
    PATCH_NIVEL_IDIOMA: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                // const data = {
                //     idiomas: item.idiomas || [],
                //     logica_buscar_idioma: item.logica_buscar_idioma || false
                // }

                return item
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH_TIPO_ESTUDIO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                // const data = {
                //     tiposestudios: item.tiposestudios || [],
                // }

                return item
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH_FUNCION: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                // const data = {
                //     funcion: item.funcion || [],
                // }

                return item
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    POST_SUBIR_ADJUNTO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    tituloadjunto: item.tituloadjunto || '',
                    adjunto: item.adjunto || '',
                }

                if (!data.tituloadjunto) delete data.tituloadjunto

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return sendMultipart(items)
            }
        },
    },

    DELETE_ADJUNTO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },

}

const TicketsClientMensajesAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idticket || '',
                    codigoticket: item.codigoticket || '',
                    label__fechaFinIntervencion: receiveFechaFormat2(receiveFecha(item.fechaFinIntervencion)),
                    label__fechaInicioIntervencion: receiveFechaFormat2(receiveFecha(item.fechaInicioIntervencion)),
                    label__fechaRegistro: receiveFechaFormat2(receiveFecha(item.fechaRegistro)),
                    label__fechaUltimaModificacion: receiveFechaFormat2(receiveFecha(item.fechaUltimaModificacion)),
                    identidad: item.identidad || '',
                    idpersonaregistro: item.idpersonaregistro || '',
                    idtecnicoasociado: item.idtecnicoasociado || '',
                    idticket: item.idticket || '',
                    producto: item.producto || '',
                    ticket: item.ticket || '',
                    tipoIncidencia: item.tipoIncidencia || '',
                    totalminutosgestion: item.totalminutosgestion || '',
                    urgencia: item.urgencia || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },
}

const TicketClientMensajeAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idofertaempleo || '',
                    nombreoferta: item.nombreoferta || '',
                    idpais: item.idpais || '',
                    idpersona: item.idpersona || '',
                    identidad: item.identidad || '',
                    pub_nom_emp: parseString(parseBoolean(item.pub_nom_emp)),
                    observaciones: item.observaciones || '',
                    idtipocontrato: item.idtipocontrato || '',
                    idestadooferta: item.idestadooferta || '',
                    categoriaoferta: item.categoriaoferta || '',
                    numerovacantes: item.numerovacantes || '',
                    idsalario: item.idsalario || '',
                    fpublicacioninicio: item.fpublicacioninicio || '',
                    fpublicacionfin: item.fpublicacionfin || '',
                    idhorario: item.idhorario || '',
                    oferta: item.oferta || '',
                    funcionesoferta: item.funcionesoferta || '',
                    nivelidioma: item.nivelidioma || [],
                    tipo_estudio: item.tipo_estudio || [],
                    funcion: item.funcion || [],
                    nivelidiomacompleto: item.idioma || [],
                    tipoestudiocompleto: item.tipo_estudio?.map((item2: any) => {
                        return {
                            idtipoestudio: item2.idtipoestudio,
                            idtipoestudioespecialidad: item2?.idtipoestudioespecialidad
                        }
                    }) || [],
                    encargadooferta: item.encargadooferta || {},
                    entidad: item.entidad || {},
                    logica_buscar_idioma: item.logica_buscar_idioma || '',
                    logica_buscar_interes: item.logica_buscar_interes || '',
                    logica_buscar_experiencia: item.logica_buscar_experiencia || '',
                    logica_buscar_tipoestudio: item.logica_buscar_tipoestudio || '',
                    logica_buscar_tipoestudioespecialidad: item.logica_buscar_tipoestudioespecialidad || '',
                    idadjunto: item.idadjunto || '',
                    tituloadjunto: item.tituloadjunto || '',
                    pathadjunto: item.pathadjunto || '',

                    path_image: item.path_image || '',
                    id_image: item.id_image || '',

                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idticket || '',
                    idticket: item.idticket || '',
                    tipopersonaremitente: item.tipopersonaremitente || '',
                    asunto: item.asunto || '',
                    descripcion: item.descripcion || '',
                    identificadorUnicoMail: item.identificadorUnicoMail || '',
                    remitente: item.remitente || '',
                    mailremitente: item.mailremitente || '',
                    listadestinatarios: item.listadestinatarios || '',
                    observacionestecnico: item.observacionestecnico || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = data

                return items
            }
        },
    },

    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    nombreoferta: item.nombreoferta || '',
                    idpais: item.idpais || '',
                    idpersona: item.idpersona || '',
                    identidad: item.identidad || '',
                    pub_nom_emp: parseBoolean(item.pub_nom_emp),
                    observaciones: item.observaciones || '',
                    idtipocontrato: item.idtipocontrato || '',
                    idestadooferta: item.idestadooferta || '',
                    categoriaoferta: item.categoriaoferta || '',
                    numerovacantes: item.numerovacantes || '',
                    idsalario: item.idsalario || '',
                    fpublicacioninicio: item.fpublicacioninicio || '',
                    fpublicacionfin: item.fpublicacionfin || '',
                    idhorario: item.idhorario || '',
                    oferta: item.oferta || '',
                    funcionesoferta: item.funcionesoferta || '',
                    ticket: item.ticket || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },

}

export {
    TicketsClientAdapters,
    TicketClientAdapters,
    TicketsClientMensajesAdapters,
    TicketClientMensajeAdapters
}
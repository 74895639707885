import React from 'react'
import { Form } from 'react-bootstrap'

interface IMsgInputServer {
    name? : any, 
    fetchData? : any, 
    className? : string, 
    setErrorServer? : any, 
    errorServer? : any, 
    adapter? : string, 
    dynamic? : string
}

export const MsgInputServer = React.memo(({ name, fetchData, className = '', setErrorServer = (() => null), errorServer, adapter, dynamic } : IMsgInputServer) => {

    let errors = null

    if (fetchData?.error?.response?.data?.data?.errors?.[name]) {
        setErrorServer(true)
        errors = fetchData?.error?.response?.data?.data?.errors?.[name].map((elem : any, index : number) => {
            return (
                <Form.Text key={index} className={`text-danger d-block ${className}`}>{elem}</Form.Text>
            )
        })
    }

    if (fetchData?.error?.data?.errors?.[name]) {
        setErrorServer(true)
        errors = fetchData?.error?.data?.errors?.[name].map((elem : any, index : number) => {
            return (
                <Form.Text key={index} className={`text-danger d-block ${className}`}>{elem}</Form.Text>
            )
        })
    }

    return (
        <>
            {errors}
        </>
    )
})

import React from "react";
import { Placeholder } from "../Placeholder/Placeholder";

export const LoaderTable = () => {
    return (
        <>
            <Placeholder className='py-2' />
            <Placeholder className='py-3' height='2rem' />
            <Placeholder className='py-3' height='2rem' />
            <Placeholder className='py-3' height='2rem' />
            <Placeholder className='py-3' height='2rem' />
            <Placeholder className='py-3' height='2rem' />
        </>
    );
};

import { useLocation } from 'react-router-dom';
import logo from '../../assetsTheme/images/logo.png'
import { TicketPublicServices } from './controllers/ticketspublic.service';
import { TicketForm } from './TicketForm';
import queryString from 'query-string';
import { useEffect } from 'react';
import { LoaderFormPrimary } from '../../components/Loaders/LoaderFormPrimary';
import { Alert } from 'react-bootstrap';

const Ticket = () => {

  const location = useLocation()

  const { token } = queryString.parse(location.search);

  const [fetchTicketPublicGET, fetchTicketPublicGETData] = TicketPublicServices.GET()

  useEffect(() => {

    fetchTicketPublicGET({
      params: {
        token
      }
    })

  }, []);

  const [fetchTicketMensajePOST, fetchTicketMensajePOSTData] = TicketPublicServices.POST()

  const handleSubmitTicketMensajePOST = (body: any) => {

    fetchTicketMensajePOST({
      params: {
        token,
        _method: 'PATCH'
      },
      body
    })
  }

  return (
    <section className="PageLogin align-items-start justify-content-center">

      <div className='w-100'>

        <div className="container py-3">

          <div className="row">
            <div className="col-md-6">

              <img src={logo} alt="logo" style={{ maxHeight: '50px', maxWidth: '130px' }} className='d-block me-auto' />

            </div>
            <div className="col-md-6 d-flex justify-content-end align-items-end">

              <div>
                {
                  fetchTicketPublicGETData.data &&
                  <>
                    {fetchTicketPublicGETData.data?.nombre_destinatario_link} ({fetchTicketPublicGETData.data?.email_destinatario_link})
                  </>
                }
              </div>
            </div>
          </div>

        </div>

        <div className="container border-top pt-2">

          {
            fetchTicketPublicGETData.loading ?
              <LoaderFormPrimary />
              :
              <>
                {
                  fetchTicketPublicGETData.error ?
                    <Alert variant='info' className='py-1 text-center mt-4'>
                      {fetchTicketPublicGETData?.error.message || 'Error'}
                    </Alert>
                    :
                    <TicketForm
                      fetchData={fetchTicketPublicGETData.data}
                      handleSubmitTicketMensajePOST={handleSubmitTicketMensajePOST}
                      fetchTicketMensajePOSTData={fetchTicketMensajePOSTData}
                      token={token}
                    />
                }
              </>
          }

        </div>
      </div>

    </section>
  )
}

export default Ticket 
import { Form } from 'react-bootstrap'
import { InputText } from '../../../../../../../components/Inputs'
import { InputSelect } from '../../../../../../../components/Inputs/InputSelect'
import { getSelectByKeys } from '../../../../../../../helpers/helpers'
import { useLang } from '../../../../../../../language'
import { EstadosEventoServices } from '../../../../../../../services/statics.service'

export const FilterFormHead = ({ formValuesSearch, handleInputChangeSearch, showFilter, fetchParamsMultipleData }: any) => {

    const Lang = useLang()

    return (
        <>
            <Form.Group className="col-8">
                <InputText
                    label={Lang('NOMBRE_OFERTA') + ':'}
                    name='nombreoferta'
                    value={formValuesSearch.nombreoferta}
                    size='sm'
                    onChange={handleInputChangeSearch}
                />
            </Form.Group>

        </>
    )
}

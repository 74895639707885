import { faEdit, faFile, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { Badge, Button, ListGroup } from 'react-bootstrap'
import { useLang } from '../../language'

import './ListFiles.css'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

export const ListFiles = ({ list, onClickEdit, onClickDelete, isBlob = true, 
    withToken = false, inline = false, size = 'small' }: any) => {

    const Lang = useLang()

    const { auth } = useSelector((state: RootState) => state.auth)

    // const [fetchDocumento, fetchDocumentoData] = useAxios({ 'get', `/dapcons/${idDapcon}/form/doc-adjuntos-obligatorios/obtener-documento`, '', 'blob'})
    const [fetchDocumentoData, setFetchDocumentoData] = useState<any>({ data: null, loading: null, error: null, status: null });

    const isMounted = useRef(true)

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])

    const handleFetchFile = (url: any) => {

        setFetchDocumentoData({ data: null, loading: true, error: null, status: null })

        let config: any = { responseType: 'blob' };

        if (withToken) {

            config = {
                ...config,
                headers: { Authorization: `Bearer ${auth.token}` }
            }
        }

        axios.get(url, config).then((response: any) => {
            setFetchDocumentoData({
                loading: false,
                error: null,
                data: response,
                status: response,
            });
        });
    }

    // useEffect(() => {
    //   ;

    // }, [fetchDocumentoData])


    useEffect(() => {
        if (fetchDocumentoData.data) {

            // 2. Create blob link to download
            const docData = fetchDocumentoData.data

            const filename = docData?.headers?.['content-disposition']?.split('filename=')?.[1]
            const blobData = docData?.data

            const instanceBlob = new Blob([blobData])

            const date = new Date()

            const url = window.URL.createObjectURL(instanceBlob);
            const link: any = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}_${filename}`);
            // 3. Append to html page
            document.body.appendChild(link);

            // 4. Force download
            link.click();

            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);

            setFetchDocumentoData({ data: null, loading: true, error: null, status: null })

        }
    }, [fetchDocumentoData])

    return (
        <ListGroup as="ol" className={`ListFiles ${size === 'small' ? 'ListFiles--small' : ''} ${inline ? 'ListFilesInline flex-wrap' : ''}`}>
            {
                list?.map((item: any, index: number) => {
                    return (
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                            key={index}
                        >
                            <div className='flex-grow-1'>
                                <div className='d-flex'>
                                    {
                                        isBlob ?
                                            <div className="fw-bold cursor-pointer hover-text-decoration" onClick={() => handleFetchFile(item.url)}>
                                                <FontAwesomeIcon icon={faFile} className='me-2' />
                                                {item.titulo || Lang('FICHERO_ADJUNTO')}
                                            </div>
                                            :
                                            <a className="text-dark text-decoration-none fw-bold cursor-pointer hover-text-decoration" href={item.url} target='_blank' rel="noreferrer">
                                                <FontAwesomeIcon icon={faFile} className='me-2' />
                                                {item.titulo || Lang('FICHERO_ADJUNTO')}
                                            </a>
                                    }

                                    <small className='ms-auto'>
                                        {
                                            item.etiqueta && <Badge bg="warning" pill text="dark">
                                                {item.etiqueta}
                                            </Badge>
                                        }

                                    </small>
                                </div>

                                {item.descripcion && <small className='mt-2'>{item.descripcion}</small>}

                            </div>
                            <div className='ps-3'>
                                {
                                    onClickEdit && <Button variant='outline-secondary' size='sm' onClick={(e: any) => onClickEdit(null, item)}>
                                        <FontAwesomeIcon icon={faEdit} className='' />
                                    </Button>
                                }

                                {
                                    onClickDelete && <Button variant='outline-danger' size='sm' onClick={(e: any) => onClickDelete(null, item)}>
                                        <FontAwesomeIcon icon={faTrash} className='' />
                                    </Button>
                                }

                            </div>
                        </ListGroup.Item>
                    )
                })
            }


        </ListGroup>
    )
}


const ParametrosAdapters = {

    GET: {

        receive: {

            schema: function () { },

            adapter: function (data: any) {

                const dataTemp = data?.data

                let resEnd: any = {}

                for (const key in dataTemp) {
                    resEnd[key] = Object.keys(dataTemp[key]).map((key2: any) => {
                        return {
                            id: key2,
                            nombre: dataTemp[key][key2],
                        }
                    })
                }

                return {
                    data: Object.keys(resEnd)?.length === 1 ? resEnd[Object.keys(resEnd)[0]] : resEnd,
                    status: data?.status
                }
            }
        },
    },
}

export {
    ParametrosAdapters
}
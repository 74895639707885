import { useAxios } from "../../../../../../../../hooks/useAxios"
import { EstadoOfertaAdapters, EstadosOfertaAdapters } from "./estadooferta.adapter"

const EstadosOfertaServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/admin/estadooferta`, type: 'json', adapters: EstadosOfertaAdapters.GET })
    }
}

const EstadoOfertaServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/`, type: 'json', adapters: EstadoOfertaAdapters.GET })
    },

    POST: function () {

        return useAxios({ method: 'post', url: `/bolsadetrabajo/admin/estadooferta`, type: 'json', adapters: EstadoOfertaAdapters.POST })
    },

    PATCH: function (id: string) {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/admin/estadooferta/${id}`, type: 'json', adapters: EstadoOfertaAdapters.PATCH })
    },

    DELETE: function (id: string) {

        return useAxios({ method: 'delete', url: `/bolsadetrabajo/admin/estadooferta/${id}`, type: 'json', adapters: EstadoOfertaAdapters.DELETE })
    }
}

export {
    EstadosOfertaServices,
    EstadoOfertaServices
}
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react'
import { Button, Form, Table } from 'react-bootstrap';
import { ButtonSpinner } from '../../../../../components/ButtonSpinner/ButtonSpinner';
import { OverlayLoader } from '../../../../../components/OverlayLoader/OverlayLoader';
import { Placeholder } from '../../../../../components/Placeholder/Placeholder';
import { useForm } from '../../../../../hooks/useForm';
import { useLang } from '../../../../../language';
import { convertParamToObject } from '../../../../../services/helpers';
import { ParametrosServices } from '../../../../../services/parametros.service';
import { PersonaDatosGeneralesAdapters } from '../../../../administracion/pages/Personas/adapters/personas.adapter';
import { misActividadesProfesionalesServices } from '../../controllers/services/actividadesProfesionales.service';
import { ModalMiActProfesional } from './ModalMiActProfesional';

interface IState {
  data: any,
  loading: any,
  error: any,
  status: any
}

const Index = () => {

  const [fetchMisActividadesProfesionalesGET, fetchMisActividadesProfesionalesGETData, resetFetchMisActividadesProfesionalesGET
  ] = misActividadesProfesionalesServices.GET()

  useEffect(() => {

    fetchMisActividadesProfesionalesGET()

  }, [])

  // Parametros

  const [fetchActProfesionalesParam, fetchActProfesionalesParamData] = ParametrosServices.GET()

  useEffect(() => {

    fetchActProfesionalesParam({
      params: {
        actividadesprofesionales: true
      }
    })

  }, [])

  useEffect(() => {
    if (fetchActProfesionalesParamData.status === 200) {

      setactividadesProfParams(convertParamToObject(fetchActProfesionalesParamData.data))
    }
  }, [fetchActProfesionalesParamData])


  const Lang = useLang()

  const [formPersonaDatosGenerales, handleInputPersonaDatosGenerales, handleFormPersonaDatosGenerales
  ] = useForm({
    ...PersonaDatosGeneralesAdapters.GET.receive.schema({}),
  })


  // -------------------- PATCH Persona Entidad

  const [fetchMiActProfesionalPATCH, fetchMiActProfesionalPATCHData] = misActividadesProfesionalesServices.PATCH()

  const handleSubmitEditar = (item: any) => {

    fetchMiActProfesionalPATCH({
      id: item.id + '/actualizar',
      body: item
    })
  }

  useEffect(() => {
    if (fetchMiActProfesionalPATCHData.status === 200) {
      fetchMisActividadesProfesionalesGET()
      // handleCloseModal_MiActProfesional()
    }
  }, [fetchMiActProfesionalPATCHData])


  // -------------- DELETE

  const [activeId, setactiveId] = useState('')

  const [fetchMiActProfesionalDELETE, fetchMiActProfesionalDELETEData] = misActividadesProfesionalesServices.DELETE()

  const handleSubmitDELETE = (item: any) => {

    setactiveId(item.id)

    fetchMiActProfesionalDELETE({
      id: item.id + '/eliminar',
      body: item
    })
  }

  useEffect(() => {
    if (fetchMiActProfesionalDELETEData.status === 200) {
      fetchMisActividadesProfesionalesGET()
      setactiveId('')
    }
  }, [fetchMiActProfesionalDELETEData])


  const [actividadesProfParams, setactividadesProfParams] = useState<any>({})


  // Crear nueva colegiatura

  const [showModal_MiActProfesional, setShowModal_MiActProfesional] = useState({
    value: false,
    payload: {}
  });

  const handleCloseModal_MiActProfesional = () => setShowModal_MiActProfesional({
    value: false,
    payload: {}
  });

  const handleShowModal_MiActProfesional = (payload: object) => setShowModal_MiActProfesional({
    value: true,
    payload
  });


  // Funciones helpers

  const handleChangeCheck = (e: any, item: any, index: number) => {

    // let listaModulosTemp = cloneDeep(formPersonaEntidad[name])

    // if (e.target.checked) {

    //   listaModulosTemp.push(e.target.name)

    // } else {

    //   listaModulosTemp = listaModulosTemp.filter((item: any) => item !== e.target.name)
    // }

    // handleFormPersonaEntidad({
    //   ...formPersonaEntidad,
    //   [name]: listaModulosTemp
    // })

    // fetchMisActividadesProfesionalesGETData.data.items[index].publicaweb = e.target.checked

    handleSubmitEditar({
      ...item,
      publicaweb: e.target.checked
    })
  }

  return (
    <div className='row my-3'>

      {/* <div className="col-12">
        <h5 className='h6 mb-3 mt-2 border-bottom pb-3'>{Lang('GUIA_DE_PROFESIONALES')}</h5>
      </div> */}

      <div className="col-12">

        <div className="row">

          <div className="col-12 text-end mb-3">
            <Button onClick={() => handleShowModal_MiActProfesional({})} size='sm'>
              <FontAwesomeIcon icon={faPlus} className='me-2' />{Lang('NUEVA_ACTIVIDAD_PROFESIONAL')}
            </Button>
          </div>

          <div className="col-12">
            <Table responsive="sm" className='position-relative'>
              <thead>
                <tr>
                  <th>{Lang('ACTIVIDAD_PROFESIONAL')}</th>
                  <th className='text-center'>{Lang('PUBLICAR_EN_WEB')}</th>
                  <th className='text-center'>{Lang('ACCIONES')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  fetchMisActividadesProfesionalesGETData.loading ?
                    <>
                      <tr style={{ 'borderWidth': 0 }} className='pe-none'>
                        <td colSpan={20}><Placeholder className='px-4 py-2' height='1.2rem' /></td>
                      </tr>
                      <tr style={{ 'borderWidth': 0 }} className='pe-none'>
                        <td colSpan={20}><Placeholder className='px-4 py-2 delay-1' height='1.2rem' /></td>
                      </tr>
                      <tr style={{ 'borderWidth': 0 }} className='pe-none'>
                        <td colSpan={20}><Placeholder className='px-4 py-2 delay-2' height='1.2rem' /></td>
                      </tr>
                      <tr style={{ 'borderWidth': 0 }} className='pe-none'>
                        <td colSpan={20}><Placeholder className='px-4 py-2 delay-3' height='1.2rem' /></td>
                      </tr>
                      <tr style={{ 'borderWidth': 0 }} className='pe-none'>
                        <td colSpan={20}><Placeholder className='px-4 py-2 delay-4' height='1.2rem' /></td>
                      </tr>
                    </> :
                    <>

                      {
                        fetchMisActividadesProfesionalesGETData.data?.items?.map((item: any, index: number) => {
                          
                          return (
                            <tr>
                              <td>
                                {actividadesProfParams[item.idperitacion]}
                              </td>
                              <td className='text-center'>

                                <Form.Check
                                  type='checkbox'
                                  id={'publicaweb' + '_' + item.nombre}
                                  label={item.nombre}
                                  name={item.id}
                                  onChange={(e: any) => handleChangeCheck(e, item, index)}
                                  autoComplete='one-time-code'
                                  defaultChecked={item.publicaweb?.length > 0}
                                />

                              </td>

                              <td className='text-center'>
                                <ButtonSpinner
                                  variant="danger" type="submit" className="ms-2" size='sm'
                                  handleSubmit={() => handleSubmitDELETE(item)} fetchDataLoading={[activeId === item.id && fetchMiActProfesionalDELETEData.loading]}
                                  icon={<FontAwesomeIcon icon={faTrash} />}
                                />
                              </td>
                            </tr>
                          )
                        })
                      }
                      {
                        fetchMisActividadesProfesionalesGETData.data?.items?.length === 0 &&
                        <tr>
                          <td colSpan={20} className='text-center py-5 bg-gray-1'>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</td>
                        </tr>
                      }

                    </>
                }

              </tbody>

              {
                fetchMiActProfesionalPATCHData.loading &&
                <OverlayLoader />
              }
            </Table>

          </div>

          {/* <div className="col-12 text-center mt-3">
            <ButtonSpinner
              variant="secondary" type="submit" className=""
              // handleSubmit={handleSubmitEliminar} fetchDataLoading={[fetchRelacionDELETEData.loading]}
              value={Lang('GUARDAR_CAMBIOS')} icon={<FontAwesomeIcon icon={faSave} />}
            />
          </div> */}
        </div>
      </div>


      <ModalMiActProfesional
        showModal_MiActProfesional={showModal_MiActProfesional}
        handleCloseModal_MiActProfesional={handleCloseModal_MiActProfesional}
        fetchActProfesionalesParamData={fetchActProfesionalesParamData}
        fetchSuccess={fetchMisActividadesProfesionalesGET}
      />

    </div>
  )
}

export default Index;
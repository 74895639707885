import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../../../assetsTheme/images/icon.png'
import { useDispatch, useSelector } from 'react-redux'
import { logOut } from '../../../store/slices/auth';
import { RootState } from '../../../store';
import { useState } from 'react';
import { DropdownUser } from '../DropdownUser';
import { ModalCambioContrasenia } from '../ModalCambioContrasenia';

interface IBody {
    children: React.ReactNode;
    setshowNavLeft?: (boolean: boolean) => boolean,
    showNavLeft?: boolean,
    label?: string
}

const Body = ({ children, setshowNavLeft = (() => false), showNavLeft, label = '' }: IBody) => {

    const dispatch = useDispatch()

    const { auth } = useSelector((state: RootState) => state.auth)



    // Modal Cambiar credenciales

    const [showCambiarPassword, setShowCambiarPassword] = useState({
        value: false,
        payload: null
    });

    const handleCloseCambiarPassword = () => setShowCambiarPassword({
        value: false,
        payload: null
    })

    const handleShowCambiarPassword = (payload: any) => setShowCambiarPassword({
        value: true,
        payload
    });


    return (
        <section className="LayoutMain__contentright">
            <nav className='LayoutMain__navtop d-flex justify-content-between align-items-center'>
                <span className='d-none d-md-inline-block fw-normal '>
                    {label}
                </span>
                <div className="d-flex align-items-center d-md-none pointer" onClick={() => setshowNavLeft(!showNavLeft)}>
                    <FontAwesomeIcon icon={faBars} className='p-2' style={{ 'fontSize': '1.3rem' }} />
                    <img src={logo} alt="" height='35px' className='ms-1' />
                </div>

                <div className="d-flex align-items-center" id=''>
                    <DropdownUser
                        auth={auth} 
                        dispatch={dispatch} 
                        logOut={logOut} 
                        handleShowCambiarPassword={handleShowCambiarPassword} 
                    />

                </div>
            </nav>
            <main className='LayoutMain__contentmain'>
                {children}
            </main>

            <ModalCambioContrasenia
                showCambiarPassword={showCambiarPassword} 
                handleCloseCambiarPassword={handleCloseCambiarPassword}
            />
        </section>
    )
}

export default Body
import { useLang } from "../language"

const EstadoCobroServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'P': Lang('PENDIENTE'),
            'C': Lang('COBRADO'),
            'I': Lang('IMPAGADO'),
        }
    }
}

const EstadoPagoServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'P': Lang('PENDIENTE'),
            'C': Lang('PAGADO'),
            'I': Lang('IMPAGADO'),
        }
    }
}

const TipoPersonaServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'colegiado': Lang('COLEGIADO'),
            'colegiado_externo': Lang('COLEGIADO_EXTERNO'),
            'precolegiado': Lang('PRECOLEGIADO'),
            'no_colegiado': Lang('NO_COLEGIADO'),
            'todos': Lang('TODOS'),
        }
    }
}

const EstadoPersonaServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'vigente': Lang('VIGENTE'),
            'baja': Lang('BAJA'),
            'todos': Lang('TODOS'),
        }
    }
}

const EstadoEntidadServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'activos': Lang('VIGENTE'),
            'baja': Lang('BAJA'),
            'todos': Lang('TODOS'),
        }
    }
}

const AbonaServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'E': Lang('ENTIDAD'),
            'P': Lang('PERSONA'),
        }
    }
}

const EstadoFacturaServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'pendiente': Lang('PENDIENTES'),
            'cobrada': Lang('COBRADAS'),
            // 'I': 'Impagades',
        }
    }
}

const TipoFacturaServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'factura': Lang('FACTURAS'),
            'proforma': Lang('PROFORMAS'),
            'abono': Lang('ABONO'),
            // '1': 'Rectficatius',
            // '3': 'Rectificades',
        }
    }
}

const TipoAsociadoServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'C': Lang('COLEGIADO'),
            'P': Lang('PRECOLEGIADO'),
            'E': Lang('COLEGIADO_EXTERNO'),
            'N': Lang('NO_ASOCIADO'),
        }
    }
}

const ClasesSeguroServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'RC': Lang('RESPONSABILIDAD_CIVIL'),
            'OT': Lang('OTROS'),
        }
    }
}

const TipoColegiadoServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'C': Lang('COLEGIADO'),
            'P': Lang('PRECOLEGIADO'),
            'E': Lang('COLEGIADO_EXTERNO'),
        }
    }
}

const EstadoCivilServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'C': Lang('CASADOA'),
            'S': Lang('SOLTEROA'),
            'D': Lang('DIVORCIADOA'),
            'V': Lang('VIUDOA'),
            'H': Lang('CONVIVIENTE'),
            'L': Lang('SEPARADOA'),
        }
    }
}

const EstadosVisadoServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'a': Lang('ANULADO'),
            'b': Lang('BORRADOR'),
            'i': Lang('EN_INCIDENCIA'),
            'r': Lang('EN_REVISION'),
            'v': Lang('VISADO'),
        }
    }
}

const EstadosVisadoHistoricoServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'v': Lang('VISADO'),
            'a': Lang('ANULADO'),
        }
    }
}

const TiposDocumentoVisadoServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'dv': Lang('DOCUMENTO_A_SELLAR'),
            'sa': Lang('DOCUMENTO_DE_ADMINISTRACION'),
            'o': Lang('OTROS'),
        }
    }
}

const TiposDocumentoTerceroServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'N': 'NIF/NIE',
            'P': Lang('PASAPORTE'),
            'C': `CIF`,
            'E': Lang('CIF_EXTRANJERO'),
        }
    }
}

const TiposSolicitudVisadoServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'o': 'On-line',
            'd': Lang('SOPORTE_DIGITAL'),
            'p': Lang('SOPORTE_PAPEL'),
        }
    }
}

const TiposComunicacionesServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'B': Lang('BOLETIN'),
            'E': Lang('EMAIL'),
        }
    }
}

const TiposServiciosLineaServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'visado': Lang('VISADO'),
            'inscripcion': Lang('INSCRIPCIONES'),
            'slam': 'SLAM',
        }
    }
}

const EstadosEventoServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'borrador': Lang('BORRADOR'),
            'anulado': Lang('ANULADO'),
            'publicado': Lang('PUBLICADO'),
            'tramite': Lang('TRAMITE'),
            'finalizado': Lang('FINALIZADO'),
        }
    }
}

const TiposTarifaAplicadaAServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'colegiado': Lang('COLEGIADO'),
            'precolegiado': Lang('PRECOLEGIADO'),
            'externo': Lang('EXTERNO'),
            'general': Lang('GENERAL'),
            'visador': Lang('VISADOR'),
        }
    }
}

const EstadosInscripcionServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'borrador': Lang('BORRADOR'),
            'listaespera': Lang('LISTA_DE_ESPERA'),
            'pendientepago': Lang('PENDIENTE_DE_PAGO'),
            'anulada': Lang('ANULADA'),
            'inscripcion': Lang('INSCRIPCION'),
        }
    }
}

const TiposGeneracionFacturaServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'L': Lang('LINEAS_DE_FACTURA'),
            'F': Lang('FACTURA'),
        }
    }
}

const TiposCondicionPagoServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'efectivo': Lang('EFECTIVO'),
            'transferencia': Lang('TRANSFERENCIA'),
            'remesa': Lang('REMESA'),
        }
    }
}

const TiposSeccionesPaginaServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'Carousel': Lang('CAROUSEL'),
            'Formacion': Lang('FORMACION'),
            'Galeria': Lang('GALERIA'),
            'Mixto': Lang('MIXTO'),
            'Noticia': Lang('NOTICIA'),
            'Patrocinadores': Lang('PATROCINADORES'),
        }
    }
}

const EstadoListadoServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'activos': Lang('VIGENTE'),
            'baja': Lang('BAJA'),
            'todos': Lang('TODOS'),
        }
    }
}

const TiposServicioServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'V': Lang('VENTA'),
            'C': Lang('COMPRA'),
        }
    }
}

const TiposCuentaContableEntidadServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'entidad_cliente': Lang('ENTIDAD_CLIENTE'),
            'entidad_proveedor': Lang('ENTIDAD_PROVEEDOR'),
        }
    }
}

const TiposCuentaContablePersonaServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'persona_cliente': Lang('PERSONA_CLIENTE'),
            'persona_proveedor': Lang('PERSONA_PROVEEDOR'),
        }
    }
}

const ExportadoContabilidadServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'exportados': Lang('EXPORTADOS'),
            'noexportados': Lang('NO_EXPORTADOS'),
        }
    }
}

const EstadosAlquilerServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'pendiente_pago': Lang('PENDIENTE_DE_PAGO'),
            'pendiente_recojo': Lang('PENDIENTE_DE_RECOJO'),
            'pendiente_entrega': Lang('PENDIENTE_DE_ENTREGA'),
            'anulado': Lang('ANULADO'),
            'finalizado': Lang('FINALIZADO'),
        }
    }
}

const EstadosStatusServices = {

    GET: function () {

        const Lang = useLang()

        return {
            'activos': Lang('ACTIVOS'),
            'baja': Lang('BAJA'),
            'todos': Lang('TODOS'),
        }
    }
}

export {
    EstadoCobroServices,
    TipoPersonaServices,
    EstadoPersonaServices,
    AbonaServices,
    EstadoFacturaServices,
    TipoFacturaServices,
    TipoAsociadoServices,
    ClasesSeguroServices,
    EstadoEntidadServices,
    TipoColegiadoServices,
    EstadoCivilServices,
    EstadosVisadoServices,
    TiposDocumentoVisadoServices,
    TiposDocumentoTerceroServices,
    TiposSolicitudVisadoServices,
    EstadosVisadoHistoricoServices,
    TiposComunicacionesServices,
    TiposServiciosLineaServices,
    EstadosEventoServices,
    TiposTarifaAplicadaAServices,
    EstadosInscripcionServices,
    TiposGeneracionFacturaServices,
    TiposCondicionPagoServices,
    TiposSeccionesPaginaServices,
    EstadoListadoServices,
    TiposServicioServices,
    TiposCuentaContablePersonaServices,
    TiposCuentaContableEntidadServices,
    ExportadoContabilidadServices,
    EstadoPagoServices,
    EstadosAlquilerServices,
    EstadosStatusServices,
}
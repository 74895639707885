import { useNavigate } from "react-router-dom";
import { useLang } from "../../../../../../../language";
import { useModalData } from "../../../../../../../hooks/useModalData";
import { PersonaColegiadoBTServices } from "../../controllers/personacolegiadobt.service";
import { cloneDeep, groupBy } from "lodash";
import { Form, Table } from "react-bootstrap";
import { useEffect, useState } from "react";

export const TableFunciones = ({ data, loading, total, perPage, handleFormChange, formValuesSearch, fetchPersonaBTGET,
    fetchPersonaDatosProfesionalesGET, idofertaempleo, urlParams, fetchCondicionPagosRemesasGETData, fetchMultipleParamBolsaData }: any) => {

    

    const navigate = useNavigate();

    const Lang = useLang()

    const [dataModal_NivelIdioma, handleShowModal_NivelIdioma, handleCloseModal_NivelIdioma] = useModalData()


    const [fetchPersonaEntidadPATCH, fetchPersonaEntidadPATCHData] = PersonaColegiadoBTServices.PATCH_FUNCIONES()

    // Funciones helpers

    const handleChangeCheck = (e: any, name: any, index: number) => {

        const dataTemp: any = cloneDeep(dataAllMatch)        

        dataTemp[index][name] = e.target.checked

        fetchPersonaEntidadPATCH({
            body: {
                funciones: dataTemp
            }
        })

        setdataAllMatch(dataTemp)
    }

    // Inicializando valores de params a funcion

    const [dataAllMatch, setdataAllMatch] = useState([]);

    useEffect(() => {
        if (fetchMultipleParamBolsaData.status && data) {

            const dataKey: any = groupBy(data, 'idfuncion')

            const dataParamsAdapted = fetchMultipleParamBolsaData?.data?.funciones?.map((item: any) => {
                return {
                    idfuncion: item.id,
                    nombre: item.nombre,
                    experiencia: false,
                    interes: false,
                }
            })

            const dataParamsAdaptedComplete = dataParamsAdapted?.map((item: any) => {
                return {
                    ...item,
                    experiencia: dataKey[item.idfuncion]?.[0]?.experiencia || false,
                    interes: dataKey[item.idfuncion]?.[0]?.interes || false,
                }
            })

            setdataAllMatch(dataParamsAdaptedComplete)

        }
    }, [fetchMultipleParamBolsaData, data]);


    return (
        <div className='SeccionFunciones'>
            <div className="d-flex flex-wrap justify-content-between align-items-start mb-3">
                <h5 className="mb-3 mb-lg-0 ">{Lang('FUNCIONES')}</h5>
            </div>

            <div className="row">
                <div className="col-lg-6">

                    <Table className='position-relative'>
                        <thead>
                            <tr>
                                <th>
                                    <small>{Lang('FUNCION')}</small>
                                </th>
                                <th>
                                    <small>{Lang('EXPERIENCIA')}</small>
                                </th>
                                <th>
                                    <small>{Lang('INTERES')}</small>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                <>
                                    {
                                        dataAllMatch?.slice(0, dataAllMatch?.length / 2)?.map((item: any, index: number) => {

                                            return (
                                                <tr key={item.idfuncion}>
                                                    <td className=''>
                                                        <small>{item['nombre']}</small>
                                                    </td>

                                                    <td className='text-center text-nowrap shrink-td' valign='middle'>
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={'publicaweb' + '_' + item.nombre}
                                                            label=''
                                                            name={item.idfuncion}
                                                            onChange={(e: any) => handleChangeCheck(e, 'experiencia', index)}
                                                            autoComplete='one-time-code'
                                                            defaultChecked={item['experiencia']}
                                                            className='d-inline-block'
                                                        />
                                                    </td>

                                                    <td className='text-center text-nowrap shrink-td' valign='middle'>
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={'publicaweb' + '_' + item.nombre}
                                                            label=''
                                                            name={item.idfuncion}
                                                            onChange={(e: any) => handleChangeCheck(e, 'interes', index)}
                                                            autoComplete='one-time-code'
                                                            className='d-inline-block'
                                                            defaultChecked={item['interes']}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {/* {
                                        Object.keys(fetchMultipleParamBolsaData?.data?.funciones)?.length === 0 &&
                                        <tr>
                                            <td colSpan={20} className='text-center py-5 bg-gray-1'>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</td>
                                        </tr>
                                    } */}
                                </>
                            }

                        </tbody>

                    </Table>

                </div>


                <div className="col-lg-6">

                    <Table className='position-relative'>
                        <thead>
                            <tr>
                                <th>
                                    <small>{Lang('FUNCION')}</small>
                                </th>
                                <th>
                                    <small>{Lang('EXPERIENCIA')}</small>
                                </th>
                                <th>
                                    <small>{Lang('INTERES')}</small>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                <>
                                    {
                                        dataAllMatch?.slice(dataAllMatch?.length / 2)?.map((item: any, index: number) => {

                                            return (
                                                <tr key={item.idfuncion}>
                                                    <td className=''>
                                                        <small>{item['nombre']}</small>
                                                    </td>
                                                    <td className='text-center text-nowrap shrink-td' valign='middle'>
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={'publicaweb' + '_' + item.nombre}
                                                            label=''
                                                            name={item.idfuncion}
                                                            onChange={(e: any) => handleChangeCheck(e, 'experiencia', (dataAllMatch?.length / 2) + index)}
                                                            autoComplete='one-time-code'
                                                            className='d-inline-block'
                                                            defaultChecked={item['experiencia']}
                                                        />
                                                    </td>

                                                    <td className='text-center text-nowrap shrink-td' valign='middle'>
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={'publicaweb' + '_' + item.nombre}
                                                            label=''
                                                            name={item.idfuncion}
                                                            onChange={(e: any) => handleChangeCheck(e, 'interes', (dataAllMatch?.length / 2) + index)}
                                                            autoComplete='one-time-code'
                                                            className='d-inline-block'
                                                            defaultChecked={item['interes']}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {/* {
                                        Object.keys(fetchMultipleParamBolsaData?.data?.funciones)?.length === 0 &&
                                        <tr>
                                            <td colSpan={20} className='text-center py-5 bg-gray-1'>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</td>
                                        </tr>
                                    } */}
                                </>
                            }

                        </tbody>

                    </Table>

                </div>
            </div>

            {/* <ModalFuncion
                data={dataModal_NivelIdioma}
                handleClose={handleCloseModal_NivelIdioma}
                fetchPersonaDatosProfesionalesGET={fetchPersonaDatosProfesionalesGET}
                urlParams={urlParams}
                fetchCondicionPagosRemesasGETData={fetchCondicionPagosRemesasGETData}
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                fetchPersonaBTGET={fetchPersonaBTGET}
            /> */}

        </div>
    )
}

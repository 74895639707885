
import { useAxios } from '../../../../../../../../hooks/useAxios';
import { InscripcionAdminAdapters, InscripcionesAdminAdapters } from '../adapters/inscripciones.adapter';

const InscripcionesAdminServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/admin/inscripciones/ofertasempleo`, type: 'json', adapters: InscripcionesAdminAdapters.GET })
    },

    GET_EXCEL: function () {

        return useAxios({ method: 'get', url: `/inscripciones-bolsa/inscripciones`, type: 'blob', adapters: InscripcionesAdminAdapters.GET_EXCEL })
    },

    POST: function () {

        return useAxios({ method: 'post', url: `/bolsadetrabajo/admin/inscripciones/ofertasempleo`, type: 'json', adapters: InscripcionesAdminAdapters.POST })
    },

    PATCH: function () {

        return useAxios({ method: 'post', url: `/bolsadetrabajo/admin/inscripciones/ofertasempleo`, type: 'json', adapters: InscripcionesAdminAdapters.PATCH })
    },

    DELETE: function () {

        return useAxios({ method: 'delete', url: `/bolsadetrabajo/admin/inscripciones/ofertaempleo`, type: 'json', adapters: InscripcionesAdminAdapters.DELETE })
    },

    POST_ENVIAR_EMAILS_MASIVOS: function (idofertaempleo: string) {

        return useAxios({ method: 'post', url: `/bolsadetrabajo/admin/ofertasempleo/${idofertaempleo}/notificaciones/colegiados`, type: 'json', adapters: InscripcionesAdminAdapters.POST_ENVIAR_EMAILS_MASIVOS })
    },

    POST_ENVIAR_EMAILS_MASIVOS_ENCARGADO: function (idofertaempleo: string) {

        return useAxios({ method: 'post', url: `/bolsadetrabajo/admin/ofertasempleo/${idofertaempleo}/notificaciones/encargados`, type: 'json', adapters: InscripcionesAdminAdapters.POST_ENVIAR_EMAILS_MASIVOS_ENCARGADO })
    }
}

const InscripcionServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/inscripciones-bolsa/inscripciones`, type: 'json', adapters: InscripcionAdminAdapters.GET })
    },
    
    POST: function () {

        return useAxios({ method: 'post', url: `/inscripciones-bolsa/inscripcion-admin`, type: 'json', adapters: InscripcionAdminAdapters.POST })
    },

    PATCH: function (idinscripcion: any) {

        return useAxios({ method: 'patch', url: `/inscripciones-bolsa/inscripcion-admin/${idinscripcion}`, type: 'json', adapters: InscripcionAdminAdapters.PATCH })
    },

    DELETE: function (idinscripcion: string) {

        return useAxios({ method: 'delete', url: `/inscripciones-bolsa/inscripcion-admin/${idinscripcion}`, type: 'json', adapters: InscripcionesAdminAdapters.DELETE })
    }
}

export {
    InscripcionesAdminServices,
    InscripcionServices,
}
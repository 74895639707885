import { Pagination } from 'react-bootstrap';
import './PaginationT.scss'

interface IPaginationT{
    total: any,
    handleFormChange: any,
    formValuesSearch: any,
    perPage: any,
}

export const PaginationT = ({ total, handleFormChange, formValuesSearch, perPage }: IPaginationT) => {

    let totalPages = Math.ceil(total / perPage)

    const currentPage = Number(formValuesSearch.page)

    let pageArray: any = [];

    if (totalPages > 1) {
        if (totalPages <= 9) {
            var i = 1;
            while (i <= totalPages) {
                pageArray.push(i);
                i++;
            }
        } else {

            if (currentPage <= 5) {

                pageArray = [1, 2, 3, 4, 5, 6, 7, 8, "", totalPages];

            } else if (totalPages - currentPage <= 4) {

                pageArray = [
                    1,
                    "",
                    totalPages - 7,
                    totalPages - 6,
                    totalPages - 5,
                    totalPages - 4,
                    totalPages - 3,
                    totalPages - 2,
                    totalPages - 1,
                    totalPages
                ];

            } else {

                pageArray = [
                    1,
                    "",
                    currentPage - 3,
                    currentPage - 2,
                    currentPage - 1,
                    currentPage,
                    currentPage + 1,
                    currentPage + 2,
                    currentPage + 3,
                    "",
                    totalPages
                ];
            }
        }
    }

    return (
        <div className="d-flex justify-content-center py-3">
            <Pagination className='mb-0 overflow-auto'>
                {pageArray.map((ele:any, ind:number) => {
                    const toReturn = [];

                    if (ind === 0) {
                        toReturn.push(
                            <Pagination.First
                                key={"firstpage"}
                                onClick={
                                    currentPage === 1
                                        ? () => { }
                                        : () => {
                                            handleFormChange({
                                                ...formValuesSearch,
                                                page: 1
                                            });
                                            // history.push({
                                            //     search: getQueryStringSearchCollegiats({
                                            //         ...formValuesSearch,
                                            //         page: 1
                                            //     }),
                                            // });
                                        }
                                }
                            />
                        );

                        toReturn.push(
                            <Pagination.Prev
                                key={"prevpage"}
                                onClick={
                                    currentPage === 1
                                        ? () => { }
                                        : () => {
                                            handleFormChange({
                                                ...formValuesSearch,
                                                page: currentPage - 1
                                            });
                                            // history.push({
                                            //     search: getQueryStringSearchCollegiats({
                                            //         ...formValuesSearch,
                                            //         page: currentPage - 1
                                            //     }),
                                            // });
                                        }
                                }
                            />
                        );
                    }

                    if (ele === "") toReturn.push(<Pagination.Ellipsis key={ind} />);
                    else
                        toReturn.push(
                            <Pagination.Item
                                key={ind}
                                active={currentPage === ele ? true : false}
                                onClick={
                                    currentPage === ele
                                        ? () => { }
                                        : () => {
                                            handleFormChange({
                                                ...formValuesSearch,
                                                page: ele
                                            });
                                            // history.push({
                                            //     search: getQueryStringSearchCollegiats({
                                            //         ...formValuesSearch,
                                            //         page: ele
                                            //     }),
                                            // });
                                        }
                                }
                            >
                                {ele}
                            </Pagination.Item>
                        );

                    if (ind === pageArray.length - 1) {
                        toReturn.push(
                            <Pagination.Next
                                key={"nextpage"}
                                onClick={
                                    currentPage === ele
                                        ? () => { }
                                        : () => {
                                            handleFormChange({
                                                ...formValuesSearch,
                                                page: currentPage + 1
                                            });
                                            // history.push({
                                            //     search: getQueryStringSearchCollegiats({
                                            //         ...formValuesSearch,
                                            //         page: currentPage + 1
                                            //     }),
                                            // });
                                        }
                                }
                            />
                        );

                        toReturn.push(
                            <Pagination.Last
                                key={"lastpage"}
                                onClick={
                                    currentPage === ele
                                        ? () => { }
                                        : () => {
                                            handleFormChange({
                                                ...formValuesSearch,
                                                page: ele
                                            });
                                            // history.push({
                                            //     search: getQueryStringSearchCollegiats({
                                            //         ...formValuesSearch,
                                            //         page: ele
                                            //     }),
                                            // });
                                        }
                                }
                            />
                        );
                    }

                    return toReturn;
                })}
            </Pagination>
        </div>
    )
}

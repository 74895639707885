import { useEffect, useState } from 'react'
import { useLang } from '../../../../../../../language'
import { useForm } from '../../../../../../../hooks/useForm'
import { useValidator } from '../../../../../../../hooks/useValidator'
import { getValuesParams, isStatusSucess } from '../../../../../../../helpers/helpers'
import { ModalStatusServer } from '../../../../../../../components/Modals/ModalStatusServer'
import { InformacionAdicionalServices } from '../controllers/services/informacionAdicional.service'

import { InformacionAdicionalAdapters } from '../controllers/adapters/informacionAdicional.adapter'
import { TableInscripciones } from './TableInscripciones'
import FilterAdvanced from '../../../../../../../components/Filter/FilterAdvanced/FilterAdvanced'
import { Form } from 'react-bootstrap'
import { InputText } from '../../../../../../../components/Inputs'
import { ButtonSpinner } from '../../../../../../../components/ButtonSpinner/ButtonSpinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { InputSelect } from '../../../../../../../components/Inputs/InputSelect'
import { InscripcionesAdminServices } from '../controllers/services/inscripciones.service'
import { ParametrosServices } from '../../../../../../../services/parametros.service'
import WizardEmail from './WizardEmail'

export const Inscripciones = ({ formData = {}, idofertaempleo, fetchInscripcionEventoGETData }: any) => {

    const Lang = useLang()

    const [fetchMultipleParamBolsa, fetchMultipleParamBolsaData] = ParametrosServices.GET_BOLSA_TRABAJO()

    useEffect(() => {

        fetchMultipleParamBolsa({
            params: {
                estadopersona: true,
                horarios: true,
                salarios: true,
                tiposContratos: true,
                order: 'asc',
            }
        })

    }, [])
    
    const [fetchInscripcionesGET, fetchInscripcionesGETData] = InscripcionesAdminServices.GET()

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
        page: 1,
        limit: '',
        magicbolsa: '',
        order: '',
        idofertaempleo: idofertaempleo,
        estado: '',
        interes: '',
        notificarempresa: '',
        notificado: '',
    });

    const handleSearch = (e: any) => {

        if (e) {
            e.preventDefault();
        }

        // Obtener todos los valores existentes en el form de values Search
        const urlParams = getValuesParams(formValuesSearch)

        if (formValuesSearch.page !== 1) {

            handleFormChange({
                ...formValuesSearch,
                page: 1
            });

        } else {

            let formValuesSearchTemp = { ...formValuesSearch }

            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

            fetchInscripcionesGET({
                params: {
                    ...urlParams
                }
            })
        }
    }

    useEffect(() => {

        // Obtener todos los valores existentes en el form de values Search
        const urlParams = getValuesParams(formValuesSearch)

        fetchInscripcionesGET({
            params: {
                ...urlParams
            }
        })


    }, [formValuesSearch.page, formValuesSearch.limit, formValuesSearch.order])


    const [excluidos, setExcluidos] = useState([])
    const [incluidos, setIncluidos] = useState([])

    const [checkAll, setCheckAll] = useState(false)


    const [formDatosGenerales, handleInputDatosGenerales, handleFormDatosGenerales, resetDatosGenerales] = useForm({
        ...InformacionAdicionalAdapters.GET.receive.schema({}),
    })

    const [valuesValidationEvento, handleValidationEvento, handleResetValidationEvento] = useValidator({
        descripcion: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
    })


    useEffect(() => {

        if (isStatusSucess(fetchInscripcionesGETData.status)) {

            handleFormDatosGenerales({
                ...fetchInscripcionesGETData.data
            })
        }
    }, [fetchInscripcionesGETData])


    // // --------------- PATCH Evento

    const [fetchInscripcionPATCH, fetchInscripcionPATCHData] = InformacionAdicionalServices.PATCH()

    const handleSubmitEditar = () => {

        if (handleValidationEvento(formDatosGenerales)) {
            fetchInscripcionPATCH({
                id: formDatosGenerales.id,
                body: {
                    ...formDatosGenerales,
                }
            })
        }

        // resetDatosGenerales()
    }

    useEffect(() => {

        if (isStatusSucess(fetchInscripcionPATCHData.status)) {

            resetDatosGenerales()

            fetchInscripcionesGET()
        }
    }, [fetchInscripcionPATCHData])


    const handleEditorChange = (name: string, content: any) => {

        const target = {
            name,
            value: content,
        }

        handleInputDatosGenerales({ target })
    }

    const [dataExcluidos, setdataExcluidos] = useState([])

    const [activeChecks, setactiveChecks] = useState(false)

    const unselectAllItems = () => {
        setCheckAll(false)
        setIncluidos([])
        setExcluidos([])
    }

  const [fetchEnviarEmailPOST, fetchEnviarEmailPOSTData] = InscripcionesAdminServices.POST_ENVIAR_EMAILS_MASIVOS(idofertaempleo)

    return (
        <div>
            <div className="row mt-3 mb-3">

                <div className="col-12 mb-4">
                    <FilterAdvanced
                        handleSearch={handleSearch}
                        formValuesSearch={formValuesSearch}
                        handleInputChangeSearch={handleInputChangeSearch}
                        fetchData={fetchInscripcionesGETData}
                        resetformValuesSearch={resetformValuesSearch}
                        simple={true}
                    >

                        <FilterAdvanced.Head label={Lang('BUSCADOR')}>

                        </FilterAdvanced.Head>

                        <FilterAdvanced.Body>

                            <>
                                <Form.Group className="mb-3 col">
                                    <InputText
                                        label={`${Lang('CADENA')}:`}
                                        name='magicbolsa'
                                        size='sm'
                                        value={formValuesSearch.magicbolsa}
                                        onChange={handleInputChangeSearch}
                                    />
                                </Form.Group>

                                <Form.Group className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
                                    <InputSelect
                                        label={Lang('ESTADO') + ':'}
                                        name='estado'
                                        value={formValuesSearch.estado}
                                        onChange={handleInputChangeSearch}
                                        size='sm'
                                        values={fetchMultipleParamBolsaData.data?.['estadopersona'] || []}
                                    />
                                </Form.Group>

                                <Form.Group className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
                                    <InputSelect
                                        label={Lang('INTERESADO')}
                                        name='interes'
                                        value={formValuesSearch.interes}
                                        onChange={handleInputChangeSearch}
                                        size='sm'
                                        values={[
                                            { id: true, nombre: 'Si'},
                                            { id: false, nombre: 'No'},
                                        ]}
                                    />
                                </Form.Group>

                                <Form.Group className="col">
                                    <InputSelect
                                        label={Lang('VISIBLE_EMPRESA')}
                                        name='notificarempresa'
                                        value={formValuesSearch.notificarempresa}
                                        onChange={handleInputChangeSearch}
                                        size='sm'
                                        values={[
                                            { id: true, nombre: 'Si'},
                                            { id: false, nombre: 'No'},
                                        ]}
                                    />
                                </Form.Group>

                                <Form.Group className="col">
                                    <InputSelect
                                        label={Lang('NOTIFICADO')}
                                        name='notificado'
                                        value={formValuesSearch.notificado}
                                        onChange={handleInputChangeSearch}
                                        size='sm'
                                        values={[
                                            { id: true, nombre: 'Si'},
                                            { id: false, nombre: 'No'},
                                        ]}
                                    />
                                </Form.Group>

                                <div className="col ms-auto flex-grow-0 text-nowrap mb-3">
                                    <div className="d-flex align-items-end justify-content-end h-100">
                                        {/* <ButtonSpinner
                                            variant="outline-secondary" type="submit" className="me-3" size='sm'
                                            handleSubmit={resetformValuesSearch} fetchDataLoading={[false]}
                                            value={Lang('LIMPIAR')} icon={<FontAwesomeIcon icon={faBroom} />}
                                        /> */}
                                        <ButtonSpinner
                                            variant="secondary" type="submit" size='sm' className=""
                                            handleSubmit={handleSearch} fetchDataLoading={[fetchInscripcionesGETData.loading]}
                                            value={Lang('BUSCAR')} icon={<FontAwesomeIcon icon={faSearch} className='me-1' />}
                                        />
                                    </div>

                                </div>

                            </>

                        </FilterAdvanced.Body>

                    </FilterAdvanced>
                </div>

                <div className="col-12">
                    <div className="row justify-content-center">
                        {
                            activeChecks && <div className="col-12 mb-4 col-md-10 col-lg-8 col-xl-8 animate__animated animate__fadeInUp animate__faster">
                                <WizardEmail
                                    excluidos={excluidos}
                                    setExcluidos={setExcluidos}
                                    incluidos={incluidos}
                                    setIncluidos={setIncluidos}
                                    checkAll={checkAll}
                                    setCheckAll={setCheckAll}
                                    urlParams={getValuesParams(formValuesSearch)}
                                    fetch={fetchEnviarEmailPOST}
                                    fetchData={fetchEnviarEmailPOSTData}
                                    onlyEmail={true}
                                />
                            </div>
                        }
                    </div>
                </div>

                <div className="col-12">
                    <TableInscripciones
                        data={fetchInscripcionesGETData.data?.items}
                        loading={fetchInscripcionesGETData.loading}
                        total={fetchInscripcionesGETData.data?.total}
                        perPage={fetchInscripcionesGETData.data?.per_page}

                        formValuesSearch={formValuesSearch}
                        handleFormChange={handleFormChange}
                        handleInputChangeSearch={handleInputChangeSearch}
                        setdataExcluidos={setdataExcluidos}

                        checkAll={checkAll}
                        setCheckAll={setCheckAll}
                        setIncluidos={setIncluidos}
                        setExcluidos={setExcluidos}
                        excluidos={excluidos}
                        incluidos={incluidos}
                        urlParams={getValuesParams(formValuesSearch)}
                        fetchInscripcionesGET={fetchInscripcionesGET}
                        idofertaempleo={idofertaempleo}

                        // fetchTarifasGETData={fetchTarifasGETData}
                        estado={fetchInscripcionEventoGETData?.data?.estado}
                        isGratuito={fetchInscripcionEventoGETData?.data?.gratuito}

                        activeChecks={activeChecks}
                        setactiveChecks={setactiveChecks}
                        fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                    />

                </div>

            </div>

            <ModalStatusServer fetchData={fetchInscripcionPATCHData} />

        </div>
    )
}

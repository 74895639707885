import { faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { ButtonSpinner } from '../../ButtonSpinner/ButtonSpinner'
import { InputText } from '../../Inputs'
import TableAdvanced from '../../Tables/TableAdvanced/TableAdvanced'
import { getValuesParams } from '../../../helpers/helpers'
import { useForm } from '../../../hooks/useForm'
import { useLang } from '../../../language'
import { PersonaEntidadesServices } from '../../../modules/administracion/pages/Personas/Persona/SeccionDatosProfesionales/SeccionEntidades/services/datosprofesionales.persona.services'

export const FilterPersonaEntidad = ({ showModal_Filtro, handleCloseModal_Filtro, setFilter, params = {} }: any) => {

    const Lang = useLang()

    const [fetchPersonaEntidadGET, fetchPersonaEntidadGETData] = PersonaEntidadesServices.GET()

    const [formValuesSearch2, handleInputChangeSearch2, handleFormChange2, resetformValuesSearch2] = useForm({
        page: 1,
        limit: '',
        ...params
    });

    useEffect(() => {
        handleFormChange2({
            ...formValuesSearch2,
            ...params
        })
    }, [params])

    const handleSearch: any = (e: any) => {

        if (e) {
            e.preventDefault();
        }

        if (formValuesSearch2.page !== 1) {

            handleFormChange2({
                ...formValuesSearch2,
                page: 1
            });

        } else {

            let formValuesSearch2Temp = { ...formValuesSearch2 }

            Object.keys(formValuesSearch2Temp).forEach((k) => formValuesSearch2Temp[k] === '' && delete formValuesSearch2Temp[k]);

            fetchPersonaEntidadGET({
                params: {
                    ...formValuesSearch2Temp
                }
            })
        }
    }

    useEffect(() => {
        if (showModal_Filtro.value) {

            // Obtener todos los valores existentes en el form de values Search
            const urlParams = getValuesParams(formValuesSearch2)

            fetchPersonaEntidadGET({
                params: {
                    ...urlParams
                }
            })

        }
    }, [formValuesSearch2.page, formValuesSearch2.order_by, formValuesSearch2.limit])

    const allActions: any = []

    const allActionsPerLine = [
        {
            label: <FontAwesomeIcon icon={faArrowRight} className='' />,
            action: (item: any) => {

                setFilter({
                    id: item.identidad,
                    nombre: item.nombreentidad,
                    sede: item.identidadsede,
                })

                handleCloseModal_Filtro()
            },
            withoutID: true,
        }
    ]

    const handleChangeCheckAll = (e: any) => {

        if (e.target.checked) {

            selectAllItems()

        } else {

            unselectAllItems()
        }
    }

    const headersTable = [
        <small>{Lang('NOMBRE')}</small>,
        <small>CIF/NIF</small>,
        ''
    ]

    const dataLineTable = [
        {
            className: 'max-w-15rem',
            name: 'nombreentidad'
        },
        {
            className: '',
            name: 'persona_nif'
        }
    ]
    
    useEffect(() => {

        if (showModal_Filtro.value && formValuesSearch2.idpersona) {

            const urlParams = getValuesParams(formValuesSearch2)

            fetchPersonaEntidadGET({
                params: {
                    ...urlParams
                }
            })
        }

    }, [showModal_Filtro.value])

    const [excluidos, setExcluidos] = useState([])
    const [incluidos, setIncluidos] = useState([])

    const [checkAll, setCheckAll] = useState(false)

    const selectAllItems = () => {
        setCheckAll(true)
        setIncluidos([])
        setExcluidos([])
    }

    const unselectAllItems = () => {
        setCheckAll(false)
        setIncluidos([])
        setExcluidos([])
    }


    return (
        <div>
            <Modal show={showModal_Filtro.value} onHide={handleCloseModal_Filtro} size='lg' id='ModalRegistro'>
                <Modal.Header className='border-bottom-0 py-4'>
                    <Modal.Title className='h6'></Modal.Title>
                    <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleCloseModal_Filtro}></button>
                </Modal.Header>
                <Modal.Body className='px-4 py-0'>

                    <div className="row">
                        <Form.Group className="mb-3 ms-auto col">
                            <InputText
                                label={Lang('CADENA') + ':'}
                                name='magic'
                                size='sm'
                                value={formValuesSearch2.magic}
                                onChange={handleInputChangeSearch2}
                            />
                        </Form.Group>

                        <div className="col flex-grow-0 text-nowrap mb-3">
                            <div className="d-flex align-items-end justify-content-end h-100">

                                <ButtonSpinner
                                    variant="secondary" type="submit" size='sm' className=""
                                    handleSubmit={handleSearch} fetchDataLoading={[fetchPersonaEntidadGETData.loading]}
                                    value={Lang('BUSCAR')} icon={<FontAwesomeIcon icon={faSearch} className='me-1' />}
                                />
                            </div>

                        </div>
                    </div>

                    <TableAdvanced>

                        <TableAdvanced.Head
                            title={Lang('LISTA_DE_PERSONAS_ENTIDADES')}
                            allActions={allActions}
                        />

                        <TableAdvanced.Body
                            headers={headersTable}
                            dataLineTable={dataLineTable}
                            data={fetchPersonaEntidadGETData.data?.items}
                            isLoading={fetchPersonaEntidadGETData.loading}

                            excluidos={excluidos}
                            incluidos={incluidos}
                            setExcluidos={setExcluidos}
                            setIncluidos={setIncluidos}

                            checkAll={checkAll}
                            allActionsPerLine={allActionsPerLine}
                            checkMultiples={false}
                        />

                        <TableAdvanced.Footer
                            total={fetchPersonaEntidadGETData.data?.total}
                            handleFormChange={handleFormChange2}
                            formValuesSearch={formValuesSearch2}
                            perPage={fetchPersonaEntidadGETData.data?.per_page}
                        />

                    </TableAdvanced>
                </Modal.Body>

            </Modal>



        </div>
    )
}

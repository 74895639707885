import 'bootstrap/dist/css/bootstrap.min.css';

import './assetsTheme/css/global.scss'
import './assetsTheme/css/overwrite.scss'
import './assets/css/utils.scss'

import 'animate.css';

import { ModulesRouter } from './routes/ModulesRouter'
import { BrowserRouter, useLocation, useParams } from 'react-router-dom';

import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash';
import { RootState } from './store';
import { CookiesProvider, useCookies } from 'react-cookie';

function App() {

  console.log(process.env.REACT_APP_ENVIRONMENT)

  const { auth } = useSelector((state: RootState) => state.auth)

  const urlParamToken = (new URLSearchParams(window.location.search)).get('token') || '';
  
  if (process.env.REACT_APP_WITH_LOGIN === 'false') {

    if (isEmpty(auth) && process.env.REACT_APP_ENVIRONMENT !== 'local' && !urlParamToken) {

      const win: Window = window;

      win.location = (process.env.REACT_APP_PUBLIC_URL_PORTAL + '/login.php') || '';

    }
  }

  return (
    <BrowserRouter basename={process.env.REACT_APP_SUBDOMAIN}>

      <CookiesProvider>

        <ModulesRouter />

      </CookiesProvider>

    </BrowserRouter>
  );
}

export default App;

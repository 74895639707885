import { useEffect } from "react";
import { PersonaBTServices } from "../../controllers/personasbt.service";
import { useForm } from "../../../../../../../hooks/useForm";
import { useLang } from "../../../../../../../language";
import { ButtonSpinner } from "../../../../../../../components/ButtonSpinner/ButtonSpinner";
import { InputSelect } from "../../../../../../../components/Inputs/InputSelect";
import { Button, Form } from "react-bootstrap";
import { InputText } from "../../../../../../../components/Inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink, faSave } from "@fortawesome/free-solid-svg-icons";
import { isStatusSucess } from "../../../../../../../helpers/helpers";
import { useNavigate } from "react-router-dom";
import { ParametrosServices } from "../../../../../../../services/parametros.service";
import { Placeholder } from "../../../../../../../components/Placeholder/Placeholder";
import { InputChecks } from "../../../../../../../components/Inputs/InputChecks";
import { cloneDeep } from "lodash";

export const DatosGenerales = ({ idpersona, fetchPersonaBTGETData, fetchPersonaBTGET }: any) => {

    const Lang = useLang()

    const [formDatosGenerales, handleInputDatosGenerales, handleFormDatosGenerales, resetDatosGenerales] = useForm({
        habilitarofertas: [],
        recibirnotificacionesmail: [],
    })


    useEffect(() => {

        if (isStatusSucess(fetchPersonaBTGETData.status)) {

            handleFormDatosGenerales({
                ...fetchPersonaBTGETData.data
            })
        }

    }, [fetchPersonaBTGETData]);


    const navigate = useNavigate()

    const [fetchMultipleParamBolsa, fetchMultipleParamBolsaData] = ParametrosServices.GET_BOLSA_TRABAJO()

    useEffect(() => {

        fetchMultipleParamBolsa({
            params: {
                funciones: true,
                tiposContratos: true,
                estadooferta: true,
                salarios: true,
                horarios: true,
                order: 'asc',
            }
        })

    }, [])


    // Funciones helpers

    const handleChangeCheck = (e: any, name: string) => {

        let listaModulosTemp = cloneDeep(formDatosGenerales[name])

        if (e.target.checked) {

            listaModulosTemp.push(e.target.name)

        } else {

            listaModulosTemp = listaModulosTemp.filter((item: any) => item !== e.target.name)
        }

        handleFormDatosGenerales({
            ...formDatosGenerales,
            [name]: listaModulosTemp
        })
    }

    // --------------- PATCH persona

    const [fetchPersonaBTPATCH, fetchPersonaBTPATCHData] = PersonaBTServices.PATCH(idpersona)

    const handleSubmitPatch = () => {

        fetchPersonaBTPATCH({
            body: formDatosGenerales
        })
    }

    useEffect(() => {

        if (isStatusSucess(fetchPersonaBTPATCHData.status)) {
            
            fetchPersonaBTGET()
        }

    }, [fetchPersonaBTPATCHData]);


    return (
        <>
            <div className='p-3 card mb-4'>
                {
                    fetchPersonaBTGETData.loading ?
                        <LoaderForm />
                        :
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex flex-wrap justify-content-between align-items-center mb-3  pb-3 border-bottom ">
                                        <h5 className='h6 my-0'>{Lang('DATOS_GENERALES')}</h5>
                                        <Button onClick={() => navigate(`/administracion/personas/${idpersona}`)} className="" size='sm'>
                                            Veure des d'administració <FontAwesomeIcon icon={faExternalLink} className='ms-1' />
                                        </Button>
                                    </div>
                                </div>

                            </div>
                            <div className="row">

                                <Form.Group className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <InputText
                                        label={Lang('NOMBRE')}
                                        name='nombre_completo'
                                        value={formDatosGenerales.nombre_completo}
                                        onChange={handleInputDatosGenerales}
                                        readonly
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <InputText
                                        label={Lang('NIF')}
                                        name='nif'
                                        value={formDatosGenerales.nif}
                                        onChange={handleInputDatosGenerales}
                                        readonly
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <InputText
                                        label={Lang('EMAIL')}
                                        name='email'
                                        value={formDatosGenerales.email}
                                        onChange={handleInputDatosGenerales}
                                        readonly
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <InputText
                                        label={Lang('MOVIL')}
                                        name='telefonomovil'
                                        value={formDatosGenerales.telefonomovil}
                                        onChange={handleInputDatosGenerales}
                                        readonly
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                                    <InputSelect
                                        label={`${Lang('TIPO_CONTRATO')}:`}
                                        name='idtipocontrato'
                                        value={formDatosGenerales.idtipocontrato}
                                        values={fetchMultipleParamBolsaData.data?.['tiposContratos'] || []}
                                        onChange={handleInputDatosGenerales}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-12 col-sm-6 col-md-5 col-lg-5 col-xl-4">
                                    <InputSelect
                                        label={`${Lang('SALARIO')}:`}
                                        name='idsalario'
                                        value={formDatosGenerales.idsalario}
                                        values={fetchMultipleParamBolsaData.data?.['salarios'] || []}
                                        onChange={handleInputDatosGenerales}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                                    <InputSelect
                                        label={`${Lang('HORARIO')}:`}
                                        name='idhorario'
                                        value={formDatosGenerales.idhorario}
                                        values={fetchMultipleParamBolsaData.data?.['horarios'] || []}
                                        onChange={handleInputDatosGenerales}
                                    />
                                </Form.Group>

                                <div className="col-12"></div>

                                <Form.Group className="my-2 col flex-grow-0 text-nowrap me-2">
                                    <InputChecks
                                        name='habilitarofertas'
                                        value={formDatosGenerales.habilitarofertas}
                                        values={[
                                            {
                                                id: 'habilitarofertas',
                                                nombre: Lang('HABILITAR_OFERTAS')
                                            }
                                        ]}
                                        classNameContainer='d-block me-0 mb-1'
                                        onChange={(e: any) => handleChangeCheck(e, 'habilitarofertas')}
                                    // fetchData={nuevo ? fetchPersonaPOSTData : fetchPerfilesPATCHData}
                                    />
                                </Form.Group>

                                <Form.Group className="my-2 col flex-grow-0 text-nowrap">
                                    <InputChecks
                                        name='recibirnotificacionesmail'
                                        value={formDatosGenerales.recibirnotificacionesmail}
                                        values={[
                                            {
                                                id: 'recibirnotificacionesmail',
                                                nombre: Lang('RECIBIR_NOTIFICACIONES_EMAIL')
                                            }
                                        ]}
                                        classNameContainer='d-block me-0 mb-1'
                                        onChange={(e: any) => handleChangeCheck(e, 'recibirnotificacionesmail')}
                                    // fetchData={nuevo ? fetchPersonaPOSTData : fetchPerfilesPATCHData}
                                    />
                                </Form.Group>

                                <div className='col-12 mt-2 pt-3 d-flex justify-content-between border-top'>
                                    <div></div>
                                    <ButtonSpinner
                                        variant="secondary" type="submit" className="ms-auto"
                                        handleSubmit={handleSubmitPatch} fetchDataLoading={[fetchPersonaBTPATCHData.loading]}
                                        value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                    />
                                </div>
                            </div>
                        </>
                }
            </div>
        </>
    );
};


const LoaderForm = () => {
    return (

        <div className='row'>
            <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <Placeholder className='py-2' height='2rem' />
            </div>
            <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <Placeholder className='py-2' height='2rem' />
            </div>
            <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <Placeholder className='py-2' height='2rem' />
            </div>
            <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <Placeholder className='py-2' height='2rem' />
            </div>
            <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <Placeholder className='py-2' height='2rem' />
            </div>
            <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <Placeholder className='py-2' height='2rem' />
            </div>
            <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <Placeholder className='py-2' height='2rem' />
            </div>
            <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <Placeholder className='py-2' height='2rem' />
            </div>
            <div className="mb-3 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <Placeholder className='py-2' height='2rem' />
            </div>
            <div className='col-12 mt-0 pt-3 d-flex justify-content-end border-top'>
                <Placeholder className='py-0 me-0' height='2rem' width='5rem' />
            </div>
        </div>
    )
}

import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { InputSelect } from "../../../../../../../../components/Inputs/InputSelect";
import { ParametrosServices } from "../../../../../../../../services/parametros.service";
import { useLang } from "../../../../../../../../language";
import { cloneDeep, isArray } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBroom } from "@fortawesome/free-solid-svg-icons";
import { InputChecks } from "../../../../../../../../components/Inputs/InputChecks";

export const BloqueEstudios = ({ fetchMultipleParamBolsaData, formTiposEstudiosLogica, setformTiposEstudiosLogica,
    handleReset_BloquePuestoTrabajo, formTiposEstudios, setformTiposEstudios, fetchEspecialidadesGETData, fetchOfertaGETData }: any) => {

    const Lang = useLang()

    const [ListEspecialidades, setListEspecialidades] = useState<any>({});

    const handleOnChangeEstudios = (e: any, index: number) => {

        const formTiposEstudiosTemp = cloneDeep(formTiposEstudios)

        formTiposEstudiosTemp[index][e.target.name] = e.target.value

        // setformTiposEstudios(formTiposEstudiosTemp)

        let ListEspecialidadesTemp = cloneDeep(ListEspecialidades)

        if (e.target.name === 'idtiposestudios') {

            ListEspecialidadesTemp[index] = fetchEspecialidadesGETData?.data?.items?.filter((item: any) => item?.idtipoestudio === e.target.value)?.map((item2: any) => {
                return {
                    id: item2.id,
                    nombre: item2.especialidad
                }
            })

            formTiposEstudiosTemp[index]['idtiposestudiosespecialidades'] = ''

            setListEspecialidades(ListEspecialidadesTemp)
        }

        renderFilaIdiomas(formTiposEstudiosTemp)


    }

    const renderFilaIdiomas = (list: Array<any>) => {

        let formTiposEstudiosTemp = cloneDeep(list)

        formTiposEstudiosTemp = formTiposEstudiosTemp.filter((item: any) => item.idtiposestudios)

        formTiposEstudiosTemp = [...formTiposEstudiosTemp, { idtiposestudios: '', idtiposestudiosespecialidades: '' }]

        setformTiposEstudios(formTiposEstudiosTemp)
    }


    useEffect(() => {

        if (fetchOfertaGETData?.data && fetchEspecialidadesGETData?.data) {

            const estudiosData = fetchOfertaGETData?.data?.tipoestudiocompleto

            renderFilaIdiomas(estudiosData?.map((item: any) => {
                return {
                    idtiposestudios: item.idtipoestudio,
                    idtiposestudiosespecialidades: item.idtipoestudioespecialidad,
                }
            }))

            let ListEspecialidadesTemp = cloneDeep(ListEspecialidades)

            for (let i = 0; i < estudiosData.length; i++) {

                ListEspecialidadesTemp[i] = fetchEspecialidadesGETData?.data?.items?.filter((item: any) => item?.idtipoestudio === estudiosData[i].idtipoestudio)?.map((item2: any) => {
                    return {
                        id: item2.id,
                        nombre: item2.especialidad
                    }
                })
            }

            setListEspecialidades(ListEspecialidadesTemp)

            setformTiposEstudiosLogica({
                logica_buscar_tipoestudio: fetchOfertaGETData?.data?.logica_buscar_tipoestudio === 'and' ? ['and'] : [],
                logica_buscar_tipoestudioespecialidad: fetchOfertaGETData?.data?.logica_buscar_tipoestudioespecialidad === 'and' ? ['and'] : [],
            })
        }

    }, [fetchOfertaGETData, fetchEspecialidadesGETData]);

    // --------- Logica estudio

    const handleChangeCheck = (e: any, name: string) => {

        let listaTemp = cloneDeep(formTiposEstudiosLogica[name])

        if (e.target.checked) {

            listaTemp.push(e.target.name)

        } else {

            listaTemp = listaTemp.filter((item: any) => item !== e.target.name)
        }

        setformTiposEstudiosLogica({
            ...formTiposEstudiosLogica,
            [name]: listaTemp
        })

    }

    return (
        <>
            <Form className='row'>

                {
                    formTiposEstudios?.map((item: any, index: number) => {
                        return (
                            <>
                                <Form.Group className="mb-3 col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 col-xxl-5">
                                    <InputSelect
                                        label={`${Lang('ESTUDIO')}:`}
                                        name='idtiposestudios'
                                        value={item.idtiposestudios}
                                        values={fetchMultipleParamBolsaData?.data?.['tiposestudios'] || []}
                                        onChange={(e) => handleOnChangeEstudios(e, index)}
                                        size="sm"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 col-xxl-5">
                                    <InputSelect
                                        label={`${Lang('ESPECIALIDAD')}:`}
                                        name='idtiposestudiosespecialidades'
                                        value={item.idtiposestudiosespecialidades}
                                        values={ListEspecialidades?.[index]}
                                        size="sm"
                                        onChange={(e) => handleOnChangeEstudios(e, index)}
                                    />
                                </Form.Group>

                                <div className="col-12"></div>
                            </>
                        )
                    })
                }

            </Form>

            <div className="d-flex justify-content-between align-items-start flex-wrap mt-2 pt-3">
                <Button onClick={handleReset_BloquePuestoTrabajo} size="sm" variant="outline-secondary">
                    <FontAwesomeIcon icon={faBroom} /> {Lang('LIMPIAR_TODO')}
                </Button>

                <div className='mt-1 row justify-content-end'>
                    <Form.Group className="mb-0 col flex-grow-0 text-nowrap">
                        <div className="d-flex">
                            <small>
                                <InputChecks
                                    name='logica_buscar_tipoestudio'
                                    value={formTiposEstudiosLogica.logica_buscar_tipoestudio}
                                    values={[{ id: 'and', nombre: Lang('REQUERIR_TODOS_ESTUDIOS') }]}
                                    classNameContainer='d-inline-block me-0'
                                    onChange={(e: any) => handleChangeCheck(e, 'logica_buscar_tipoestudio')}
                                />
                            </small>

                            <small className='ps-3'>
                                <InputChecks
                                    name='logica_buscar_tipoestudioespecialidad'
                                    value={formTiposEstudiosLogica.logica_buscar_tipoestudioespecialidad}
                                    values={[{ id: 'and', nombre: Lang('REQUERIR_TODAS_ESPECIALIDADES') }]}
                                    classNameContainer='d-inline-block me-0'
                                    onChange={(e: any) => handleChangeCheck(e, 'logica_buscar_tipoestudioespecialidad')}
                                />
                            </small>

                        </div>
                    </Form.Group>
                </div>
            </div>
        </>
    );
};

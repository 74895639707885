import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { LoaderFormParametro } from "./LoaderFormParametro";
import { useForm } from "../../../../../../hooks/useForm";
import { OfertaServices } from "../controllers/ofertas.service";
import { useLang } from "../../../../../../language";
import { isStatusSucess } from "../../../../../../helpers/helpers";

export const ModalFichaOferta = ({ data, handleClose }: any) => {

  const Lang = useLang()

  const [fetchOfertaGET, fetchOfertaGETData] = OfertaServices.GET_FICHA()

  const [formOferta, handleInputOferta, handleFormOferta, resetFormOferta] = useForm({
    idpersona: '',
    interes: [],
    notificarempresa: [],
    preferido: [],
  })

  useEffect(() => {

    if (data.value) {

      fetchOfertaGET({
        id: data?.form?.id
      })

    } else {

      resetFormOferta()
    }

  }, [data.value])

  useEffect(() => {

    if (isStatusSucess(fetchOfertaGETData.status)) {

      handleFormOferta({
        ...fetchOfertaGETData.data
      })
    }


  }, [fetchOfertaGETData]);


  return (
    <>
      <Modal show={data.value} onHide={handleClose} size='lg'>
        <Modal.Header className='border-bottom-0 py-4'>
          <Modal.Title className='h5'>
            {Lang('FICHA_DE_LA_OFERTA')}
          </Modal.Title>
          <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleClose}></button>
        </Modal.Header>
        {
          fetchOfertaGETData?.loading ?
            <LoaderFormParametro />
            :
            <>
              <Modal.Body className='px-4 py-0'>

                <div className="row">
                  <div className="col-12 text-center">

                    <h4 className="h5 mb-3">
                      {formOferta.nombreoferta}
                    </h4>

                  </div>

                  <div className="col-12">

                    <div className="content" dangerouslySetInnerHTML={{ __html: formOferta.oferta }}></div>
                  </div>


                  <div className="col-12">

                    <div className="content" dangerouslySetInnerHTML={{ __html: formOferta.funcionesoferta }}></div>
                  </div>

                  <div className="col-lg-4 mb-3">
                    <p className="mb-2">
                      <strong>{Lang('TIPO_CONTRATO')}:</strong>
                    </p>
                    {formOferta.tipo_contrato?.nombre}
                  </div>

                  <div className="col-lg-4 mb-3">
                    <p className="mb-2">
                      <strong>{Lang('HORARIO')}:</strong>
                    </p>
                    {formOferta.horario?.descripcionhorario}
                  </div>


                  <div className="col-lg-4 mb-3">
                    <p className="mb-2">
                      <strong>{Lang('SALARIO')}:</strong>
                    </p>
                    {formOferta.salario?.cantidad}
                  </div>


                  <div className="col-lg-4 mb-3">
                    <p className="mb-2">
                      <strong>{Lang('IDIOMAS')}:</strong>
                    </p>
                    {
                      formOferta.idioma?.map((item: any) => {
                        return (
                          <li>
                            {item.idioma} ({item.nivelidioma})
                          </li>
                        )
                      })
                    }
                  </div>

                  <div className="col-lg-6 mb-3">
                    <p className="mb-2">
                      <strong>{Lang('ESTUDIOS')}:</strong>
                    </p>
                    {
                      formOferta.tipo_estudio?.map((item: any) => {
                        return (
                          <li>
                            {item.tipoestudio} ({item.especialidad})
                          </li>
                        )
                      })
                    }
                  </div>

                  <div className="col-lg-6 mb-3">
                    <p className="mb-2">
                      <strong>{Lang('FUNCIONES')}:</strong>
                    </p>
                    <small>{Lang('EXPERIENCIA_EN')}:</small>
                    {
                      formOferta.funcion?.filter((itemP: any) => itemP.experiencia)?.map((item: any) => {
                        return (
                          <li>
                            {item.nombre}
                          </li>
                        )
                      })
                    }
                  </div>


                </div>


              </Modal.Body>
              <Modal.Footer className='border-0 d-flex justify-content-end mt-0'>
                {
                  formOferta.interes &&
                  <small>
                    {Lang('YA_ESTA_INSCRITO_OFERTA')}
                  </small>
                }
                <Button
                  onClick={() => window.open(`${process.env.REACT_APP_PUBLIC_URL_PORTAL}/${process.env.REACT_APP_URL_PORTAL_ID_BOLSA}${formOferta.id}`)}
                  className="" size='sm'>
                  {
                    formOferta.interes ? Lang('VER_OFERTA_PUBLICADA') : Lang('IR_A_INSCRIBIRME_OFERTA')
                  }
                  <FontAwesomeIcon icon={faExternalLink} className='ms-1' />
                </Button>
              </Modal.Footer>
            </>

        }
      </Modal>
    </>
  );
};

import { useEffect } from 'react'
import { TableFunciones } from './TableFunciones';
import './SeccionFunciones.scss'

export const SeccionFunciones = ({ idofertaempleo, fetchCondicionPagosRemesasGETData, fetchInscripcionGETData = {},
    fetchMultipleParamBolsaData, fetchInscripcionGET }: any) => {

    return (
        <div>
            <TableFunciones
                data={fetchInscripcionGETData.data?.funcion || []}
                loading={false}
                idofertaempleo={idofertaempleo}
                fetchInscripcionGETData={fetchInscripcionGETData}
                fetchCondicionPagosRemesasGETData={fetchCondicionPagosRemesasGETData}
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                fetchInscripcionGET={fetchInscripcionGET}
                logica_buscar_interes={fetchInscripcionGETData.data?.logica_buscar_interes}
                logica_buscar_experiencia={fetchInscripcionGETData.data?.logica_buscar_experiencia}
            />
        </div>
    )
}

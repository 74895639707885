import { Form } from 'react-bootstrap'
import { MsgInput } from '../MsgInput/MsgInput'
import { MsgInputServer } from '../MsgInput/MsgInputServer'
import { getMsgInputColor, getMsgInputServerColor } from '../../helpers/helpers';

export const InputChecks = ({ className = '', name, values = [], onChange, validation = {},
    fetchData = {}, value, disabled, classNameContainer = '' }: any) => {

    const handleOnchange = (e:any) => {

        if (validation[name])
            validation[name].required.isOk = true

        if (fetchData.data)
            fetchData.data.status = 'Success'

        onChange(e)
    }

    return (
        <>
            {
                values.map((item: any) => {
                    return (
                        <div className={classNameContainer ? classNameContainer : 'd-inline-block me-4'} key={item.id + '_' + item.nombre}>
                            <Form.Check
                                type='checkbox'
                                className={`${className} ${(validation ? getMsgInputColor(validation, name) : false) || getMsgInputServerColor(validation, name) ? 'is-invalid' : ''}`}
                                id={name + '_' + item.nombre}
                                label={item.nombre}
                                name={item.id}
                                onChange={handleOnchange}
                                autoComplete='one-time-code'
                                defaultChecked={item.id === value}
                                checked={value?.find((item2:any) => item2 === item.id)}
                                disabled={disabled}
                            />
                        </div>
                    )
                })
            }
            {
                validation && <MsgInput obj={validation} name={name} />
            }
            <MsgInputServer fetchData={fetchData} name={name} />

        </>
    )
}

import { faThLarge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../../../assetsTheme/images/logo.png'
import { useLang } from '../../../language';

interface INavleft {
    navLinks: Array<any>;
    setshowNavLeft?: (boolean: boolean) => boolean,
    showNavLeft?: boolean,
    label?: string
}

const Navleft = ({ navLinks, setshowNavLeft = (() => false), showNavLeft, label }: INavleft) => {

    const navigate = useNavigate();

    const handleClickNavigateTo = (url: string) => navigate(`/${url}`)

    const Lang = useLang()

    return (
        <div className={`LayoutMain__navleft--container ${showNavLeft ? 'LayoutMain__navleft--active' : ''}`}>
            <section className={`LayoutMain__navleft d-flex flex-column`}>
                <img src={logo} alt="" width={150} />
                <small className='d-md-none fw-normal text-center mb-0 mt-4'>
                    {label}
                </small>
                <nav className='LayoutMain__navlinks mb-auto mt-0 mt-md-3'>
                    {
                        navLinks.map((item: any) => {
                            return (
                                item.component ?
                                    <>
                                        <NavLink
                                            key={item.link}
                                            to={item.link}
                                            onClick={item.onClick}
                                            className='d-flex align-items-center'
                                        >
                                            <div style={{ 'width': '1.5rem' }} className="me-2">
                                                {item.icon}
                                            </div>
                                            <span>{item.title}</span>
                                        </NavLink>

                                    </>
                                    :
                                    <NavLink
                                        key={item.link}
                                        to={item.link}
                                        onClick={() => setshowNavLeft(false)}
                                        className={({ isActive }) =>
                                            isActive ? 'd-flex align-items-center active' : 'd-flex align-items-center'
                                        }
                                    >
                                        <div style={{ 'width': '1.5rem' }} className="me-2">
                                            {item.icon}
                                        </div>
                                        <span>{item.title}</span>
                                    </NavLink>
                            )
                        })
                    }
                </nav>
                <section className='LayoutMain__navmenu text-center' onClick={() => handleClickNavigateTo('')}>
                    <FontAwesomeIcon icon={faThLarge} className='me-2 fs-5' /> {Lang('MENU_PRINCIPAL')}
                </section>
            </section>
            <div id="LayoutMain__navleft--backdrop" onClick={() => setshowNavLeft(!showNavLeft)}></div>
        </div>

    )
}

export default Navleft
import { parseBoolean, parseString, receiveFecha, receiveFechaFormat2, sendMultipart } from "../../../../../../adapters/helpers"

const OfertasAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idofertaempleo || '',
                    nombreoferta: item.nombreoferta || '',
                    horario: item.horario?.descripcionhorario || '',
                    estado_oferta: item.estado_oferta?.nombre || '',
                    entidad: item.entidad?.nombre || '',
                    categoriaoferta: item.categoriaoferta || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },

    GET_EXCEL: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    headers: item.headers
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}
                

                const data = {
                    // filtros: item.filtros || '',
                    // lista_ids: item.lista_ids || '',
                    // lista_exclusion: item.lista_exclusion || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    
    GET_BETWEEN_DATES: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = item

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data
                
                return {
                    data: {
                        items: dataTemp
                    },
                    status: data?.status
                }
            }
        },
    },

    GET_CHECK_DISPONIBILIDAD: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    cupos_disponibles: item.cupos_disponibles || 0,
                    tarifa: item.tarifa || {},
                    condiciones_pago: item.condiciones_pago || [],
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data
                
                return {
                    data: dataTemp,
                    status: data?.status
                }
            }
        },
    },

}

const OfertaAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idofertaempleo || '',
                    nombreoferta: item.nombreoferta || '',
                    idpais: item.idpais || '',
                    idpersona: item.idpersona || '',
                    identidad: item.identidad || '',
                    pub_nom_emp: parseString(parseBoolean(item.pub_nom_emp)),
                    observaciones: item.observaciones || '',
                    idtipocontrato: item.idtipocontrato || '',
                    idestadooferta: item.idestadooferta || '',
                    categoriaoferta: item.categoriaoferta || '',
                    numerovacantes: item.numerovacantes || '',
                    idsalario: item.idsalario || '',
                    fpublicacioninicio: item.fpublicacioninicio || '',
                    fpublicacionfin: item.fpublicacionfin || '',
                    idhorario: item.idhorario || '',
                    oferta: item.oferta || '',
                    funcionesoferta: item.funcionesoferta || '',
                    nivelidioma: item.nivelidioma || [],
                    tipo_estudio: item.tipo_estudio || [],
                    funcion: item.funcion || [],
                    nivelidiomacompleto: item.idioma || [],
                    tipoestudiocompleto: item.tipo_estudio?.map((item2: any) => {
                        return {
                            idtipoestudio: item2.idtipoestudio,
                            idtipoestudioespecialidad: item2?.['pivot']?.idtipoestudioespecialidad
                        }
                    }) || [],
                    encargadooferta: item.encargadooferta || {},
                    entidad: item.entidad || {},
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },
    
    GET_FICHA: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idofertaempleo || '',
                    nombreoferta: item.nombreoferta || '',
                    idpais: item.idpais || '',
                    idpersona: item.idpersona || '',
                    identidad: item.identidad || '',
                    pub_nom_emp: parseString(parseBoolean(item.pub_nom_emp)),
                    observaciones: item.observaciones || '',
                    idtipocontrato: item.idtipocontrato || '',
                    idestadooferta: item.idestadooferta || '',
                    categoriaoferta: item.categoriaoferta || '',
                    numerovacantes: item.numerovacantes || '',
                    idsalario: item.idsalario || '',
                    fpublicacioninicio: item.fpublicacioninicio || '',
                    fpublicacionfin: item.fpublicacionfin || '',
                    idhorario: item.idhorario || '',
                    oferta: item.oferta || '',
                    funcionesoferta: item.funcionesoferta || '',
                    nivelidioma: item.nivelidioma || [],
                    tipo_estudio: item.tipo_estudio || [],
                    funcion: item.funcion || [],
                    nivelidiomacompleto: item.idioma || [],
                    tipoestudiocompleto: item.tipo_estudio?.map((item2: any) => {
                        return {
                            idtipoestudio: item2.idtipoestudio,
                            idtipoestudioespecialidad: item2?.['pivot']?.idtipoestudioespecialidad
                        }
                    }) || [],
                    encargadooferta: item.encargadooferta || {},
                    entidad: item.entidad || {},
                    tipo_contrato: item.tipo_contrato || {},
                    horario: item.horario || {},
                    salario: item.salario || {},
                    idioma: item.idioma || [],
                    interes: item.interes || false,
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },
    
    GET_BUSCAR_PERSONAS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    datos: item.datos || [],
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    nombreoferta: item.nombreoferta || '',
                    idpais: item.idpais || '',
                    idpersona: item.idpersona || '',
                    identidad: item.identidad || '',
                    pub_nom_emp: parseBoolean(item.pub_nom_emp),
                    observaciones: item.observaciones || '',
                    idtipocontrato: item.idtipocontrato || '',
                    idestadooferta: item.idestadooferta || '',
                    categoriaoferta: item.categoriaoferta || '',
                    numerovacantes: item.numerovacantes || '',
                    idsalario: item.idsalario || '',
                    fpublicacioninicio: item.fpublicacioninicio || '',
                    fpublicacionfin: item.fpublicacionfin || '',
                    idhorario: item.idhorario || '',
                    oferta: item.oferta || '',
                    funcionesoferta: item.funcionesoferta || '',
                    // eventoidioma: item.eventoidioma || [],
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    nombreoferta: item.nombreoferta || '',
                    idpais: item.idpais || '',
                    idpersona: item.idpersona || '',
                    identidad: item.identidad || '',
                    pub_nom_emp: parseBoolean(item.pub_nom_emp),
                    observaciones: item.observaciones || '',
                    idtipocontrato: item.idtipocontrato || '',
                    idestadooferta: item.idestadooferta || '',
                    categoriaoferta: item.categoriaoferta || '',
                    numerovacantes: item.numerovacantes || '',
                    idsalario: item.idsalario || '',
                    fpublicacioninicio: item.fpublicacioninicio || '',
                    fpublicacionfin: item.fpublicacionfin || '',
                    idhorario: item.idhorario || '',
                    oferta: item.oferta || '',
                    funcionesoferta: item.funcionesoferta || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH_CHANGE_ESTADO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    estado: item.estado || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },

    PATCH_IMAGEN: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    imagen: item.imagen || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return sendMultipart(items)
            }
        },
    },

    DELETE_IMAGEN: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },
    
    PATCH_NIVEL_IDIOMA: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idiomas: item.idiomas || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH_TIPO_ESTUDIO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    tiposestudios: item.tiposestudios || [],
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH_FUNCION: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    funcion: item.funcion || [],
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

}

export {
    OfertasAdapters,
    OfertaAdapters
}
import { Button, Form } from "react-bootstrap";
import { InputSelect } from "../../../../../../../../components/Inputs/InputSelect";
import { useLang } from "../../../../../../../../language";
import { InputText } from "../../../../../../../../components/Inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBroom } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";

export const BloquePerfilCandidato = ({ formValuesSearch, handleFormChange, fetchMultipleParamBolsaData,
    handleInputChangeSearch, fetchProvinciasParamData, handleReset_BloquePerfilCandidato, fetchOfertaGETData,
    perfilCandidato, setperfilCandidato }: any) => {

    const Lang = useLang()

    const handleChangePerfilCandidato = (e: any) => {

        setperfilCandidato({
            ...perfilCandidato,
            [e.target.name]: e.target.value
        })
    }

    
    useEffect(() => {

        if (fetchOfertaGETData?.data) {

            const ofertaData = fetchOfertaGETData?.data

            // ---- Actualizando Perfil Candidato

            setperfilCandidato({
                idtipocontrato: ofertaData.idtipocontrato,
                idsalario: ofertaData.idsalario,
                idhorario: ofertaData.idhorario,
            })

        }

    }, [fetchOfertaGETData]);


    return (
        <>
            <Form className='row'>

                <div className="col-12"></div>

                <Form.Group className="mb-3 col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                    <InputSelect
                        label={`${Lang('TIPO_CONTRATO')}:`}
                        name='idtipocontrato'
                        value={perfilCandidato.idtipocontrato}
                        values={fetchMultipleParamBolsaData.data?.['tiposContratos'] || []}
                        onChange={handleChangePerfilCandidato}
                        size="sm"
                    />
                </Form.Group>

                <Form.Group className="mb-3 col-12 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                    <InputSelect
                        label={`${Lang('SALARIO')}:`}
                        name='idsalario'
                        value={perfilCandidato.idsalario}
                        values={fetchMultipleParamBolsaData.data?.['salarios'] || []}
                        onChange={handleChangePerfilCandidato}
                        size="sm"
                    />
                </Form.Group>

                <Form.Group className="mb-3 col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                    <InputSelect
                        label={`${Lang('HORARIO')}:`}
                        name='idhorario'
                        value={perfilCandidato.idhorario}
                        values={fetchMultipleParamBolsaData.data?.['horarios'] || []}
                        onChange={handleChangePerfilCandidato}
                        size="sm"
                    />
                </Form.Group>

            </Form>

            <div className="d-flex justify-content-start mt-2 pt-3 border-top">
                <Button onClick={handleReset_BloquePerfilCandidato} size="sm" variant="outline-secondary">
                    <FontAwesomeIcon icon={faBroom} /> {Lang('LIMPIAR_TODO')}
                </Button>
            </div>
        </>
    );
};

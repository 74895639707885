import { faBell, faBook, faCircleCheck, faEnvelope, faFileExcel, faPlus, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { cloneDeep } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { ModalConfirm } from '../../../../../../../components/Modals/ModalConfirm'
import TableAdvanced from '../../../../../../../components/Tables/TableAdvanced/TableAdvanced'
import { getDataFile, getValuesParams } from '../../../../../../../helpers/helpers'
import { useLang } from '../../../../../../../language'
import { ModalAgregarInscripcion } from './ModalAgregarInscripcion'
import { InscripcionesAdminServices } from '../controllers/services/inscripciones.service'
import { ModalEditarInscripcion } from './ModalEditarInscripcion'
import { convertParamToObject } from '../../../../../../../services/helpers'
import { useModalData } from '../../../../../../../hooks/useModalData'
import { ModalFichaPersona } from './ModalFichaPersona'
import { ModalEnvioEmailMasivoOfertaEncargados } from '../../../../../../../components/Modals/ModalEnvioEmailMasivoOfertaEncargados'
import ButtonOrderBy from '../../../../../../../components/ButtonOrderBy/ButtonOrderBy'

export const TableInscripciones = ({ data, loading, total, perPage, handleFormChange, formValuesSearch, setdataExcluidos, handleInputChangeSearch,
    setimportesChangeList, importesChangeList, checkAll, setCheckAll, setIncluidos, setExcluidos, excluidos, fetchMultipleParamBolsaData,
    incluidos, idofertaempleo, fetchInscripcionesGET, fetchTarifasGETData, estado, urlParams, isGratuito, activeChecks, setactiveChecks }: any) => {

    const Lang = useLang()

    const selectAllItems = () => {
        setCheckAll(true)
        setIncluidos([])
        setExcluidos([])
    }

    const unselectAllItems = () => {
        setCheckAll(false)
        setIncluidos([])
        setExcluidos([])
    }

    const allActions = [
        {
            label: <span className='me-2'><FontAwesomeIcon icon={faEnvelope} className='me-2' />{Lang('ENVIAR_EMAIL')}</span>,
            variant: 'outline-secondary',
            action: () => setactiveChecks(!activeChecks),
            withoutID: true,
        },
        {
            label: <span className='me-2'><FontAwesomeIcon icon={faBell} className='me-2' />{Lang('NOTIFICAR_ENCARGADOS')}</span>,
            variant: 'outline-secondary',
            action: () => handleShowModal_NotificarEncargados(),
            withoutID: true,
        },
        {
            label: <span className='me-2'><FontAwesomeIcon icon={faPlus} className='me-2' />{Lang('NUEVA_INSCRIPCION')}</span>,
            variant: 'primary',
            action: () => handleShowModal_Documento({
                action: 'CREATE',
            }),
        },
    ]

    const allActionsPerLine: any = [
        {
            label: <FontAwesomeIcon icon={faUser} className='' />,
            action: (form: any) => handleShowModal_FichaPersona({
                form,
                action: 'EDIT'
            }),
            withoutID: true,
            variant: 'outline-primary'
        },
        {
            label: <FontAwesomeIcon icon={faBook} className='' />,
            action: (form: any) => handleShowModal_EditarInscripcion({
                form,
                action: 'EDIT'
            }),
            withoutID: true
        },
    ]

    const handleChangeCheckAll = (e: any) => {

        if (e.target.checked) {

            selectAllItems()

        } else {

            unselectAllItems()
        }
    }

    const handleChangeInputLineTable = (e: any) => {

        setimportesChangeList({
            ...importesChangeList,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {

        setdataExcluidos(excluidos)

    }, [excluidos])


    const [headersTable, setheadersTable] = useState<any>([])

    useEffect(() => {
        if (activeChecks) {
            setheadersTable([
                <Form.Check type="checkbox" onChange={handleChangeCheckAll} />,
                <small>
                    <ButtonOrderBy
                        label={Lang('NOMBRE')}
                        id='nombrecompleto'
                        className='text-center'
                        handleInputChange={handleInputChangeSearch}
                        formValuesSearch={formValuesSearch}
                    />
                </small>,
                <small>
                    <ButtonOrderBy
                        label={Lang('EMAIL')}
                        id='email'
                        className='text-center'
                        handleInputChange={handleInputChangeSearch}
                        formValuesSearch={formValuesSearch}
                    />
                </small>,
                <small className='text-center d-block'>
                    <ButtonOrderBy
                        label={Lang('ESTADO')}
                        id='idestadopersona'
                        className='text-center'
                        handleInputChange={handleInputChangeSearch}
                        formValuesSearch={formValuesSearch}
                    />
                </small>,
                <small className='text-center d-block'>
                    <ButtonOrderBy
                        label={Lang('FECHA_ESTADO')}
                        id='fechaestado'
                        className='text-center'
                        handleInputChange={handleInputChangeSearch}
                        formValuesSearch={formValuesSearch}
                    />
                </small>,
                <small className='text-center d-block'>{Lang('INTERESADO')}</small>,
                <small className='text-center d-block'>{Lang('VISIBLE_EMPRESA')}</small>,
                <small className='text-center d-block'>{Lang('NOTIFICADO')}</small>,
                ''
            ])
        } else {
            setheadersTable([
                <small>
                    <ButtonOrderBy
                        label={Lang('NOMBRE')}
                        id='nombrecompleto'
                        className='text-center'
                        handleInputChange={handleInputChangeSearch}
                        formValuesSearch={formValuesSearch}
                    />
                </small>,
                <small>
                    <ButtonOrderBy
                        label={Lang('EMAIL')}
                        id='email'
                        className='text-center'
                        handleInputChange={handleInputChangeSearch}
                        formValuesSearch={formValuesSearch}
                    />
                </small>,
                <small className='text-center d-block'>
                    <ButtonOrderBy
                        label={Lang('ESTADO')}
                        id='idestadopersona'
                        className='text-center'
                        handleInputChange={handleInputChangeSearch}
                        formValuesSearch={formValuesSearch}
                    />
                </small>,
                <small className='text-center d-block'>
                    <ButtonOrderBy
                        label={Lang('FECHA_ESTADO')}
                        id='fechaestado'
                        className='text-center'
                        handleInputChange={handleInputChangeSearch}
                        formValuesSearch={formValuesSearch}
                    />
                </small>,
                <small className='text-center d-block'>{Lang('INTERESADO')}</small>,
                <small className='text-center d-block'>{Lang('VISIBLE_EMPRESA')}</small>,
                <small className='text-center d-block'>{Lang('NOTIFICADO')}</small>,
                ''
            ])
        }
    }, [activeChecks, formValuesSearch.order])

    const dataLineTable = [
        {
            name: 'nombrecompleto',
        },
        {
            name: 'email'
        },
        {
            className: 'text-center',
            name: 'idestadopersona',
            adapter: convertParamToObject(fetchMultipleParamBolsaData?.data?.['estadopersona'])
        },
        {
            className: 'text-center',
            name: 'label__fechaestado'
        },
        {
            className: 'text-center',
            name: 'boolean__interes',
            adapter: {
                true: <FontAwesomeIcon icon={faCircleCheck} className='me-1' />,
                false: '',
            }
        },
        {
            className: 'text-center',
            name: 'boolean__notificarempresa',
            adapter: {
                true: <FontAwesomeIcon icon={faCircleCheck} className='me-1' />,
                false: '',
            }
        },
        {
            className: 'text-center',
            name: 'boolean__notificado',
            adapter: {
                true: <FontAwesomeIcon icon={faCircleCheck} className='me-1' />,
                false: '',
            }
        }
    ]

    // Acciones

    const classNameRow = (item: any) => {

        return item['recibirnotificacionesmail'] === false ? 'tr-form-check-none' : ''
    }

    // const [fetchDocumento, fetchDocumentoData] = useAxios({ 'get', `/dapcons/${idDapcon}/form/doc-adjuntos-obligatorios/obtener-documento`, '', 'blob'})
    const [fetchDocumentoData, setFetchDocumentoData] = useState<any>({ data: null, loading: null, error: null, status: null });

    const isMounted = useRef(true)

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])

    const handleFetchFile = (url: any) => {

        setFetchDocumentoData({ data: null, loading: true, error: null, status: null })

        const config: any = { responseType: 'blob' };

        axios.get(url, config).then((response: any) => {
            setFetchDocumentoData({
                loading: false,
                error: null,
                data: response,
                status: response,
            });
        });
    }

    useEffect(() => {
        if (fetchDocumentoData.data) {

            // 2. Create blob link to download
            const docData = fetchDocumentoData.data

            const filename = docData?.headers?.['content-disposition']?.split('filename=')?.[1]
            const blobData = docData?.data

            const instanceBlob = new Blob([blobData])

            const date = new Date()

            const url = window.URL.createObjectURL(instanceBlob);
            const link: any = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}_${filename}`);
            // 3. Append to html page
            document.body.appendChild(link);

            // 4. Force download
            link.click();

            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);

            setFetchDocumentoData({ data: null, loading: true, error: null, status: null })

        }
    }, [fetchDocumentoData])


    // Descargar Excel

    const [showConfirm_Excel, setshowConfirm_Excel] = useState(false)

    const [fetchFacturasGETExcel, fetchFacturasGETExcelData, resetFacturasGETExcelData] = InscripcionesAdminServices.GET_EXCEL()

    useEffect(() => {

        if (fetchFacturasGETExcelData.status === 200) {
            getDataFile(fetchFacturasGETExcelData, resetFacturasGETExcelData, 'Inscripcio', 'xlsx')
            setshowConfirm_Excel(false)
        }
    }, [fetchFacturasGETExcelData])


    const handleClickExcel = () => {

        setshowConfirm_Excel(true)

        let urlParamsTemp: any = getValuesParams(cloneDeep(formValuesSearch))

        delete urlParamsTemp['page']

        fetchFacturasGETExcel({
            params: {
                ...urlParamsTemp,
                to_excel: 1
            }
        })

    }

    // Crear nueva colegiatura

    const [showModal_Documento, setShowModal_Documento] = useState({
        value: false,
        payload: {}
    });

    const handleCloseModal_Documento = () => setShowModal_Documento({
        value: false,
        payload: {}
    });

    const handleShowModal_Documento = (payload: object) => setShowModal_Documento({
        value: true,
        payload
    });


    // Crear editar inscripcion

    const [showModal_EditarInscripcion, setShowModal_EditarInscripcion] = useState({
        value: false,
        payload: {}
    });

    const handleCloseModal_EditarInscripcion = () => setShowModal_EditarInscripcion({
        value: false,
        payload: {}
    });

    const handleShowModal_EditarInscripcion = (payload: object) => setShowModal_EditarInscripcion({
        value: true,
        payload
    });

    const [dataModal_FichaPersona, handleShowModal_FichaPersona, handleCloseModal_FichaPersona] = useModalData()

    const [dataModal_NotificarEncargados, handleShowModal_NotificarEncargados, handleCloseModal_NotificarEncargados] = useModalData()

    const [fetchNotificarEncargadosPOST, fetchNotificarEncargadosPOSTData] = InscripcionesAdminServices.POST_ENVIAR_EMAILS_MASIVOS_ENCARGADO(idofertaempleo)

    return (
        <>

            <TableAdvanced>

                <TableAdvanced.Head
                    title={Lang('INSCRIPCIONES')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    headers={headersTable}
                    dataLineTable={dataLineTable}
                    data={data}
                    isLoading={loading}

                    excluidos={excluidos}
                    incluidos={incluidos}
                    setExcluidos={setExcluidos}
                    setIncluidos={setIncluidos}

                    checkAll={checkAll}
                    allActionsPerLine={allActionsPerLine}
                    checkMultiples={activeChecks}

                    classNameRow={classNameRow}
                />

                <TableAdvanced.Footer
                    total={total}
                    handleFormChange={handleFormChange}
                    formValuesSearch={formValuesSearch}
                    perPage={perPage}
                />

            </TableAdvanced>


            <ModalAgregarInscripcion
                data={showModal_Documento}
                handleClose={handleCloseModal_Documento}
                idofertaempleo={idofertaempleo}
                fetchInscripcionesGET={fetchInscripcionesGET}
                urlParams={urlParams}
            />

            <ModalEditarInscripcion
                data={showModal_EditarInscripcion}
                handleClose={handleCloseModal_EditarInscripcion}
                idofertaempleo={idofertaempleo}
                fetchInscripcionesGET={fetchInscripcionesGET}
                urlParams={urlParams}
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
            />

            <ModalFichaPersona
                data={dataModal_FichaPersona}
                handleClose={handleCloseModal_FichaPersona}
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
            />

            <ModalConfirm
                handleClose={() => setshowConfirm_Excel(false)}
                show={showConfirm_Excel}
                // onClickOK={handleLineaFacturaDELETE}
                messageHTML={
                    <h5 className='text-center fw-normal h5'>{Lang('DESCARGAR_EXCEL')}</h5>
                }
                textOnClickOk={Lang('DESCARGANDO')}
                fetchData={fetchFacturasGETExcelData}
                disableBtnClose
            />

            <ModalEnvioEmailMasivoOfertaEncargados
                data={dataModal_NotificarEncargados}
                handleClose={handleCloseModal_NotificarEncargados}
                fetch={fetchNotificarEncargadosPOST}
                fetchData={fetchNotificarEncargadosPOSTData}
                onlyEmail={true}

            />

        </>
    )
}

import { ParametrosAdapters } from "../adapters/parametros.adapter"
import { useAxios } from "../hooks/useAxios"

const ParametrosServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/maestros/parametros`, type: 'json', adapters: ParametrosAdapters.GET })
    },

    GET_BOLSA_TRABAJO: function () {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/maestrovariables`, type: 'json', adapters: ParametrosAdapters.GET })
    }
}

export {
    ParametrosServices,
}
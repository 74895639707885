import { BreadcrumbLine } from '../../../../../components/BreadcrumbLine/BreadcrumbLine';
import { useLang } from '../../../../../language';
import { DatosGenerales } from '../Inscripciones/Inscripcion/DatosGenerales';

const NuevaOferta = () => {

  const Lang = useLang()

  const BreadcrumbLineItems = [
    {
      title: Lang('NUEVO_EVENTO'),
      link: '/inicio'
    }
  ]

  return (

    <div className="container-fluid">
      <div className="row">

        <div className="col-12">
          <div className='d-flex justify-content-between align-items-center mb-1 Nav-top-DAPcon'>
            <BreadcrumbLine BreadcrumbLineItems={BreadcrumbLineItems} />
          </div>
        </div>

        <div className="col-12">

          <div className="row justify-content-center">

            <div className="col-lg-10 animate__animated animate__fadeInUp">
              <DatosGenerales />
            </div>
            
          </div>

        </div>

      </div>
    </div>
  )
}

export default NuevaOferta;
import React from 'react'
import { Button, Dropdown, DropdownButton, OverlayTrigger, Tooltip } from 'react-bootstrap'

interface ITableAdvancedHead {
    checkAll?: boolean,
    incluidos?: Array<any>,
    title?: string,
    allActions?: any,
    hide?: boolean
}

export const TableAdvancedHead = ({ checkAll, incluidos = [], title, allActions, hide }: ITableAdvancedHead) => {
    return (
        <div className={`d-flex flex-wrap justify-content-between align-items-start mb-3 ${hide ? 'd-none' : ''}`}>
            <h5 className="mb-3 mb-lg-0 ">{title}</h5>
            <div className='d-flex flex-wrap'>
                {
                    allActions?.map((item: any, index: any) => {
                        return (
                            <WrapperTooltip tooltip={item.tooltip} key={index} >
                                {
                                    item.multipleActions ? (
                                        <DropdownButton
                                            key={`lvl2-${index}`}
                                            align="end"
                                            title={item.label}
                                            id="dropdown-menu-align-end"
                                            className='border-0 ms-3'
                                            variant={item.variant ? item.variant : 'success'}
                                            size="sm"
                                            disabled={item.disabled}
                                        >
                                            {
                                                item.multipleActions.map((item2: any, index2: number) => {
                                                    return (
                                                        <Dropdown.Item key={index2} eventKey="1" onClick={item2.action}>{item2.icon} {item2.label}</Dropdown.Item>
                                                    )
                                                })
                                            }
                                        </DropdownButton>
                                    ) : (
                                        <Button key={index} onClick={item.action} size='sm'
                                            className='ms-3' variant={item.variant ? item.variant : 'primary'} disabled={item.disabled}>
                                            {item.label}
                                        </Button>
                                    )
                                }
                            </WrapperTooltip>
                        )
                    })
                }
            </div>

        </div>
    )
}

const WrapperTooltip = ({ children, tooltip }: any) => {

    return (
        <>

            {
                tooltip ?
                    <OverlayTrigger
                        key={tooltip.placement}
                        placement={tooltip.placement}
                        overlay={
                            <Tooltip id={`tooltip-${tooltip.placement}`}>
                                {tooltip.text}
                            </Tooltip>
                        }
                    >
                        <span className="d-inline-block">
                            {children}
                        </span>

                    </OverlayTrigger>
                    :
                    children
            }

        </>
    )
}
import { Button, Spinner } from 'react-bootstrap'

interface IButtonSpinner{
    variant?: string, 
    type?: string, 
    className?: string, 
    handleSubmit?: any, 
    fetchDataLoading?: Array<any>, 
    value?: string, 
    size?: string,
    icon?: any, 
    disabled?: boolean
}

export const ButtonSpinner = ({ variant, type, className = '', handleSubmit, fetchDataLoading = [], 
    value, size, icon = '', disabled = false }: IButtonSpinner) => {

    const isLoading = fetchDataLoading.filter((item) => item === true).length > 0

    const propsSize:any = {}

    if (size) {
        propsSize.size = size
    }

    return (
        <>
            <Button
                variant={variant} type={type} {...propsSize}
                className={className} onClick={handleSubmit} disabled={isLoading || disabled}>
                {
                    isLoading ?
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className='me-1'
                        />
                        : ''
                } {icon} <span>{value}</span>
            </Button>
        </>
    )
}

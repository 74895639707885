import { isEmpty } from 'lodash';
import React, { lazy } from 'react'
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Loader } from '../components/Layouts/LayoutMain2/Loader/Loader';
import { LoaderLayoutMain1 } from '../components/Loaders/LoaderLayoutMain1/LoaderLayoutMain1';
import ModulesRouterBolsaTrabajo from '../modules/bolsa-trabajo/admin/routes/ModulesRouter';
import ModulesRouterBolsaTrabajoCliente from '../modules/bolsa-trabajo/cliente/routes/ModulesRouter';
import ModulesRouterGuiaProfesionales from '../modules/guia-profesionales/cliente/routes/ModulesRouter';
import ModulesRouterInscripcionesCliente from '../modules/inscripciones/cliente/routes/ModulesRouter';

import { Apps } from '../pages/Index/Apps';
import Login from '../pages/Login/Login';
import { RootState } from '../store';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import ModulesRouterMisFacturas from '../modules/facturacion/cliente/routes/ModulesRouter';
import { eliminarCookies } from '../helpers/helpers';
import Ticket from '../pages/Ticket/Ticket';
import ModulesRouterTicketingCliente from '../modules/ticketing/client/routes/ModulesRouter';

// Modulos de administracion

const ModulesRouterFacturacion = lazy(() => import('../modules/facturacion/routes/ModulesRouter').then((module: any) => (
    { default: module.default }
)));

const ModulesRouterGestorContenidos = lazy(() => import('../modules/gestorContenidos/routes/ModulesRouter').then((module: any) => (
    { default: module.default }
)));

const ModulesRouterAdministracion = lazy(() => import('../modules/administracion/routes/ModulesRouter').then((module: any) => (
    { default: module.default }
)));

const ModulesRouterMisDatos = lazy(() => import('../modules/misDatos/routes/ModulesRouter').then((module: any) => (
    { default: module.default }
)));

const ModulesRouterAlquilerAparatos = lazy(() => import('../modules/alquilerAparatos/admin/routes/ModulesRouter').then((module: any) => (
    { default: module.default }
)));

const ModulesRouterVisados = lazy(() => import('../modules/visados/admin/routes/ModulesRouter').then((module: any) => (
    { default: module.default }
)));

const ModulesRouterInscripciones = lazy(() => import('../modules/inscripciones/admin/routes/ModulesRouter').then((module: any) => (
    { default: module.default }
)));

const ModulesRouterTicketing = lazy(() => import('../modules/ticketing/admin/routes/ModulesRouter').then((module: any) => (
    { default: module.default }
)));


// Modulos de cliente

const ModulesRouterAlquilerAparatosCliente = lazy(() => import('../modules/alquilerAparatos/cliente/routes/ModulesRouter').then((module: any) => (
    { default: module.default }
)));

const ModulesRouterVisadosCliente = lazy(() => import('../modules/visados/cliente/routes/ModulesRouter').then((module: any) => (
    { default: module.default }
)));

const ModulesRouterBolsaTrabajoEmpresa = lazy(() => import('../modules/bolsa-trabajo/empresa/routes/ModulesRouter').then((module: any) => (
    { default: module.default }
)));

export const ModulesRouter = () => {

    const { auth } = useSelector((state: RootState) => state.auth)

    const isLogged = !isEmpty(auth)

    if (!isLogged) {
        eliminarCookies()
    }

    return (
        <Routes>

            <Route path='login' element={<PublicRoute isAuthenticated={isLogged} />}>

                <Route index element={process.env.REACT_APP_WITH_LOGIN === 'true' ? <Login /> : <></>} />

            </Route>

            <Route path='ticket/*' element={<Ticket />} />

            <Route path='/*' element={<PrivateRoute isAuthenticated={isLogged} isAllowedRol={true} />}>

                <Route index element={<Apps />} />

                <Route
                    path="administracion/*"
                    element={
                        <React.Suspense fallback={<LoaderLayoutMain1 />}>
                            <ModulesRouterAdministracion />
                        </React.Suspense>
                    }
                />
                <Route
                    path="facturacion/*"
                    element={
                        <React.Suspense fallback={<LoaderLayoutMain1 />}>
                            <ModulesRouterFacturacion />
                        </React.Suspense>
                    }
                />
                <Route
                    path="gestor-contenidos/*"
                    element={
                        <React.Suspense fallback={<LoaderLayoutMain1 />}>
                            <ModulesRouterGestorContenidos />
                        </React.Suspense>
                    }
                />

                <Route
                    path="alquiler-aparatos-admin/*"
                    element={
                        <React.Suspense fallback={<LoaderLayoutMain1 />}>
                            <ModulesRouterAlquilerAparatos pathRoot='/alquiler-aparatos-admin' />
                        </React.Suspense>
                    }
                />

                <Route
                    path="alquiler-aparatos/*"
                    element={
                        <React.Suspense fallback={<LoaderLayoutMain1 />}>
                            <ModulesRouterAlquilerAparatosCliente />
                        </React.Suspense>
                    }
                />

                <Route
                    path="visados-admin/*"
                    element={
                        <React.Suspense fallback={<LoaderLayoutMain1 />}>
                            <ModulesRouterVisados pathRoot='/visados-admin' />
                        </React.Suspense>
                    }
                />

                <Route
                    path="visados/*"
                    element={
                        <React.Suspense fallback={<LoaderLayoutMain1 />}>
                            <ModulesRouterVisadosCliente />
                        </React.Suspense>
                    }
                />

                <Route
                    path="inscripciones-admin/*"
                    element={
                        <React.Suspense fallback={<LoaderLayoutMain1 />}>
                            <ModulesRouterInscripciones pathRoot='/inscripciones-admin' />
                        </React.Suspense>
                    }
                />

                <Route
                    path="inscripciones/*"
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <ModulesRouterInscripcionesCliente />
                        </React.Suspense>
                    }
                />

                <Route
                    path="bolsa-trabajo-admin/*"
                    element={
                        <React.Suspense fallback={<LoaderLayoutMain1 />}>
                            <ModulesRouterBolsaTrabajo pathRoot='/bolsa-trabajo-admin' />
                        </React.Suspense>
                    }
                />

                <Route
                    path="bolsa-trabajo/*"
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <ModulesRouterBolsaTrabajoCliente />
                        </React.Suspense>
                    }
                />

                <Route
                    path="bolsa-trabajo-empresa/*"
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <ModulesRouterBolsaTrabajoEmpresa />
                        </React.Suspense>
                    }
                />

                <Route
                    path="ticketing-admin/*"
                    element={
                        <React.Suspense fallback={<LoaderLayoutMain1 />}>
                            <ModulesRouterTicketing pathRoot='/ticketing-admin' />
                        </React.Suspense>
                    }
                />

                <Route
                    path="ticketing/*"
                    element={
                        <React.Suspense fallback={<LoaderLayoutMain1 />}>
                            <ModulesRouterTicketingCliente pathRoot='/ticketing' />
                        </React.Suspense>
                    }
                />

                <Route
                    path="mis-datos/*"
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <ModulesRouterMisDatos />
                        </React.Suspense>
                    }
                />

                <Route
                    path="mis-facturas/*"
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <ModulesRouterMisFacturas />
                        </React.Suspense>
                    }
                />

                <Route
                    path="guia-profesionales/*"
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <ModulesRouterGuiaProfesionales />
                        </React.Suspense>
                    }
                />

            </Route>

            {
                process.env.REACT_APP_WITH_LOGIN === 'true' ?
                    <Route path="*" element={<Login />} />
                    :
                    <Route path="*" element={<></>} />
            }


        </Routes>
    )
}
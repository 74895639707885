import { useEffect } from 'react'
import { TableCurriculums } from "./TableCurriculums"
import { PersonaBTServices } from '../../controllers/personasbt.service';


export const Curriculums = ({ idpersona, fetchCondicionPagosRemesasGETData,
    fetchMultipleParamBolsaData }: any) => {

    const [fetchCvsGET, fetchCvsGETData, resetFetchCvsGET] = PersonaBTServices.GET_CVS()

    useEffect(() => {

        fetchCvsGET({
            params: {
                idpersona,
                limit: 50,
            }
        })

    }, []);

    return (
        <div>
            <TableCurriculums
                data={fetchCvsGETData?.data?.items || []}
                loading={fetchCvsGETData.loading}
                idpersona={idpersona}
                fetchCondicionPagosRemesasGETData={fetchCondicionPagosRemesasGETData}
                fetchMultipleParamBolsaData={fetchMultipleParamBolsaData}
                fetchCvsGET={fetchCvsGET}
            />
        </div>
    )
}

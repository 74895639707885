import { faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { ButtonSpinner } from '../../ButtonSpinner/ButtonSpinner'
import { InputText } from '../../Inputs'
import TableAdvanced from '../../Tables/TableAdvanced/TableAdvanced'
import { getValuesParams } from '../../../helpers/helpers'
import { useForm } from '../../../hooks/useForm'
import { useLang } from '../../../language'
import { NoticiasServices } from '../../../modules/gestorContenidos/pages/Noticias/services/noticias.service'

export const FilterNoticias = ({ showModal_FiltroPersonas, handleCloseModal_FiltroPersonas, setContentClipboard, params = {} }: any) => {

    const Lang = useLang()

    const [fetchPersonasGET, fetchPersonasGETData] = NoticiasServices.GET()

    const [formValuesSearch2, handleInputChangeSearch2, handleFormChange2, resetformValuesSearch2] = useForm({
        page: 1,
        limit: '',
        nombreyapelldios: '',
        ...params
    });

    const handleSearch: any = (e: any) => {

        if (e) {
            e.preventDefault();
        }

        if (formValuesSearch2.page !== 1) {

            handleFormChange2({
                ...formValuesSearch2,
                page: 1
            });

        } else {

            let formValuesSearch2Temp = { ...formValuesSearch2 }

            Object.keys(formValuesSearch2Temp).forEach((k) => formValuesSearch2Temp[k] === '' && delete formValuesSearch2Temp[k]);

            fetchPersonasGET({
                params: {
                    ...formValuesSearch2Temp
                }
            })
        }
    }

    useEffect(() => {
        if (showModal_FiltroPersonas.value) {

            // Obtener todos los valores existentes en el form de values Search
            const urlParams = getValuesParams(formValuesSearch2)

            fetchPersonasGET({
                params: {
                    ...urlParams
                }
            })

        }
    }, [formValuesSearch2.page, formValuesSearch2.order_by, formValuesSearch2.limit])

    const allActions: any = []

    const allActionsPerLine: any = [
        // {
        //     label: <FontAwesomeIcon icon={faArrowRight} className='' />,
        //     action: (item: any) => {
        //         setFilterPersona({
        //             id: item.id,
        //             nombre: item.persona
        //         })
        //         handleCloseModal_FiltroPersonas()
        //     },
        //     withoutID: true,
        // }
    ]

    const handleChangeCheckAll = (e: any) => {

        if (e.target.checked) {

            selectAllItems()

        } else {

            unselectAllItems()
        }
    }

    const headersTable = [
        '',
        <small>{Lang('TITULO')}</small>,
        <small className='d-block text-center'>{Lang('FECHA_DE_PUBLICACION')}</small>,
        <small>{Lang('PUBLICADO')}</small>,
        <small>{Lang('TIPO')}</small>,
    ]


    const dataLineTable = [
        {
            name: 'titulo'
        },
        {
            className: 'text-center',
            name: 'fecha_registro'
        },
        {
            name: 'publicado'
        },
        {
            name: 'tiponoticia',
        }
    ]

    useEffect(() => {

        if (showModal_FiltroPersonas.value) {

            const urlParams = getValuesParams(formValuesSearch2)

            fetchPersonasGET({
                params: {
                    ...urlParams
                }
            })
        }

    }, [showModal_FiltroPersonas.value])

    const [excluidos, setExcluidos] = useState([])
    const [incluidos, setIncluidos] = useState([])

    const [checkAll, setCheckAll] = useState(false)

    const selectAllItems = () => {
        setCheckAll(true)
        setIncluidos([])
        setExcluidos([])
    }

    const unselectAllItems = () => {
        setCheckAll(false)
        setIncluidos([])
        setExcluidos([])
    }

    const styleInline = `
    <style>
        .table-desktop-fixed{
            table-layout: fixed;
        }
        @media only screen and (max-width:600px) {
            .td__boletin {
                display: block;
            }
            .table-desktop-fixed{
                table-layout: initial;
            }
        }
    </style>
    `

    const copyNoticiasToClipboard = () => {

        const items = incluidos?.map((id: any) => fetchPersonasGETData?.data?.items?.filter((item2: any) => item2.id === id)?.[0] || null)
            .filter((itemFilter: any) => itemFilter)

        let res = ''

        for (let i = 0; i < items.length; i++) {
            res += `<tr><td style="padding: 12px;${i !== 0 ? 'border-top: 1px solid #ddd;' : ''}">
            <table style="height: auto!important;width: 100%!important;" class="table-desktop-fixed">
                <tr>
                ${items[i]?.imagen_small ? `
                    <td class="td__boletin" style="width: 215px!important;margin: 0 auto;">
                         <img width="200" src="${items[i]?.imagen_small}" style="padding-right: 15px;" />
                    </td>
                ` : ''}
                    <td class="td__boletin" valign="top" style="height: auto!important;">
                        <a target="_blank" rel="noopener noreferrer" style="color: #52BACF;" data-mce-href="${process.env.REACT_APP_PUBLIC_URL_PORTAL + '/actualitat.php?idnoticia=' + items[i]?.id}" href="${process.env.REACT_APP_PUBLIC_URL_PORTAL + '/actualitat.php?idnoticia=' + items[i]?.id}">${items[i]?.titulo || ''}</a>
                        <p style="margin-bottom: 0px;">${items[i]?.descr_corta}</p>
                    </td>
                </tr>
            </table>
            </td></tr>`
        }

        res = `${styleInline}<table style="border: 1px solid #ddd; width: 100%!important;"><tbody>${res}</tbody></table><br>`

        if (items.length > 0) {

            setContentClipboard(res)
            navigator.clipboard.writeText(res)
        }

        handleCloseModal_FiltroPersonas()
    }

    useEffect(() => {

        if (!showModal_FiltroPersonas.value) {
            unselectAllItems()
        }

    }, [showModal_FiltroPersonas.value]);


    return (
        <div>
            <Modal show={showModal_FiltroPersonas.value} onHide={handleCloseModal_FiltroPersonas} size='lg' id='ModalRegistro'>
                <Modal.Header className='border-bottom-0 py-4'>
                    <Modal.Title className='h6'>{Lang('SELECCIONE_UNA_NOTICIA')}</Modal.Title>
                    <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleCloseModal_FiltroPersonas}></button>
                </Modal.Header>
                <Modal.Body className='px-4 py-0'>

                    <div className="row">
                        <Form.Group className="mb-3 ms-auto col">
                            <InputText
                                label={Lang('CADENA') + ':'}
                                name='magic'
                                size='sm'
                                value={formValuesSearch2.magic}
                                onChange={handleInputChangeSearch2}
                            />
                        </Form.Group>

                        <div className="col flex-grow-0 text-nowrap mb-3">
                            <div className="d-flex align-items-end justify-content-end h-100">

                                <ButtonSpinner
                                    variant="secondary" type="submit" size='sm' className=""
                                    handleSubmit={handleSearch} fetchDataLoading={[fetchPersonasGETData.loading]}
                                    value={Lang('BUSCAR')} icon={<FontAwesomeIcon icon={faSearch} className='me-1' />}
                                />
                            </div>
                        </div>
                    </div>

                    <TableAdvanced>

                        <TableAdvanced.Head
                            title={Lang('LISTA_DE_NOTICIAS')}
                            allActions={allActions}
                        />

                        <TableAdvanced.Body
                            headers={headersTable}
                            dataLineTable={dataLineTable}
                            data={fetchPersonasGETData.data?.items}
                            isLoading={fetchPersonasGETData.loading}

                            excluidos={excluidos}
                            incluidos={incluidos}
                            setExcluidos={setExcluidos}
                            setIncluidos={setIncluidos}

                            checkAll={checkAll}
                            allActionsPerLine={allActionsPerLine}
                            checkMultiples={true}
                        />

                        <TableAdvanced.Footer
                            total={fetchPersonasGETData.data?.total}
                            handleFormChange={handleFormChange2}
                            formValuesSearch={formValuesSearch2}
                            perPage={fetchPersonasGETData.data?.per_page}
                        />

                    </TableAdvanced>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="outline-secondary" onClick={handleCloseModal_FiltroPersonas}>
                        {Lang('SALIR')}
                    </Button>
                    <Button variant="secondary" onClick={copyNoticiasToClipboard} className='ms-auto'>
                        {Lang('CONTINUAR')} <FontAwesomeIcon icon={faArrowRight} className='' />
                    </Button>
                </Modal.Footer>

            </Modal>



        </div>
    )
}

import { parseString, receiveCheck, receiveFecha, receiveFechaFormat2, sendCheck, sendMultipart } from "../../../../../../adapters/helpers"

const PersonasColegiadoBTAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idpersona || '',
                    email: item.email || '',
                    habilitarofertas: item.habilitarofertas || '',
                    idhorario: item.idhorario || '',
                    idsalario: item.idsalario || '',
                    idtipocontrato: item.idtipocontrato || '',
                    nif: item.nif || '',
                    nombrecompleto: item.nombrecompleto || '',
                    pathcv: item.pathcv || '',
                    recibirnotificacionesmail: item.recibirnotificacionesmail || '',
                    telefonomovil: item.telefonomovil || '',
                    titulocv: item.titulocv || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idpersona: item.idpersona || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    GET_EXCEL: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    headers: item.headers
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data

                const items = this.schema(dataTemp)


                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}


                const data = {
                    // filtros: item.filtros || '',
                    // lista_ids: item.lista_ids || '',
                    // lista_exclusion: item.lista_exclusion || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    GET_BETWEEN_DATES: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = item

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                return {
                    data: {
                        items: dataTemp
                    },
                    status: data?.status
                }
            }
        },
    },

    GET_CHECK_DISPONIBILIDAD: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    cupos_disponibles: item.cupos_disponibles || 0,
                    tarifa: item.tarifa || {},
                    condiciones_pago: item.condiciones_pago || [],
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                return {
                    data: dataTemp,
                    status: data?.status
                }
            }
        },
    },
}

const PersonaColegiadoBTAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idpersona || '',
                    // btpersona: item.btpersona || {},
                    habilitarofertas: receiveCheck(item.habilitarofertas, 'habilitarofertas'),
                    recibirnotificacionesmail: receiveCheck(item.recibirnotificacionesmail, 'recibirnotificacionesmail'),
                    idhorario: item.idhorario || '',
                    idtipocontrato: item.idtipocontrato || '',
                    idsalario: item.idsalario || '',
                    // email: item.email || '',
                    // estudios: item.estudios || '',
                    // fechastatuspersona: item.fechastatuspersona || '',
                    funciones: item.funciones || '',
                    idiomas: item.idiomas || '',
                    nombrecompleto: item.nombrecompleto || '',
                    estudios: item.estudios || []
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp?.[0])

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },

    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    habilitarofertas: sendCheck(item.habilitarofertas),
                    recibirnotificacionesmail: sendCheck(item.recibirnotificacionesmail),
                    idhorario: item.idhorario || '',
                    idtipocontrato: item.idtipocontrato || '',
                    idsalario: item.idsalario || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH_IDIOMAS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idiomas: item.idiomas || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH_ESTUDIOS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    tiposestudios: item.tiposestudios || [],
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH_FUNCIONES: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    funciones: item.funciones || [],
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

}

export {
    PersonasColegiadoBTAdapters,
    PersonaColegiadoBTAdapters
}
import { clearFieldsEmpty_adjunto, receiveCheck, receiveFecha, receiveFechaFormat2, sendCheck, sendMultipart } from "../../../../../../../../adapters/helpers"

const InscripcionesAdminAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idpersona || '',
                    email: item.email || '',
                    label__fechaestado: receiveFechaFormat2(receiveFecha(item.fechaestado)),
                    fechaestado: item.fechaestado || '',
                    idcomunicaciondestinatario: item.idcomunicaciondestinatario || '',
                    idcv: item.idcv || '',
                    idestadopersona: item.idestadopersona || '',
                    idofertaempleo: item.idofertaempleo || '',
                    interes: receiveCheck(item.interes, 'interes'),
                    notificarempresa: receiveCheck(item.notificarempresa, 'notificarempresa'),
                    preferido: receiveCheck(item.preferido, 'preferido'),
                    nif: item.nif || '',
                    nombrecompleto: item.nombrecompleto || '',
                    nombreoferta: item.nombreoferta || '',
                    pathcv: item.pathcv || '',
                    telefonomovil: item.telefonomovil || '',
                    observacion_admin: item.observacion_admin || '',
                    boolean__interes: item.interes || false,
                    boolean__notificarempresa: item.notificarempresa || false,
                    boolean__notificado: item.idcomunicaciondestinatario ? true : false,
                    idoferta_persona: item.idoferta_persona || '',
                    recibirnotificacionesmail: item.recibirnotificacionesmail || false,
                    habilitarofertas: item.habilitarofertas || false,
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data



                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                // const items = this.schema(dataTemp)

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },

    GET_EXCEL: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    headers: item.headers
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data

                const items = this.schema(dataTemp)


                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)


                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    datos: item.datos || [],
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)


                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idpersona: item.idpersona || '',
                    idestadopersona: item.idestadopersona || '',
                    notificarempresa: sendCheck(item.notificarempresa),
                    observacion_admin: item.observacion_admin || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return sendMultipart(items)
            }
        },
    },

    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)


                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },

    POST_ENVIAR_EMAILS_MASIVOS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    comunicacion: item.comunicacion || '',
                    asunto: item.asunto || '',
                    tipo: item.tipo || '',
                    idtiponotificacion: item.idtiponotificacion || '',
                    // idweb: item.idweb || '',
                    remitentenombre: item.remitentenombre || '',
                    remitenteemail: item.remitenteemail || '',
                    filtros: item.filtros || {},
                    lista_ids: item.lista_ids || {},
                    lista_exclusion: item.lista_exclusion || {},
                    adjunto: item.adjunto || '',
                }

                return clearFieldsEmpty_adjunto(data)

            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    POST_ENVIAR_EMAILS_MASIVOS_ENCARGADO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    comunicacion: item.comunicacion || '',
                    asunto: item.asunto || '',
                    tipo: item.tipo || '',
                    idtiponotificacion: item.idtiponotificacion || '',
                    // idweb: item.idweb || '',
                    remitentenombre: item.remitentenombre || '',
                    remitenteemail: item.remitenteemail || '',
                    adjunto: item.adjunto || '',
                }

                return clearFieldsEmpty_adjunto(data)

            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },
}

const InscripcionAdminAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idinscripcion || '',
                    idpersona: item.idpersona || '',
                    abona: item.abona || '',
                    idevento: item.idevento || '',
                    idpersonaentidad: item.idpersonaentidad || '',
                    idcondicionpago: item.condicion_pago?.idcondicionpago || '',
                    ideventotarifa: item.ideventotarifa || '',
                    observaciones: item.observaciones || '',
                    facturas_lineas: item.facturas_lineas || [],
                    nombre_completo: item.persona?.nombre_completo || '',
                    estado: item.estado || '',
                    facturar: receiveCheck(item.facturar, 'facturar'),
                    comunicar: receiveCheck(item.comunicar, 'comunicar'),
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                // const items = dataTemp?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)


                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idpersona: item.idpersona || '',
                    idevento: item.idevento || '',
                    idpersonaentidad: item.idpersonaentidad || '',
                    idcondicionpago: item.idcondicionpago || '',
                    ideventotarifa: item.ideventotarifa || '',
                    observaciones: item.observaciones || '',
                    facturar: sendCheck(item.facturar),
                    comunicar: sendCheck(item.comunicar),
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)


                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idpersona: item.idpersona || '',
                    idevento: item.idevento || '',
                    idpersonaentidad: item.idpersonaentidad || '',
                    idcondicionpago: item.idcondicionpago || '',
                    ideventotarifa: item.ideventotarifa || '',
                    observaciones: item.observaciones || '',
                    estado: item.estado || '',
                    facturar: sendCheck(item.facturar),
                    comunicar: sendCheck(item.comunicar),
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },
}

export {
    InscripcionesAdminAdapters,
    InscripcionAdminAdapters,
}
import { useAxios } from "../../../../../../../../hooks/useAxios"
import { FuncionAdapters, FuncionesAdapters } from "./funciones.adapter"

const FuncionesServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/admin/funciones`, type: 'json', adapters: FuncionesAdapters.GET })
    }
}

const FuncionServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/`, type: 'json', adapters: FuncionAdapters.GET })
    },

    POST: function () {

        return useAxios({ method: 'post', url: `/bolsadetrabajo/admin/funciones`, type: 'json', adapters: FuncionAdapters.POST })
    },

    PATCH: function (id: string) {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/admin/funciones/${id}`, type: 'json', adapters: FuncionAdapters.PATCH })
    },

    DELETE: function (id: string) {

        return useAxios({ method: 'delete', url: `/bolsadetrabajo/admin/funciones/${id}`, type: 'json', adapters: FuncionAdapters.DELETE })
    }
}

export {
    FuncionesServices,
    FuncionServices
}
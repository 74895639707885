import { Route, Routes } from 'react-router-dom';
import Index from '../pages/Index/Index';
import { useLang } from '../../../../language';
import LayoutMain2 from '../../../../components/Layouts/LayoutMain2/LayoutMain2';

export default function ModulesRouterGuiaProfesionales() {

    const Lang = useLang()

    return (
        <section className='LayoutMain animate__animated animate__fadeIn'>

            <LayoutMain2 label={Lang('GUIA_DE_PROFESIONALES')}>

                <LayoutMain2.NavTop/>

                <LayoutMain2.Body>

                    <Routes>

                        <Route
                            index
                            element={<Index />}
                        />
                    </Routes>

                </LayoutMain2.Body>

            </LayoutMain2>

        </section>
    )
}
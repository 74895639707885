import { Navigate, Outlet } from "react-router";

interface IPrivateRoute{
    isAuthenticated: boolean,
    isAllowedRol: boolean
}

export const PrivateRoute = ({ isAuthenticated, isAllowedRol }: IPrivateRoute) => {

    return (isAuthenticated && isAllowedRol) ? <Outlet /> : <Navigate to="/login" replace />
}

import { useAxios } from "../../../../../../hooks/useAxios"
import { CVAdapters, CVsAdapters } from "./cv.adapter"

const CVsServices = {

    GET_ALL: function () {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/curriculums-currentuser`, 
        type: 'json', adapters: CVsAdapters.GET_ALL })
    },

    GET: function () {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/curriculums-currentuser`, 
        type: 'json', adapters: CVsAdapters.GET })
    },

}

const CVServices = {

    POST: function () {

        return useAxios({ method: 'POST', url: `/bolsadetrabajo/curriculums-currentuser`, 
        type: 'json', adapters: CVAdapters.POST })
    },

    
    PATCH: function () {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/curriculums-currentuser`, 
        type: 'json', adapters: CVAdapters.PATCH })
    },

    DELETE: function () {

        return useAxios({ method: 'DELETE', url: `/bolsadetrabajo/curriculums-currentuser`, 
        type: 'json', adapters: CVAdapters.DELETE })
    }

}

export {
    CVsServices,
    CVServices
}
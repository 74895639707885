import { Placeholder } from "../Placeholder/Placeholder"

export const LoaderFormPrimary = () => {
    return (
        <div className="">

            <div className='row'>
                <div className="mb-3 col-12 col-sm-6 col-md-3">
                    <Placeholder className='py-2' height='2rem' />
                </div>
                <div className="mb-3 col-12 col-sm-6 col-md-3">
                    <Placeholder className='py-2' height='2rem' />
                </div>
                <div className="mb-3 col-12 col-sm-6 col-md-3">
                    <Placeholder className='py-2' height='2rem' />
                </div>
                <div className="mb-3 col-12 col-sm-6 col-md-3">
                    <Placeholder className='py-2' height='2rem' />
                </div>
                <div className="mb-3 col-12">
                    <Placeholder className='py-2' height='20rem' />
                </div>
            </div>
        </div>
    )
}
